import React,{useEffect,useState} from 'react';
import { Fragment } from 'react';
import { useSelector } from "react-redux";
import BoLoader from '../../helpers/BoLoader';
import clientmanagmentObj from '../../services/client-managment.service';
const SettingRoles = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [Roles, setRoles] = useState([]);
    const [userId, setuserId] = useState(currentUser.user.UserId);
    //Loader
    const [boloader, setBoLoader] = useState(false);

    useEffect(() => {
        setBoLoader(true);
        clientmanagmentObj.getSettingRolesClient(userId).then((response) => {
            const roles = response.data.payload.result.map((data) => {
                setBoLoader(false);
                return {
                    value: data.claimType,
                    label: data.claimValue,
                };
            });
            setRoles(roles);
            
        }).catch((err) => {
            setBoLoader(false);
           
        });

    }, []);
    return (
        <Fragment>
            <div className="">
                <h3>Roles</h3>
                <BoLoader loading={boloader} />
                <ul className="ul-settings settings-role mt-32">
                    {/* <li>
                        <div>
                            <label>Role:</label>
                            <span>Virtual</span>
                        </div>
                    </li> */}
                    <li>
                        <div>
                            <label>Roles:</label>
                            <ul className="ul-settings-sub">
                            {Roles.map((Roles) => {
                                            return (
                                                <li>{Roles.label} </li>
                                            );
                                        })}
                            </ul>
                        </div>
                    </li>
                </ul>
            </div>
        </Fragment>

    );
}

export default SettingRoles