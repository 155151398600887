import ClientService from "../services/client-managment.service";
import { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
// import RefreshToken from "./RefreshToken";

const GetAllClients = (props) => {
  //const [recallMthd, setMthdRcl] = useState(false);

  useEffect(() => {
    getClientsDropDown();
  }, []);
  const [clientName, setClientName] = useState();
  const [allClientsData, setAllClientsData] = useState();
  const { user: currentUser } = useSelector((state) => state.auth);

  const getClientsDropDown = () => {
    ClientService.getAllClients()
      .then((response) => {
        if (response.data.payload.succeeded) {
          let data = response.data.payload.result;
          //data.shift();
          setAllClientsData(data);
          const clientNameVal = data.map((options) => {
            let val = "";
            if (options.id === currentUser.user.ClientId) {
              val = options.clientName;
              return val;
            } else return "";
          });

          const finalVal = clientNameVal.filter((x) => {
            return x !== "";
          });
          setClientName(finalVal);
        }
      })
      .catch((err) => {
        // if (err && err.response && err.response.status === 401) {
        //   (async () => {
        //     let resp = await RefreshToken();
        //     if (resp) setMthdRcl(true);
        //   })();
        // }
      });
  };
  return (
    <Fragment>
      {allClientsData && (
        <Fragment>
          {currentUser?.role.includes("Super admin") && (
            <select
              id="clientNameDD"
              className="form-control no-icon"
              onChange={props.clientnamechangehandler}
            >
              {props.searchgriddata === "Y" && (
                <option value="">Select Client Ledger</option>
              )}
              {allClientsData.map((options) => (
                <option key={options.uuid} currency={options.currency} value={options.id} paymentProcessor={options.paymentProcessor} 
                cardFlow={options.cardFlow} issuerCategory={options.issuerCategory} cardVisaMaster={options?.cardVisaMaster}>
                  {options.clientName} 
                </option>
              ))}
            </select>
          )}
          {currentUser?.role.includes("Client admin") && (
            <input
              type="text"
              value={clientName}
              disabled="disabled"
              className="form-controls"
            />
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default GetAllClients;
