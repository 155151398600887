import React, { useEffect, useState, useRef, Fragment } from "react";
import cardHolderService from "../../services/cardholder.service";
import DateFormat from "../../helpers/DateFormat";
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import DebitCard from "../cardholders/DebitCard";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import { Link } from "react-router-dom";
import GetClientsLoadDebit from "../../helpers/GetClientsLoadDebit";
import Form from "react-validation/build/form";
import cardholdercardsObj from "../../services/cards.services";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import ExportToExcel from "../superadmin/ExportToExcel";
import DialoguePopup from "../../helpers/DialoguePopup";
import ReactFlagsSelect from "react-flags-select";
import { TrimCharacters } from "../../helpers/TrimCharacters";
import CurrencyFormat from 'react-currency-format';
import { isEmail } from "validator";
import SearchGridAccountTransactions from "./SearchGridAccountTransactions";
import AddBenificiary from "./AddBenificiary";
import TransferIban from "./TransferIban";
const Emailrequired = value => {
  if (!value) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Email is required!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};


const validEmail = value => {
  if (!isEmail(value)) {
    document.getElementById("Email-frmgrp").classList.add("has_error");
    return <span className="help-block">Invalid Email!</span>;
  } else document.getElementById("Email-frmgrp").classList.remove("has_error");
};

let selectBoxVal = "";
const AccountHolderDetails = props => {
  const descriptionRequired = value => {
    if (!value) {
      document.getElementById("id_desc").classList.add("has_error");
      return <span className="help-block">Description is required!</span>;
    } else document.getElementById("id_desc").classList.remove("has_error");
  };
  const [cardHolderDetail, setCardHolderDetail] = useState();
  const [cardHolderDetailSuccessful, setcardHolderDetailSuccessful] =
    useState(false);
  const [active, setactiveStatus] = useState(true);
  const [statusChangeConfirmBox, setStatusChangeConfirmBox] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [userId, setUserId] = useState(props?.location?.state?.userId);
  const [cardDetails, setCardDetails] = useState();
  const [modalShowLoadCard, setmodalShowLoadCard] = useState(false);
  const [modalShowDebitCard, setmodalShowDebitCard] = useState(false);
  const [modalShowKyc, setmodalShowKyc] = useState(false);
  const [TokenId, setTokenId] = useState();
  const [RefereshData, setRefereshData] = useState();
  const { user: currentUser } = useSelector(state => state.auth);
  //Suspend Unsuspend Card
  const [modaldeactivateshow, setModaldeactivateshow] = useState(false);
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [Status, setStatus] = useState("Unsuspended");
  const [closedprop, setClosedprop] = useState(props?.location?.state?.cardStatus);
  const [showClientErr, setShowClientErr] = useState("d-none");
  const [Description, setDescription] = useState();
  const [ClientId, setClientId] = useState();
  const form = useRef();
  const checkBtn = useRef();
  //Closedcard
  const [BlockAction, setBlockAction] = useState("");
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [dialougemodalShow, setDialougemodalShow] = useState(false);
  const [dataset, setDataset] = useState();
  //Edit Cardholder Details
  const [modalShowEditCard, setModalShowEditCard] = useState(false);
  const [FirstName, setFirstName] = useState();
  const [LastName, setLastName] = useState();
  const [Address, setAddress] = useState();
  const [City, setCity] = useState();
  const [Country, setCountry] = useState();
  const [ZipCode, setZipCode] = useState();
  const [FirstNameErr, setFirstNameErr] = useState("d-none");
  const [LastNameErr, setLastNameErr] = useState("d-none");
  const [AddressErr, setAddressErr] = useState("d-none");
  const [EmailEdit, setEmailEdit] = useState("");
  //Transactions types
  const [AllTransactionClassName, setAllTransactionClassName] = useState("nav-link active");
  const [AllTransactionAreaSelected, setAllTransactionAreaSelected] = useState("true");
  const [SendMoneyClassName, setSendMoneyClassName] = useState("nav-link");
  const [SendMoneyAreaSelected, setSendMoneyAreaSelected] = useState("false");

  // Change Pin 
  const [newpin, setnewpin] = useState("");
  const [confirmpin, setconfirmpin] = useState("");

  const [modalChnagePin, setmodalChnagePin] = useState(false);
  const [modalerrorShow, setModalerrorShow] = useState(false);
  const [modalrefillamountshow, setModalrefillamountshow] = useState(false);
  const [account_identifications, setAccount_identifications] = useState();
  // Account Transfer
  const [modalShowAccountTransfer, setModalShowAccountTransfer] = useState(false);
  // Benificiary Transfer
  const [modalShowBenificiary, setModalShowBenificiary] = useState(false);
  const onHideModal = () => {
    setnewpin("");
    setconfirmpin("");
  };



  let passwordValue = "";
  const newRequired = (value) => {
    passwordValue = value;
    if (!value) {
      document.getElementById("id_new_pass").classList.add("has_error");
      return <span className="help-block">New Pin is Required</span>;
    } else document.getElementById("id_new_pass").classList.remove("has_error");
  };
  const confirmRequired = (value) => {
    if (!value) {
      document.getElementById("id_confirm_pass").classList.add("has_error");
      return <span className="help-block">Confirm Pin is Required</span>;
    } else
      document.getElementById("id_confirm_pass").classList.remove("has_error");
  };

  const vpassword = (value) => {
    if (value !== passwordValue) {
      document.getElementById("id_confirm_pass").classList.add("has_error");
      return (
        <span className="help-block">
          The password and confirm pin must match
        </span>
      );
    } else
      document.getElementById("id_confirm_pass").classList.remove("has_error");
  };

  const onChangeNewPin = (e) => {
    const value = e.target.value;

    setnewpin(value);
  };
  const onChangeConfirmPin = (e) => {
    const value = e.target.value;
    setconfirmpin(value);

  };

  const requiredFirstName = value => {
    if (!value) {
      document.getElementById("edit_firstname").classList.add("has_error");
      setFirstNameErr("");
      return false;
    } else {
      document
        .getElementById("edit_firstname")
        .classList.remove("has_error");
      setFirstNameErr("d-none");
      return true;
    }
  };

  const requiredLastName = value => {
    if (!value) {
      document.getElementById("edit_lastname").classList.add("has_error");
      setLastNameErr("");
      return false;
    } else {
      document
        .getElementById("edit_lastname")
        .classList.remove("has_error");
      setLastNameErr("d-none");
      return true;
    }
  }; const requiredAddress = value => {
    if (!value) {
      document.getElementById("edit_address").classList.add("has_error");
      setAddressErr("");
      return false;
    } else {
      document
        .getElementById("edit_address")
        .classList.remove("has_error");
      setAddressErr("d-none");
      return true;
    }
  };

  if (currentUser) {
    haspermissions.LoadCard =
      currentUser?.user?.Permissions?.includes("Load Card");
    haspermissions.DebitCard =
      currentUser?.user?.Permissions?.includes("Debit Card");
    haspermissions.UpdateCardholderInfo =
      currentUser?.user?.Permissions?.includes("Update Cardholder Info");
    haspermissions.SuspendCard = currentUser?.user?.Permissions?.includes("Suspend Card");

  }

  useEffect(() => {
    setBoLoader(true);
    setcardHolderDetailSuccessful(false);
    callHolderServiceCall();
    setcardHolderDetailSuccessful(false);
  }, [props?.location?.state]);
  const callHolderServiceCall = async () => {
    await cardHolderService
      .getibandetail(props?.location?.state?.id)
      .then(res => {
        if (res && res.data && res.data.success) {
          if (res && res.data && res.data.payload) {
            setCardHolderDetail(res.data.payload?.item1);
            if(res.data.payload?.item1?.data?.account_identifications?.length>0)
            setAccount_identifications(res.data.payload?.item1?.data?.account_identifications);
          else{
            setAccount_identifications([
              {
                  "identification_type": "IBAN",
                  "identification_value": "GB24LHVB04032900785105",
                  "identification_category": "GLOBAL"
              },
              {
                  "identification_type": "BIC",
                  "identification_value": "LHVBGB2LXXX",
                  "identification_category": "GLOBAL"
              },
              {
                  "identification_type": "SORT_CODE",
                  "identification_value": "040329",
                  "identification_category": "LOCAL"
              },
              {
                  "identification_type": "ACCOUNT_NUMBER",
                  "identification_value": "00785105",
                  "identification_category": "LOCAL"
              }
          ]);
          }
            setcardHolderDetailSuccessful(true);

          } else {
            setcardHolderDetailSuccessful(false);
          }
        }
        setBoLoader(false);
      })
      .catch(err => {
        setBoLoader(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
        setcardHolderDetailSuccessful(false);
      });
  };


  const loadCardPopupHandler = () => {
    setmodalShowLoadCard(true);
  };

  const onChangeDescription = e => {
    const desc = e.target.value;
    if (desc != "") {
      setDescription(desc);
    }
  };
  const requiredClient = value => {
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };
  const onSuspendCard = (e) => {
    if (e) e.preventDefault();
    let OperatorUserId = currentUser?.user?.UserId;
    let UserIdCard = userId
    let IsBlock = true;
    let CardCurrency = cardDetails?.cardFeeCurrencyCode;
    let client = requiredClient(ClientId);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0 && client) {

      setBoLoader(true);
      cardHolderService
        .suspendCard(TokenId, OperatorUserId, UserIdCard, IsBlock, ClientId, Description, BlockAction, CardCurrency)
        .then((res) => {
          setBoLoader(false);
          if (res && res.data && res.data.payload) {
            onShowModelClearFields();
            setModalsuccessfullShow(true);
          }
        })
        .catch((error) => {
          setBoLoader(false);
          setModaldeactivateshow(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message

            : "Error Occured";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougemodalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onUnsuspendCard = (e) => {
    if (e) e.preventDefault();
    let OperatorUserId = currentUser?.user?.UserId;
    let UserIdCard = userId
    let IsBlock = false;
    let CardCurrency = cardDetails?.cardFeeCurrencyCode;
    let client = requiredClient(ClientId);
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0 && client) {
      setBoLoader(true);
      cardHolderService
        .unSuspendCard(TokenId, OperatorUserId, UserIdCard, IsBlock, ClientId, Description, CardCurrency)
        .then((res) => {
          setBoLoader(false);
          if (res && res.data && res.data.payload) {
            onShowModelClearFields();

            setModalsuccessfullShow(true);
          }
        })
        .catch((error) => {
          setBoLoader(false);
          setModaldeactivateshow(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message

            : "Error Occured";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };

  const ClientNameChangeHandler = e => {
    const clientName = e.target.selectedOptions[0].value;
    if (clientName) {
      let clientid = parseInt(clientName);
      setClientId(clientid);
      requiredClient(clientid);
    }
  };
  const onShowModelClearFields = () => {
    setModaldeactivateshow(false);
    setDescription("");
    setClientId();
    setShowClientErr("d-none");
  };


  const onEditCardDetails = () => {
    let chckfirstname = requiredFirstName(FirstName);
    let chcklastname = requiredLastName(LastName);
    let chckaddress = requiredAddress(Address);
    if (chckfirstname && chcklastname && chckaddress) {
      setBoLoader(true);
      cardHolderService.onEditCard(FirstName, LastName, Address, City, Country, ZipCode, userId, EmailEdit).then((response) => {
        setBoLoader(false);
        if (response)
          setModalShowEditCard(false);
        let msgBody = response.data.message
        let errIcon = "icon-success";
        errorAndSuccessModl("Success", msgBody, errIcon);
      }).catch((error) => {
        setBoLoader(false);
        let msgBody = error?.response?.data?.message
          ? error?.response?.data?.message

          : "Error Occured";
        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
      });
    }

  }
  const onChangeFirstName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setFirstName(value);
    requiredFirstName(value)
  };
  const onChangeLastName = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setLastName(value);
    requiredLastName(value);
  };
  const onChangeAddress = e => {
    const name = e.target.value;
    setAddress(name);
    requiredAddress(name);
  };
  const onChangeCity = e => {
    let value = e.target.value;
    value = value.replace(/[^A-Za-z ]/ig, '')
    setCity(value);
  };

  const onChangeZipCode = e => {
    let value = e.target.value;
    value = value.replace(/[^a-z0-9 ]/gi, '')
    setZipCode(value);
  };

  //Validations Dropdown End
  const onChangeEmail = e => {
    const email = e.target.value;
    setEmailEdit(email);
  };
  const refereshPage = () => {
    setBoLoader(true);
    callHolderServiceCall();
  }
  const onCancelEditCards = () => {
    setFirstName(cardHolderDetail?.firstName);
    setLastName(cardHolderDetail?.lastName);
    setAddress(cardHolderDetail?.address);
    setCity(cardHolderDetail?.city);
    setCountry(cardHolderDetail?.country);
    setZipCode(cardHolderDetail?.zipCode);
    setEmailEdit(cardHolderDetail?.email)
    setLastNameErr("d-none");
    setFirstNameErr("d-none");
    setAddressErr("d-none");
  }

  const transactionTab = () => {
    setAllTransactionClassName("nav-link active d-flex align-items-center");
    setAllTransactionAreaSelected("true");
    setSendMoneyClassName("nav-link d-flex align-items-center");
    setSendMoneyAreaSelected("false");
  }
  const sendMoneyTab = () => {
    setSendMoneyClassName("nav-link d-flex active align-items-center");
    setSendMoneyAreaSelected("true");
    setAllTransactionClassName("nav-link  d-flex align-items-center");
    setAllTransactionAreaSelected("false");

  }

  const handleRegister = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true)
      cardholdercardsObj
        .changeCardHolderPin(newpin,
          TokenId,
          cardHolderDetail?.userId)
        .then((response) => {
          setBoLoader(false);
          if (response) {
            setnewpin("");
            setconfirmpin("");
            setmodalChnagePin(false);
          }
          let msgBody = "Change Pin Successfully"
          let errIcon = "icon-success";
          errorAndSuccessModl("Success", msgBody, errIcon);
        }).catch((error) => {
          setBoLoader(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message

            : "Error Occured";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };
  const refillMainWallet = () => {
    setBoLoader(true);
    cardholdercardsObj.refillbalance(props?.location?.state?.userId).then((resp) => {
      if (resp) {
        setBoLoader(false);
        let msgBody = resp?.response?.data?.message
          ? resp?.response?.data?.message
          : "Account Refilled SuccessFully";
        let errIcon = "icon-success";
        errorAndSuccessModl("Success", msgBody, errIcon);
      }


    }).catch((error) => {
      setBoLoader(false);
      let msgBody = error?.response?.data?.message
        ? error?.response?.data?.message
        : "Error Occured";
      let errIcon = "icon-error";
      errorAndSuccessModl("Error", msgBody, errIcon);
    });
  }
  const onHideRefillPopUpHandler = () => {
    setModalrefillamountshow(false)
  }

  const accountIdentification = () => {
    return account_identifications.map((data, index) => {
      const { identification_type, identification_value } = data; // Destructuring
      const formattedIdentificationType = identification_type.replace(/_/g, ' '); // Replace underscores with spaces //destructuring
      return (
      
        <div className="mb-20">
          <p>{formattedIdentificationType}</p>

          <h5>{identification_value}</h5>
        </div>
     );

    })
  }
  const TransferBenificaryPopupHandler=()=>{
    setModalShowBenificiary(true);
  }
  const TransferAccountPopupHandler=()=>{
    setModalShowAccountTransfer(true);
  }
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      <BoLoader loading={boloader} />
      {cardHolderDetailSuccessful ? (
        <Fragment>
          <div className="row align-items-center">
            <div className="col-lg-2 mb-24">
              <h3>Account Detail</h3>
            </div>
            <div className="col-lg-10 mb-24">
              <div className="form-group submit-field d-md-flex justify-content-end btns-four">

                <button
                  className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0 res-custom-btn"
                  onClick={loadCardPopupHandler}
                >
                  Add Benificary
                </button>
                <button
                  className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0 res-custom-btn"
                  onClick={TransferAccountPopupHandler}
                >
                   Account Transfer
                </button>
                <button
                  className="btn btn-large btn-extra-large mr-19 mb-3 mb-md-0 res-custom-btn"
                  onClick={TransferBenificaryPopupHandler}
                >
                  Benificary Transfer
                </button>

              </div>
            </div>

          </div>

          <div className="row-card-holder-details mb-40">
            <div className="row flex-column-reverse flex-sm-row">
              <div className="col-md-12 col-sm-8">
                <ul className="ul-card-details">
                  <li>
                    <div className="mb-20">
                      <p>Company Name</p>
                      <h5>{cardHolderDetail?.firstName}</h5>
                    </div>

                    <div className="mb-20">
                      <p>Email</p>
                      <h5 className="text-break"> {cardHolderDetail?.email}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Currency</p>
                      <h5>
                        {cardHolderDetail?.data?.currency !== ""
                          ? cardHolderDetail?.data?.currency
                          : "$"}
                      </h5>
                    </div>
                    <div className="mb-20">
                      <p>Date of Birth</p>
                      <h5>
                        <DateFormat date={cardHolderDetail?.dateOfBirth} />
                      </h5>
                    </div>
                  </li>
                  <li>
                    <div className="mb-20">
                      <p>Account Title</p>
                      <h5> {cardHolderDetail?.data?.name}</h5>
                    </div>
                    <div className="mb-20">
                      <p>City</p>
                      <h5> {cardHolderDetail?.deliveryCity}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Country</p>
                      <h5> {cardHolderDetail?.deliveryCountry}</h5>
                    </div>
                    <div className="mb-20">
                      <p>Zip Code</p>
                      <h5> {cardHolderDetail?.deliveryZipCode}</h5>
                    </div>
                  </li>
                  <li>
                    {/* <div className="mb-20">
                      <p>Type</p>
                      <h5>{cardHolderDetail?.data?.type}</h5>
                    </div>
                   */}
                    <div className="mb-20">
                      <p>Available Balance</p>
                      <h5> <CurrencyFormat value={cardHolderDetail?.data?.available_balance} displayType={'text'} thousandSeparator={true} /></h5>
                    </div>
                    <div className="mb-20">
                      <p> Blocked Balance</p>
                      <h5> <CurrencyFormat value={cardHolderDetail?.data?.blocked_balance} displayType={'text'} thousandSeparator={true} /></h5>
                    </div>
                    <div className="mb-20">
                      <p>Address</p>
                      <h5> {cardHolderDetail?.deliveryAddress1}</h5>
                    </div>
                  </li>
                  <li>
                  {accountIdentification()}
                  </li>
                </ul>
              </div>
              <div className="col-md-3 col-sm-4">
                <div className="row">
                  {
                    props?.location?.state?.issuerCategory === "IssuerB4B" && (
                      <div className="col-6 col-sm-12 mt-16">
                        <div>
                          <button
                            className="btn btn-large btn-extra-large float-end btn-edit"
                            onClick={() => setModalrefillamountshow(true)}
                            disabled={cardHolderDetail?.pendingBalance == 0}
                          >
                            Top Up Balance
                          </button>
                        </div>
                      </div>
                    )
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="row align-items-center">
            <div className="col-sm-12">
              <div className="bo-tabs-mbl">
                <ul className="nav nav-tabs" id="myTab2" role="tablist">
                  <li className="nav-item" role="presentation">
                    <button
                      className={AllTransactionClassName}
                      id="allClients-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#allClients"
                      type="button"
                      role="tab"
                      onClick={transactionTab}
                      aria-controls="allClients"
                      aria-selected={AllTransactionAreaSelected}
                    >
                      <span ></span>IBAN Tranasactions
                    </button>

                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className={SendMoneyClassName}
                      id="newClients-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#newClients"
                      type="button"
                      role="tab"
                      aria-controls="newClients"
                      onClick={sendMoneyTab}
                      aria-selected={SendMoneyAreaSelected}
                    >
                      <span ></span>Benificiary List
                    </button>
                  </li>
                </ul>
              </div>
            </div>

          </div>
          {
            AllTransactionAreaSelected === "true" && (
              <SearchGridAccountTransactions
                placeholdervalue="Search Card Holder Transactions"
                headersdata={[
                  "ICON",
                  "TRANSACTION TYPE",
                  "CURRENCY",
                  "AMOUNT",
                  "DATE",
                  "STATUS",
                ]}
                screenname="cardholdertransactions"
                UserId={props?.location?.state?.id}
                issuerCategory={props?.location?.state?.issuerCategory}
                setDataset={setDataset}
                calledfrom="accounttransaction"
              />
            )
          }
          {
            SendMoneyAreaSelected === "true" && (
              <SearchGridAccountTransactions
                placeholdervalue="Search Card Holder Transactions"
                headersdata={[
                  "FIRST NAME",
                  "LAST NAME",
                  "ACCOUNT ID",
                  "CURRENCY",
                  "ACCOUNT CODE",
                  "ACCOUNT NUMBER",
                  "TYPE",
                ]}
                screenname="cardholdertransactions"
                UserId={props?.location?.state?.id}
                issuerCategory={props?.location?.state?.issuerCategory}
                setDataset={setDataset}
                calledfrom="benificiarylist"
              />
            )
          }

          <AddBenificiary
            show={modalShowLoadCard}
            onHide={() => setmodalShowLoadCard(false)}
            calledfrom="cardholderdetails"
            AccountId={props?.location?.state?.id}
          />
           <TransferIban
            show={modalShowAccountTransfer}
            onHide={() => setModalShowAccountTransfer(false)}
            calledfrom="accounttransfer"
            AccountId={props?.location?.state?.id}
          />
           <TransferIban
            show={modalShowBenificiary}
            onHide={() => setModalShowBenificiary(false)}
            calledfrom="benificary"
            AccountId={props?.location?.state?.id}
          />

          {TokenId && (
            <DebitCard
              show={modalShowDebitCard}
              onHide={() => setmodalShowDebitCard(false)}
              calledfrom="cardholderdetails"
              TokenID={TokenId}
              CurrencyCode={cardDetails?.cardFeeCurrencyCode}
              Balance={cardHolderDetail?.availableBalance}
              paymentProcessor={cardDetails?.paymentProcessor}
              clientId={cardHolderDetail?.clientId}
              issuerCategory={cardHolderDetail?.issuerCategory}
              userId={userId}
              companyname={cardHolderDetail?.companyName}
            />
          )}

          <ModelPopup
            show={modalShowKyc}
            key={12}
            onHide={() => setmodalShowKyc(false)}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            modelbody={[
              <Fragment>
                <form onSubmit={e => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <h2>KYC Card Detail</h2>
                      <ul className="ul-card-details-popup mt-16 ul-issue-card">
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Name</label>
                            </div>
                            <div className="col-8">
                              <span>{cardHolderDetail?.cardHolderName}</span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Token ID</label>
                            </div>
                            <div className="col-8">
                              <span>{cardDetails?.tokenId}</span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Company Name</label>
                            </div>
                            <div className="col-8">
                              <span>{cardHolderDetail?.companyName}</span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-16">
                          <div className="row">
                            <div className="col-4">
                              <label>Card Expiry Date</label>
                            </div>
                            <div className="col-8">
                              <span>
                                <DateFormat date={cardDetails?.expiryDate} />
                              </span>
                            </div>
                          </div>
                        </li>
                        <li className="mb-32">
                          <div className="row">
                            <div className="col-4">
                              <label>Status</label>
                            </div>
                            <div className="col-8">

                              <span>Approved</span>
                            </div>
                          </div>
                        </li>
                        {
                          cardHolderDetail?.idType && (
                            <Fragment>
                              <li className="mb-24">
                                <div className="row">
                                  <div className="col-4">
                                    <label>ID Type</label>
                                  </div>
                                  <div className="col-8">
                                    <span>{cardHolderDetail?.idType}</span>
                                  </div>
                                </div>
                              </li>
                              <li className="mb-40">
                                <div className="text-center id-doc">
                                  <a
                                    href={cardHolderDetail?.filePathIdType}
                                    id="downloadidtype"
                                  >
                                    <img
                                      src={cardHolderDetail?.filePathIdType}
                                      alt="id document"
                                    />
                                  </a>
                                  <div>
                                    <a
                                      href={cardHolderDetail?.filePathIdType}
                                      style={{ textDecoration: `underline`, color: `blue` }}>
                                      Download image
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </Fragment>)
                        }
                        {
                          cardHolderDetail?.addressProof && (
                            <Fragment>
                              <li className="mb-24">
                                <div className="row">
                                  <div className="col-4">
                                    <label>Proof of Address</label>
                                  </div>
                                  <div className="col-8">
                                    <span>{cardHolderDetail?.addressProof}</span>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="text-center add-paper">
                                  <a
                                    href={cardHolderDetail?.filePathAddressProof}
                                    id="downloadaddrproof"
                                  >
                                    <img
                                      src={cardHolderDetail?.filePathAddressProof}
                                      alt="house dodument"
                                    />
                                  </a>
                                  <div>
                                    <a href={cardHolderDetail?.filePathAddressProof}
                                      style={{ textDecoration: `underline`, color: `blue` }}>
                                      Download image
                                    </a>
                                  </div>
                                </div>
                              </li>
                            </Fragment>)}


                      </ul>
                      <div className="form-group submit-field d-flex justify-content-end mt-30 mb-0">
                        <button
                          className="btn btn-large"
                          onClick={() => {
                            setmodalShowKyc(false);
                            //setShowAccptCnfrmPopup(true);
                          }}
                        >
                          Close
                        </button>
                      </div>
                    </div>
                  </div>
                </form>

                {/* {noDataFound && <p>No details found for selected card holder</p>} */}
              </Fragment>,
            ]}
          />
          <ModelPopup
            key={3}
            show={modaldeactivateshow}
            onHide={() => setModaldeactivateshow(false)}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            modelbody={[
              <Fragment>
                {Status === "Suspended" ? (
                  <Fragment>
                    <div className="row">
                      <BoLoader loading={boloader} />
                      <div className="col-md-12">
                        {BlockAction === "Closed" ? (<h2>Close Card</h2>) : (<h2>Suspend Card</h2>)}

                        <div className="mt-16">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="tab-content" id="myTabContent">
                                <div>
                                  <div>
                                    <Form onSubmit={onSuspendCard} ref={form}>
                                      <div className="row mt-16">

                                        <ul className="ul-card-details">
                                          <li className="d-flex  mb-20">
                                            <label>Balance:</label>
                                            &nbsp;
                                            <span><CurrencyFormat value={cardHolderDetail?.availableBalance} displayType={'text'} thousandSeparator={true} /></span> &nbsp;
                                            {cardDetails?.cardFeeCurrencyCode}
                                          </li>
                                          <li className="d-flex  mb-20">
                                            <label>Client Name:</label>
                                            &nbsp;
                                            <span>{cardHolderDetail?.companyName}</span> &nbsp;

                                          </li>
                                        </ul>

                                        <div className="col-md-6">
                                          <div className="form-group" id="id_client">
                                            <label>Select Client Ledger</label>
                                            <div className="form-controls">
                                              <GetClientsLoadDebit
                                                paymentProcessor={cardDetails?.paymentProcessor}
                                                clientId={cardHolderDetail?.clientId}
                                                issuerCategory={cardHolderDetail?.issuerCategory}
                                                clientCurrency={cardDetails?.cardFeeCurrencyCode}
                                                clientnamechangehandler={
                                                  ClientNameChangeHandler
                                                }
                                              />
                                              <span
                                                className={
                                                  "help-block " + showClientErr
                                                }
                                              >
                                                Client is required!
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group" id="id_desc">
                                            <label>Description</label>
                                            <div className="form-controls">
                                              <Input
                                                type="text"
                                                className="form-control no-icon"
                                                name="LastName"
                                                value={Description}
                                                onChange={onChangeDescription}
                                                validations={[descriptionRequired]}
                                                maxLength="50"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                          <a
                                            className="btn btn-outline mr-19"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                              onShowModelClearFields();
                                            }}
                                          >
                                            Cancel
                                          </a>
                                          <a
                                            className="btn btn-large"
                                            onClick={onSuspendCard}
                                          >
                                            Done
                                          </a>
                                        </div>
                                      </div>
                                      <CheckButton
                                        style={{ display: "none" }}
                                        ref={checkBtn}
                                      />
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>
                ) : (
                  <Fragment>
                    <div className="row">
                      <BoLoader loading={boloader} />
                      <div className="col-md-12">
                        <h2>Unsuspend Card</h2>
                        <div className="mt-16">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="tab-content" id="myTabContent">
                                <div>
                                  <div>
                                    <Form onSubmit={onUnsuspendCard} ref={form}>
                                      <div className="row mt-16">

                                        <ul className="ul-card-details">
                                          <li className="d-flex  mb-20">
                                            <label>Balance:</label>
                                            &nbsp;
                                            <span><CurrencyFormat value={cardHolderDetail?.availableBalance} displayType={'text'} thousandSeparator={true} /></span> &nbsp;
                                            {cardDetails?.cardFeeCurrencyCode}
                                          </li>
                                          <li className="d-flex  mb-20">
                                            <label>Client Name:</label>
                                            &nbsp;
                                            <span>{cardHolderDetail?.companyName}</span> &nbsp;

                                          </li>
                                        </ul>

                                        <div className="col-md-6">
                                          <div className="form-group" id="id_client">
                                            <label>Select Client Ledger</label>
                                            <div className="form-controls">
                                              <GetClientsLoadDebit
                                                paymentProcessor={cardDetails?.paymentProcessor}
                                                clientId={cardHolderDetail?.clientId}
                                                issuerCategory={cardHolderDetail?.issuerCategory}
                                                clientCurrency={cardDetails?.cardFeeCurrencyCode}
                                                clientnamechangehandler={
                                                  ClientNameChangeHandler
                                                }
                                              />
                                              <span
                                                className={
                                                  "help-block " + showClientErr
                                                }
                                              >
                                                Client is required!
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="col-md-6">
                                          <div className="form-group" id="id_desc">
                                            <label>Description</label>
                                            <div className="form-controls">
                                              <Input
                                                type="text"
                                                className="form-control no-icon"
                                                name="LastName"
                                                value={Description}
                                                onChange={onChangeDescription}
                                                validations={[descriptionRequired]}
                                                maxLength="50"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                      <div className="col-md-12">
                                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                          <a
                                            className="btn btn-outline mr-19"
                                            data-bs-dismiss="modal"
                                            onClick={() => {
                                              onShowModelClearFields();
                                            }}
                                          >
                                            Cancel
                                          </a>
                                          <a
                                            className="btn btn-large"
                                            onClick={onUnsuspendCard}
                                          >
                                            Done
                                          </a>
                                        </div>
                                      </div>
                                      <CheckButton
                                        style={{ display: "none" }}
                                        ref={checkBtn}
                                      />
                                    </Form>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Fragment>

                )}

              </Fragment>,
            ]}
          />
          <ModelPopup
            key={1}
            show={modalsuccessfullShow}
            onHide={() => { setModalsuccessfullShow(false); document.getElementById("clientNavLink").click(); }}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            backdrop="static"
            keyboard={false}
            modelbody={[
              <Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <div className="icon-alert icon-alert-popup">
                      <span className="icon-successful">
                        <span className="path1"></span>
                        <span className="path2"></span>
                      </span>
                    </div>
                    <h2>Successful</h2>
                    <div className="mt-16">
                      <div>
                        <form>
                          <div className="row">
                            <div className="col-md-12">
                              {Status === "Suspended" ? (
                                BlockAction === "Closed" ? (<p>Card Closed Successfully</p>) : (<p>Card Suspended Successfully</p>)

                              ) : (
                                <p>Card Unsuspended Successfully</p>
                              )}
                            </div>
                            <div className="col-md-12">
                              <div className="form-group submit-field mt-40 mb-0">
                                <Link
                                  to="#"
                                  className="btn btn-large w-100"
                                  onClick={() => {
                                    setModalsuccessfullShow(false);
                                    document.getElementById("cardsNavLink").click();
                                  }}
                                >
                                  Go To Cards
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>,
            ]}
          />

          <ModelPopup
            show={modalChnagePin}
            key={16}
            onHide={() => { setmodalChnagePin(false) }}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            backdrop="static"
            keyboard={false}
            modelbody={[
              <Fragment>
                <div className="row">
                  <div className="col-md-12">
                    <h2 className="mx-xl-5">Change Card PIN</h2>
                    <div className="mt-20">
                      <div
                        id="resetCardPin"
                        className="carousel slide mb-32"
                        data-bs-ride="carousel"
                      >
                        {/* <div className="carousel-inner"> */}
                        {/* <div className="carousel-item active"> */}

                        {/* </div> */}
                        {/* <div className="carousel-item">
                        <img className="d-block w-100" src={card} alt="..." />
                      </div>
                      <div className="carousel-item">
                        <img className="d-block w-100" src={card} alt="..." />
                      </div> */}

                        {/* </div> */}
                        {/* <button
                      className="carousel-control-prev"
                      type="button"
                      data-bs-target="#myCards"
                      data-bs-slide="prev"
                    >
                      <span className="prev-icon d-flex align-items-center justify-content-center">
                        <img src={arrowleft} aria-hidden="true" alt="arrow" />
                      </span>
                      <span className="visually-hidden">Previous</span>
                    </button> */}
                        {/* <button
                      className="carousel-control-next"
                      type="button"
                      data-bs-target="#myCards"
                      data-bs-slide="next"
                    >
                      <span className="next-icon d-flex align-items-center justify-content-center">
                        <img src={arrowright} aria-hidden="true" alt="arrow" />
                      </span>
                      <span className="visually-hidden">Next</span>
                    </button> */}
                      </div>
                      <div>
                        <Form ref={form}>
                          <div className="row">

                            <div className="col-md-12">
                              <div className="form-group" id="id_new_pass">
                                <label for="email">New Card PIN*</label>
                                <div className="form-controls">
                                  <Input
                                    type="password"
                                    className="form-control no-icon"
                                    value={newpin}
                                    onChange={onChangeNewPin}
                                    validations={[newRequired]}
                                    placeholder="Enter New 4 Digit PIN"
                                    maxLength={4}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12" id="id_confirm_pass">
                              <div className="form-group">
                                <label for="email">Confirm Card PIN*</label>
                                <div className="form-controls">
                                  <Input
                                    type="password"
                                    className="form-control  no-icon"
                                    value={confirmpin}
                                    onChange={onChangeConfirmPin}
                                    validations={[confirmRequired, vpassword]}
                                    placeholder="Confirm 4 Digit PIN"
                                    maxLength={4}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12">
                              <div className="form-group submit-field d-flex justify-content-between mt-24">
                                <a
                                  className="btn btn-outline mr-19"
                                  data-bs-dismiss="modal"
                                  onClick={() => {
                                    setmodalChnagePin(false)
                                  }}
                                >
                                  Cancel
                                </a>
                                <a
                                  className="btn btn-large"
                                  onClick={handleRegister}
                                >
                                  Change
                                </a>

                              </div>
                            </div>
                          </div>
                          <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>
            ]}
          />
          <ModelPopup
            key={31}
            show={modalerrorShow}
            onHide={() => { setModalerrorShow(false); onHideModal(); }}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            backdrop="static"
            keyboard={false}
            modelbody={[
              <Fragment>
                <div class="row">
                  <div class="col-md-12">
                    <div class="icon-alert icon-alert-popup">
                      <span class="icon-error">
                        <span class="path1"></span>
                        <span class="path2"></span>
                      </span>
                    </div>
                    <h2>Error</h2>
                    <div class="mt-16">
                      <div>
                        <form>
                          <div class="row">
                            <div class="col-md-12">
                              <p>You Entered incorrect current PIN.</p>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group submit-field d-flex justify-content-between mt-24">
                                <Link to="#" class="btn btn-outline mr-19">
                                  Back
                                </Link>
                                <Link to="#" class="btn btn-large">
                                  Try Again
                                </Link>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>,
            ]}
          />

          <ModelPopup
            show={modalShowEditCard}
            key={15}
            onHide={() => { setModalShowEditCard(false); onCancelEditCards(); }}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            backdrop="static"
            keyboard={false}
            modelbody={[
              <Fragment>
                <form onSubmit={e => e.preventDefault()}>
                  <h2>Edit CardHolder Details</h2>
                  <div className="row mt-16">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="form-group" id="edit_firstname">
                            <label>First Name*</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={FirstName}
                                onChange={onChangeFirstName}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                // validations={[firstNameRequired]}
                                maxLength="20"
                              />

                            </div>
                            <span
                              className={
                                "help-block " + FirstNameErr
                              }
                            >
                              First Name is required!
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_lastname">
                            <label>Last Name*</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={LastName}
                                onChange={onChangeLastName}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                maxLength="20"
                              />

                            </div>
                            <span
                              className={
                                "help-block " + LastNameErr
                              }
                            >
                              Last Name is required!
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_address">
                            <label>Address*</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={Address}
                                onChange={onChangeAddress}
                                maxLength="40"
                              />

                            </div>
                            <span
                              className={
                                "help-block " + AddressErr
                              }
                            >
                              Address is required!
                            </span>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_city">
                            <label>City</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={City}
                                onChange={onChangeCity}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkAlpha(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                maxLength="20"
                              />

                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="id_deliverycountry">
                            <label>Country*</label>
                            <div className="form-controls">
                              <ReactFlagsSelect
                                className="form-control no-icon"
                                selected={Country}
                                onSelect={code => {
                                  setCountry(code);
                                }}
                                searchable={true}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group" id="edit_zipcode">
                            <label>Zip Code</label>
                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="cardholderName"
                                value={ZipCode}
                                onChange={onChangeZipCode}
                                onKeyDown={(evt) => {
                                  let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                  if (chck)
                                    evt.preventDefault();
                                }
                                }
                                maxLength="10"
                              />

                            </div>
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="form-group" id="edit_email">
                            <label>Email</label>

                            <div className="form-controls">
                              <input
                                type="text"
                                className="form-control  no-icon"
                                name="email"
                                value={EmailEdit}
                                onChange={onChangeEmail}
                                validations={[Emailrequired, validEmail]}
                                // onKeyDown={(evt) => {
                                //   let chck = TrimCharacters.checkSpecialCharacters(evt.key)
                                //   if (chck)
                                //     evt.preventDefault();
                                // }
                                // }
                                maxLength="60"
                              />

                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                          <a
                            className="btn btn-outline mr-19"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setModalShowEditCard(false);
                              onCancelEditCards();
                            }}
                          >
                            Cancel
                          </a>
                          <a
                            className="btn btn-large"
                            onClick={onEditCardDetails}
                          >
                            Save
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>

                {/* {noDataFound && <p>No details found for selected card holder</p>} */}
              </Fragment>,
            ]}
          />

          <ModelPopup
            key={4}
            show={modalrefillamountshow}
            onHide={() => setModalrefillamountshow(false)}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            backdrop="static"
            keyboard={false}
            modelbody={[
              <Fragment>
                <div class="row">
                  <div class="col-md-12">
                    <h2>Confirmation</h2>
                    <div class="mt-16">
                      <div>
                        <form>
                          <div class="row">
                            <div class="col-md-12">
                              <p>Are you sure you want to top up from your pending balance?</p>
                            </div>
                            <div class="col-md-12">
                              <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                                <a
                                  class="btn btn-outline"
                                  onClick={onHideRefillPopUpHandler}
                                >
                                  No
                                </a>
                                <a
                                  class="btn btn-large"
                                  onClick={() => {
                                    refillMainWallet();
                                    setModalrefillamountshow(false);
                                  }}
                                >
                                  Yes
                                </a>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </Fragment>,
            ]}
          />
          <DialoguePopup
            show={dialougemodalShow}
            onHide={() => setDialougemodalShow(false)}
            hideallpopups={() => { setDialougemodalShow(false); refereshPage(); }}
            modelheading={modelhdng}
            modelbody={modelbdy}
            modelicon={MdlIcon}
          />
        </Fragment>
      ) : (<div><h5></h5></div>)}
    </div>
  );
};

export default AccountHolderDetails;
