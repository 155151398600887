import { useState, useRef, useEffect, Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import { useSelector } from "react-redux";
import Input from "react-validation/build/input";
import BoLoader from "../../helpers/BoLoader";
import Form from "react-validation/build/form";
import DialoguePopup from "../../helpers/DialoguePopup";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import transactionService from "../../services/transaction.service";
import GetAllClients from "../../helpers/GetAllClients";
import GetClientsLoadDebit from "../../helpers/GetClientsLoadDebit";
import CurrencyFormat from 'react-currency-format';
import GetAllAccounts from "./GetAllAccount";
import GetAllBenificary from "./GetAllBenificary";

const TransferIban = props => {
    const CheckNegativeAmount = value => {
        let obj = parseFloat(value);
        if (obj < 0) {
            document.getElementById("id_Amount").classList.add("has_error");
            return <span className="help-block">Amount cannot be negative!</span>;
        } else document.getElementById("id_Amount").classList.remove("has_error");
    };
    const CheckZeroAmount = value => {
        let obj = parseFloat(value);
        if (obj === 0) {
            document.getElementById("id_Amount").classList.add("has_error");
            return (
                <span className="help-block">Please enter amount greater than 0!</span>
            );
        } else document.getElementById("id_Amount").classList.remove("has_error");
    };
    const accountNumberRequired = value => {
        if (!value) {
            document.getElementById("id_AccountNumber").classList.add("has_error");
            return <span className="help-block">Token ID is required!</span>;
        } else
            document.getElementById("id_AccountNumber").classList.remove("has_error");
    };
    const AmountRequired = value => {
        if (!value) {
            document.getElementById("id_Amount").classList.add("has_error");
            return <span className="help-block">Amount is required!</span>;
        } else document.getElementById("id_Amount").classList.remove("has_error");
    };
    const descriptionRequired = value => {
        if (!value) {
            document.getElementById("id_desc").classList.add("has_error");
            return <span className="help-block">Description is required!</span>;
        } else document.getElementById("id_desc").classList.remove("has_error");
    };
    const { user: currentUser } = useSelector(state => state.auth);

    const [boloader, setBoLoader] = useState(false);
    const [Amount, setAmount] = useState("");
    const [ClientId, setClientId] = useState();
    const [UserId, setUserId] = useState(String(currentUser.user.UserId));
    //mSwipe Payment Processor
    const [CardHashId, setCardHashId] = useState();

    const [Name, setName] = useState();
    const [Email, setEmail] = useState();
    const [modalShow, setModalShow] = useState(false);
    const [ReceiverName, setReceiverName] = useState();
    const [TokenId, setTokenId] = useState();
    //Dialogue Popup
    const [dialougeModalShow, setDialougeModalShow] = useState(false);
    const [modelhdng, setMdlHdng] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");
    //Modal Popups
    const [confirmModalShow, setconfirmModalShow] = useState(false);
    const [showClientErr, setShowClientErr] = useState("d-none");
    const [Description, setDescription] = useState();
    const [FromAccountId , setFromAccountId] = useState();
    const [ToAccountId , setToAccountId] = useState();
    const [BeneficaryId  , setBeneficaryId] = useState();


    const form = useRef();
    const checkBtn = useRef();
    useEffect(() => {
        if (props.calledfrom === "cardholderdetails") {
            setTokenId(props.TokenID);
        }
    }, []);
    const maxLengthCheck = object => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
        let t = object.target.value;
        if (t.includes("."))
            object.target.value = (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3));
    };

    const onChangeAmount = e => {
        const Amount = e.target.value;
        if (Amount != "") {
            let value = parseFloat(Amount);
            setAmount(value);
        }
    };

    const CheckCardDetails = () => {
        props?.onHide();
        setconfirmModalShow(true);
    };
    const AccountFromChangeHandler = e => {
        const clientName = e.target.selectedOptions[0].value;
        setFromAccountId(clientName);
        requiredClient(clientName);

    };
    const AccountToChangeHandler = e => {
        const clientName = e.target.selectedOptions[0].value;
        setToAccountId(clientName);
        requiredClient(clientName);

    };
    const BenificaryChangeHandler = e => {
        const clientName = e.target.selectedOptions[0].value;
        setBeneficaryId(clientName);
        requiredClient(clientName);

    };
    const onChangeDescription = e => {
        const desc = e.target.value;
        if (desc != "") {
            setDescription(desc);
        }
    };
    const renderLoadCard = () => {
        return (
            <Fragment>
                <div className="row">
                    <BoLoader loading={boloader} />
                    <div className="col-md-12">
                        {
                            props?.calledfrom == "accounttransfer" ?
                                (<h2>Account Transfer</h2>) : (<h2>Benificary Transfer</h2>)
                        }
                        <div className="mt-16">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tab-content" id="myTabContent">
                                        <div>
                                            <div>
                                                <Form onSubmit={CheckCardDetails} ref={form}>
                                                    <div className="row mt-16">

                                                        <div className="col-md-6" id="id_client">
                                                            <div className="form-group">
                                                                <label>Select From Account *</label>
                                                                <div className="form-controls">
                                                                    
                                                                            <GetAllAccounts
                                                                                paymentProcessor={props?.paymentProcessor}
                                                                                clientId={props?.clientId}
                                                                                issuerCategory={props?.issuerCategory}
                                                                                clientCurrency={props?.CurrencyCode}
                                                                                calledfrom="cardholderdetails"
                                                                                clientnamechangehandler={
                                                                                    AccountFromChangeHandler
                                                                                }
                                                                            />
                                                                      
                                                                    <span
                                                                        className={
                                                                            "help-block " + showClientErr
                                                                        }
                                                                    >
                                                                        Client is required!
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" id="id_client">
                                                            <div className="form-group">
                                                                {
                                                                     props?.calledfrom == "accounttransfer" ?(
                                                                        <label>Select To Account *</label>
                                                                     ):( <label>Select Benificiary *</label>)
                                                                }
                                                                
                                                                <div className="form-controls">
                                                                    {
                                                                        props?.calledfrom === "accounttransfer" && (
                                                                            <GetAllAccounts
                                                                                paymentProcessor={props?.paymentProcessor}
                                                                                clientId={props?.clientId}
                                                                                issuerCategory={props?.issuerCategory}
                                                                                clientCurrency={props?.CurrencyCode}
                                                                                calledfrom="cardholderdetails"
                                                                                clientnamechangehandler={
                                                                                    AccountToChangeHandler
                                                                                }
                                                                            />
                                                                        )


                                                                    }
                                                                     {
                                                                        props?.calledfrom === "benificary" && (
                                                                            <GetAllBenificary
                                                                                paymentProcessor={props?.paymentProcessor}
                                                                                clientId={props?.clientId}
                                                                                issuerCategory={props?.issuerCategory}
                                                                                clientCurrency={props?.CurrencyCode}
                                                                                calledfrom="cardholderdetails"
                                                                                clientnamechangehandler={
                                                                                    BenificaryChangeHandler
                                                                                }
                                                                            />
                                                                        )
                                                                        

                                                                    }
                                                                    <span
                                                                        className={
                                                                            "help-block " + showClientErr
                                                                        }
                                                                    >
                                                                        Client is required!
                                                                    </span>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_Amount">
                                                                <label>Amount*</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="number"
                                                                        className="form-control no-icon"
                                                                        name="LastName"
                                                                        value={Amount}
                                                                        onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                        maxLength="9"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        onInput={maxLengthCheck}
                                                                        onChange={onChangeAmount}
                                                                        validations={[
                                                                            AmountRequired,
                                                                            CheckNegativeAmount,
                                                                            CheckZeroAmount,
                                                                        ]}
                                                                    />
                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_desc">
                                                                <label>Description*</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="LastName"
                                                                        maxLength="50"
                                                                        value={Description}
                                                                        onChange={onChangeDescription}
                                                                        validations={[descriptionRequired]}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                                            <a
                                                                className="btn btn-outline mr-19"
                                                                data-bs-dismiss="modal"
                                                                onClick={() => {
                                                                    onShowModelClearFields();
                                                                }}
                                                            >
                                                                Cancel
                                                            </a>
                                                            <a
                                                                className="btn btn-large"
                                                                onClick={CheckCardDetails}
                                                            >
                                                                Done
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <CheckButton
                                                        style={{ display: "none" }}
                                                        ref={checkBtn}
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
    const requiredClient = value => {
        if (!value) {
            document.getElementById("id_client").classList.add("has_error");
            setShowClientErr("");
            return false;
        } else {
            document.getElementById("id_client").classList.remove("has_error");
            setShowClientErr("d-none");
            return true;
        }
    };
    const loadCardSave = e => {
        if (e) e.preventDefault();
       
        setBoLoader(true);
        let fromvalue=parseInt(FromAccountId);
        let tovalue=parseInt(ToAccountId);
        transactionService
            .accountTransfer(
                fromvalue,
                "",
                Amount,
                Description,
                "",
                tovalue
            )
            .then(
                response => {
                    setBoLoader(false);
                    if (response && response.data && response.data.payload) {
                        if (response.data.payload) {
                            setconfirmModalShow(false);
                            setModalShow(true);
                        }
                    }
                },
                error => {
                    setBoLoader(false);
                    setconfirmModalShow(false);
                    setModalShow(false);
                    let msgBody = error?.response?.data?.message
                        ? error?.response?.data?.message
                        : "Error Occured";
                    let errIcon = "icon-error";
                    errorAndSuccessModl("Error", msgBody, errIcon);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            window.location.reload();
                            setBoLoader(false);
                        } else {
                            setBoLoader(false);
                        }
                    })();
                }
            );
    };
    const benificarySave = e => {
        if (e) e.preventDefault();
        setBoLoader(true);
        let fromvalue=parseInt(FromAccountId)
        transactionService
            .benificaryTransfer(
                fromvalue,
                "",
                Amount,
                Description,
                BeneficaryId
            )
            .then(
                response => {
                    setBoLoader(false);
                    if (response && response.data && response.data.payload) {
                        if (response.data.payload) {
                            setconfirmModalShow(false);
                            setModalShow(true);
                        }
                    }
                },
                error => {
                    setBoLoader(false);
                    setconfirmModalShow(false);
                    setModalShow(false);
                    let msgBody = error?.response?.data?.message
                        ? error?.response?.data?.message
                        : "Error Occured";
                    let errIcon = "icon-error";
                    errorAndSuccessModl("Error", msgBody, errIcon);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            window.location.reload();
                            setBoLoader(false);
                        } else {
                            setBoLoader(false);
                        }
                    })();
                }
            );
    };

    const closeConfrmModal = () => {
        setconfirmModalShow(false);
    };

    const renderConfirmDetails = () => {
        return (
            <div className="row">
                <BoLoader loading={boloader} />
                <div className="col-md-12">
                    <h2>Confirmation</h2>
                    <div className="mt-16">
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>Please Confirm Details of Transactions.</p>
                                </div>
                                <div className="col-md-12">
                                    <ul className="ul-card-details-popup mt-20">
                                        <li className="d-flex justify-content-between mb-16">
                                            <label>From Account</label>
                                            <span>{Name}</span>
                                        </li>
                                        <li className="d-flex justify-content-between mb-16">
                                            <label>To Account</label>
                                            <span>{Email}</span>
                                        </li>
                                        <li className="d-flex justify-content-between mb-16">
                                            <label>Amount</label>
                                            <span>{TokenId}</span>
                                        </li>
                                        <li className="d-flex justify-content-between mb-16">
                                            <label>Description</label>
                                            <span>{Amount}</span>
                                        </li>

                                    </ul>
                                    <div class="form-group submit-field d-flex justify-content-between mt-40">
                                        <button
                                            class="btn btn-outline mr-19"
                                            onClick={closeConfrmModal}
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        {
                                            props?.calledfrom=="accounttransfer" &&(
                                                <a class="btn btn-large" onClick={loadCardSave}>
                                                Confirm
                                            </a>
                                            )
                                        }
                                        {
                                            props?.calledfrom=="benificary" &&(
                                                <a class="btn btn-large" onClick={benificarySave}>
                                                Confirm
                                            </a>
                                            )
                                        }
                                       
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const closeP2pModal = e => {
        e.preventDefault();
        setModalShow(false);
        setconfirmModalShow(false);
        setAmount("");
        if (props?.calledfrom === "cardholderdetails") window.location.reload();
    };
    const renderDetails = () => {
        return (
            <div className="row">
                <BoLoader loading={boloader} />
                <div className="col-md-12">
                    <div class="icon-alert icon-alert-popup">
                        <span class="icon-successful">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </span>
                    </div>
                    <h2>Transfered</h2>
                    <div className="mt-16">
                        <div>
                            <Form onSubmit={closeP2pModal}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Amount has been sent successfully to.</p>
                                    </div>
                                    <div className="col-md-12">
                                        <ul className="ul-card-details-popup mt-20">
                                            <li className="d-flex justify-content-between mb-16">
                                                <label>Token ID</label>
                                                <span>{TokenId}</span>
                                            </li>
                                            <li className="d-flex justify-content-between mb-16">
                                                <label>Amount</label>
                                                <span>{Amount}</span>
                                            </li>

                                            <li className="d-flex justify-content-between mb-16">
                                                <label>Name</label>
                                                <span>{Name}</span>
                                            </li>
                                            <li className="d-flex justify-content-between mb-16">
                                                <label>Email</label>
                                                <span>{Email}</span>
                                            </li>
                                        </ul>
                                        <div class="form-group submit-field text-center mt-40">

                                            <button class="btn btn-large" onclick={closeP2pModal}>
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const errorAndSuccessModl = (heading, body, errIcon) => {
        setDialougeModalShow(true);
        setMdlHdng(heading);
        setMdlBdy(body);
        setMdlIcon(errIcon);
    };
    const onShowModelClearFields = () => {
        if (props.calledfrom !== "cardholderdetails") {
            setTokenId("");
            setAmount("");
        }
        else {
            setAmount("");
        }
        props.onHide();
    };
    return (
        <Fragment>
            <ModelPopup
                key={21}
                show={props.show}
                onHide={() => onShowModelClearFields()}
                modelheading={""}
                showheader={"false"}
                className="smallPopup issueCardSmall"
                dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
                backdrop="static"
                keyboard={false}
                modelbody={renderLoadCard()}
            />
            <ModelPopup
                key={22}
                show={confirmModalShow}
                onHide={() => setconfirmModalShow(false)}
                modelheading={""}
                showheader={"false"}
                dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                className="smallPopup issueCardSmall"
                backdrop="static"
                keyboard={false}
                modelbody={renderConfirmDetails()}
            />
            <ModelPopup
                key={23}
                show={modalShow}
                onHide={() => setModalShow(false)}
                modelheading={""}
                modelbody={renderDetails()}
                showheader={"false"}
                dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                className="smallPopup issueCardSmall"
                backdrop="static"
                keyboard={false}
            />
            <DialoguePopup
                show={dialougeModalShow}
                onHide={() => {
                    setDialougeModalShow(false);
                    if (props?.calledfrom !== "cardholderdetails") {
                        setTokenId("");
                    }
                    setAmount("");
                }}
                modelheading={modelhdng}
                modelbody={modelbdy}
                modelicon={MdlIcon}
                backdrop="static"
                keyboard={false}
            />
        </Fragment>
    );
};

export default TransferIban;
