import React, { useState, useEffect, useRef, Fragment } from "react";
import Form from "react-validation/build/form";
import ModelPopup from "../../helpers/ModelPopup";
import DialoguePopup from "../../helpers/DialoguePopup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BoLoader from "../../helpers/BoLoader";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";

import * as EmailValidator from "email-validator";
import superClientServiceObj from "../../services/super-client.service";



const EditSuperClient = props => {
 
  return (
    <ModelPopup
      show={props?.show}
      onHide={props?.onHide}
      modelheading={""}
      showheader={"false"}
      backdrop="static"
      keyboard={false}
      className="smallPopup issueCardSmall "
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76 "
      modelbody={
        <EditSuperClients
          show={props?.show}
          onHide={props?.onHide}
          editsuperclient={props?.editsuperclient}
          onAddClient={props?.onAddClient}
        />
      }
    />
  );
};
const EditSuperClients = props => {

  const CompanyNameRequired = value => {
    if (!value) {
      document.getElementById("companyName").classList.add("has_error");
      return <span className="help-block">Company Name is required!</span>;
    } else document.getElementById("companyName").classList.remove("has_error");
  };
  const EmailRequired = value => {
    if (!value) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Email is required!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const checkValidEail = emailAdress => {
    let value = EmailValidator.validate(emailAdress);
    return value;
  };
  const EmailFormatRequired = value => {
    let verifyemailcheck = checkValidEail(value);
    if (!verifyemailcheck) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Please enter valid email!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };


  const form = useRef();
  const checkBtn = useRef();
  const [isValidPhoneNumber, setisValidPhoneNumber] = useState(true);

  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");

  //loader
  const [boloader, setBoLoader] = useState(false);
  const [showPhoneErr, setshowPhoneErr] = useState("d-none");
  const [showPhoneMinimumErr, setshowPhoneMinimumErr] = useState("d-none");
 
  //EditSuperAdmin Fields
  const [ClientName, setName] = useState(props?.editsuperclient?.name);
  const [Mobile, setphoneNumber] = useState(props?.editsuperclient?.phoneNumber);
  const [Email, setemail] = useState(props?.editsuperclient?.email);
  const [Id, setId] = useState(props?.editsuperclient?.id);
  const [IsDeleted, setIsDeleted] = useState(false);

  const requiredPhoneNo = value => {
    if (Mobile.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
    if (!value) {
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneErr("");
      return false;
    } else {
      document.getElementById("id_phonenumber").classList.remove("has_error");
      setshowPhoneErr("d-none");
      return true;
    }
  };

  useEffect(() => {
    if (Mobile.length === 0) {
      setisValidPhoneNumber(true);
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else if (Mobile.length < 3) {
      setisValidPhoneNumber(false);
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else {
      setisValidPhoneNumber(true);
      document.getElementById("id_phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    }
  }, [Mobile]);

  const onChangename = e => {
    const ClientName = e.target.value;
    setName(ClientName);

  };

  const onChangeEmail = e => {
    const Email = e.target.value;
    setemail(Email);

  };

  const saveUser = e => {
    e.preventDefault();
    form.current.validateAll();
    let phoneValid = requiredPhoneNo(Mobile);

    if (
      checkBtn.current.context._errors.length === 0 &&
      phoneValid
    ) {
      setBoLoader(true);
      superClientServiceObj
        .editSuperClient(
          Id,
          ClientName,Email,
          Mobile,IsDeleted
        )
        .then(response => {
          setBoLoader(false);
          if (response && response.data.message) {
            if (response.data.message=="success") {
              props?.onAddClient();
            }
          }
        })
        .catch(error => {
          setBoLoader(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message
            : "Error Occured while Registering client";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          (async () => {
            let resp = await RefreshToken(
              error && error.response && error.response.status
            );
            if (resp) {
              if (resp) {
                saveUser();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            }
          })();
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };

  const onChangePhoneNumber = e => {
    requiredPhoneNo(e);
    setphoneNumber(e);
  };

  return (
    <div className="">
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Edit Super Client</h2>
          <div className="mt-24">
            <Form onSubmit={(e) => e.preventDefault()} ref={form}>
              <div className="row">
                <div className="col-md-12" id="companyName">
                  <div className="form-group">
                    <label>Name*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="Name"
                        onChange={onChangename}
                        validations={[CompanyNameRequired]}
                        value={ClientName}
                        maxLength="80"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="email">
                  <div className="form-group">
                    <label>Email*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        onChange={onChangeEmail}
                        validations={[
                          EmailRequired,
                        
                          EmailFormatRequired,
                        ]}
                        value={Email}
                        maxLength="50"
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_phonenumber" >
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <div className="form-group">
                      <PhoneInput
                        className=""
                        country={"us"}
                        onChange={onChangePhoneNumber}
                        value={Mobile}
                      />
                      <span className={"help-block " + showPhoneErr}>
                        Phone No is required!
                      </span>
                      {!isValidPhoneNumber && (
                        <div id="id_minimumphonelength">
                          <span className={"help-block " + showPhoneMinimumErr}>
                            Minimum three number are required!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                {/* <div class="col-md-6" id="cPassword">
                <div className="form-group" id="Password">
                  <label htmlFor="password">Password*</label>
                  <div class="form-controls">
                    <Input
                      type={passwordShown ? "text" : "password"}
                      className="form-control"
                      name="password"
                      value={Password}
                      onChange={onChangePassword}
                      validations={[prequired, vpassword]}
                      maxLength="20"
                    />
                    <span class="icon-lock _ico"></span>
                    <span
                      className={"show-password " + showHidePassClass}
                      onClick={showHidePassHandler}
                    ></span>
                  </div>
                </div>
                </div> */}
                {/* <div class="col-md-6" id="cPassword">
                  <div className="form-group">
                    <label htmlFor="confirmPassword">Confirm Password*</label>
                    <div class="form-controls">
                      <Input
                        type={cPasswordShown ? "text" : "password"}
                        className="form-control"
                        name="confirmPassword"
                        value={confirmpassword}
                        onChange={onChangeConfirmPassword}
                        validations={[crequired, vcpassword]}
                        maxLength="20"
                      />
                      <span class="icon-lock _ico"></span>
                      <span
                        className={"show-password " + showHideCPassClass}
                        onClick={showHideCPassHandler}
                      ></span>
                    </div>
                  </div>
                </div> */}
                <div className="col-md-12">
                  <div className="form-group submit-field d-flex justify-content-center mt-40 mb-0">
                    <a
                      className="btn btn-outline mr-19"
                      data-bs-dismiss="modal"
                      onClick={props.onHide}
                    >
                      Cancel
                    </a>
                    <a className="btn btn-large" onClick={saveUser}>
                      Edit Super Client
                    </a>
                  </div>
                </div>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />

    </div>
  );
};

export default EditSuperClient;
