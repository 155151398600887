import React, { Fragment, useRef, useState } from "react";
import BoLoader from "../../helpers/BoLoader";
import ModelPopup from "../../helpers/ModelPopup";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import GetAllClients from "../../helpers/GetAllClients";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import clientmanagmentObj from "../../services/client-managment.service";
import DialoguePopup from "../../helpers/DialoguePopup";
import { Link } from "react-router-dom";
import { useEffect } from "react";
const AmountRequired = value => {
  if (!value) {
    document.getElementById("id_Amount").classList.add("has_error");
    return <span className="help-block">Amount is required!</span>;
  } else document.getElementById("id_Amount").classList.remove("has_error");
};
const DescriptionRequired = value => {
  if (!value) {
    document.getElementById("id_description").classList.add("has_error");
    return <span className="help-block">Description is required!</span>;
  } else document.getElementById("id_description").classList.remove("has_error");
};
const TransferClient = props => {
  const CheckNegativeAmount = value => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount cannot be negative!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
 
  const CheckZeroAmount = value => {
    let obj = parseFloat(value);
    if (obj === 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return (
        <span className="help-block">Please enter amount greater than 0!</span>
      );
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
    var t = object.target.value;
    if(t.includes("."))
    object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
  };

  //SuccessFull Modal
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);

  //Loader
  const [boloader, setBoLoader] = useState(false);
  //Parameters
  const [SenderClientId, setSenderClientId] = useState("");
  const [ReceiverClientId, setReceiverClientId] = useState("");
  const [Amount, setAmount] = useState("");
  const [Description, setDescription] = useState("");
  //Dialogue Popup
  const [dialougeModalShow, setDialougeModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const form = useRef();
  const checkBtn = useRef();
  const [allRecords, setAllRecords]=useState([])
  const [ReceiverArray, setReceiverArray]=useState([])
  const [currencyCode, setCurrencyCode]=useState("")
  //Validation Checks
  const [showClientFromErr, setshowClientFromErr] = useState("d-none");
  const [showClientToErr, setshowClientToErr] = useState("d-none");

  useEffect(()=>{
    clientmanagmentObj.getAllClients()
        .then((response) => {
          if (response.data.payload.succeeded) {
            setAllRecords(response.data.payload)
          }
        })
        .catch((err) => {
          // if (err && err.response && err.response.status === 401) {
          //   (async () => {
          //     let resp = await RefreshToken();
          //     if (resp) setMthdRcl(true);
          //   })();
          // }
        });
   
  },[])

  const clientFromRequired = value => {
    if (!value) {
      document.getElementById("id_clientfrom").classList.add("has_error");
      setshowClientFromErr("");
      return false;
    } else {
      document.getElementById("id_clientfrom").classList.remove("has_error");
      setshowClientFromErr("d-none");
      return true;
    }
  };
  const clientToRequired = value => {
    if (!value) {
      document.getElementById("id_clientto").classList.add("has_error");
      setshowClientToErr("");
      return false;
    } else {
      document.getElementById("id_clientto").classList.remove("has_error");
      setshowClientToErr("d-none");
      return true;
    }
  };
  const senderClientId =async  e => {
    const clientName = e.target.selectedOptions[0].value;
    await clientmanagmentObj
    .getprofiledetails(String(clientName))
    .then((response) => {
      let receiverArray = allRecords?.result?.filter((item)=>(item.currency === response?.data?.payload?.result?.currencyCode))
      setReceiverArray(receiverArray)
    })
    
    setSenderClientId(clientName);
    clientFromRequired(clientName);
  };

  const receiverClientId = e => {
    const clientName = e.target.selectedOptions[0].value;
    setReceiverClientId(clientName);
    clientToRequired(clientName);
  };
  const onChangeAmount = e => {
    const Amount = e.target.value;
    if (Amount != "") {
      let value = parseFloat(Amount);
      setAmount(value);
    }
  };
  const onChangeDescription = e => {
    const Desc = e.target.value;
    if (Desc != "") {
      setDescription(Desc);
    }
  };
  const saveClientTransfer = e => {
    if (e) e.preventDefault();
    let senderclientid;
    let recieverclientid;
    if (SenderClientId) senderclientid = parseInt(SenderClientId);
    if (ReceiverClientId) recieverclientid = parseInt(ReceiverClientId);
    let clientfrom = clientFromRequired(SenderClientId);
    let clientto = clientToRequired(ReceiverClientId);
    if (
      checkBtn.current.context._errors.length === 0 &&
      clientfrom &&
      clientto
    ) {
      setBoLoader(true);
      clientmanagmentObj
        .clientAmountTransfer(Amount, senderclientid, recieverclientid,Description)
        .then(
          response => {
            setBoLoader(false);
            if (response && response.data && response.data.payload) {
              if (response.data.payload?.result === "success") {
                setModalsuccessfullShow(true);
                props.onHide();
                setshowClientFromErr("d-none");
                setshowClientToErr("d-none");
                setAmount("");
                setDescription("");

              }
            }
          },
          error => {
            setBoLoader(false);
            setModalsuccessfullShow(false);
            let msgBody = error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougeModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onShowModelClearFields = () => {
    setReceiverClientId("");
    setSenderClientId("");
    setAmount("");
    setDescription("");
    setshowClientFromErr("d-none");
    setshowClientToErr("d-none");
    props.onHide();
  };
  const renderTransferClient = () => {
    return (
      <Fragment>
        <div className="row">
          <BoLoader loading={boloader} />
          <div className="col-md-12">
            <h2> Ledger Transfer</h2>
            <div className="mt-16">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content" id="myTabContent">
                    <div>
                      <div>
                        <Form onSubmit={(e)=>e.preventDefault()} ref={form}>
                          <div className="row mt-16">
                            <div className="col-md-6" id="id_clientfrom">
                              <div className="form-group form-search ">
                                <label className="mb-24">Client From*</label>
                                <div className="form-controls">
                                  <GetAllClients
                                    clientnamechangehandler={senderClientId}
                                    searchgriddata={"Y"}
                                  />
                                  <span className={"help-block " + showClientFromErr}>
                                  Client From is required!
                                </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6" id="id_clientto">
                              <div className="form-group form-search ">
                                <label className="mb-24">Client To*</label>
                                <div className="form-controls">
                                <select
                                id="clientNameDD"
                                className="form-control no-icon"
                                onChange={receiverClientId}
                              >
                                  <option value="">Select Client Ledger</option>
                                
                                {ReceiverArray.map((options) => (
                                  <option key={options.uuid} currency={options.currency} value={options.id} paymentProcessor={options.paymentProcessor} 
                                  cardFlow={options.cardFlow} issuerCategory={options.issuerCategory}>
                                    {options.clientName} 
                                  </option>
                                ))}
                              </select>
                                  <span className={"help-block " + showClientToErr}>
                                 Client To is required!
                                </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6" >
                              <div className="form-group" id="id_Amount">
                                <label>Amount*</label>
                                <div className="form-controls">
                                  <Input
                                    type="number"
                                    className="form-control no-icon"
                                    name="Amount"
                                    value={Amount}
                                    onWheel={(e) => e.target.blur()}
                                    maxLength="9"
                                    onInput={maxLengthCheck}
                                    onChange={onChangeAmount}
                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                    validations={[
                                      AmountRequired,
                                      CheckNegativeAmount,
                                      CheckZeroAmount,
                                    ]}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6" >
                              <div className="form-group" id="id_description">
                                <label>Description*</label>
                                <div className="form-controls">
                                  <Input
                                    type="text"
                                    className="form-control no-icon"
                                    name="Description"
                                    onChange={onChangeDescription}
                                    validations={[
                                      DescriptionRequired,
                                    ]}
                                    value={Description}
                                    maxLength="50"
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                              <a
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  onShowModelClearFields();
                                }}
                              >
                                Cancel
                              </a>
                              <a
                                className="btn btn-large"
                                onClick={saveClientTransfer}
                              >
                                Done
                              </a>
                            </div>
                          </div>
                          <CheckButton
                            style={{ display: "none" }}
                            ref={checkBtn}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <ModelPopup
        key={1}
        show={props.show}
        onHide={() => onShowModelClearFields()}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        backdrop="static"
        keyboard={false}
        modelbody={renderTransferClient()}
      />
      <DialoguePopup
        show={dialougeModalShow}
        onHide={() => {
          setDialougeModalShow(false);
        }}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        backdrop="static"
        keyboard={false}
      />
      <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Transferred Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </Fragment>
  );
};
export default TransferClient;
