import authService from "../services/auth.service";
const accessToken = JSON.parse(sessionStorage.getItem("user"));
export default async function RefreshToken(errState) {
  if (errState === 401) {
    const result = await authService
      .refreshtoken(accessToken?.auth_token)
      .then((resp) => {
        accessToken.auth_token = resp.data;
        sessionStorage.setItem("user", JSON.stringify(accessToken));
        return true;
      })
      .catch((err) => {
        return false;
      });
    return result;
  } else return false;
}
