import { LOGIN_SUCCESS, LOGIN_FAIL, LOGOUT,PRELOGIN,REFRESH_TOKEN } from "../actions/types";

const user = JSON.parse(sessionStorage.getItem("user"));

const initialState = user
  ? { isLoggedIn: true, user }
  : { isLoggedIn: false, user: null };

const authReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        isLoggedIn: true,
        user: payload.user,
      };
    case LOGIN_FAIL:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
    case LOGOUT:
      return {
        ...state,
        isLoggedIn: false,
        user: null,
      };
      case PRELOGIN:
        return {
          ...state,
          isLoggedIn: false,
          user: null,
        };
      case REFRESH_TOKEN:
          return {
            ...state,
            user: { ...user, accessToken: payload }
          };
    default:
      return state;
  }
};
export default authReducer;
