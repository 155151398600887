import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import clientmanagmentObj from "../../services/client-managment.service";
import identityverificationObj from "../../services/identityverification.service";
import { history } from "../../helpers/history";
import Form from "react-validation/build/form";
import ModelPopup from "../../helpers/ModelPopup";
import crossimg from "../../assets/images/icons/cross.svg";
import fileuploadimg from "../../assets/images/icons/file-upload.svg";
import { useSelector } from "react-redux";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import BoLoader from "../../helpers/BoLoader";
import DialoguePopup from "../../helpers/DialoguePopup";
import { Fragment } from "react";
import RefreshToken from "../../helpers/RefreshToken";

var depositDate = "";
const DepositClientSubmit = (props) => {
  return (
    <ModelPopup
      show={props.show}
      onHide={props.onHide}
      modelheading={""}
      showheader={"false"}
      className="smallPopup issueCardSmall"
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
      modelbody={<DepositClient show={props.show} onHide={props.onHide} />}
    />
  );
};

const DepositClient = (props) => {
  const form = useRef();
  const checkBtn = useRef();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [RequestId, setRequestId] = useState();
  const [AccountNumber, setAccountNumberr] = useState("");
  const [Bank, setBank] = useState("");
  const [DepositDate, setDepositDate] = useState("");
  const [DepositAmount, setDepositAmount] = useState();
  const [LogoName, setlogoName] = useState("");
  const [WireConfirmationUrl, setWireConfirmationUrl] = useState("");
  const [LogoSize, setlogoSize] = useState();
  const [ClientId, setClientId] = useState(currentUser.user.ClientId);
  const [DateValid, setDateValid] = useState(true);

  //loader
  const [boloader, setBoLoader] = useState(false);
  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");

  useEffect(() => {
    setDateValid(true);
  }, [DepositDate]);
  const requestIdRequired = (value) => {
    if (!value) {
      document.getElementById("id_request").classList.add("has_error");
      return <span className="help-block">Reference No. is required</span>;
    } else document.getElementById("id_request").classList.remove("has_error");
  };
  const accountNumberRequired = (value) => {
    if (!value) {
      document.getElementById("id_accountnumber").classList.add("has_error");
      return <span className="help-block">Account number is required</span>;
    } else
      document.getElementById("id_accountnumber").classList.remove("has_error");
  };
  const bankNameRequired = (value) => {
    if (!value) {
      document.getElementById("id_bankname").classList.add("has_error");
      return <span className="help-block">Bank name is required</span>;
    } else document.getElementById("id_bankname").classList.remove("has_error");
  };
  const deposiAmountRequired = (value) => {
    if (!value) {
      document.getElementById("id_depositamount").classList.add("has_error");
      return <span className="help-block">deposit amount is required</span>;
    } else
      document.getElementById("id_depositamount").classList.remove("has_error");
  };
  const dateRequired = (value) => {
    if (!value) {
      document.getElementById("id_date").classList.add("has_error");
      return <span className="help-block">deposit amount is required</span>;
    } else document.getElementById("id_date").classList.remove("has_error");
  };
  const required = (value) => {
    if (!value) {
      return <span className="help-block">this field is required</span>;
    }
  };

  const onChangeRequestId = (e) => {
    const requestId = e.target.value;
    if (requestId != "") {
      let value = parseInt(requestId);
      setRequestId(value);
    }
  };
  const onChangepAccountNumber = (e) => {
    const accountNumber = e.target.value;
    setAccountNumberr(accountNumber);
  };
  const onChangeBank = (e) => {
    const bank = e.target.value;
    setBank(bank);
  };

  const onChangeDepositAmount = (e) => {
    const depositAmount = e.target.value;
    if (depositAmount != "") {
      let value = parseInt(depositAmount);
      setDepositAmount(value);
    }
  };

  const onChangeLogo = (e) => {
    const file_data = e.target.files[0];
    const formdata = new FormData();
    setlogoName(file_data.name);
    var size = formatBytes(file_data.size);
    setlogoSize(size);
    formdata.append("File", file_data);
    identityverificationObj
      .uploaduserdocs(formdata)
      .then((response) => {
        let name = response.data.payload;
        setWireConfirmationUrl(name);
      })
      .catch((error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          }
        })();
      });
  };
  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return "0 Bytes";
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
  };
  const saveUser = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (DepositDate === null || DepositDate === "") {
      setDateValid(false);
      return;
    } else {
      setDateValid(true);
    }

    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      clientmanagmentObj
        .depositamount(
          RequestId,
          AccountNumber,
          Bank,
          DepositDate,
          DepositAmount,
          WireConfirmationUrl,
          ClientId
        )
        .then((response) => {
          setBoLoader(false);
          if (response.data.message === "Success") {
            history.push("depositclients");
            window.location.reload();
          }
        })
        .catch((err) => {
          setBoLoader(false);
          let msgBody = err.response.data.title
            ? err.response.data.title
            : "Error Occured while saving data ";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          (async () => {
            let resp = await RefreshToken(
              err && err.response && err.response.status
            );
            if (resp) {
              if (resp) {
                saveUser(e);
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            }
          })();
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onCrossImg = () => {
    setlogoName("");
  };

  const onChangeDepositDate = (e) => {
    depositDate = e;
    if (e) {
      depositDate = dateFilter(depositDate);
      setDepositDate(depositDate);
    }
  };
  const dateFilter = (date) => {
    return new Date(date);
  };

  return (
    <Fragment>
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Submit Deposit</h2>
          <div className="mt-24">
            <Form onSubmit={saveUser} ref={form}>
              <div className="row">
                <div className="col-md-6" id="id_request">
                  <div className="form-group">
                    <label>Reference No.</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        className="form-control no-icon"
                        name="Name"
                        onWheel={(e) => e.target.blur()}
                        onChange={onChangeRequestId}
                        validations={[requestIdRequired, required]}
                        value={RequestId}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_accountnumber">
                  <div className="form-group">
                    <label>Account Number</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        onChange={onChangepAccountNumber}
                        validations={[accountNumberRequired, required]}
                        value={AccountNumber}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_bankname">
                  <div className="form-group">
                    <label>Bank</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="phoneNumber"
                        onChange={onChangeBank}
                        validations={[bankNameRequired, required]}
                        value={Bank}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_date">
                  <div className="form-group">
                    <label>Date</label>
                    <div className="form-controls no-icon">
                      <ReactDatePickerHelper
                        datevalue={DepositDate}
                        onchangedate={onChangeDepositDate}
                      />
                      {!DateValid && (
                        <span className="help-block has_error">
                          Date is Required
                        </span>
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="id_depositamount">
                  <div className="form-group">
                    <label>Deposit Amount</label>
                    <div className="form-controls">
                      <Input
                        type="number"
                        onWheel={(e) => e.target.blur()}
                        className="form-control no-icon"
                        name="prefundedAmount"
                        onChange={onChangeDepositAmount}
                        validations={[deposiAmountRequired, required]}
                        value={DepositAmount}
                      />
                    </div>
                  </div>
                </div>

                {LogoName === "" ? (
                  <div className="col-md-6" id="id_fileupload">
                    <div className="form-group">
                      <label>Upload Wirer Confirmation</label>
                      <div className="form-controls">
                        <div className="bo-file-control">
                          <Input
                            className="form-control file-control-input"
                            type="file"
                            readOnly
                            onChange={onChangeLogo}
                          />
                          <h5 className="form-control file-control file-control-text">
                            <label>Drag here to upload or</label> Browse file
                          </h5>
                          <span className="icon-upload-file _ico">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                        </div>
                      </div>
                      <span className="fs-12 clr-submarine mt-2 d-block">
                        Supported file types pdf/jpg file.
                      </span>
                    </div>
                  </div>
                ) : (
                  <div className="col-md-6">
                    <div className="form-group">
                      <div className="bo-file d-flex">
                        <div className="file-name">
                          <span className="icon-upload-file _ico">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                          {/* <img src={fileuploadimg} alt="" /> */}
                          <h5>
                            <span>{LogoName}</span>
                          </h5>
                        </div>
                        <div className="ml-auto file-size" onClick={onCrossImg}>
                          <span>{LogoSize}</span>
                          <img
                            className="cross-icon"
                            src={crossimg}
                            onClick={onCrossImg}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                <div className="col-md-12">
                  <div className="form-group submit-field d-flex justify-content-center mt-40 mb-0">
                    <a
                      className="btn btn-outline mr-19"
                      data-bs-dismiss="modal"
                      onClick={props.onHide}
                    >
                      Cancel
                    </a>
                    <a className="btn btn-large" onClick={saveUser}>
                      Submit
                    </a>
                  </div>
                </div>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
    </Fragment>
  );
};

export default DepositClientSubmit;
