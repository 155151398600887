import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import ModelPopup from "../helpers/ModelPopup";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { Fragment } from "react";
import TwoFaForm from "./account/TwoFaForm";
import settingsService from "../services/settings.service";
import DialoguePopup from "../helpers/DialoguePopup";
import BoLoader from "../helpers/BoLoader";
import RefreshToken from "../helpers/RefreshToken";
const cprequired = (value) => {
  if (!value) {
    if (!value) {
      document.getElementById("frmgrp-cpassword").classList.add("has_error");
      return <span className="help-block">Current Password is required!</span>;
    } else
      document.getElementById("frmgrp-cpassword").classList.remove("has_error");
  }
};
const nprequired = (value) => {
  if (!value) {
    if (!value) {
      document.getElementById("frmgrp-nwpassword").classList.add("has_error");
      return <span className="help-block">New Password is required!</span>;
    } else
      document
        .getElementById("frmgrp-nwpassword")
        .classList.remove("has_error");
  }
};
const cnprequired = (value) => {
  if (!value) {
    if (!value) {
      document.getElementById("frmgrp-cnwpassword").classList.add("has_error");
      return (
        <span className="help-block">Confirm New Password is required!</span>
      );
    } else
      document
        .getElementById("frmgrp-cnwpassword")
        .classList.remove("has_error");
  }
};
let passwordValue = "";
const vcpassword = (value) => {
  if (value.length < 8 || value.length > 20) {
    document.getElementById("frmgrp-cpassword").classList.add("has_error");
    return (
      <span className="help-block"> The password must be between 8 and 20 characters!</span>
    );
  } else
    document.getElementById("frmgrp-cpassword").classList.remove("has_error");
};
const vnpassword = (value) => {
  passwordValue = value;
  if (value.length < 8 || value.length > 20) {
    document.getElementById("frmgrp-nwpassword").classList.add("has_error");
    return (
      <span className="help-block"> The password must be between 8 and 20 characters!</span>
    );
  } else
    document.getElementById("frmgrp-nwpassword").classList.remove("has_error");
};
const vcnpassword = (value) => {
  if (value.length < 8 || value.length > 20) {
    document.getElementById("frmgrp-cnwpassword").classList.add("has_error");
    return (
      <span className="help-block">Please enterd a valid confirm password!</span>
    );
  } else if (value !== passwordValue) {
    return (
      <span className="help-block">
         The password must be between 8 and 20 characters!
      </span>
    );
  } else
    document.getElementById("frmgrp-cnwpassword").classList.remove("has_error");
};
const SettingsPageData = () => {
  const form = useRef();
  const checkBtn = useRef();
  const { user: currentUser } = useSelector((state) => state.auth);
  const [changePasswordMdlShow, setchangePasswordMdlShow] = useState(false);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [cnewPassword, setCNewPassword] = useState("");
  const [showTwoFA, setShowTwoFA] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [modalShow, setModalShow] = useState(false);
  //Loader
  const [boloader, setBoLoader] = useState(false);

  const ChangePasswordHandler = (e) => {
    setchangePasswordMdlShow(true);
  };
  const onChangeCPasswordHandler = (e) => {
    setCurrentPassword(e.target.value);
  };
  const onChangeNewPasswordHandler = (e) => {
    setNewPassword(e.target.value);
  };
  const onChangeCNPasswordHandler = (e) => {
    setCNewPassword(e.target.value);
  };
  const submitChangePasswordHandler = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      validateCurrentPassword();
    } 
    // else {
    //   let errIcon = "icon-error";
    //   errorAndSuccessModl(
    //     "Error",
    //     "Please enter correct current password",
    //     errIcon
    //   );
    // }
  };
  const validateCurrentPassword = (e) => {
    setBoLoader(true);
    settingsService
      .checkCurrentPassword(currentUser.id, currentPassword, newPassword)
      .then((resp) => {
        if (resp && resp.data && !resp.data.payload.result.success) {
          let msgBody =
            resp && resp.data && resp.data.payload.result.message
              ? resp.data.payload.result.message
              : "Error Occured while Validating Current Password";
          if (msgBody.includes(",")) {
            msgBody = (
              <Fragment>
                {msgBody.split(".")[0].replace(",", "")}
                <br />
                {msgBody.split(".")[1].replace(",", "")}
                <br />
                {msgBody.split(".")[2].replace(",", "")}
              </Fragment>
            );
          }
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          setShowTwoFA(false);
        } else {
          setShowTwoFA(true);
        }

        setBoLoader(false);
      })
      .catch((err) => {
        setBoLoader(false);
        let msgBody =
          err && err.response && err.response.data
            ? err.response.data.message
            : "Error Occured while Validating Current Password";
        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
        setShowTwoFA(false);
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      });
  };
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };
  const CloseMdlPopHandler = (e) => {
    e.preventDefault();
    setCurrentPassword("");
    setNewPassword("");
    setCNewPassword("");
    setchangePasswordMdlShow(false);
    setShowTwoFA(false);
  };
  const closeModelonPopupHide = () => {
    setCurrentPassword("");
    setNewPassword("");
    setCNewPassword("");
    setchangePasswordMdlShow(false);
    setShowTwoFA(false);
  };
  return (
    <Fragment>
      <BoLoader loading={boloader} />
      <section>
        <h3>Profile Details</h3>
        <form onSubmit={(e) => e.preventDefault()}>
          <div className="row mt-20">
            <div className="col-md-6">
              <ul className="ul-settings">
                <li>
                  <div>
                    <label>Name:</label>
                    <span>{currentUser.user.Name}</span>
                  </div>
                </li>
                <li>
                  <div>
                    <label>Email:</label>
                    <span>{currentUser.user.Email}</span>
                  </div>
                </li>
              </ul>
            </div>
          </div>
          <div className="row align-items-end">
            <div className="col-lg-4 col-md-5">
              <div className="form-group">
                <label htmlFor="email">Password</label>
                <div className="form-controls">
                  <input
                    type="password"
                    className="form-control"
                    value="1234567890"
                    disabled="disabled"
                  />
                  <span className="icon-lock _ico"></span>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-4">
              <div className="form-group submit-field">
                <button
                  data-bs-target="#changePassword"
                  data-bs-toggle="modal"
                  className="btn btn-large w-100-mbl"
                  onClick={ChangePasswordHandler}
                >
                  Change Password
                </button>
              </div>
            </div>
          </div>
        </form>
      </section>
      <ModelPopup
        show={changePasswordMdlShow}
        onHide={() => {
          closeModelonPopupHide();
        }}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="" style={{ display: showTwoFA ? "none" : "" }}>
              <div className="row">
                <div className="col-md-12">
                  <h2>Change Password</h2>
                  <div className="mt-16">
                    <div>
                      <Form onSubmit={submitChangePasswordHandler} ref={form}>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="form-group" id="frmgrp-cpassword">
                              <label>Current Password</label>
                              <div className="form-controls">
                                <Input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter Current Password"
                                  name="currentPassword"
                                  maxLength="20"
                                  value={currentPassword}
                                  onChange={onChangeCPasswordHandler}
                                  validations={[cprequired, vcpassword]}
                                  //onBlur={validateCurrentPassword}
                                />
                                <span className="icon-lock _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group" id="frmgrp-nwpassword">
                              <label>New Password</label>
                              <div className="form-controls">
                                <Input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter New Password"
                                  name="newPassword"
                                  maxLength="20"
                                  value={newPassword}
                                  onChange={onChangeNewPasswordHandler}
                                  validations={[nprequired, vnpassword]}
                                />
                                <span className="icon-lock _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group" id="frmgrp-cnwpassword">
                              <label>Confirm Password</label>
                              <div className="form-controls">
                                <Input
                                  type="password"
                                  className="form-control"
                                  placeholder="Enter Confirm Password"
                                  name="cnewPassword"
                                  maxLength="20"
                                  value={cnewPassword}
                                  onChange={onChangeCNPasswordHandler}
                                  validations={[cnprequired, vcnpassword]}
                                />
                                <span className="icon-lock _ico"></span>
                              </div>
                            </div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-24 mb-0">
                              <button
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={CloseMdlPopHandler}
                              >
                                Cancel
                              </button>
                              <button className="btn btn-large">Change</button>
                            </div>
                          </div>
                        </div>
                        <CheckButton
                          style={{ display: "none" }}
                          ref={checkBtn}
                        />
                      </Form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Fragment>
              {showTwoFA && (
                <TwoFaForm
                  userEmail={currentUser.user.Email}
                  userId={currentUser.id}
                  userMobile={currentUser.user.PhoneNumber ?? ""}
                  pagename={"settings"}
                  currentpassword={currentPassword}
                  newpassword={newPassword}
                  closepopup={() => CloseMdlPopHandler()}
                />
              )}
            </Fragment>
          </Fragment>,
        ]}
      />
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={() => {
          setModalShow(false);
          CloseMdlPopHandler();
        }}
      />
    </Fragment>
  );
};

export default SettingsPageData;
