import React, { useState, Fragment } from "react";
import arrowright from "../../assets/images/icons/arrow-right.svg";
import ModelPopup from "../../helpers/ModelPopup";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import ChangePinCardDetails from "./ChangePinCardDetails";
import { useSelector } from "react-redux";
import TwoFaForm from "../account/TwoFaForm";
import { Link } from "react-router-dom";

const CardHolderDetails = () => {
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [modaldeactivateshow, setModaldeactivateshow] = useState(false);
  const [modalresetshow, setModalresetshow] = useState(false);
  const [modalchangeshow, setModalchangeshow] = useState(false);
  const [calledfrom, setcalledfrom] = useState("");
  const [showTwoFA, setShowTwoFA] = useState(false);
  const [modalchangepinshow, setModalchangepinshow] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);

  const showHideModalReset = () => {
    setModalchangepinshow(false);
    setCnfrmModalShow(true);
    setShowTwoFA(true);
  };
  const showHideModalChange = () => {
    setCnfrmModalShow(false);
    setModalchangepinshow(true);
    setcalledfrom("change");
  };
  const getCardHolderDetails = () => {
    cardholdercardsObj.getCardHolderDetails().then(
      (response) => {},
      (error) => {}
    );
  };
  return (
    <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
      <div className="bo-right-sidebar-wrap">
        <h3>Card Details</h3>
        <ul className="ul-card-details-popup mt-20">
          <li className="d-flex justify-content-between mb-20">
            <label>Status</label>
            <span>
              <label className="switch">
                <input
                  type="checkbox"
                  onChange={() => setModaldeactivateshow(true)}
                />
                <span className="slider round"></span>
              </label>
              Active
            </span>
          </li>
          <li className="d-flex justify-content-between mb-20">
            <label>Balance</label>
            <span>$285100</span>
          </li>
          <li className="d-flex justify-content-between mb-20">
            <label>Token ID</label>
            <span>967840183</span>
          </li>
          <li className="d-flex justify-content-between mb-20">
            <label>Valid Thru</label>
            <span>06/23</span>
          </li>
          <li className="d-flex justify-content-between mb-20">
            <label>Card Type</label>
            <span>Virtual Card</span>
          </li>
          <li className="d-flex justify-content-between mb-20">
            <label>CVV</label>
            <span>
              <i className="icon-hidden"></i>***
            </span>
          </li>
          <li className="d-flex justify-content-between mb-20">
            <label>PIN</label>
            <span>
              <i className="icon-hidden"></i>***
            </span>
          </li>
        </ul>
        <div className="form-group submit-field d-flex mt-20">
          <Link
            to="#"
            className="btn btn-large w-100"
            data-bs-target="#changePin"
            data-bs-toggle="modal"
            onClick={() => setModalchangeshow(true)}
          >
            Change PIN
          </Link>
        </div>
        <div className="form-group submit-field d-flex  mt-1">
          <Link
            to="#"
            className="btn text-btn justify-content-center w-100"
            data-bs-target="#resetPin"
            onClick={() => setModalresetshow(true)}
            data-bs-toggle="modal"
          >
            <i className="icon-lock"></i>Reset PIN
          </Link>
        </div>
        <hr />
        <div className="mt-24 bo-help">
          <h3>Help</h3>
          <p>
            For further querires Admin will reach out via email if more details
            are required we will contact you.
          </p>
          <div className="a-arrow-btn mt-20">
            <Link to="#">
              Submit Your Query 
              <span className="icon-btn_arrow_right"></span>
              {/* <img src={arrowright} alt="arrow" /> */}
            </Link>
          </div>
        </div>
      </div>
      <ModelPopup
        key={1}
        show={modalchangeshow}
        onHide={() => setModalchangeshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Change PIN</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>Are you sure you want to change Card PIN?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              class="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => setModalchangeshow(false)}
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              class="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                showHideModalChange();
                                setModalchangeshow(false);
                              }}
                            >
                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={2}
        show={modalresetshow}
        onHide={() => setModalresetshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>Are you sure you want to reset Card PIN?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              class="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => setModalresetshow(false)}
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              class="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => {
                                showHideModalReset();
                                setModalresetshow(false);
                              }}
                            >
                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={3}
        show={modaldeactivateshow}
        onHide={() => setModaldeactivateshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Are you sure you want to deactivate your card?</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            <Link
                              to="#"
                              className="btn btn-outline"
                              data-bs-dismiss="modal"
                              onClick={() => setModaldeactivateshow(false)}
                            >
                              No
                            </Link>
                            <Link
                              to="#"
                              className="btn btn-large"
                              data-bs-dismiss="modal"
                              onClick={() => setModaldeactivateshow(false)}
                            >
                              Yes
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />

      <ChangePinCardDetails
        key={8}
        show={modalchangepinshow}
        onHide={() => setModalchangepinshow(false)}
        calledfrom={calledfrom}
      />

      <ModelPopup
        key={7}
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            {showTwoFA && (
              <TwoFaForm
                userEmail={currentUser.user.Email}
                userId={currentUser.id}
                userMobile={currentUser.user.PhoneNumber ?? ""}
                pagename={"cardholderresetpin"}
                onHide={() => setCnfrmModalShow(false)}
              />
            )}
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default CardHolderDetails;
