// import React, { useState } from "react";
import { Fragment } from "react";
import CardDetails from "./CardDetails";
import CardHolderDetails from "./CardHolderDetails";
import SearchGridCards from "./SearchGridCards";
import CardLimit from "./CardLimits";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import useWindowDimensions from "../../helpers/WindowWidth";
const CardHolderCards = () => {
  // const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const { height, width } = useWindowDimensions();
  const getCardDetails = () => {
    cardholdercardsObj.getCardDetails().then(
      (response) => {},
      (error) => {}
    );
  };
  const getCardLimits = () => {
    cardholdercardsObj.getCardLimits().then(
      (response) => {},
      (error) => {}
    );
  };

  return (
    <Fragment>
      <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
        <h3>
          Your Cards<span className="bo-badge bo-badge-large">3</span>
        </h3>
        <div className="row mt-20">
          <CardDetails />
          <CardLimit />
        </div>

        <div className="row">{width <= 991 && <CardHolderDetails />}</div>

        <SearchGridCards
          headersdata={[
            "Transaction ID",
            "Date",
            "Time",
            "Category",
            "Amount",
            "VIEW",
          ]}
        />
      </div>
      {width > 991 && <CardHolderDetails />}
    </Fragment>
  );
};

export default CardHolderCards;
