import { Link } from "react-router-dom";
const ClientCards = () => {
  return (
    <div
      className="tab-pane fade fade show active"
      id="cards"
      role="tabpanel"
      aria-labelledby="cards-tab"
    >
      <div>
        <h3>Cards</h3>
        <form className="mt-20">
          <div className="row row-search">
            <div className="col-xl-8 col-12">
              <div className="form-group">
                <div className="form-controls">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Search Card"
                  />
                  <span className="icon-search _ico"></span>
                  {/* search box  */}
                  <div className="bo-cardsearch d-none">
                    <div className="row">
                      <div className="col-md-6">
                        <div className="form-group form-search mr-20">
                          <label>Name</label>
                          <div className="form-controls">
                            <input className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-search">
                          <label>Client ID</label>
                          <div className="form-controls">
                            <input className="form-control" />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group submit-field form-search">
                          <Link to="#" className="btn btn-large">
                            Search
                          </Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <span className="help-block d-none"></span>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="form-group">
                <div className="form-controls">
                  <input
                    className="form-control right-icon"
                    placeholder="Date From"
                  />
                  <span className="icon-calender _ico icon-right"></span>
                </div>
                <span className="help-block d-none">Enter valid date</span>
              </div>
            </div>
            <div className="col-xl-2 col-6">
              <div className="form-group">
                <div className="form-controls">
                  <input
                    className="form-control right-icon"
                    placeholder="Date To"
                  />
                  <span className="icon-calender _ico icon-right"></span>
                </div>
                <span className="help-block d-none">Enter valid date</span>
              </div>
            </div>
          </div>
        </form>
        <div className="table-wrap">
          <table className="table table-borderless bo-table table-cards">
            <thead>
              <th>Cardholder</th>
              <th>token id</th>
              <th>expiry date</th>
              <th>issue date</th>
              <th>balance</th>
              <th>status</th>
              <th>view</th>
            </thead>
            <tbody>
              <tr>
                <td>Anika Bergson</td>
                <td>197549098</td>
                <td>March 23, 2026</td>
                <td>March 23, 2021</td>
                <td>$800</td>
                <td>Active</td>
                <td>
                  <span className="icon-show"></span>
                </td>
              </tr>
              <tr>
                <td>Anika Bergson</td>
                <td>197549098</td>
                <td>March 23, 2026</td>
                <td>March 23, 2021</td>
                <td>$800</td>
                <td>Active</td>
                <td>
                  <span className="icon-show"></span>
                </td>
              </tr>
              <tr>
                <td>Anika Bergson</td>
                <td>197549098</td>
                <td>March 23, 2026</td>
                <td>March 23, 2021</td>
                <td>$800</td>
                <td>Inactive</td>
                <td>
                  <span className="icon-show"></span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        <div className="loading-more">
          <div className="loader">
            <div></div>
          </div>
          <p>loading more</p>
        </div>
      </div>
    </div>
  );
};

export default ClientCards;
