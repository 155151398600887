import React from "react";
import logo from "../assets/images/logo.svg";
import particlesmblicon from "../assets/images/particles-mbl.svg";

function BoPaymentWrapper(props) {
  return (
    <div className="col-md-5 col-lg-4 onbo-sidebar">
      <img className="particles-mbl" src={particlesmblicon} alt="prctice" />
      <div className="onbo-inn">
        <div className="bo-thumb">
          <a href="/">
            <img src={logo} className="img-fluid" alt="BO Payments" />
          </a>
        </div>
        <div className="bo-info mt-70">
          <h1>
            <h1>{props.pagename}</h1>
          </h1>
          <h4>{props.pagemsg}</h4>
        </div>
      </div>
    </div>
  );
}

export default BoPaymentWrapper;
