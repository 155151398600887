import BeatLoader from "react-spinners/BeatLoader";
import { css } from "@emotion/react";
import React from "react";

const override = css`
  display: block;
`;
const BoLoader = (props) => {
  return (
    <div
      className="sweet-loading"
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        zIndex: 999999,
        width: "100vw",
        height: "100vh",
        backgroundColor: "#000",
        opacity: 0.5,
        display: props.loading ? "flex" : "none",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <BeatLoader
        color={"#89455e"}
        loading={props.loading}
        size={15}
        speedMultiplier="1"
        css={override}
      />
    </div>
  );
};

export default BoLoader;
