import React, { useState, useRef, Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import card from "../../assets/images/Card.png";
import arrowleft from "../../assets/images/icons/arrow-left.svg";
import arrowright from "../../assets/images/icons/arrow-right.svg";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import CheckButton from "react-validation/build/button";
import cardholdercardsObj from "../../services/cardholder-cards.service";
import { history } from "../../helpers/history";
import { Link } from "react-router-dom";
const ChangePinCardDetails = (props) => {
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [modalerrorShow, setModalerrorShow] = useState(false);
  const [currentpin, setcurrentpin] = useState("");
  const [newpin, setnewpin] = useState("");
  const [confirmpin, setconfirmpin] = useState("");
  const form = useRef();
  const checkBtn = useRef();
  const currentRequired = (value) => {
    if (!value) {
      document.getElementById("id_current_pass").classList.add("has_error");
      return <span className="help-block">Current Pin is Required</span>;
    } else
      document.getElementById("id_current_pass").classList.remove("has_error");
  };
  let passwordValue = "";
  const newRequired = (value) => {
    passwordValue = value;
    if (!value) {
      document.getElementById("id_new_pass").classList.add("has_error");
      return <span className="help-block">New Pin is Required</span>;
    } else document.getElementById("id_new_pass").classList.remove("has_error");
  };
  const confirmRequired = (value) => {
    if (!value) {
      document.getElementById("id_confirm_pass").classList.add("has_error");
      return <span className="help-block">Confirm Pin is Required</span>;
    } else
      document.getElementById("id_confirm_pass").classList.remove("has_error");
  };

  const vpassword = (value) => {
    if (value !== passwordValue) {
      document.getElementById("id_confirm_pass").classList.add("has_error");
      return (
        <span className="help-block">
          The password and confirm password must match
        </span>
      );
    } else
      document.getElementById("id_confirm_pass").classList.remove("has_error");
  };
  const onClickReset = () => {
    setModalsuccessfullShow(true);
    //alert("this is Reset");
  };
  const onClickChange = () => {
    setModalsuccessfullShow(true);
    // alert("this is Change");
  };
  const onChangeCurrentPin = (e) => {
    const value = e.target.value;

    setcurrentpin(value);
  };
  const onChangeNewPin = (e) => {
    const value = e.target.value;

    setnewpin(value);
  };
  const onChangeConfirmPin = (e) => {
    const value = e.target.value;
    setconfirmpin(value);
  };
  const onHideModal = () => {
    props.onHide();
  };
  const handleRegister = (e) => {
    e.preventDefault();
    form.current.validateAll();
    if (checkBtn.current.context._errors.length === 0) {
      if (props.calledfrom === "reset") {
        onClickReset();
        //props.onHide();
      } else {
        onClickChange();
        props.onHide();
      }
    }
  };
  const changeCardHolderPin = () => {
    cardholdercardsObj
      .changeCardHolderPin(currentpin, newpin, confirmpin)
      .then((response) => {
        if (!response.data.isError) {
          if (response.data.payload.succeeded) {
          }
        }
      })
      .catch((err) => {});
  };
  const gotoDashboard = () => {
    props.onHide();
    history.push("dashboard");
  };

  return (
    <Fragment>
      <ModelPopup
        key={6}
        show={props.show}
        onHide={props.onHide}
        calledfrom={props.calledfrom}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall "
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={
          <div className="row">
            <div className="col-md-12">
              {props.calledfrom === "reset" ? (
                <h2>Reset Card PIN</h2>
              ) : (
                <h2>Change Card PIN</h2>
              )}

              <div className="mt-16">
                <div
                  id="resetCardPin"
                  className="carousel slide mb-32"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-indicators">
                    <button
                      type="button"
                      data-bs-target="#resetCardPin"
                      data-bs-slide-to="0"
                      className="active"
                      aria-current="true"
                      aria-label="Slide 1"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#resetCardPin"
                      data-bs-slide-to="1"
                      aria-label="Slide 2"
                    ></button>
                    <button
                      type="button"
                      data-bs-target="#resetCardPin"
                      data-bs-slide-to="2"
                      aria-label="Slide 3"
                    ></button>
                  </div>
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <img className="d-block w-100" src={card} alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src={card} alt="..." />
                    </div>
                    <div className="carousel-item">
                      <img className="d-block w-100" src={card} alt="..." />
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    data-bs-target="#myCards"
                    data-bs-slide="prev"
                  >
                    <span className="prev-icon d-flex align-items-center justify-content-center">
                      <img src={arrowleft} aria-hidden="true" alt="arrow" />
                    </span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#myCards"
                    data-bs-slide="next"
                  >
                    <span className="next-icon d-flex align-items-center justify-content-center">
                      <img src={arrowright} aria-hidden="true" alt="arrow" />
                    </span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
                <div>
                  <Form onSubmit={handleRegister} ref={form}>
                    <div className="row">
                      {props.calledfrom === "change" && (
                        <div className="col-md-12">
                          <div className="form-group" id="id_current_pass">
                            <label for="email">Current Card PIN*</label>
                            <div className="form-controls">
                              <Input
                                type="password"
                                className="form-control no-icon"
                                value={currentpin}
                                onChange={onChangeCurrentPin}
                                validations={[currentRequired]}
                                placeholder="Enter Current 4 Digit PIN"
                                maxLength={4}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="col-md-12">
                        <div className="form-group" id="id_new_pass">
                          <label for="email">New Card PIN*</label>
                          <div className="form-controls">
                            <Input
                              type="password"
                              className="form-control no-icon"
                              value={newpin}
                              onChange={onChangeNewPin}
                              validations={[newRequired]}
                              placeholder="Enter New 4 Digit PIN"
                              maxLength={4}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12" id="id_confirm_pass">
                        <div className="form-group">
                          <label for="email">Confirm Card PIN*</label>
                          <div className="form-controls">
                            <Input
                              type="password"
                              className="form-control  no-icon"
                              value={confirmpin}
                              onChange={onChangeConfirmPin}
                              validations={[confirmRequired, vpassword]}
                              placeholder="Confirm 4 Digit PIN"
                              maxLength={4}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-between mt-24">
                          <Link
                            to="#"
                            className="btn btn-outline mr-19"
                            data-bs-dismiss="modal"
                            onClick={onHideModal}
                          >
                            Cancel
                          </Link>
                          {props.calledfrom === "reset" ? (
                            <Link
                              to="#"
                              className="btn btn-large"
                              onClick={handleRegister}
                            >
                              Reset
                            </Link>
                          ) : (
                            <Link
                              to="#"
                              className="btn btn-large"
                              onClick={handleRegister}
                            >
                              Change
                            </Link>
                          )}
                        </div>
                      </div>
                    </div>
                    <CheckButton style={{ display: "none" }} ref={checkBtn} />
                  </Form>
                </div>
              </div>
            </div>
          </div>
        }
      />
      <ModelPopup
        key={4}
        show={modalerrorShow}
        onHide={() => setModalerrorShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <div class="icon-alert icon-alert-popup">
                  <span class="icon-error">
                    <span class="path1"></span>
                    <span class="path2"></span>
                  </span>
                </div>
                <h2>Error</h2>
                <div class="mt-16">
                  <div>
                    <form>
                      <div class="row">
                        <div class="col-md-12">
                          <p>You Entered incorrect current PIN.</p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group submit-field d-flex justify-content-between mt-24">
                            <Link to="#" class="btn btn-outline mr-19">
                              Back
                            </Link>
                            <Link to="#" class="btn btn-large">
                              Try Again
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Your Card PIN has been changed successfully.</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                gotoDashboard();
                              }}
                            >
                              Go to Dashboard
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </Fragment>
  );
};
export default ChangePinCardDetails;
