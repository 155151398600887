import { Button } from "bootstrap";
import React, { useState, useEffect, Fragment } from "react";
import ReactExport from "react-export-excel";
import xls from "../../assets/images/xls.svg";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const ExportToExcel = props => {
 const ExcelColmn=()=>{
    if(props.calledfrom==="transaction"){
        return(
            <ExcelFile
            element={
              <button className="btn btn-outline">
                <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
                {" "}
              </button>
            }
          >
            <ExcelSheet data={props.Dataset} name={props.Name}>
           
               <ExcelColumn label="NAME" value="name" />
               <ExcelColumn label="TOKEN ID" value="accountNumber" />
               <ExcelColumn label="AMOUNT" value="amount" />
               <ExcelColumn label="DATE" value="createdDate" />
               <ExcelColumn label="STATUS" value="status" />
              
            </ExcelSheet>
          </ExcelFile>   )
    }
    else if (props.calledfrom === "cardholdertransactions") {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Export{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="TRANSACTION TYPE" value="transactionType" />
            <ExcelColumn label="MERCHANT NAME" value="merchantName" />
            <ExcelColumn label="STATUS" value="status" />
            <ExcelColumn label="AMOUNT" value="authAmount" />
            <ExcelColumn label="DATE" value="dateOfTransaction" />
            <ExcelColumn label="REF #" value="retrievalReferenceNumber" />
          </ExcelSheet>
        </ExcelFile>
      );
    } 
    else if (props.calledfrom === "ledgerclient") {
      return (
        <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} />{" "}
              Download{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
            <ExcelColumn label="Date" value="createdDate" />
            <ExcelColumn label="Transaction" value="debitCredit" />
            <ExcelColumn label="Transaction TYPE" value="transactionType" />
            <ExcelColumn label="TOKEN ID" value="tokenId" />
            <ExcelColumn label="AMOUNT" value="amount" />
            <ExcelColumn label="FROM CLIENT" value="clientName" />
            <ExcelColumn label="TO CLIENT" value="toClient" />
            <ExcelColumn label="DESCRIPTION" value="description" />
          </ExcelSheet>
        </ExcelFile>
      );
    }
    else if(props.calledfrom==="client"){
        return(
            <ExcelFile
            element={
              <button className="btn btn-outline">
                <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
                {" "}
              </button>
            }
          >
            <ExcelSheet data={props.Dataset} name={props.Name}>
           
               <ExcelColumn label="CLIENT NAME" value="clientName" />
               <ExcelColumn label="ADDRESS" value="address" />
               <ExcelColumn label="REG.DATE" value="registrationDate" />
               <ExcelColumn label="CONTACT PERSON" value="pointOfContact" />
               <ExcelColumn label="STATUS" value="clientStatus" />
               <ExcelColumn label="CLIENT LEDGER BALANCE" value="balance" />
               <ExcelColumn label="CLIENT LEDGER CURRENCY" value="currency" />
              
            </ExcelSheet>
          </ExcelFile>   
        )
    }
    else if(props.calledfrom==="roles"){
      return(
          <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
              {" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
         
          <ExcelColumn label="FIRST NAME" value="firstName" />
               <ExcelColumn label="LAST NAME" value="lastName" />
               <ExcelColumn label="EMAIL" value="email" />
               <ExcelColumn label="ACTIVE STATUS" value="isActive" />
               <ExcelColumn label="PHONE NUMBER" value="phoneNumber" />
               <ExcelColumn label="DATE OF BIRTH" value="dateofBirth" />
            
          </ExcelSheet>
        </ExcelFile>   
      )
  }
    else if(props.calledfrom==="cards"){
        return(
            <ExcelFile
            element={
              <button className="btn btn-outline">
                <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Download{" "}
              </button>
            }
          >
            <ExcelSheet data={props.Dataset} name={props.Name}>
           
            <ExcelColumn label="FIRST NAME" value="cardHolderFirstName" />
               <ExcelColumn label="LAST NAME" value="cardHolderLastName" />
               <ExcelColumn label="TOKEN ID" value="tokenId" />
               <ExcelColumn label="CARD NO" value="cardNumber" />
               <ExcelColumn label="CLIENT NAME" value="companyName" />
               <ExcelColumn label="EXPIRY DATE" value="expiryDate" />
               <ExcelColumn label="ISSUE DATE" value="issueDate" />
               <ExcelColumn label="STATUS" value="cardStatus" />

              
            </ExcelSheet>
          </ExcelFile>   
        )
    }
    else if(props.calledfrom==="suspendedcards"){
      return(
          <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Download{" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
         
             <ExcelColumn label="FIRST NAME" value="firstName" />
             <ExcelColumn label="LAST NAME" value="lastName" />
             <ExcelColumn label="TOKEN ID" value="tokenId" />
             <ExcelColumn label="CLIENT NAME" value="clientName" />
             <ExcelColumn label="SUSPENDED DATE" value="createdAt" />
             <ExcelColumn label="DESCRIPTION" value="description" />
             <ExcelColumn label="ISSUE DATE" value="issueDate" />
             <ExcelColumn label="EXPIRY DATE" value="expiryDate" />

            
          </ExcelSheet>
        </ExcelFile>   
      )
  }
    else if(props.calledfrom==="deposit"){
      return(
          <ExcelFile
          element={
            <button className="btn btn-outline">
              <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
              {" "}
            </button>
          }
        >
          <ExcelSheet data={props.Dataset} name={props.Name}>
         
             <ExcelColumn label="REFERENCE NO." value="requestId" />
             <ExcelColumn label="DATE" value="depositDate" />
             <ExcelColumn label="AMOUNT" value="acceptedAmount" />
             <ExcelColumn label="CLIENT NAME" value="clientName" />
             <ExcelColumn label="SENDING BANK" value="bank" />
             <ExcelColumn label="ACCOUNT NO" value="accountNumber" />
             <ExcelColumn label="STATUS" value="depositStatus" />

            
          </ExcelSheet>
        </ExcelFile>   
      )
  }
  else if(props.calledfrom==="bulkload"){
    return(
        <ExcelFile
        element={
          <button className="btn btn-outline">
            <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
            To Excel{" "}
          </button>
        }
      >
        <ExcelSheet data={props.Dataset} name={props.Name}>
       
           <ExcelColumn label="Amount" value="amount" />
           <ExcelColumn label="TokenId" value="tokenId" />
           <ExcelColumn label="Currency" value="currency" />
          
        </ExcelSheet>
      </ExcelFile>   
    )
}
else if(props.calledfrom==="bulkcard"){
  return(
      <ExcelFile
      element={
        <button className="btn btn-outline">
          <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
          To Excel{" "}
        </button>
      }
    >
      <ExcelSheet data={props.Dataset} name={props.Name}>
     
         <ExcelColumn label="FirstName" value="firstName" />
         <ExcelColumn label="LastName" value="lastName" />
         <ExcelColumn label="PrefferedName" value="preferredName" />
         <ExcelColumn label="Nationality" value="nationality" />
         <ExcelColumn label="CountryCode" value="countryCode" />
         <ExcelColumn label="Gender" value="gender" />
         <ExcelColumn label="Mobile" value="mobile" />
         <ExcelColumn label="DateOfBirth" value="dateOfBirth" />
         <ExcelColumn label="DeliveryAddress1" value="deliveryAddress1" />
         <ExcelColumn label="DeliveryCity" value="deliveryCity" />
         <ExcelColumn label="DeliveryZipCode" value="deliveryZipCode" />
         <ExcelColumn label="DeliveryCountry" value="deliveryCountry" />
         <ExcelColumn label="BillingAddress1" value="billingAddress1" />
         <ExcelColumn label="BillingCity" value="billingCity" />
         <ExcelColumn label="BillingZipCode" value="billingZipCode" />
         <ExcelColumn label="BillingCountry" value="billingCountry" />
         <ExcelColumn label="Email" value="email" />
         <ExcelColumn label="SpendingLimits" value="spendingLimits" />
        
      </ExcelSheet>
    </ExcelFile>   
  )
}
    else{
        return( <ExcelFile
            element={
              <button className="btn btn-outline">
                <img style={{ width: "20px", marginTop: "-3px" }} src={xls} /> Export
                {" "}
              </button>
            }
          >
            <ExcelSheet data={props.Dataset} name={"No Data"}>
            </ExcelSheet>
          </ExcelFile>   );
    }
}
  return (
   <ExcelColmn/>
  );
};

export default ExportToExcel;
