import React, { useState, useRef, Fragment, useEffect } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import RefreshToken from "../../helpers/RefreshToken";
import DialoguePopup from "../../helpers/DialoguePopup";
import BoLoader from "../../helpers/BoLoader";
import cardsService from "../../services/cards.services";
import DateFormat from "../../helpers/DateFormat";
import ModelPopup from "../../helpers/ModelPopup";

const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status:"",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
const SearchGridNotes = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [clientId, setclientId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  //Dialouge Popup Error Messages
  const [modalShowDialouge, setModalShowDialouge] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  //Success Message
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [userId, setUserId] = useState();
  //Desciption Modal
  const [modalShowNotesDesc, setmodalShowNotesDesc] = useState(false);
  const [modalShowNotesAccept, setmodalShowNotesAccept] = useState(false);
  const [descriptionnotes, setDescriptionnotes] = useState(false);
  const [IdNotes, setIdNotes] = useState(false);

  useEffect(() => {
    SearchClickHandler()
   
  }, []);
 
 
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date.getTime());
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const SearchClickHandler = (e,isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = !clientId ? clientIdVal : clientId;
    else SearchGridInputData[0].ClientId = "";
    setModalShow(false);
    setModalShow(false);
    setSearchDisabled(false);
    cardsService.searhGridNotes
      (
        name,
        SearchGridInputData[0].ClientId.toString(),
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
        SearchGridInputData[0].Status,
        props?.userId,

      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result
              );
              setSearchData(appendedData);
            } else {
              setSearchData(resp.data.payload.result);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
          }
        }
        
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const showClientDetails = (id) => {
  setCnfrmModalShow(true);
  setUserId(id);
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
 
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        description,
        userId,
        createdDate,
        operatorUserId,
        id,
        isDeleted
      } = data; //destructuring
      return (
        <tr key={index}>
           <td style={fontStyle}>{operatorUserId}</td>
          <td style={fontStyle} title={description} onClick={()=>showNotesDesc(description)}>{description?.substr(0, 40)}{description?.length > 40 && ("...")}</td>
          <td style={fontStyle}><DateFormat date={createdDate} /></td>
         {!isDeleted  ?(
          <td>
              <span
                className="icon-show"
                onClick={() => showAcceptRejectModel(id)}
              ></span>
            </td>
         ):(<td>YES</td>)} 
          
        </tr>
      );
    });
  };
 
  // const ClearInputData = () => {
  //   if (document.getElementById("clientNameDD") !== null)
  //     document.getElementById("clientNameDD").value = "";
  //   setClientIdVal("");
  //   setTimeout(() => {
  //     [...document.getElementsByClassName("r-input-group")].forEach(
  //       (element) => {
  //         element.children[0].value = "";
  //       }
  //     );
  //     document.getElementById("searchBtnClients").click();
  //   }, 500);
  // };
  const showNotesDesc=(description)=>{
    setmodalShowNotesDesc(true);
    setDescriptionnotes(description)
  }
  const readUnreadNotes=()=>{
    setmodalShowNotesAccept(false);
  }
  const showAcceptRejectModel=(id)=>{
    setIdNotes(id)
    setmodalShowNotesAccept(true);
  }
  const saveResponseNote=()=>{
    setBoLoader(true);
    cardsService.saveResponseNote(IdNotes).then(()=>{
      setBoLoader(false);
      setmodalShowNotesAccept(false);
      SearchClickHandler();
    }).catch(()=>{
      setBoLoader(false);
    })
  }
  return (
    <div className="mt-0 mt-sm-4">
      <div className={classContent} id="myTabContent2">
      <BoLoader loading={boloader} />
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          {/* no client found  */}
          <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src="../../assets/images/icons/clients-empty.svg" /> */}
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
        </div>

        {searchGridSuccess && (
          <div className="table-wrap" ref={searchTbl}>
            <table
              className="table table-borderless bo-table table-cards"
              onScroll={tblScrollHandler}
              onWheel={tblScrollHandler}
            >
              <thead>
                <tr>{renderTableHeader()}</tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
        )}
        {loadingData ? (
          <div className="loading-more">
            <div className="loader">
              <div></div>
            </div>
            <p>loading data</p>
          </div>
        ) : totalCount === 0 ? (
          <div className="d-flex align-content-center justify-content-center  no-clients-msg">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src={emptyclient} /> */}
              <h3>Empty Notes Listing</h3>
              <h6>There is no Note(s) available for now.</h6>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
          <ModelPopup
            show={modalShowNotesDesc}
            key={23}
            onHide={() => {setmodalShowNotesDesc(false);}}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            modelbody={[
              <Fragment>
                <span className="note-break">{descriptionnotes}</span>
              </Fragment>,
            ]}
          />
          <ModelPopup
            show={modalShowNotesAccept}
            key={32}
            onHide={() => {setmodalShowNotesAccept(false);}}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            modelbody={[
              <Fragment>
                 <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Notes</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Note Resolved?</p>
                        </div>
                        <div className="col-md-12">
                         <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                            <button
                            type="button"
                            className="btn btn-outline mr-19"
                              onClick={() => {
                                saveResponseNote();
                              }}
                           >
                             YES
                           </button>
                            <button
                             type="button"
                             className="btn btn-large"
                              onClick={() => {
                                setmodalShowNotesAccept(false);
                              }}
                            >
                              Close
                              </button>
                              </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
              </Fragment>,
            ]}
          />
      <DialoguePopup
        show={modalShowDialouge}
        onHide={() => setModalShowDialouge(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      
      />
    </div>
  );
};

export default SearchGridNotes;
