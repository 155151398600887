import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import React, { useState, useRef, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import CountDownTimer from "./countDownTimer";
import { send_two_FA_Code, get_two_FA_Code } from "../../actions/account";
import { history } from "../../helpers/history";
import loaderimg from "../../assets/images/icons/loader.svg";
import settingsService from "../../services/settings.service";
import BoLoader from "../../helpers/BoLoader";
import ChangePinCardDetails from "../cardholderCardScreens/ChangePinCardDetails";
import DialoguePopup from "../../helpers/DialoguePopup";

////// Note :Donot remove commented code as it is used for mobile 2fa ,which we will uncomment when required

let ecode1 = "";
let ecode2 = "",
  ecode3 = "",
  ecode4 = "",
  ecode5 = "",
  ecode6 = "";
// smscode1 = "",
// smscode2 = "",
// smscode3 = "",
// smscode4 = "",
// smscode5 = "",
// smscode6 = "";

let maskedUserEmail = "";
let interval = "";
let isFirstTime = true;
// let maskedUserMobileNumber = "";
const TwoFaForm = (props) => {
  const { twoFACode: twoFaData } = useSelector((state) => state.account);
  const [maskedEmail, setMaskedEmail] = useState("");
  // const [maskedMobileNo, setMaskedMobileNo] = useState("");
  const [settingsScreenSuccess, setsettingsScreenSuccess] = useState(false);
  const [settingsScreenFailed, setsettingsScreenFailed] = useState(false);
  const [cardHolderCardResetPin, setCardHolderCardResetPin] = useState(false);
  const [disable2faButton, setDisable2faButton] = useState("disabled");
  // const [clientAdminResetPin, setclientAdminResetPin] = useState(false);
  //const [isFirstTime, setIsFirstTime] = useState(true);
  const [boloader, setBoLoader] = useState(false);
  useEffect(() => {
    isFirstTime = true;
    document.getElementById("timerbtn").click();
    maskedUserEmail = "";
    let splitEmail = props.userEmail.split("@");
    let splitEmailIndex = splitEmail[0];
    for (var i in splitEmailIndex) {
      if (i > 0 && i < splitEmailIndex.length) maskedUserEmail += "*";
      else maskedUserEmail += splitEmailIndex[i];
    }
    maskedUserEmail = maskedUserEmail + "@" + splitEmail[1];
    setMaskedEmail(maskedUserEmail);
    // let lst3_dgt_mobileNo = props.userMobile.substr(
    //   10,
    //   props.userMobile.length
    // );
    // for (var j in props.userMobile) {
    //   if (j > 0 && j < props.userMobile.length - 3)
    //     maskedUserMobileNumber += "*";
    // }
    // setMaskedMobileNo(maskedUserMobileNumber + lst3_dgt_mobileNo);
    if (props.pagename === "settings") Resend2FA();
    if (props.pagename === "cardholderresetpin") {
      setCardHolderCardResetPin(false);
      Resend2FA();
    }
    interval = setInterval(() => {
      let mint = document.getElementById("mints");
      let secs = document.getElementById("secs");
      if (
        mint &&
        mint.textContent === "00" &&
        secs &&
        secs.textContent === "00"
      )
        setDisable2faButton("");
    }, 10000);
  }, []);
  const form = useRef();
  const checkBtn = useRef();

  const [emailCode1, setEmailCode1] = useState("");

  const [emailCode2, setEmailCode2] = useState("");

  const [emailCode3, setEmailCode3] = useState("");

  const [emailCode4, setEmailCode4] = useState("");

  const [emailCode5, setEmailCode5] = useState("");

  const [emailCode6, setEmailCode6] = useState("");
  const [two_fa_successful, set_two_fa_Successful] = useState(false);
  const [showtimer, settimerflag] = useState(true);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [modalShow, setModalShow] = useState(false);

  // const [smsCode1, setSmsCode1] = useState("");
  // const [smsCode2, setSmsCode2] = useState("");
  // const [smsCode3, setSmsCode3] = useState("");
  // const [smsCode4, setSmsCode4] = useState("");
  // const [smsCode5, setSmsCode5] = useState("");
  // const [smsCode6, setSmsCode6] = useState("");
  // const onChangeSmsCode1 = (e) => {
  //   smscode1 = e.target.value;
  //   setSmsCode1(smsCode1);
  //   checkAllValuesFilled();
  // };
  // const onChangeSmsCode2 = (e) => {
  //   smscode2 = e.target.value;
  //   setSmsCode2(smsCode2);
  //   checkAllValuesFilled();
  // };
  // const onChangeSmsCode3 = (e) => {
  //   smscode3 = e.target.value;
  //   setSmsCode3(smsCode3);
  //   checkAllValuesFilled();
  // };
  // const onChangeSmsCode4 = (e) => {
  //   smscode4 = e.target.value;
  //   setSmsCode4(smsCode4);
  //   checkAllValuesFilled();
  // };
  // const onChangeSmsCode5 = (e) => {
  //   smscode5 = e.target.value;
  //   setSmsCode5(smsCode5);
  //   checkAllValuesFilled();
  // };
  // const onChangeSmsCode6 = (e) => {
  //   smscode6 = e.target.value;
  //   setSmsCode6(smsCode6);
  //   checkAllValuesFilled();
  // };

  const onChangeEmailCode1 = (e) => {
    ecode1 = e.target.value;
    setEmailCode1(ecode1);
    if (ecode1 !== "") document.getElementsByName("emailcode2")[0].focus();
    checkAllValuesFilled();
  };

  const onChangeEmailCode2 = (e) => {
    ecode2 = e.target.value;
    setEmailCode2(ecode2);
    if (ecode2 !== "") document.getElementsByName("emailcode3")[0].focus();
    checkAllValuesFilled();
    if (ecode2 === "") document.getElementsByName("emailcode1")[0].focus();
  };
  const onChangeEmailCode3 = (e) => {
    ecode3 = e.target.value;
    setEmailCode3(ecode3);
    if (ecode3 !== "") document.getElementsByName("emailcode4")[0].focus();
    checkAllValuesFilled();
    if (ecode3 === "") document.getElementsByName("emailcode2")[0].focus();
  };
  const onChangeEmailCode4 = (e) => {
    ecode4 = e.target.value;
    setEmailCode4(ecode4);
    if (ecode4 !== "") document.getElementsByName("emailcode5")[0].focus();
    checkAllValuesFilled();
    if (ecode4 === "") document.getElementsByName("emailcode3")[0].focus();
  };
  const onChangeEmailCode5 = (e) => {
    ecode5 = e.target.value;
    setEmailCode5(ecode5);
    if (ecode5 !== "") document.getElementsByName("emailcode6")[0].focus();
    checkAllValuesFilled();
    if (ecode5 === "") document.getElementsByName("emailcode4")[0].focus();
  };
  const onChangeEmailCode6 = (e) => {
    ecode6 = e.target.value;
    setEmailCode6(ecode6);
    checkAllValuesFilled();
    if (ecode6 === "") document.getElementsByName("emailcode5")[0].focus();
  };
  const checkAllValuesFilled = () => {
    if (
      ecode1 !== "" &&
      ecode2 !== "" &&
      ecode3 !== "" &&
      ecode4 !== "" &&
      ecode5 !== "" &&
      ecode6 !== ""
      // smscode1 !== "" &&
      // smscode2 !== "" &&
      // smscode3 !== "" &&
      // smscode4 !== "" &&
      // smscode5 !== "" &&
      // smscode6 !== ""
    ) {
      isFirstTime = false;
      document.getElementById("emailcodebtn").click();
    }
  };
  const verificationCodeKeyPress = (event) => {
    if (!/[+0-9]/.test(event.key)) {
      event.preventDefault();
    }
  };
  const closeFailedPasswrdScreen = () => {
    localStorage.removeItem("2facode");
    document.getElementsByClassName("cross-modal")[0].click();
    props.closepopup();
  };
  const handleEmailVerification = (e) => {
    e.preventDefault();
    form.current.validateAll();
    let emailCode = ecode1 + ecode2 + ecode3 + ecode4 + ecode5 + ecode6;
    if (checkBtn.current.context._errors.length === 0 && emailCode !== "") {
      // let smsRcvdCode =
      //   smscode1 + smscode2 + smscode3 + smscode4 + smscode5 + smscode6;
      if (twoFaData) {
        let mint = document.getElementById("mints");
        let secs = document.getElementById("secs");

        if (
          emailCode === twoFaData.data.result.emailCode.toString() &&
          // smsRcvdCode === twoFaData.data.result.smsCode.toString() &&
          // mint.textContent !== "00" &&
          secs.textContent !== "00"
        ) {
          ecode1 = "";
          ecode2 = "";
          ecode3 = "";
          ecode4 = "";
          ecode5 = "";
          ecode6 = "";
          setBoLoader(true);
          clearInterval(interval);
          localStorage.removeItem("2facode");
          settimerflag(false);
          if (props.pagename === "settings") {
            settingsService
              .changePassword(
                props.userId,
                props.currentpassword,
                props.newpassword
              )
              .then((resp) => {
                setBoLoader(false);
                if (
                  resp &&
                  resp.data &&
                  resp.data.payload &&
                  resp.data.payload.succeeded
                ) {
                  setsettingsScreenSuccess(true);
                } else {
                  setsettingsScreenFailed(true);
                }
              })
              .catch((err) => {
                setBoLoader(false);
                setsettingsScreenFailed(true);
              });
          } else if (props.pagename === "cardholderresetpin") {
            setCardHolderCardResetPin(true);
            setBoLoader(false);
          } else {
            set_two_fa_Successful(true);

            setTimeout(() => {
              localStorage.removeItem("userRegisteration");
              history.push("/identityVerification", props.pagename);
            }, 2000);
          }
        } else if (
          emailCode !== twoFaData.data.result.emailCode.toString() &&
          !isFirstTime
          // smsRcvdCode === twoFaData.data.result.smsCode.toString() &&
        ) {
          errorAndSuccessModl(
            "Error",
            "You have entered an invalid code.",
            "icon-successful",
            "icon-error"
          );
          isFirstTime = true;
        }
      }
    }
  };
  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };
  const dispatch = useDispatch();
  const Resend2FA = () => {
    setDisable2faButton("disabled");
    dispatch(send_two_FA_Code(props.userId))
      .then(() => {
        // if (props.pagename !== "settings")
        document.getElementById("timerbtn").click();
        dispatch(get_two_FA_Code(props.userId))
          .then(() => {})
          .catch(() => {});
      })
      .catch(() => {});
  };

  return (
    <div className="row">
      <BoLoader loading={boloader} />
      {!two_fa_successful && (
        <div
          className="bo-on-screens position-relative"
          style={{
            display:
              settingsScreenFailed || settingsScreenSuccess ? "none" : "",
          }}
        >
          <div className="col-md-12 mb-40">
            {props.pagename === "settings" ? (
              <h2>Authentication Code</h2>
            ) : (
              <h3>Verify Your Email & Mobile Number</h3>
            )}
            <p></p>
          </div>
          <div className="col-sm-12 col-md-10">
            <Form onSubmit={handleEmailVerification} ref={form}>
              <div className="row">
                <div className="col-md-12">
                  <h6>Enter 6 digits code sent to your email {maskedEmail}</h6>
                  <div className="form-group authentications">
                    <label htmlFor="email"></label>
                    <div className="form-controls d-flex">
                      <Input
                        type="password"
                        className="form-control"
                        name="emailcode1"
                        maxLength={1}
                        value={emailCode1}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeEmailCode1}

                        // style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="emailcode2"
                        maxLength={1}
                        value={emailCode2}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeEmailCode2}
                        // style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="emailcode3"
                        maxLength={1}
                        value={emailCode3}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeEmailCode3}
                        // style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="emailcode4"
                        maxLength={1}
                        value={emailCode4}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeEmailCode4}
                        // style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="emailcode5"
                        maxLength={1}
                        value={emailCode5}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeEmailCode5}
                        // style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="emailcode6"
                        maxLength={1}
                        value={emailCode6}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeEmailCode6}
                        // style={{ width: "50px" }}
                      />
                    </div>
                  </div>
                </div>
                {/* <div className="col-md-12">
                  <p>
                    Enter 6 digits code sent to your mobileNumber
                    <h6> {maskedMobileNo}</h6>
                  </p>
                  <div className="form-group authentications">
                    <label for="email"></label>
                    <div className="form-controls d-flex">
                      <Input
                        type="password"
                        className="form-control"
                        name="smsCode1"
                        value={smsCode1}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeSmsCode1}
                        style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="smsCode2"
                        value={smsCode2}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeSmsCode2}
                        style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="smsCode3"
                        value={smsCode3}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeSmsCode3}
                        style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="smsCode4"
                        value={smsCode4}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeSmsCode4}
                        style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="smsCode5"
                        value={smsCode5}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeSmsCode5}
                        style={{ width: "50px" }}
                      />
                      <Input
                        type="password"
                        className="form-control"
                        name="emailcode6"
                        value={smsCode6}
                        onKeyPress={verificationCodeKeyPress}
                        onChange={onChangeSmsCode6}
                        style={{ width: "50px" }}
                      />
                    </div>
                  </div>
                </div> */}

                {showtimer && (
                  <div className="col-md-12 d-flex align-items-center">
                    {" "}
                    <CountDownTimer />
                    {/* </b>
                    </p> */}
                  </div>
                )}

                {showtimer && (
                  <div className="col-md-6 mt-40">
                    <div className="form-group submit-field">
                      <button
                        id="Resend2faBtn"
                        type="button"
                        className="btn btn-large"
                        onClick={Resend2FA}
                        disabled={disable2faButton}
                      >
                        Resend Code
                      </button>
                    </div>
                  </div>
                )}
              </div>
              <div className="form-group" style={{ display: "none" }}>
                <button className="btn btn-primary btn-block" id="emailcodebtn">
                  Verify Email and Sms Code
                </button>
              </div>

              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      )}
      {two_fa_successful && (
        <div className="bo-on-screens position-relative onbo-content-center minh-row-mbl">
          <div className="col-md-8 col-lg-5">
            <div className="icon-alert">
              <span className="icon-loader">
                <span className="path1"></span> <span className="path2"></span>
                <span className="path3"></span> <span className="path4"></span>
                <span className="path5"></span> <span className="path6"></span>
                <span className="path7"></span> <span className="path8"></span>
              </span>
              {/* <img src={loaderimg} alt="loader" /> */}
            </div>
            <h3 className="mb-4">Verifiying 2FA....</h3>
            <h6>verifiying your Email to create an account</h6>
          </div>
        </div>
      )}
      {settingsScreenSuccess && (
        <div className="">
          <div className="col-md-12">
            <div className="icon-alert icon-alert-popup">
              <span className="icon-successful">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
            </div>
            <h2>Successful</h2>
            <div className="mt-16">
              <div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <p>Your Password has been changed successfully.</p>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group submit-field mt-40 mb-0">
                        <button
                          className="btn btn-large w-100"
                          data-bs-dismiss="modal"
                          onClick={() => {
                            document
                              .getElementById("dashboardNavLink")
                              .classList.add("active");
                            document
                              .getElementById("settingsNavLink")
                              .classList.remove("active");
                            history.push("/dashboard");
                          }}
                        >
                          Go to Dashboard
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      {settingsScreenFailed && (
        <div className="onbo-content-center minh-row-mbl">
          <div className="col-md-12">
            <div className="icon-alert icon-alert-popup">
              <span className="icon-error">
                <span className="path1"></span>
                <span className="path2"></span>
              </span>
            </div>
            <h2>Unsuccessful</h2>
            <div className="mt-16">
              <div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">
                    <div className="col-md-12">
                      <p>You entered incorrect Password.</p>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group submit-field d-flex justify-content-between mt-24 mb-0">
                        <button
                          className="btn btn-outline mr-19"
                          onClick={closeFailedPasswrdScreen}
                          data-bs-dismiss="modal"
                        >
                          Back
                        </button>
                        <button
                          className="btn btn-large"
                          onClick={() => {
                            document
                              .getElementsByClassName("icon-logout")[0]
                              .click();
                            props.closepopup();
                          }}
                        >
                          Sign in
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      )}
      <ChangePinCardDetails
        key={9}
        show={cardHolderCardResetPin}
        onHide={() => {
          setCardHolderCardResetPin(false);
          props.onHide();
        }}
        calledfrom={"reset"}
      />
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={() => setModalShow(false)}
      />
    </div>
  );
};

export default TwoFaForm;
