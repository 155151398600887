import React, { useState, useRef, Fragment, useEffect } from "react";
import { history } from "../../helpers/history";
import clientmanagmentObj from "../../services/client-managment.service";
import validator from "validator";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import GetAllClients from "../../helpers/GetAllClients";
import ExportToExcel from "../superadmin/ExportToExcel";
import roleManagmentObj from "../../services/role-managment.service";
const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status: "",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
const SearchGridSuperAdminRoles = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [clientId, setclientId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [AllClientClassName, setAllClientClassName] = useState("nav-link");
  const [NewClientClassName, setNewClientClassName] = useState("nav-link");
  const [AllClientAreaSelected, setAllClientAreaSelected] = useState("false");
  const [NewClientsAreaSelected, setNewClientsAreaSelected] = useState("false");
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  const [Status, setStatus] = useState("");
  const [dataset, setDataset] = useState();

  useEffect(() => {
    allClients();
    if (props.calledfrom === "dashboard")
      setClassContent("");
  }, []);
  useEffect(() => {
    setclientId("");
    setClientIdVal("");
    SearchClickHandler(null, true);
  }, [NewClientClassName]);
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const clientChangeHandler = (e) => {
    SearchGridInputData[0].ClientId = e.target.value;
    if (validator.isNumeric(SearchGridInputData[0].ClientId))
      setclientId(SearchGridInputData[0].ClientId);
    else setclientId("");
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const SearchClickHandler = (e, isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = props?.clientId ? props.clientId : 1;
    else SearchGridInputData[0].ClientId = props?.clientId ? props.clientId : 1;
    // if(isFromUseEffect)
    //   setModalShow(true);
    // else
    setModalShow(false);
    setModalShow(false);
    setSearchDisabled(false);
    roleManagmentObj
      .searhGridRoleManagment(
        name,
        props?.calledfrom!=="superclient" ?SearchGridInputData[0].ClientId.toString() :1,
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
        Status,
        props?.calledfrom==="superclient"? props?.superclientid :0
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            // if(isFromUseEffect)
            // setModalShow(true);
            // else
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result.pageModel.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.pageModel
              );
              setSearchData(appendedData);
              setDataset(appendedData);
            } else {
              setSearchData(resp.data.payload.result.pageModel);
              setDataset(resp.data.payload.result.pageModel);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const showClientDetails = (id, clientId) => {
    let calledfrom = props?.calledfrom
    history.push("/updateroles", { id, clientId, calledfrom });
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      if(key==="VIEW" && props?.clientStatus==="Suspended")
      return
      else
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const allClients = () => {
    setAllClientClassName("nav-link active d-flex align-items-center");
    setAllClientAreaSelected("true");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setSearchDisabled(false);
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    setclientId("");
    setClientIdVal("");
    SearchClickHandler();
  };
  const newClients = () => {
    setNewClientClassName("nav-link d-flex align-items-center active");
    setNewClientsAreaSelected("true");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setSearchDisabled(false);
    emptyClientId();
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    SearchClickHandler();
  };
  const emptyClientId = () => {
    setclientId("");
    setClientIdVal("");
  };

  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        firstName,
        lastName,
        email,
        isActive,
        phoneNumber,
        dateofBirth,
        id,

      } = data; //destructuring
      return (
        <tr key={id}>

          <td style={fontStyle} title={firstName}>{firstName?.substr(0,10)??"-"}{firstName?.length>10 &&("...")}</td>
          <td style={fontStyle}  title={lastName}>{lastName?.substr(0,10)??"-"}{lastName?.length>10 &&("...")}</td>
          <td style={fontStyle} title={email}>{email?.substr(0,10)??"-"}{email?.length>10 &&("...")}</td>

          <td className="td-address">{isActive ? "ACTIVE" : "SUSPENDED"}</td>
          <td style={fontStyle}>{phoneNumber}</td>
          <td>
            <DateFormat date={dateofBirth} />
          </td>
{
 props?.clientStatus==="Suspended" ?(<></>):(
<td>
            <span
              className="icon-show"
              onClick={() => showClientDetails(id, props.clientId)}
            ></span>
          </td>
  )
}
          
        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setclientId("");
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    setStatus("");
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setClientIdVal("");
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
  const ClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].value;
    setClientIdVal(clientName);
  };
  const onChangestatus = (e) => {
    const status = e.target.selectedOptions[0].value;
    if (status != "") {
      setStatus(status);
    }
  };

  return (
    <div className="mt-4">
      {props.calledfrom !== "dashboard" &&
        <div className="bo-tabs-mbl">
          <ul className="nav nav-tabs" id="myTab2" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={AllClientClassName}
                id="allClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#allClients"
                type="button"
                role="tab"
                onClick={allClients}
                aria-controls="allClients"
                aria-selected={AllClientAreaSelected}
              >
                <span className="icon-client"></span>ALL Users
              </button>

            </li>
            <li className="nav-item ms-auto">
              <div className="toRight mb-16"  >
                <ExportToExcel
                  Dataset={dataset}
                  calledfrom="roles"
                  Name={"Roles"}
                />
              </div>
            </li>
            {/* <li className="nav-item" role="presentation">
          <button
            className={NewClientClassName}
            id="newClients-tab"
            data-bs-toggle="tab"
            data-bs-target="#newClients"
            type="button"
            role="tab"
            aria-controls="newClients"
            onClick={newClients}
            aria-selected={NewClientsAreaSelected}
          >
            <span className="icon-client"></span>New Clients
          </button>
        </li> */}
          </ul>
        </div>
      }
      <div className={classContent} id="myTabContent2">
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          {/* no client found  */}
          <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src="../../assets/images/icons/clients-empty.svg" /> */}
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
          <Fragment>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row row-search">
                <div className="col-xl-12 col-12">
                  <div className="form-group">
                    <div className="form-controls">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.placeholdervalue}
                        onClick={handleInputSearchClick}
                        disabled={SearchInputDisabled}
                      />
                      <span className="icon-search _ico"></span>
                      {/* search box  */}
                      <div
                        className="bo-cardsearch"
                        style={{ display: modalShow ? "" : "none" }}
                      >
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="col-md-4">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                            </div>
                            <div className=" col-md-12 form-group form-search mr-20">
                              <label>Name</label>
                              <div className="form-controls mr-20">
                                <input
                                  type="text"
                                  value={name}
                                  onChange={nameChangeHandler}
                                  className="form-control"
                                  maxLength={40}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Client ID</label>
                              <div className="form-controls">
                                <input
                                  id="id_clientId"
                                  type="text"
                                  value={clientId}
                                  onChange={clientChangeHandler}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date From</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateFrom}
                                  onchangedate={dateFromHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date To</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateTo}
                                  onchangedate={dateToHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label className="mb-24">Client Name</label>
                              <div className="form-controls">
                                <GetAllClients
                                  clientnamechangehandler={
                                    ClientNameChangeHandler
                                  }
                                  searchgriddata={"Y"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Status</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangestatus}
                                  value={Status}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value="Active">
                                    Active
                                  </option>
                                  <option value="Suspended">
                                    Suspended
                                  </option>

                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field form-search">
                              <button
                                id="searchBtnClients"
                                className="btn btn-large mr-19"
                                onClick={SearchClickHandler}
                              >
                                Search
                              </button>
                              <button
                                className="btn btn-outline"
                                onClick={ClearSearchData}
                              >
                                Clear Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="help-block d-none"></span>
                  </div>
                </div>
              </div>
            </form>
          </Fragment>
        </div>

        {searchGridSuccess && (
          <div className="table-wrap" ref={searchTbl}>
            <table
              className="table table-borderless bo-table table-cards"
              onScroll={tblScrollHandler}
              onWheel={tblScrollHandler}
            >
              <thead>
                <tr>{renderTableHeader()}</tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
        )}
        {loadingData ? (
          <div className="loading-more">
            <div className="loader">
              <div></div>
            </div>
            <p>loading data</p>
          </div>
        ) : totalCount === 0 ? (
          <div className="d-flex align-content-center justify-content-center  no-clients-msg">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src={emptyclient} /> */}
              <h3>Empty User Listing</h3>
              <h6>There is no User(s) available for now.</h6>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchGridSuperAdminRoles;
