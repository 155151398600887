import { useState } from "react";
import { Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";
import depositService from "../../services/deposit.service";
import { history } from "../../helpers/history";
import SearchGridTableHelper from "../../helpers/SearchGridTableHelper";
import DateFormat from "../../helpers/DateFormat";
import RefreshToken from "../../helpers/RefreshToken";
import Input from "react-validation/build/input";
import CurrencyFormat from 'react-currency-format';
const DepositDetail = props => {
  const CheckNegativeAmount = value => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return <span className="help-block">Amount cannot be negative!</span>;
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };
  const CheckZeroAmount = value => {
    let obj = parseFloat(value);
    if (obj === 0) {
      document.getElementById("id_Amount").classList.add("has_error");
      return (
        <span className="help-block">Please enter amount greater than 0!</span>
      );
    } else document.getElementById("id_Amount").classList.remove("has_error");
  };

  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
    var t = object.target.value;
    if(t.includes("."))
    object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
  };
  const [boloader, setBoLoader] = useState(false);
  const [depositBtnViewClicked, setdepositBtnViewClicked] = useState(false);
  const [depositmodalShow, setDepositModalShow] = useState(false);
  const [depositRejectionModalShow, setdepositRejectionModalShow] =
    useState(false);
  const [depositRejectionCnfrmModalShow, setdepositRejectionCnfrmModalShow] =
    useState(false);
  const [selectedDepsitId, setSelectedDepositId] = useState("");
  const [DepositCurrency, setDepositCurrency] = useState();
  const [depositcnfrmModlShow, setdepositcnfrmModlShow] = useState(false);
  const [depositSuccessModalShow, setdepositSuccessModalShow] = useState(false);
  const [AcceptedAmount, setAcceptedAmount] = useState();
  const [GreaterAmount, setGreaterAmount] = useState();
  const [showDepositErr, setshowDepositErr] = useState("d-none");
  const [greateramounterr, setGreateramounterr] = useState("d-none");
  const [negativeamounterr, setNegativeamounterr] = useState("d-none");

  const requiredAcceptedAmount = value => {
    if (!value) {
      document.getElementById("id_deposit").classList.add("has_error");
      setshowDepositErr("");
      return false;
    } else {
      document.getElementById("id_deposit").classList.remove("has_error");
      setshowDepositErr("d-none");
      return true;
    }
  };
  const depositGreater = value => {
    let obj = parseFloat(value);
    if (obj > GreaterAmount) {
      document.getElementById("id_amountGreater").classList.add("has_error");
      setGreateramounterr("");
      return false;
    } else {
      document.getElementById("id_amountGreater").classList.remove("has_error");
      setGreateramounterr("d-none");
      return true;
    }
  };
  const negativeAmount = value => {
    let obj = parseFloat(value);
    if (obj < 0) {
      document.getElementById("id_amountnegative").classList.add("has_error");
      setNegativeamounterr("");
      return false;
    } else {
      document.getElementById("id_amountnegative").classList.remove("has_error");
      setNegativeamounterr("d-none");
      return true;
    }
  };
  const showDepositPopup = (id,depositCurrency) => {
    setshowDepositErr("d-none")
    setDepositModalShow(true);
    setSelectedDepositId(id);
    setdepositBtnViewClicked(true);
    setDepositCurrency(depositCurrency);
  };
  const RejctDepositCnfrmationHandler = () => {
    setDepositModalShow(false);
    setdepositRejectionCnfrmModalShow(true);
  };
  const AcceptDepositCnfrmationHandler = () => {
    let acceptedamount = requiredAcceptedAmount(AcceptedAmount);
    let greateramount = depositGreater(AcceptedAmount);
    let negativeamount = negativeAmount(AcceptedAmount);
    if (acceptedamount && greateramount && negativeamount) {
      setDepositModalShow(false);
      setdepositcnfrmModlShow(true);
    }
  };
  const handleCnfrmDepositPopUpClick = () => {
    acceptRjectDeposit(true, selectedDepsitId, AcceptedAmount,DepositCurrency);
  };
  const acceptRjectDeposit = (acceptDeposit, depositUuId, AcceptedAmount,DepositCurrency) => {

    setBoLoader(true);
    depositService
      .approverejectdeposit(acceptDeposit, depositUuId, AcceptedAmount,DepositCurrency)
      .then(
        response => {
          setBoLoader(false);
          if (response.data.success) {
            if (acceptDeposit) {
              setdepositcnfrmModlShow(false);
              setdepositSuccessModalShow(true);
            } else {
              setdepositRejectionCnfrmModalShow(false);
              setdepositRejectionModalShow(true);
            }
          }
        },
        error => {
          (async () => {
            let resp = await RefreshToken(
              error && error.response && error.response.status
            );
            if (resp) {
              acceptRjectDeposit();
              setBoLoader(false);
            } else {
              setBoLoader(false);
            }
          })();
        }
      );


  };
  const renderCnfrmDepositPopup = () => {
    return props.searchdata
      .filter(depositId => depositId.uuid === selectedDepsitId)
      .map((data, index) => {
        const { requestId } = data;
        return (
          <div className="row">
            <div className="col-md-12">
              <h2>Confirmation</h2>
              <div className="mt-16">
                <div>
                  <form onSubmit={e => e.preventDefault()}>
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          Are you sure to accept Deposit Request {requestId}?
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group submit-field d-flex justify-content-center mt-20 mb-0">
                          <button
                            className="btn btn-outline mr-19"
                            data-bs-dismiss="modal"
                            onClick={() => setdepositcnfrmModlShow(false)}
                          >
                            Cancel
                          </button>
                          <button
                            className="btn btn-large"
                            data-bs-dismiss="modal"
                            onClick={handleCnfrmDepositPopUpClick}
                          >
                            Accept
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );
      });
  };
  const renderSuccessDepositPopup = () => {
    return props.searchdata
      .filter(depositId => depositId.uuid === selectedDepsitId)
      .map((data, index) => {
        const { requestId } = data;
        return (
          <div className="row">
            <div className="col-md-12">
              <div className="icon-alert icon-alert-popup">
                <span className="icon-successful">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>
              </div>
              <h2>Successful</h2>
              <div className="mt-16">
                <div>
                  <form onSubmit={e => e.preventDefault()}>
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          Deposit {requestId} Request has been accepted
                          successfully.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group submit-field mt-40 mb-0">
                          <button
                            className="btn btn-large w-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setdepositSuccessModalShow(false);
                              // document
                              // .getElementById("dashboardNavLink")
                              // .classList.add("active");
                              document
                                .getElementById("depositNavLink")
                                .classList.remove("active");
                              window.location.reload();
                              // document
                              //   .getElementById("dashboardNavLink")
                              //   .click();
                              //history.push("/dashboard");
                            }}
                          >
                            Go to Deposits
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );
      });
  };
  const renderRejectionsDepositPopup = () => {
    return props.searchdata
      .filter(depositId => depositId.uuid === selectedDepsitId)
      .map((data, index) => {
        const { requestId } = data;
        return (
          <div className="row">
            <div className="col-md-12">
              <div className="icon-alert icon-alert-popup">
                <span className="icon-successful">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>
              </div>
              <h2>Successful</h2>
              <div className="mt-16">
                <div>
                  <form onSubmit={e => e.preventDefault()}>
                    <div className="row">
                      <div className="col-md-12">
                        <p>
                          Deposit {requestId} Request has been rejected
                          successfully.
                        </p>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group submit-field mt-40 mb-0">
                          <button
                            className="btn btn-large w-100"
                            data-bs-dismiss="modal"
                            onClick={() => {
                              setdepositRejectionModalShow(false);
                              props.searchclickhandler();
                            }}
                          >
                            Close
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        );
      });
  };
  const onChangeAmount = (e, depositAmount) => {
    if (depositAmount)
      setGreaterAmount(depositAmount)
    const Amount = e.target.value;
    if (Amount != "") {
      let value = parseFloat(Amount);
      setAcceptedAmount(value);
    } else {
      setAcceptedAmount("");
    }
    requiredAcceptedAmount(Amount);
    negativeAmount(Amount);
    depositGreater(Amount)

  };
  const renderDepositPopup = () => {
    return props.searchdata
      .filter(depositId => depositId.uuid === selectedDepsitId)
      .map((data, index) => {
        const {
          requestId,
          wireConfirmationUrl,
          depositAmount,
          depositCurrency,
          bank,
          accountNumber,
          depositStatus,
          acceptedAmount,
        } = data;
        //destructuring
        return (
          <div className="row">
            <div className="col-md-12">
              <h2>Deposit Details</h2>
              <div className="mt-16">
                <form onSubmit={e => e.preventDefault()}>
                  <ul className="ul-card-details-popup">
                    <li className="d-flex justify-content-between mb-20">
                      <label>Reference No.</label>
                      <span>{requestId}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-20">
                      <label>Bank</label>
                      <span>{bank}</span>
                    </li>

                    <li className="d-flex justify-content-between mb-20">
                      <label>Account Number</label>
                      <span>{accountNumber}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-20">
                      <label>Requested Amount</label>
                      <span><CurrencyFormat value={depositAmount} displayType={'text'} thousandSeparator={true} /></span>
                    </li>

                    <li className="d-flex justify-content-between mb-20">
                      <label>Deposit Currency</label>
                      <span>{depositCurrency}  </span>
                    </li>
                    {depositStatus === "Pending" && (
                      <Fragment>
                        <li
                          class="d-flex justify-content-between mb-20"

                        >
                          <label className="mt-4">Accepted Amount*</label>
                          <input
                            type="number"
                            className="form-control no-icon w-50"
                            onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                            maxLength="9"
                            onInput={maxLengthCheck}
                            onWheel={(e) => e.target.blur()}
                            onChange={(e) => { onChangeAmount(e, depositAmount) }}
                            value={AcceptedAmount}
                          />

                        </li>
                        <div id="id_deposit">
                          <li className="d-flex justify-content-between"  >
                            <label></label>
                            <span className={"help-block " + showDepositErr}>
                              Accepted amount is required!
                            </span>
                          </li>
                        </div>
                        <div id="id_amountGreater">
                          <li className="d-flex justify-content-between mt--1"  >
                            <label></label>
                            <span className={"help-block " + greateramounterr}>
                              Amount must be smaller than deposit amount!
                            </span>
                          </li>
                        </div>
                        <div id="id_amountnegative">
                          <li className="d-flex justify-content-between mt--1"  >
                            <label></label>
                            <span className={"help-block " + negativeamounterr}>
                              Accepted Amount cannot be negative!
                            </span>
                          </li>
                        </div>
                      </Fragment>

                    )}

                    {depositStatus === "Accepted" && (
                      <li className="d-flex justify-content-between mb-20">
                        <label>Accepted Amount</label>
                        <span><CurrencyFormat value={acceptedAmount} displayType={'text'} thousandSeparator={true} /></span>
                      </li>
                    )}
                    <li className="d-flex justify-content-between mb-20">
                      <label>Status</label>
                      <span>{depositStatus}</span>
                    </li>
                    <li className="d-flex justify-content-between mb-20">
                      <label>Wire</label>
                      <span></span>
                    </li>
                  </ul>
                  <div className="deposit-slip-img">
                  {wireConfirmationUrl ? (
    // Check if the file is a PDF based on its extension
    wireConfirmationUrl.endsWith('.pdf') ? (
      <Fragment>
      {/* <iframe
        className="pdf-viewer"
        src={wireConfirmationUrl}
        title="Deposit Slip PDF"
        width="100%"
        height="10px"
      ></iframe> */}
      <a
          href={wireConfirmationUrl}
          download
          className="btn btn-large"
        >
          Download PDF
        </a>
      </Fragment>
     
    ) : (
      // Display image for other file types
      <img className="img-fluid" src={wireConfirmationUrl} alt="deposit" />
    )
  ) : (
    "No Wire Transfer Image Found!"
  )}
                  </div>
                  {depositStatus === "Pending" && (
                    <div className="col-md-12">
                      <div className="form-group submit-field d-flex justify-content-center mt-40 mb-0">
                        <button
                          className="btn btn-outline mr-19"
                          data-bs-dismiss="modal"
                          onClick={RejctDepositCnfrmationHandler}
                        >
                          Reject
                        </button>
                        <button
                          className="btn btn-large"
                          data-bs-dismiss="modal"
                          onClick={AcceptDepositCnfrmationHandler}
                        >
                          Accept
                        </button>
                      </div>
                    </div>
                  )}
                </form>
              </div>
            </div>
          </div>
        );
      });
  };
  const renderTableData = () => {
    return props.searchdata.map((data, index) => {
      const {
        uuid,
        requestId,
        depositDate,
        depositAmount,
        bank,
        accountNumber,
        depositStatus,
        acceptedAmount,
        depositCurrency,
        clientName
      } = data; //destructuring
      return (
        <tr key={uuid}>
          <td>{requestId}</td>
          <td>
            <DateFormat date={depositDate} />
          </td>
          {
            props?.status==="Pending" ?(
              <td> <CurrencyFormat value={depositAmount} displayType={'text'} thousandSeparator={true} /></td>
            ):(
              <td> <CurrencyFormat value={acceptedAmount} displayType={'text'} thousandSeparator={true} /></td>
            )
          }
          
          
          {/* <td>{bank?.substr(0,10)??"-"}{bank?.length>10 &&("...")}</td>
          <td>{accountNumber}</td> */}
          <td>{clientName}</td>
          <td>{depositStatus}</td>
          <td>
            <span
              title="View Deposit Details"
              className="icon-show"
              onClick={() => showDepositPopup(uuid,depositCurrency)}
            ></span>
          </td>
        </tr>
      );
    });
  };
  return (
    <Fragment>
      <SearchGridTableHelper
        searchgridsuccess={props.searchgridsuccess}
        searchtbl={props.searchtbl}
        tblscrollhandler={props.tblscrollhandler}
        rendertableheader={props.rendertableheader}
        renderTableData={renderTableData}
        loadingdata={props.loadingdata}
        totalcount={props.totalcount}
        screenname="deposit"
      />
      {depositBtnViewClicked && (
        <div>
          <BoLoader loading={boloader} />
          <ModelPopup
            key={2}
            show={depositmodalShow}
            onHide={() => {
              setDepositModalShow(false);
              setAcceptedAmount("");
              setshowDepositErr("d-none");
              setGreateramounterr("d-none");
              setNegativeamounterr("d-none");
            }}
            modelheading={""}
            modelbody={renderDepositPopup()}
            showheader={"false"}
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            className="smallPopup issueCardSmall"
            backdrop="static"
            keyboard={false}
          />
          <ModelPopup
            key={3}
            show={depositcnfrmModlShow}
            onHide={() => setdepositcnfrmModlShow(false)}
            modelheading={""}
            modelbody={renderCnfrmDepositPopup()}
            showheader={"false"}
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            className="smallPopup issueCardSmall"
            backdrop="static"
            keyboard={false}
          />
          <ModelPopup
            key={4}
            show={depositSuccessModalShow}
            onHide={() => {
              setdepositSuccessModalShow(false);
              props.searchclickhandler();
            }}
            modelheading={""}
            modelbody={renderSuccessDepositPopup()}
            showheader={"false"}
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            className="smallPopup issueCardSmall"
            backdrop="static"
            keyboard={false}
          />
          <ModelPopup
            key={5}
            show={depositRejectionModalShow}
            onHide={() => {
              setdepositRejectionModalShow(false);
              props.searchclickhandler();
            }}
            modelheading={""}
            modelbody={renderRejectionsDepositPopup()}
            showheader={"false"}
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            className="smallPopup issueCardSmall"
            backdrop="static"
            keyboard={false}
          />
          <ModelPopup
            key={6}
            show={depositRejectionCnfrmModalShow}
            onHide={() => setdepositRejectionCnfrmModalShow(false)}
            modelheading={""}
            showheader={"false"}
            dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
            className="smallPopup issueCardSmall"
            backdrop="static"
            keyboard={false}
            modelbody={[
              <div className="row">
                <div className="col-md-12">
                  <h2>Confirmation</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={e => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>
                              Are you sure to reject Deposit Request?
                              {/* {requestId}? */}
                            </p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              <button
                                className="btn btn-outline"
                                onClick={() =>
                                  setdepositRejectionCnfrmModalShow(false)
                                }
                              >
                                Cancel
                              </button>

                              <button
                                className="btn btn-large"
                                onClick={() => {
                                  acceptRjectDeposit(false, selectedDepsitId);
                                }}
                              >
                                Reject
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>,
            ]}
          />
        </div>
      )}
    </Fragment>
  );
};
export default DepositDetail;
