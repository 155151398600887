import { Fragment } from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import SuperAdminDashboard from "../superadmin/SuperAdminDashboard";
import ClientAdminDashBoard from "../clilentadmin/ClientAdminDashBoard";
import CHDashBoard from "../cardholderCardScreens/CHDashBoard";

const Dashboard = () => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }

  return (
    <Fragment>
      {currentUser.role.includes("Super admin") && (
        <div className="col-xl-9 col-lg-12 bo-main-content">
          <SuperAdminDashboard />
        </div>
      )}
      {/* {currentUser.role.includes("Client admin") && (
        <div className="col-xl-9 col-lg-12 bo-main-content">
          <ClientAdminDashBoard />
        </div>
      )}
      {currentUser.role.includes("Card holder") && <CHDashBoard />} */}
    </Fragment>
  );
};

export default Dashboard;
