import { useState, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import ClientService from "../../services/client-managment.service";

const GetAllAccounts = (props) => {

  useEffect(() => {
    getClientsDropDown();
  }, []);
  const [clientName, setClientName] = useState();
  const [allClientsData, setAllClientsData] = useState();
  const { user: currentUser } = useSelector((state) => state.auth);
  const getClientsDropDown = () => {
    ClientService.getAllAccounts()
      .then((response) => {
        if (response.data.payload) {
          let data = response.data.payload;
          setAllClientsData(data);
          const clientNameVal = data.map((options) => {
            let val = "";
            if (options.id === currentUser.user.ClientId) {
              val = options.clientName;
              return val;
            } else return "";
          });

          const finalVal = clientNameVal.filter((x) => {
            return x !== "";
          });
          setClientName(finalVal);
        }
      })
      .catch((err) => {
        
      });
  };
  return (
    <Fragment>
      {allClientsData && (
        <Fragment>
            <select
              id="clientNameDD"
              className="form-control no-icon"
              onChange={props.clientnamechangehandler}
            >
                <option value="">Select Account</option>
              {allClientsData.map((options) => {
                return(
               <option key={options?.accountId} value={options?.accountId} accountId={options?.accountId}
               name={options?.name} iban={options?.iban} clientId={options?.clientId}>
                {options?.name}{"  "}{options?.iban}
              </option>
                )
                })}
            </select>
        </Fragment>
      )}
    </Fragment>
  );
};

export default GetAllAccounts;
