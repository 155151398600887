import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import store from "./store";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import axios from "axios";
// import SetupInterceptors from "./services/Interceptors/SetupInterceptors";
axios.defaults.headers.common = {
  "ApiKey": "AIzaSyDEAzy9bsweFnqtqa0rVusAGhNwWKJ3oZs",
};
ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById("onboarding")
);
// SetupInterceptors(store);
serviceWorker.unregister();
