import React from "react";
import { Fragment } from "react";
// import transImg from "../assets/images/icons/transaction-empty.svg";
// import cardsImg from "../assets/images/icons/issued-card.svg";
// import depositImg from "../assets/images/icons/deposit-emptystate.svg";

const SearchGridTableHelper = (props) => {
  return (
    <Fragment>
      {props.searchgridsuccess && (
        <Fragment>
          <div className="table-wrap" ref={props.searchtbl}>
         
            <table
              className="table table-borderless bo-table table-cards"
              onScroll={props.tblscrollhandler}
              onWheel={props.tblscrollhandler}
            >
              <thead>
                <tr>{props.rendertableheader()}</tr>
              </thead>
              <tbody>{props.renderTableData()}</tbody>
            </table>
          </div>

          {props.loadingdata ? (
            <div className="loading-more">
              <div className="loader">
                <div></div>
              </div>
              <p>loading data</p>
            </div>
          ) : props.totalcount === 0 ? (
            props.screenname === "transactions" ||
              props.screenname === "cardholdertransactions" ? (
              <div className="d-flex align-content-center justify-content-center no-clients-msg">
                <div className="text-center">
                  <span className="icon-transaction-empty">
                    <span className="path1"></span><span className="path2"></span>
                    <span className="path3"></span><span className="path4"></span>
                    <span className="path5"></span><span className="path6"></span>
                  </span>
                  {/* <img src={transImg} alt="transaction" /> */}
                  <h3>Empty Transactions Listing</h3>
                  <h6>There is no transaction available for now.</h6>
                </div>
              </div>
            ) : props.screenname === "deposit" ? (
              <div className="d-flex align-content-center justify-content-center no-clients-msg">
                <div className="text-center">
                <span className="icon-deposit-emptystate">
                    <span className="path1"></span><span className="path2"></span>
                    <span className="path3"></span><span className="path4"></span>
                    <span className="path5"></span><span className="path6"></span>
                    <span className="path7"></span><span className="path8"></span>
                    <span className="path9"></span><span className="path10"></span>
                    <span className="path11"></span>
                  </span>
                  {/* <img src={depositImg} alt="transaction" /> */}
                  <h3>Empty deposit Listing</h3>
                  <h6>There is no deposit available for now.</h6>
                </div>
              </div>
            ) : (
              <div className="d-flex align-content-center justify-content-center no-clients-msg">
                <div className="text-center">
                  <span className="icon-issued-card">
                    <span className="path1"></span><span className="path2"></span>
                    <span className="path3"></span><span className="path4"></span>
                    <span className="path5"></span><span className="path6"></span>
                    <span className="path7"></span><span className="path8"></span>
                    <span className="path9"></span><span className="path10"></span>
                    <span className="path11"></span>
                  </span>
                  {/* <img src={cardsImg} alt="transaction" /> */}
                  <h3>Empty Cards Listing</h3>
                  <h6>There is no cards listing available for now.</h6>
                </div>
              </div>
            )
          ) : (
            ""
          )}
        </Fragment>
      )}
      {!props.searchgridsuccess && (
        <Fragment>
          <div className="table-wrap">
            <table>
              <tbody>
                <tr>
                  <td></td>
                </tr>
              </tbody>
            </table>
          </div>
          {props.loadingdata ? (
            <div className="loading-more">
              <div className="loader">
                <div></div>
              </div>
              <p>loading data</p>
            </div>
          ) : props.screenname === "transactions" ||
            props.screenname === "cardholdertransactions" ? (
            <div className="d-flex align-content-center justify-content-center no-clients-msg">
              <div className="text-center">
                <span className="icon-transaction-empty">
                  <span className="path1"></span><span className="path2"></span>
                  <span className="path3"></span><span className="path4"></span>
                  <span className="path5"></span><span className="path6"></span>
                </span>
                {/* <img src={transImg} alt="transaction" /> */}
                <h3>Empty transactions Listing</h3>
                <h6>There is no transaction available for now.</h6>
              </div>
            </div>
          ) : props.screenname === "deposit" ? (
            <div className="d-flex align-content-center justify-content-center no-clients-msg">
              <div className="text-center">
              <span className="icon-deposit-emptystate">
                    <span className="path1"></span><span className="path2"></span>
                    <span className="path3"></span><span className="path4"></span>
                    <span className="path5"></span><span className="path6"></span>
                    <span className="path7"></span><span className="path8"></span>
                    <span className="path9"></span><span className="path10"></span>
                    <span className="path11"></span>
                  </span>
                {/* <img src={depositImg} alt="transaction" /> */}
                <h3>Empty deposit Listing</h3>
                <h6>There is no deposit available for now.</h6>
              </div>
            </div>
          ) : (
            <div className="d-flex align-content-center justify-content-center no-clients-msg">
              <div className="text-center">
                <span className="icon-issued-card">
                  <span className="path1"></span><span className="path2"></span>
                  <span className="path3"></span><span className="path4"></span>
                  <span className="path5"></span><span className="path6"></span>
                  <span className="path7"></span><span className="path8"></span>
                  <span className="path9"></span><span className="path10"></span>
                  <span className="path11"></span>
                </span>
                {/* <img src={cardsImg} alt="transaction" /> */}
                <h3>Empty card Listing</h3>
                <h6>There is no card available for now.</h6>
              </div>
            </div>
          )}
        </Fragment>
      )}
    </Fragment>
  );
};

export default SearchGridTableHelper;
