import React, { useState, useRef, Fragment, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import { history } from "../../helpers/history";
import clientmanagmentObj from "../../services/client-managment.service";
import validator from "validator";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import GetAllClients from "../../helpers/GetAllClients";
import ExportToExcel from "./ExportToExcel";
import { haspermissions } from "../permissions/PermissionTypes";
import SearchGridSuperAdminRoles from "../rolemanagment/SearchGridSuperAdminRoles";
import roleManagmentObj from "../../services/role-managment.service";
const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status: "",
    TokenId: "",
    IssuerType: "",
    SuperClientId: "",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
const SearchGridClient = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [clientId, setclientId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [AllClientClassName, setAllClientClassName] = useState("nav-link");
  const [NewClientClassName, setNewClientClassName] = useState("nav-link");
  const [ClosedClientClassName, setClosedClientClassName] = useState("nav-link");
  const [InternalClientClassName, setInternalClientClassName] = useState("nav-link");
  const [AllUserClassName, setAllUserClassName] = useState("nav-link");
  const [AllClientAreaSelected, setAllClientAreaSelected] = useState("false");
  const [NewClientsAreaSelected, setNewClientsAreaSelected] = useState("false");
  const [ClosedClientAreaSelected, setClosedClientAreaSelected] = useState("false");
  const [InternalClientAreaSelected, setInternalClientAreaSelected] = useState("false");
  const [AllUserAreaSelected, setAllUserAreaSelected] = useState("false");
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  const [Status, setStatus] = useState();
  const [Currency, setCurrency] = useState();
  const [dataset, setDataset] = useState();
  const [IssuerCategory, setIssuerCategory] = useState("");
  const [isShown, setIsShown] = useState(0);
  const [SuperClientId, setSuperClientId] = useState("");
  const observer = useRef();
  const searchTblRef = useRef();
  if (currentUser) {
    haspermissions.InternalAccounts = currentUser?.user?.Permissions?.includes("Internal Accounts");
  }
  const lastElementRef = useCallback(node => {
    if (loadingData) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        tblScrollHandler()

      }
    })
    if (node) observer.current.observe(node)
  }, [loadingData, searchData])
  useEffect(() => {
    setclientId("");
    setClientIdVal("");
    allClients();
    if (props.calledfrom === "dashboard")
      setClassContent("");
      if(props?.calledfrom==="superclient"){
        setSuperClientId(props?.superclientid)
    }
    else{
        setSuperClientId("");
    }
  }, []);
  // useEffect(() => {

  //   SearchClickHandler(null,true);
  // }, []);
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const clientChangeHandler = (e) => {
    SearchGridInputData[0].ClientId = e.target.value;
    if (validator.isNumeric(SearchGridInputData[0].ClientId))
      setclientId(SearchGridInputData[0].ClientId);
    else setclientId("");
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const SearchClickHandler = (e, calledfrom) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = !clientId ? clientIdVal : clientId;
    else SearchGridInputData[0].ClientId = "";
    if(calledfrom=="search")
    SearchGridInputData[0].startIndex=1;
    // if(isFromUseEffect)
    //   setModalShow(true);
    // else
    setModalShow(false);
    setModalShow(false);
    setSearchDisabled(false);
    clientmanagmentObj
      .searhGrid(
        name,
        SearchGridInputData[0].ClientId.toString(),
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
        SearchGridInputData[0].Status,
        SearchGridInputData[0].TokenId,
        SearchGridInputData[0].IssuerType,
        props?.superclientid?.toString() ?? SuperClientId

      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            // if(isFromUseEffect)
            // setModalShow(true);
            // else
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result.pageModel.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.pageModel
              );
              setSearchData(appendedData);
              setDataset(appendedData);
            } else {
              setSearchData(resp.data.payload.result.pageModel);
              setDataset(resp.data.payload.result.pageModel);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);
             if(props?.calledfrom==="superclient"){
              let ledgerstr="";
              for(let i =0; i<resp.data.payload.result.totalCount; i++)
              {
                let val=resp?.data?.payload?.result?.pageModel[i];
                 ledgerstr+=val?.balance+"   "+val?.currency+"\n";
              }
              props?.ledgerBalances(ledgerstr);
             }
           
            SearchGridInputData[0].scrolled = false;
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const showClientDetails = (id, userId, clientStatus) => {
    history.push("/clientDetails", { id, userId, clientStatus });
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const allClients = () => {

    setAllClientClassName("nav-link active d-flex align-items-center");
    setAllClientAreaSelected("true");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setClosedClientClassName("nav-link d-flex align-items-center");
    setClosedClientAreaSelected("false");
    setInternalClientClassName("nav-link d-flex align-items-center");
    setInternalClientAreaSelected("false");
    setAllUserClassName("nav-link d-flex align-items-center");
    setAllUserAreaSelected("false");
    emptyClientId("Active");
    setSearchDisabled(false);
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    SearchGridInputData[0].Status = "Active";
    SearchGridInputData[0].TokenId = ""
    SearchGridInputData[0].IssuerType = ""
    setIssuerCategory("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    SearchClickHandler();
  };
  const newClients = () => {
    setNewClientClassName("nav-link d-flex align-items-center active");
    setNewClientsAreaSelected("true");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setClosedClientClassName("nav-link d-flex align-items-center");
    setClosedClientAreaSelected("false");
    setInternalClientClassName("nav-link d-flex align-items-center");
    setInternalClientAreaSelected("false");
    setAllUserClassName("nav-link d-flex align-items-center");
    setAllUserAreaSelected("false");
    setSearchDisabled(false);
    emptyClientId("Suspended");
    setIssuerCategory("");
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    SearchGridInputData[0].Status = "Suspended";
    SearchGridInputData[0].TokenId = ""
    SearchGridInputData[0].IssuerType = ""

    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    SearchClickHandler();
  };
  const closedClients = () => {
    setClosedClientClassName("nav-link d-flex align-items-center active");
    setClosedClientAreaSelected("true");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setInternalClientClassName("nav-link d-flex align-items-center");
    setInternalClientAreaSelected("false");
    setAllUserClassName("nav-link d-flex align-items-center");
    setAllUserAreaSelected("false");
    setSearchDisabled(false);
    emptyClientId("closed");
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    SearchGridInputData[0].Status = "closed";
    SearchGridInputData[0].TokenId = ""
    SearchGridInputData[0].IssuerType = ""
    setIssuerCategory("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    SearchClickHandler();
  };
  const internalClients = () => {
    setClosedClientClassName("nav-link d-flex align-items-center");
    setClosedClientAreaSelected("false");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setInternalClientClassName("nav-link d-flex align-items-center active");
    setInternalClientAreaSelected("true");
    setAllUserClassName("nav-link d-flex align-items-center");
    setAllUserAreaSelected("false");
    setSearchDisabled(false);
    emptyClientId("InternalAccounts");
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    SearchGridInputData[0].Status = "InternalAccounts";
    SearchGridInputData[0].TokenId = ""
    SearchGridInputData[0].IssuerType = ""
    setIssuerCategory("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    SearchClickHandler();
  };
  const emptyClientId = (status) => {
    setclientId("");
    setClientIdVal("");
    setStatus(status);
    setStatus(status);
  };

  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        id,
        logoUrl,
        clientName,
        //address,
        registrationDate,
        //pointOfContact,
        currency,
        provider,
        clientStatus,
        userId,
        balance
      } = data; //destructuring

      return (
        <tr key={id} ref={
          searchData?.length === index + 1 ? lastElementRef : searchTblRef
        }>
          {logoUrl !== "" ? (
            <td>
              <span style={{ cursor:'grab'}}>
              <img width="50" src={logoUrl} alt="Allclients" onClick={() => showClientDetails(id, userId, clientStatus)} />
              </span>
            </td>
          ) : (
            <td style={fontStyle}>
              <span style={{ cursor:'grab'}} className="td-logo" onClick={() => showClientDetails(id, userId, clientStatus)}>{clientName.charAt(0)}</span>
            </td>
          )}

          <td><span style={{ cursor:'grab',color:'#0d6efd' }}  title={clientName} onClick={() => showClientDetails(id, userId, clientStatus)}>{clientName?.substr(0, 200) ?? "-"}{clientName?.length > 200 && ("...")}</span></td>
          {/* <td className="td-address"  title={address}>{address?.substr(0,200)??"-"}{address?.length>200 &&("...")}</td> */}
          <td>
            <DateFormat date={registrationDate} />
          </td>
          {/* <td style={fontStyle} title={pointOfContact}className="td-name">{pointOfContact?.substr(0,10)??"-"}{pointOfContact?.length>10 &&("...")}</td> */}
          <td>{clientStatus}</td>
          <td onMouseLeave={()=> {setIsShown(id)}} onMouseEnter={()=> {setIsShown(id)}}>{
            isShown===id ?(balance+" "+currency):("*****")
          }</td>
          {/* <td>{currency}</td>
          <td>{provider === "MSwipe" ? ("BO") : provider}</td> */}
          {/* {
            props?.createclientpermission ? (
              <td>
                <span
                  className="icon-show"
                  onClick={() => showClientDetails(id, userId, clientStatus)}
                ></span>
              </td>
            ) : <td></td>
          } */}

        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setclientId("");
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    setStatus("");
    setCurrency("");
    setIssuerCategory("");
    SearchGridInputData[0].Status = ""
    SearchGridInputData[0].TokenId = ""
    SearchGridInputData[0].IssuerType = ""
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setClientIdVal("");
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
  const ClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].value;
    setClientIdVal(clientName);
  };
  const onChangestatus = (e) => {
    const status = e.target.selectedOptions[0].value;
    if (status != "") {
      setStatus(status);
      SearchGridInputData[0].Status = status;
    }
  };
  const onChangeCurrency = (e) => {
    const currency = e.target.selectedOptions[0].value;
    if (currency != "") {
      setStatus(currency);
      SearchGridInputData[0].TokenId = currency;
    }
  };
  const onChangeMswipeIssuer = e => {
    const name = e.target.selectedOptions[0].value;
    SearchGridInputData[0].IssuerType = name;
    setIssuerCategory(name);
  };
  const allUsers = () => {
    setAllUserClassName("nav-link d-flex align-items-center active");
    setAllUserAreaSelected("true");
    setClosedClientClassName("nav-link d-flex align-items-center");
    setClosedClientAreaSelected("false");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setInternalClientClassName("nav-link d-flex align-items-center");
    setInternalClientAreaSelected("false");
    btnRoleManagment();
  };
  const btnRoleManagment = () => {
    SearchClickHandlerRoles();
  };
  
  const renderTableDataRoles = () => {
    return searchData.map((data, index) => {
      const {
        firstName,
        lastName,
        email,
        isActive,
        phoneNumber,
        dateofBirth,
        id,

      } = data; //destructuring
      return (
        <tr key={id}>

          <td style={fontStyle} title={firstName}>{firstName?.substr(0,10)??"-"}{firstName?.length>10 &&("...")}</td>
          <td style={fontStyle}  title={lastName}>{lastName?.substr(0,10)??"-"}{lastName?.length>10 &&("...")}</td>
          <td  title={email}>{email}</td>

          <td className="td-address">{isActive ? "ACTIVE" : "SUSPENDED"}</td>
          <td style={fontStyle}>{phoneNumber}</td>
          <td>
            <DateFormat date={dateofBirth} />
          </td>
          <td>
            <span
              className="icon-show"
              onClick={() => showClientDetailsRoles(id, props.clientId)}
            ></span>
          </td>
        </tr>
      );
    });
  };
  const renderTableHeaderRoles = () => {
    let headersdata=[
      "First Name",
      "Last Name",
      "Email",
      "STATUS",
      "Phone Number",
      "Date of Birth",
      "View"
    ]
    let header = Object.values(headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const SearchClickHandlerRoles = (e, calledfrom) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = props?.clientId ? props.clientId : 1;
    else SearchGridInputData[0].ClientId = props?.clientId ? props.clientId : 1;
   
    setModalShow(false);
    setModalShow(false);
    setSearchDisabled(false);
    roleManagmentObj
      .searhGridRoleManagment(
        name,
        "0",
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
        "Active",
         props?.superclientid
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
           
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result.pageModel.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.pageModel
              );
              setSearchData(appendedData);
              setDataset(appendedData);
            } else {
              setSearchData(resp.data.payload.result.pageModel);
              setDataset(resp.data.payload.result.pageModel);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const showClientDetailsRoles = (id, clientId) => {
    let calledfrom = props?.calledfrom
    history.push("/updatesuperclientroles", { id, clientId, calledfrom });
  };
  return (
    <div className="mt-md-0 mt-4">
      {props.calledfrom !== "dashboard" &&
        <div className="bo-tabs-mbl">
          <ul className="nav nav-tabs" id="myTab2" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={AllClientClassName}
                id="allClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#allClients"
                type="button"
                role="tab"
                onClick={allClients}
                aria-controls="allClients"
                aria-selected={AllClientAreaSelected}
              >
                <span className="icon-client"></span>Active Clients
              </button>

            </li>
            <li className="nav-item" role="presentation">
              <button
                className={NewClientClassName}
                id="newClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#newClients"
                type="button"
                role="tab"
                aria-controls="newClients"
                onClick={newClients}
                aria-selected={NewClientsAreaSelected}
              >
                <span className="icon-client"></span>Suspended Clients
              </button>
            </li>
            <li className="nav-item" role="presentation">
              <button
                className={ClosedClientClassName}
                id="newClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#newClients"
                type="button"
                role="tab"
                aria-controls="newClients"
                onClick={closedClients}
                aria-selected={ClosedClientAreaSelected}
              >
                <span className="icon-client"></span>Closed Clients
              </button>
            </li>
            {props?.calledfrom !=="superclient" &&(
              <li className="nav-item" role="presentation">
              <button
                className={InternalClientClassName}
                id="newClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#newClients"
                type="button"
                role="tab"
                aria-controls="newClients"
                onClick={internalClients}
                aria-selected={InternalClientAreaSelected}
              >
                <span className="icon-client"></span>Internal Clients
              </button>
            </li>
            )}
            {
              props?.calledfrom==="superclient" && (
                <li className="nav-item" role="presentation">
              <button
                className={AllUserClassName}
                id="newClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#newClients"
                type="button"
                role="tab"
                aria-controls="newClients"
                onClick={allUsers}
                aria-selected={AllUserAreaSelected}
              >
                <span className="icon-RoleManagement"></span> ALL User(s)
              </button>

            </li>
              )
            }
            
            <li className="nav-item ms-auto">
              <div className="mt-16 mt-sm-0 mb-16">
                <ExportToExcel
                  Dataset={dataset}
                  calledfrom="client"
                  Name={"Client"}
                />
              </div>
            </li>


          </ul>
        </div>
      }
      <div className={classContent} id="myTabContent2">
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          {/* no client found  */}
          <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src="../../assets/images/icons/clients-empty.svg" /> */}
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
          <Fragment>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row row-search">
                <div className="col-xl-12 col-12">
                  <div className="form-group">
                    <div className="form-controls">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.placeholdervalue}
                        onClick={handleInputSearchClick}
                        disabled={SearchInputDisabled}
                      />
                      <span className="icon-search _ico"></span>
                      {/* search box  */}
                      <div
                        className="bo-cardsearch"
                        style={{ display: modalShow ? "" : "none" }}
                      >
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="col-md-4">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                            </div>
                            <div className=" col-md-12 form-group form-search mr-20">
                              <label>Name</label>
                              <div className="form-controls mr-20">
                                <input
                                  type="text"
                                  value={name}
                                  onChange={nameChangeHandler}
                                  className="form-control"
                                  maxLength={80}
                                />
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Client ID</label>
                              <div className="form-controls">
                                <input
                                  id="id_clientId"
                                  type="text"
                                  value={clientId}
                                  onChange={clientChangeHandler}
                                  className="form-control"
                                />
                              </div>
                            </div>
                          </div> */}
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date From</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateFrom}
                                  onchangedate={dateFromHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date To</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateTo}
                                  onchangedate={dateToHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label className="mb-24">Client Name</label>
                              <div className="form-controls">
                                <GetAllClients
                                  clientnamechangehandler={
                                    ClientNameChangeHandler
                                  }
                                  searchgriddata={"Y"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Status</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangestatus}
                                  value={Status}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value="Active">
                                    Active
                                  </option>
                                  <option value="Suspended">
                                    Suspended
                                  </option>
                                  {haspermissions.InternalAccounts && (
                                    <option value="InternalAccounts">
                                      Internal Accounts
                                    </option>
                                  )}

                                  <option value="ExternalAccounts">
                                    External Accounts
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Currency</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangeCurrency}
                                  value={Currency}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value="USD">
                                    USD
                                  </option>
                                  <option value="GBP">
                                    GBP
                                  </option>
                                  <option value="EUR">
                                    EUR
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Issuer</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangeMswipeIssuer}
                                  value={IssuerCategory}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value={"IssuerB"}>
                                    MSW-B
                                  </option>
                                  {/* <option value={"IssuerS"}>
                                    MSW-S
                                  </option>
                                  <option value={"IssuerSP"}>
                                    MSW-SP
                                  </option> */}
                                  <option value={"IssuerG"}>
                                    MSW-G
                                  </option>
                                  <option value={"IssuerB4B"}>
                                    MSW-B4B
                                  </option>
                                  <option value={"IssuerB4Virtual"}>
                                    MSW-B4B-V
                                  </option>
                                  {/* <option value={"IssuerW"}>
                                    WAL
                                  </option> */}
                                  <option value={"IssuerI"}>
                                    MSW-I
                                  </option>
                                  <option value={"IssuerT"}>
                                    MSW-T
                                  </option>
                                  <option value={"IssuerM"}>
                                    MSW-M
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                          
                          <div className="col-md-12">
                            <div className="form-group submit-field form-search">
                              <button
                                id="searchBtnClients"
                                className="btn btn-large mr-19"
                                onClick={()=>(SearchClickHandler("","search"))}
                              >
                                Search
                              </button>
                              <button
                                className="btn btn-outline"
                                onClick={ClearSearchData}
                              >
                                Clear Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="help-block d-none"></span>
                  </div>
                </div>
              </div>
            </form>
          </Fragment>
        </div>

        {searchGridSuccess && (
          <div className="table-wrap" ref={searchTbl}>
            <table
              className="table table-borderless bo-table table-cards table-clients"
            // onScroll={tblScrollHandler}
            // onWheel={tblScrollHandler}
            >
              <thead>
                {
                  AllUserAreaSelected === "true" && props?.calledfrom==="superclient"&& (<tr>{renderTableHeaderRoles()}</tr>)
                }
                {
                  AllUserAreaSelected === "false" && (
                    <tr>{renderTableHeader()}</tr>
                  )
                }

              </thead>
              { AllUserAreaSelected === "true"  &&(
                <tbody>{renderTableDataRoles()}</tbody>
              )}
               { AllUserAreaSelected === "false"  &&(
                <tbody> {renderTableData()}</tbody>
              )}
            </table>
          </div>
        )}
        {loadingData ? (
          <div className="loading-more">
            <div className="loader">
              <div></div>
            </div>
            <p>loading data</p>
          </div>
        ) : totalCount === 0 ? (
          <div className="d-flex align-content-center justify-content-center  no-clients-msg">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src={emptyclient} /> */}
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
    </div>
  );
};

export default SearchGridClient;
