import react, { Fragment } from 'react';


const PreviewTheme=(props)=>{
    return(
        <Fragment>
        <header className="header-main-outer">
            <section className="header-main">
                <div className="row align-items-center position-relative position-md-static">
                    <div className="col-12 p-static d-flex align-items-center justify-content-center">
                        <div className="div-hamburger">
                            <button className="hamburgers" type="button" >
                                <span></span>
                                <span></span>
                                <span></span>
                            </button>
                        </div>
                        <div className="logo d-flex align-items-center bo-thumb">
                            <a href="#">
                                <h3>Card<span>Console</span></h3>
                            </a>
                        </div>
                    </div>
                </div>
            </section>
        </header>
        <div className="row minh-row">
            <div className="col-xl-3 col-lg-0 bo-sidebar-col">

                <div className="bo-sidebar-outer">
                    <div className="logo d-flex align-items-center bo-thumb">
                        <a href="#">
                            <h3>Card<span>Console</span></h3>
                        </a>
                    </div>
                    <ul className="sidebar-ul">
                        <li>
                            <a className="active" ><i
                                    className="icon-dashboard"></i>Dashboard</a>
                        </li>
                        <li>
                            <a ><i className="icon-card"></i>My Cards</a>
                        </li>
                        <li>
                            <a ><i className="icon-money"></i>Send Money</a>
                        </li>
                        <li>
                            <a><i
                                    className="icon-transaction"></i>Transactions<span className="bo-badge">2</span></a>
                        </li>
                        <li>
                            <a ><i className="icon-settings"></i>Settings</a>
                        </li>
                        <li>
                            <a ><i className="icon-help"></i>Help</a>
                        </li>
                        <hr/>
                        <li>
                            <a className="d-flex align-items-center logout">
                                <i className="icon-logout"></i>
                                <span>
                                    <span>Logout</span><br/>
                                    <span className="user-name">David Miller</span>
                                </span>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
            <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
                <h3>Your Cards<span className="bo-badge bo-badge-large">3</span></h3>
                <div className="row mt-20">
                    <div className="col-xl-6 col-md-6 col-sm-6 mb-20">
                        <div>
                            <img className="d-block img-fluid" src="../../assets/images/stacked-cards.png" alt="..."/>
                        </div>

                    </div>
                    <div className="col-xl-6 col-md-6 col-sm-6 mb-20">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="bo-card-box bg-blush">
                                    <div className="mb-3">
                                        <img src="../../assets/images/icons/credit-limit.svg"/>
                                    </div>
                                    <h4>$4000</h4>
                                    <div className="progress w-100 mt-2 mb-2">
                                        <div className="progress-bar" role="progressbar" 
                                            aria-valuenow="32" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center bo-card-stats mb-1">
                                        <span>Credit Limit</span>
                                        <span>25%</span>
                                    </div>
                                    <p>$20000</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bo-card-box bg-blush">
                                    <div className="mb-3">
                                        <img src="../../assets/images/icons/online-limit.svg"/>
                                    </div>
                                    <h4>$7500</h4>
                                    <div className="progress w-100 mt-2 mb-2">
                                        <div className="progress-bar" role="progressbar" 
                                            aria-valuenow="32" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center bo-card-stats">
                                        <span>Online Limit</span>
                                        <span>50%</span>
                                    </div>
                                    <p>$15000</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bo-card-box bg-blush">
                                    <div className="mb-3">
                                        <img src="../../assets/images/icons/transfer-limit.svg"/>
                                    </div>
                                    <h4>$3000</h4>
                                    <div className="progress w-100 mt-2 mb-2">
                                        <div className="progress-bar" role="progressbar" 
                                            aria-valuenow="32" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center bo-card-stats">
                                        <span>Transfer Limit</span>
                                        <span>40%</span>
                                    </div>
                                    <p>$10000</p>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="bo-card-box bg-blush">
                                    <div className="mb-3">
                                        <img src="../../assets/images/icons/atm-limit.svg"/>
                                    </div>
                                    <h4>$3750</h4>
                                    <div className="progress w-100 mt-2 mb-2">
                                        <div className="progress-bar" role="progressbar" 
                                            aria-valuenow="32" aria-valuemin="0" aria-valuemax="100">
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center bo-card-stats">
                                        <span>ATM Limit</span>
                                        <span>75%</span>
                                    </div>
                                    <p>$20000</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row align-items-center mt-20">
                    <div className="col-6">
                        <h3>Transactions</h3>
                    </div>
                    <div className="col-6">
                        <div className="a-arrow-btn toRight">
                            <a>View all <img src="../../assets/images/icons/arrow-right.svg"/></a>
                        </div>
                    </div>
                </div>

                <div>
                    <form>
                        <div className="row mt-20 row-search">
                            <div className="col-xl-6 col-12">
                                <div className="form-group">
                                    <div className="form-controls">
                                        <input type="text" className="form-control" placeholder="Search Card"/>
                                        <span className="icon-search _ico"></span>
                                      
                                        <div className="bo-cardsearch d-none">
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="form-group form-search mr-20">
                                                        <label>Name</label>
                                                        <div className="form-controls">
                                                            <input className="form-control"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-search">
                                                        <label>Token ID</label>
                                                        <div className="form-controls">
                                                            <input className="form-control"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-search mr-20">
                                                        <label>Date Issue</label>
                                                        <div className="form-controls">
                                                            <input className="form-control"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="form-group form-search">
                                                        <label>Date Expiry</label>
                                                        <div className="form-controls">
                                                            <input className="form-control"/>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group submit-field form-search">
                                                        <a href="" className="btn btn-large">Search</a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <span className="help-block d-none"></span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6">
                                <div className="form-group">
                                    <div className="form-controls">
                                        <input className="form-control right-icon" placeholder="Date From"/>
                                        <span className="icon-calender _ico icon-right"></span>
                                    </div>
                                    <span className="help-block d-none">Enter valid date</span>
                                </div>
                            </div>
                            <div className="col-xl-3 col-6">
                                <div className="form-group">
                                    <div className="form-controls">
                                        <input className="form-control right-icon" placeholder="Date To"/>
                                        <span className="icon-calender _ico icon-right"></span>
                                    </div>
                                    <span className="help-block d-none">Enter valid date</span>
                                </div>
                            </div>
                        </div>
                    </form>

                    <div className="table-wrap">
                        <table className="table table-borderless bo-table table-details">
                            <thead>
                                <th>Transaction ID</th>
                                <th>Date</th>
                                <th>Time</th>
                                <th>Category</th>
                                <th>Amount</th>
                                <th>view</th>
                            </thead>
                            <tbody>
                                <tr>
                                    <td>
                                        <div className="d-flex bo-col-transaction">
                                            <div>
                                                <img src="../../assets/images/icons/receiving.svg"/>
                                                <span></span>
                                            </div>
                                            <div>
                                                <h6>Receiving</h6>
                                                <p> 2808988</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>March 23, 2021</td>
                                    <td>5:11 PM</td>
                                    <td>Deposit</td>
                                    <td>+$800</td>
                                    <td>
                                        <div className="dropdown d-inline-block table_menu_action">
                                            <a className="dropdown-toggle no-caret" id="action_menu"
                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                <span className="icon-show"></span>
                                            </a>

                                            <div className="dropdown-menu dropdown-menu-end action__menu"
                                                aria-labelledby="action_menu">
                                                <div className="action_menu">
                                                    <p>
                                                        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Erat
                                                        enim elit, netus imperdiet quis leo. Nec pharetra feugiat dui,
                                                        et id duis viverra.
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div className="d-flex bo-col-transaction">
                                            <div>
                                                <img src="../../assets/images/icons/withdraw.svg"/>
                                                <span></span>
                                            </div>
                                            <div>
                                                <h6>Withdraw</h6>
                                                <p> 2808988</p>
                                            </div>
                                        </div>
                                    </td>
                                    <td>March 23, 2021</td>
                                    <td>5:11 PM</td>
                                    <td>Bills and Utilities </td>
                                    <td>-$800</td>
                                    <td><span className="icon-show"></span></td>
                                </tr>
                              
                              
                            </tbody>
                        </table>
                    </div>

                    <div className="loading-more">
                        <div className="loader">
                            <div></div>
                        </div>
                        <p>loading more</p>
                    </div>
                </div>
            </div>

            <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar">
                <div className="bo-right-sidebar-wrap">
                    <div className="row align-items-center">
                        <div className="col-7">
                            <h3>Send Money</h3>
                        </div>
                        <div className="col-5">
                            <div className="a-arrow-btn toRight">
                                <a>View all <img src="../../assets/images/icons/arrow-right.svg"/></a>
                            </div>
                        </div>
                    </div>

                    <div className="row align-items-center favorites mt-20">
                        <div className="col-9">
                            <p>Send Money to Favorites</p>
                        </div>
                        <div className="col-3">
                            <img title="Add" src="../../assets/images/icons/add.svg"/>
                        </div>
                    </div>
                    <ul className="ul-card-details-popup mt-16 ul-dashboard mb-5">
                      
                     
                        <li className="d-flex justify-content-between mb-20 li-send-money">
                            <div className="d-flex align-items-center">
                                <span className="td-logo">JW</span>
                                <b>Jane Will</b>
                            </div>
                            <span>197549098</span>
                        </li>
                    </ul>
                    <hr/>

                    <div className="mt-32">
                        <form className="form-dashboard">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Token ID</label>
                                        <div className="form-controls">
                                            <input className="form-control"/>
                                            <span className="icon-card-check _ico"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="form-group">
                                        <label>Amount</label>
                                        <div className="form-controls">
                                            <input className="form-control"/>
                                            <span className="icon-money _ico"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Name</label>
                                        <div className="form-controls">
                                            <input className="form-control"/>
                                            <span className="icon-user _ico"></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group">
                                        <label>Email</label>
                                        <div className="form-controls">
                                            <input className="form-control"/>
                                            <span className="icon-email _ico"></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-group submit-field d-flex">
                                <a className="btn btn-large w-100">Send Money</a>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        </Fragment>
    );
}

export default PreviewTheme;