import React, { useState, useEffect, useRef, Fragment } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import { subYears } from "date-fns";

import Form from "react-validation/build/form";
import ModelPopup from "../../helpers/ModelPopup";
import DialoguePopup from "../../helpers/DialoguePopup";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import BoLoader from "../../helpers/BoLoader";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import * as EmailValidator from "email-validator";
import roleManagmentObj from "../../services/role-managment.service";

let obj = [];
const AddSuperAdminUser = props => {
  return (
    <ModelPopup
    key={18}
      show={props.show}
      onHide={()=>{props.onHide();obj = [];}}
      modelheading={""}
      showheader={"false"}
      backdrop="static"
      keyboard={false}
      className="smallPopup issueCardSmall "
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76 "
      modelbody={
        <AddSuperAdminUsers
          show={props.show}
          onHide={props.onHide}
          onAddClient={props.onAddClient}
          userId={props?.userId}
          clientId={props?.clientId}
          calledfrom={props?.calledfrom}
        />
      }
    />
  );
};
const AddSuperAdminUsers = props => {
    const required = (value) => {
        if (!value) {
          document.getElementById("Password").classList.add("has_error");
          return <span className="help-block">Password is required!</span>;
        } else document.getElementById("Password").classList.remove("has_error");
      };
      const prequired = (value) => {
        if (!value) {
          document.getElementById("Password").classList.add("has_error");
          return <span className="help-block">Password is required!</span>;
        } else document.getElementById("Password").classList.remove("has_error");
      };
      const crequired = (value) => {
        if (!value) {
          document.getElementById("cPassword").classList.add("has_error");
          return <span className="help-block">Password is required!</span>;
        } else document.getElementById("cPassword").classList.remove("has_error");
      };
      let passwordValue = "";
      const vpassword = (value) => {
        passwordValue = value;
        if (value.length < 8 || value.length > 20) {
          document.getElementById("Password").classList.add("has_error");
          return (
            <div className="help-block" role="alert">
              The password must be between 8 and 20 characters!
            </div>
          );
        }
      };
      const vcpassword = (value) => {
        if (value.length < 8 || value.length > 20) {
          document.getElementById("cPassword").classList.add("has_error");
          return (
            <div className="help-block" role="alert">
              The password must be between 8 and 20 characters!
            </div>
          );
        } else if (value !== passwordValue) {
          document.getElementById("cPassword").classList.add("has_error");
          return (
            <span className="help-block" role="alert">
              The password and confirm password must match!
            </span>
          );
        }
      };
  const firstNameRequired = value => {
    if (!value) {
      document.getElementById("firstname").classList.add("has_error");
      return <span className="help-block">First Name is required!</span>;
    } else document.getElementById("firstname").classList.remove("has_error");
  };
  const lastNameRequired = value => {
    if (!value) {
      document.getElementById("lastname").classList.add("has_error");
      return <span className="help-block">Last Name is required!</span>;
    } else document.getElementById("lastname").classList.remove("has_error");
  };
  const emailRequired = value => {
    if (!value) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Email is required!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const checkValidEail = emailAdress => {
    let value = EmailValidator.validate(emailAdress);
    return value;
  };
  const emailFormatRequired = value => {
    let verifyemailcheck = checkValidEail(value);
    if (!verifyemailcheck) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Please enter valid email!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const addressRequired = value => {
    if (!value) {
      document.getElementById("addressroles").classList.add("has_error");
      return <span className="help-block">Address is required!</span>;
    } else
      document.getElementById("addressroles").classList.remove("has_error");
  };
//   const checkPhoneNumberLength = value => {
//     if (value.length < 4) {
//       document.getElementById("id_phonenumber").classList.add("has_error");
//       return (
//         <span className="help-block">Minimum three Numbers are required!</span>
//       );
//     } else
//       document.getElementById("id_phonenumber").classList.remove("has_error");
//   };
  const requiredPass = value => {
    if (!value) {
      return <span className="help-block">this field is required!</span>;
    }
  };
  
  const requiredPermission = (value) => {
    if (!value) {
      document.getElementById("dobsuperadmin").classList.add("has_error");
      setShowDOBErr("");
      return false;
    } else {
      document.getElementById("dobsuperadmin").classList.remove("has_error");
      setShowDOBErr("d-none");
      return true;
    }
  };
  const [clientId, setClientId] = useState(props.clientId);
  const [userId, setuserId] = useState();
  const form = useRef();
  const checkBtn = useRef();
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [DateofBirth, setDateofBirth] = useState();

  const [PhoneNumber, setphoneNumber] = useState("");
  const [Email, setemail] = useState("");
  const [PermissionsList, setPermissionsList] = useState([]);
  const [Role, SetRole] = useState("Super admin");
  const [Address, setAddress] = useState("");
  const [isValidPhoneNumber, setisValidPhoneNumber] = useState(true);
 
  const [Permissions, setPermissions] = useState([]);

  //Dialogue Popup
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");

  //loader
  const [boloader, setBoLoader] = useState(false);
 
  const [showPhoneErr, setshowPhoneErr] = useState("d-none");
  const [showPhoneMinimumErr, setshowPhoneMinimumErr] = useState("d-none");

  const [showDOBErr, setShowDOBErr] = useState("d-none");
//Password states
const [Password, setPassword] = useState("");
  const [confirmpassword, setConfirmPassword] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);
  const [cPasswordShown, setCPasswordShown] = useState(false);
  const [showHidePassClass, setshowHidePassClass] = useState("icon-hidden");
  const [showHideCPassClass, setshowHideCPassClass] = useState("icon-hidden");
  const onChangeDOB = (value) => {
    let DOB = value;
    if (DOB) DOB = new Date(DOB);
    setDateofBirth(DOB);
    requiredDob(value);
  };
  const requiredDob = value => {
    if (!value) {
      document.getElementById("dob-frmgrp").classList.add("has_error");
      setShowDOBErr("");
      return false;
    } else {
      document.getElementById("dob-frmgrp").classList.remove("has_error");
      setShowDOBErr("d-none");
      return true;
     
    
    }
  };

  const requiredPhoneNo = value => {
    if (PhoneNumber.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
    if (!value) {
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneErr("");
      return false;
    } else {
      document.getElementById("phonenumber").classList.remove("has_error");
      setshowPhoneErr("d-none");
      return true;
    }
  };
  const maxLengthCheck = object => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  useEffect(() => {
    let params="";
    if(props?.calledfrom==="client")
     params="client admin";
else params="super admin"
    
    clientmanagmentObj
      .getPermissions(params,props?.clientId ?props.clientId:0,0)
      .then(response => {
        if (response && response.data && response.data.payload) {
          const lst_permissions = response.data.payload.result.map(data => {
            return {
              id: data.id,
              claimName: data.claimName,
              uuid: data.uuid,
              checked: true,
            };
          });
          setPermissionsList(lst_permissions);
        }
      })
      .catch(error => {
        // (async () => {
        //   let resp = await RefreshToken(
        //     error && error.response && error.response.status
        //   );
        //   if (resp) {
        //     window.location.reload();
        //   }
        // })();
      });
  }, []);
  useEffect(() => {
    if (PhoneNumber.length === 0) {
      setisValidPhoneNumber(true);
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else if (PhoneNumber.length < 3) {
      setisValidPhoneNumber(false);
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else {
      setisValidPhoneNumber(true);
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    }
  }, [PhoneNumber]);

  const onChangeFirstName = e => {
    const firstname = e.target.value;
    setFirstName(firstname)
  };
  const onChangeLastName = e => {
    const lastname = e.target.value;
    setLastName(lastname);
    
  };
  
 
  const onChangeEmail = e => {
    const Email = e.target.value;
    setemail(Email);
  };
  
  const onChangeaddress = e => {
    const Address = e.target.value;
    setAddress(Address);
  };
  
  const saveUser = e => {
    e.preventDefault();
    form.current.validateAll();
    let phoneValid = requiredPhoneNo(PhoneNumber);
    let  IsActive=true;
    let IsEmailConfirmed=true;
    let dob=requiredDob(DateofBirth);
    if (
      checkBtn.current.context._errors.length === 0 &&
      phoneValid &&
      isValidPhoneNumber && dob
    ) {
      setBoLoader(true);
      roleManagmentObj
        .addUpdateUserRoles(
          props?.clientId? props?.clientId:1,
          props?.clientId===1 ?Role :"Client admin" ,
            FirstName,
            LastName,
            Email,
            Address,
            DateofBirth,
            PhoneNumber,
            Password,
            IsActive,
            IsEmailConfirmed,
            Permissions
         
        )
        .then(response => {
          setBoLoader(false);
          if (response && response.data) {
            if (response.data?.success) {
              // history.push("clients");
              // window.location.reload();
              setPermissions([]);
              obj = [];
              props.onAddClient();
            }
          }
        })
        .catch(error => {
          setBoLoader(false);
          let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message
            : "Error Occured while Registering user";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
          // (async () => {
          //   let resp = await RefreshToken(
          //     error && error.response && error.response.status
          //   );
          //   if (resp) {
          //     if (resp) {
          //       saveUser();
          //       setBoLoader(false);
          //     } else {
          //       setBoLoader(false);
          //     }
          //   }
          // })();
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };

  const handleSingleCheck = e => {
    if (e.target.checked === true) {
      obj = obj.concat({
        UserId: e.target.id,
        ClaimType: e.target.attributes.claimtype.value,
        ClaimValue: e.target.attributes.claimvalue.value,
      });
      setPermissions(obj);
    }
    if (e.target.checked === false) {
      obj = Permissions.filter(
        i => i.ClaimType !== e.target.attributes.claimtype.value
      );
      setPermissions(obj);
    }
  };
  const onChangePhoneNumber = e => {
    requiredPhoneNo(e);
    setphoneNumber(e);
  };
  const showHideCPassHandler = () => {
    setCPasswordShown(cPasswordShown ? false : true);
    setshowHideCPassClass(cPasswordShown ? "icon-hidden" : "icon-show");
  };
  const onChangeConfirmPassword = (e) => {
    const confirmPassword = e.target.value;
    setConfirmPassword(confirmPassword);
  };
  const onChangePassword = (e) => {
    const password = e.target.value;
    setPassword(password);
  };
  const showHidePassHandler = () => {
    setPasswordShown(passwordShown ? false : true);
    setshowHidePassClass(passwordShown ? "icon-hidden" : "icon-show");
  };
  return (
    <div className="">
      <div className="row">
        <BoLoader loading={boloader} />
        <div className="col-md-12">
          <h2>Create User</h2>
          <div className="mt-24">
            <Form onSubmit={saveUser} ref={form}>
              <div className="row">
                <div className="col-md-6" id="firstname">
                  <div className="form-group">
                    <label>First Name*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="Name"
                        maxLength="40"
                        onChange={onChangeFirstName}
                        validations={[firstNameRequired, required]}
                        value={FirstName}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="lastname">
                  <div className="form-group">
                    <label>Last Name*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="Name"
                        maxLength="38"
                        onChange={onChangeLastName}
                        validations={[lastNameRequired, required]}
                        value={LastName}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="email">
                  <div className="form-group">
                    <label>Email*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        maxLength="50"
                        onChange={onChangeEmail}
                        validations={[
                          emailRequired,
                          required,
                          emailFormatRequired,
                        ]}
                        value={Email}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="addressroles">
                  <div className="form-group">
                    <label>Address*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control left-icon"
                        onChange={onChangeaddress}
                        value={Address}
                        validations={[addressRequired]}
                        maxLength="40"
                      />
                      <span className="icon-home _ico"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="phonenumber">
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <div className="form-group">
                      <PhoneInput
                        className=""
                        country={"gb"}
                        onChange={onChangePhoneNumber}
                        value={PhoneNumber}
                      />
                      <span className={"help-block " + showPhoneErr}>
                        Phone No is required!
                      </span>
                      {!isValidPhoneNumber && (
                        <div id="id_minimumphonelength">
                          <span className={"help-block " + showPhoneMinimumErr}>
                            Minimum three number are required!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6" id="dob-frmgrp">
                            <div className="form-group mb-3" >
                              <label>Date Of Birth* </label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={DateofBirth}
                                  onchangedate={onChangeDOB}
                                  maximumdate={subYears(new Date(), 18)}
                                />
                                <span className="icon-calender _ico"></span>
                                <span className="icon-round icon-calender-mini"></span>
                                <span className="fs-12 clr-submarine mt-2 d-block">
                                  Age should be greater than 18 years
                                </span>
                                {/* <span className="icon-round icon-calender-mini"></span> */}
                                <span className={"help-block " + showDOBErr}>
                                  DOB is required!
                                </span>
                              </div>
                            </div>
                          </div>
                          <div class="col-md-6">
                    <div className="form-group" id="Password">
                      <label htmlFor="password">Password*</label>
                      <div class="form-controls">
                        <Input
                          type={passwordShown ? "text" : "password"}
                          className="form-control"
                          name="password"
                          value={Password}
                          onChange={onChangePassword}
                          validations={[prequired, vpassword]}
                          maxLength="20"
                        />
                        <span class="icon-lock _ico"></span>
                        <span
                          className={"show-password " + showHidePassClass}
                          onClick={showHidePassHandler}
                        ></span>
                      </div>
                    </div>
                  </div>
                  <div class="col-md-6" id="cPassword">
                    <div className="form-group">
                      <label htmlFor="confirmPassword">Confirm Password*</label>
                      <div class="form-controls">
                        <Input
                          type={cPasswordShown ? "text" : "password"}
                          className="form-control"
                          name="confirmPassword"
                          value={confirmpassword}
                          onChange={onChangeConfirmPassword}
                          validations={[crequired, vcpassword]}
                          maxLength="20"
                        />
                        <span class="icon-lock _ico"></span>
                        <span
                          className={"show-password " + showHideCPassClass}
                          onClick={showHideCPassHandler}
                        ></span>
                      </div>
                    </div>
                  </div>   
                <div className="col-md-12" id="permissions">
                <label>Permissions</label>
                <br/>
                <br/>
                <br/>

                  <ul className="checkbox-block-ul mt--24">
                    {PermissionsList.map(PermissionsList => {
                      return (
                        <li>
                          <div className="bo-checkbox bo-checkbox-round">
                            <input
                              key={PermissionsList.uuid}
                              onChange={handleSingleCheck}
                              type="checkbox"
                              id={PermissionsList.uuid}
                              ClaimType={PermissionsList.claimName}
                              ClaimValue={PermissionsList.claimName}
                            />
                            <label htmlFor={PermissionsList.uuid}>
                              {PermissionsList.claimName}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  
                </div>

                <div className="col-md-12">
                  <div className="form-group submit-field d-flex justify-content-center mt-40 mb-0">
                    <a
                      className="btn btn-outline mr-19"
                      data-bs-dismiss="modal"
                      onClick={()=>{props.onHide(); obj = [];}}
                    >
                      Cancel
                    </a>
                    <a className="btn btn-large" onClick={saveUser}>
                      Add User
                    </a>
                  </div>
                </div>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
     
    </div>
  );
};

export default AddSuperAdminUser;
