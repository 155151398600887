import React from "react";
import Chart from "react-google-charts";

const DonutChart = (props) => {
  const propsData = Object.entries(props.chartdata).map((item) =>
    Object.values(item)
  );

  const options = {
    pieHole: 0.65,
    is3D: false,
    legend: "none",
    tooltip: {
      text: "value",
    },
    pieSliceText: "none",
    colors: props.colorscheme, // ["#89455E", "#E0DCDC"],
  };
  return (
    <Chart
      chartType="PieChart"
      width="22rem"
      height="22rem"
      data={[["Data", "TotalData"], ...propsData]}
      options={options}
    />
  );
};

export default DonutChart;
