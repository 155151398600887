import React, { useState, useEffect } from "react";
import DonutChart from "../../helpers/DonutChart";

import depositService from "../../services/deposit.service";
import SearchGrid from "../../helpers/SearchGrid";
import { Env } from "../../enviroment/environment";
import { Fragment } from "react";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import ExportToExcel from "../superadmin/ExportToExcel";

const API_URL = Env.DepositApiUrl;
let donutChartDepoistData = {};
const Deposit = () => {
  // const [modalShow, setModalShow] = useState(false);
  // const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [depositContent, setDepositContent] = useState();
  const [superAdminDepositDataSuccessful, setSuperAdminDepositDataSuccessful] =
    useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [dataset, setDataset] = useState();
  const [AllClientClassName, setAllClientClassName] = useState("nav-link");
  const [NewClientClassName, setNewClientClassName] = useState("nav-link");
  const [AllClientAreaSelected, setAllClientAreaSelected] = useState("false");
  const [NewClientsAreaSelected, setNewClientsAreaSelected] = useState("false");
  useEffect(() => {
    allClients();
    setBoLoader(true);
    setSuperAdminDepositDataSuccessful(false);
    depositService
      .getDepositStatusData()
      .then((response) => {
        setBoLoader(false);
        donutChartDepoistData = {
          "Avg Deposit": response.data.payload.result.avgDeposit,
          "Last Deposit": response.data.payload.result.lastDeposit,
          "Total Deposit": response.data.payload.result.totalDeposit,
        };
        setDepositContent(response.data.payload.result);
        setSuperAdminDepositDataSuccessful(true);
      })
      .catch((err) => {
        (async () => {
          let resp = await RefreshToken(
            err && err.response && err.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      });
  }, []);
  const allClients = () => {

    setAllClientClassName("nav-link active d-flex align-items-center");
    setAllClientAreaSelected("true");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
  };
  const newClients = () => {
    setNewClientClassName("nav-link active d-flex align-items-center active");
    setNewClientsAreaSelected("true");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
  };
  return (
    <div className="col-lg-12 col-xl-9 bo-main-content">
      <BoLoader loading={boloader} />

      {/* {superAdminDepositDataSuccessful && (
        <Fragment>
          <h3>Stats</h3>
          <div className="row mt-20">
            <div className="col-sm-8 col-lg-6">
              <div className="col-stats col-stats-chart mb-40">
                <div className="row align-items-center">
                  <div className="col-md-5 col-12">
                    <div className="chart-wrap">
                      <DonutChart
                        chartdata={{ ...donutChartDepoistData }}
                        colorscheme={["#89455E", "#C1A6B0", "#E0DCDC"]}
                      />
                      <div className="donut-center-text">
                        <h5>${depositContent.totalDeposit}</h5>
                        <p>Total Deposits</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-7 col-12">
                    <div className="chart-legends chart-legends-deposit">
                      <div className="row">
                        <div className="col-md-6 col-6 mb-20">
                          <p>Total Deposits</p>
                          <h5>
                            <span></span>${depositContent.totalDeposit}
                          </h5>
                        </div>
                        <div className="col-md-6 col-6 mb-20">
                          <p>Last Deposit</p>
                          <h5>
                            <span></span>${depositContent.lastDeposit}
                          </h5>
                        </div>
                        <div className="col-md-6 col-6">
                          <p>Avg. Deposit</p>
                          <h5>
                            <span></span>${depositContent.avgDeposit}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Fragment>
      )} */}
      <div>
        <div className="row align-items-center">


        </div>
        <div className="bo-tabs-mbl">
          <ul className="nav nav-tabs" id="myTab2" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={AllClientClassName}
                id="allClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#allClients"
                type="button"
                role="tab"
                onClick={allClients}
                aria-controls="allClients"
                aria-selected={AllClientAreaSelected}
              >
                <span className="icon-deposit"></span>Approved Deposits
              </button>

            </li>
            <li className="nav-item" role="presentation">
              <button
                className={NewClientClassName}
                id="newClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#newClients"
                type="button"
                role="tab"
                aria-controls="newClients"
                onClick={newClients}
                aria-selected={NewClientsAreaSelected}
              >
                <span className="icon-deposit"></span>Pending Deposits
              </button>
            </li>
            <li className="nav-item ms-auto">
            <div className="toRight mt-16 mb-16">
              <ExportToExcel
                Dataset={dataset}
                Name="Deposit"
                calledfrom="deposit"
              />
            </div>
            </li>
            
          </ul>
        </div>

        {
          AllClientAreaSelected === "true" && (<SearchGrid
            methodname="getdeposits"
            placeholdervalue="Search Deposit"
            headersdata={[
              "REFERENCE NO.",
              "DATE",
              "DEPOSITED AMOUNT",
              // "SENDING BANK",
              // "ACCOUNT NO",
              "CLIENT NAME",
              "STATUS",
              "VIEW",
            ]}
            apiurl={API_URL}
            screenname="deposit"
            setDataset={setDataset}
            status={"true"}
          />)
        }
        {
          NewClientsAreaSelected === "true" && (<SearchGrid
            methodname="getdeposits"
            placeholdervalue="Search Deposit"
            headersdata={[
              "REFERENCE NO.",
              "DATE",
              "DEPT AMOUNT",
              // "SENDING BANK",
              // "ACCOUNT NO",
              "CLIENT NAME",
              "STATUS",
              "VIEW",
            ]}
            apiurl={API_URL}
            screenname="deposit"
            setDataset={setDataset}
            status={"Pending"}
          />)
        }
      </div>
    </div>
  );
};

export default Deposit;
