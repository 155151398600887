import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.UserApiUrl; //"http://localhost:8000/api/users/";

const addUpdateUserRoles = (
    ClientId,
    Role,
    FirstName,
    LastName,
    Email,
    Address,
    DateofBirth,
    PhoneNumber,
    Password,
    IsActive,
    IsEmailConfirmed,
    Permissions,
    Id,
    SuperClientId
) => {
  return axios
    .post(
      API_URL + "createupdatesuperadmin",
      {
        ClientId,
            Role,
            FirstName,
            LastName,
            Email,
            Address,
            DateofBirth,
            PhoneNumber,
            Password,
            IsActive,
            IsEmailConfirmed,
            Permissions,
            Id,
            SuperClientId
      
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getsubadminstats = (clientId) => {
    return axios
      .post(API_URL + "getsubadminstats?clientId=" + clientId, { headers: authHeader() })
      .then((response) => {
        return response;
      });
  };
  const searhGridRoleManagment = (name, ClientId, dateFrom, dateTo, startIndex, Status,SuperClientId) => {
    return axios
      .post(
        API_URL + "getallsubadmins",
        {
          name,
          ClientId,
          dateFrom,
          dateTo,
          startIndex,
          Status,
          SuperClientId
        },
        { headers: authHeader() }
      )
      .then((resp) => {
        return resp;
      });
  };
  const getadmindetail = (userId) => {
    return axios
      .post(
        API_URL + "getadmindetail?userId=" + userId,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((resp) => {
        return resp;
      });
  };
  const suspendUser = (userId,isSuspend) => {
    return axios
      .post(
        API_URL + "suspendunsuspendadmin?userId=" + userId+"&isSuspend="+isSuspend,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((resp) => {
        return resp;
      });
  };
  const unSuspendUser = (userId,isSuspend) => {
    return axios
      .post(
        API_URL + "suspendunsuspendadmin?userId=" + userId+"&isSuspend="+isSuspend,
        {},
        {
          headers: authHeader(),
        }
      )
      .then((resp) => {
        return resp;
      });
  };
const roleManagmentObj = {
    addUpdateUserRoles,
    getsubadminstats,
    searhGridRoleManagment,
    getadmindetail,
    suspendUser,
    unSuspendUser
};
export default roleManagmentObj;
