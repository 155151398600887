import React, { useState, useEffect, useRef, Fragment } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import Form from "react-validation/build/form";
import ModelPopup from "../../helpers/ModelPopup";
import DialoguePopup from "../../helpers/DialoguePopup";
import "react-phone-input-2/lib/style.css";
import BoLoader from "../../helpers/BoLoader";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import "react-color-palette/lib/css/styles.css";
import GetAllClients from "../../helpers/GetAllClients";
import clientFeeServiceObj from "../../services/clienfeeservice";

const AddClientFee = props => {
    return (
        <ModelPopup
            show={props.show}
            onHide={props.onHide}
            modelheading={""}
            showheader={"false"}
            backdrop="static"
            keyboard={false}
            className="smallPopup issueCardSmall "
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76 "
            modelbody={
                <AddClientFees
                    show={props?.show}
                    onHide={props?.onHide}
                    onAddClient={props?.onAddClient}
                    calledfrom={props?.calledfrom}
                    updatevalues={props?.updatevalues}
                />
            }
        />
    );
};
const AddClientFees = props => {
    const FeeAmountRequired = value => {
        if (!value) {
          document.getElementById("fee_amount").classList.add("has_error");
          return <span className="help-block">Fee Amount is Required!</span>;
        } else
          document.getElementById("fee_amount").classList.remove("has_error");
      };
      const FePercentageRequired = value => {
        if (!value) {
          document.getElementById("fee_percentage").classList.add("has_error");
          return <span className="help-block">Fee Percentage is Required!</span>;
        } else
          document.getElementById("fee_percentage").classList.remove("has_error");
      };
    const CheckNegativeFeeAmount = value => {
        let obj = parseFloat(value);
        if (obj < 0) {
            document.getElementById("fee_amount").classList.add("has_error");
            return (
                <span className="help-block">Fixed Amount Cannot Be Negative!</span>
            );
        } else
            document.getElementById("fee_amount").classList.remove("has_error");
    };
    const CheckNegativeFeePercentage = value => {
        let obj = parseFloat(value);
        if (obj < 0) {
            document.getElementById("fee_percentage").classList.add("has_error");
            return (
                <span className="help-block">Fee Percentage Cannot Be Negative!</span>
            );
        } else
            document.getElementById("fee_percentage").classList.remove("has_error");
    };
    const form = useRef();
    const checkBtn = useRef();
    const [ClientId, setClientId] = useState("");
    const [FeeCost, setFeeCost] = useState();
    const [FeePercentage, setFeePercentage] = useState();
    const [Id, setId] = useState();
    //Dialogue Popup
    const [modalShow, setModalShow] = useState(false);
    const [modelhdng, setMdlHdng] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");

    //loader
    const [boloader, setBoLoader] = useState(false);
    const [FeeTypeId, setFeeType] = useState(2);
    const [feeList, setFeeList] = useState([]);
    const maxLengthCheck = object => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
        var t = object.target.value;
        if (t.includes("."))
            object.target.value = (t?.indexOf(".") >= 0) ? (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3)) : t;
    };
    useEffect(() => {
        if (props?.calledfrom === "update" || props?.calledfrom === "delete") {
            setId(props?.updatevalues?.id);
            setFeePercentage(props?.updatevalues?.feePercentage);
            setFeeCost(props?.updatevalues?.feeCost);
            setClientId(props?.updatevalues?.clientId);

        }
    }, [])
    useEffect(() => {

        clientFeeServiceObj.getFeeTypes().then((resp) => {
            if (resp) {
                setFeeList(resp?.data?.payload);
            }
        }).catch();
    }, [])
    const onChangeFeeCost = e => {
        let amount = e.target.value;
        if (amount != "") {
            let value = parseFloat(amount);
            setFeeCost(value);
        }
    };
    const onChangeFeePercentage = e => {
        let amount = e.target.value;
        if (amount != "") {
            let value = parseFloat(amount);
            setFeePercentage(value);
        }
    };

    const addFees = e => {
        e.preventDefault();
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            setBoLoader(true);
            clientFeeServiceObj
                .addFeesAmount(0, ClientId, FeeTypeId, FeeCost, FeePercentage, false)
                .then(response => {
                    setBoLoader(false);
                    if (response && response.data) {
                        if (response.data?.success) {
                            props?.onAddClient();
                        }
                    }
                })
                .catch(error => {
                    setBoLoader(false);
                    let msgBody = error?.response?.data?.message
                        ? error?.response?.data?.message
                        : "Error Occured while creating fees";
                    let errIcon = "icon-error";
                    errorAndSuccessModl("Error", msgBody, errIcon);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            if (resp) {
                                addFees();
                                setBoLoader(false);
                            } else {
                                setBoLoader(false);
                            }
                        }
                    })();
                });
        }
    };

    const updateFees = e => {
        e.preventDefault();
        form.current.validateAll();

        if (checkBtn.current.context._errors.length === 0) {
            setBoLoader(true);
            clientFeeServiceObj
                .updateFeesAmount(Id, parseInt(ClientId), FeeTypeId, FeeCost, FeePercentage, false)
                .then(response => {
                    setBoLoader(false);
                    if (response && response.data) {
                        if (response.data?.success) {
                            props?.onAddClient();
                        }
                    }
                })
                .catch(error => {
                    setBoLoader(false);
                    let msgBody = error?.response?.data?.message
                        ? error?.response?.data?.message
                        : "Error Occured while Registering client";
                    let errIcon = "icon-error";
                    errorAndSuccessModl("Error", msgBody, errIcon);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            if (resp) {
                                addFees();
                                setBoLoader(false);
                            } else {
                                setBoLoader(false);
                            }
                        }
                    })();
                });
        }
    };
    const deleteFees = e => {
        e.preventDefault();
        form.current.validateAll();
            setBoLoader(true);
            clientFeeServiceObj
                .deleteFeesAmount(Id, parseInt(ClientId), FeeTypeId, FeeCost, FeePercentage,true)
                .then(response => {
                    setBoLoader(false);
                    if (response && response.data) {
                        if (response.data?.success) {
                            props?.onAddClient();
                        }
                    }
                })
                .catch(error => {
                    setBoLoader(false);
                    let msgBody = error?.response?.data?.message
                        ? error?.response?.data?.message
                        : "Error Occured";
                    let errIcon = "icon-error";
                    errorAndSuccessModl("Error", msgBody, errIcon);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            if (resp) {
                                addFees();
                                setBoLoader(false);
                            } else {
                                setBoLoader(false);
                            }
                        }
                    })();
                });
        
    };

    const errorAndSuccessModl = (heading, body, errIcon) => {
        setModalShow(true);
        setMdlHdng(heading);
        setMdlBdy(body);
        setMdlIcon(errIcon);
    };

    const onChangeFeeType = e => {
        let obj = e.target.selectedOptions[0].value;
        if (obj != "") {
            let value = parseInt(obj);
            setFeeType(value);
        }
    }

    const ClientNameChangeHandler = e => {
        let obj = e.target.selectedOptions[0].value;
        if (obj != "") {
            let value = parseInt(obj);
            setClientId(value);
        }
    };

    const disableEnableFeeType=()=>{

    }
    return (
        <div className="">
            <div className="row">
                <BoLoader loading={boloader} />
                <div className="col-md-12">
                    {props?.calledfrom === "add" && (<h2>Add Custom Fees</h2>)}
                    {props?.calledfrom === "update" && (<h2>Update Fees</h2>)}
                    {props?.calledfrom === "delete" && (<h2>Delete Fees</h2>)}

                    <div className="mt-24">
                        <Form onSubmit={(e) => e.preventDefault()} ref={form}>
                            <div className="row">
                                <div className="col-md-6" id="client_id">
                                    <div className="form-group">
                                        <label>Select Client*</label>
                                        <div className="form-controls">
                                            {
                                                props?.calledfrom === "add" && (
                                                    <GetAllClients
                                                        clientnamechangehandler={
                                                            ClientNameChangeHandler
                                                        }
                                                        searchgriddata="Y"
                                                    />
                                                )
                                            }
                                            {
                                                props?.calledfrom === "update" && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={props?.updatevalues?.clientName}
                                                            disabled="disabled"
                                                            className="form-controls"
                                                        />
                                                    </div>
                                                )
                                            }
                                            {
                                                props?.calledfrom === "delete" && (
                                                    <div>
                                                        <input
                                                            type="text"
                                                            value={props?.updatevalues?.clientName}
                                                            disabled="disabled"
                                                            className="form-controls"
                                                        />
                                                    </div>
                                                )
                                            }

                                        </div>

                                    </div>
                                </div>
                                

                                    <div className="col-md-6" id="fee_type">
                                        <div className="form-group">
                                            <label>Fee Type*</label>
                                            <div className="form-controls">
                                                {
                                                   props?.calledfrom === "add" ?(
                                                    <select
                                                    className="form-control no-icon"
                                                    onChange={onChangeFeeType}
                                                >
                                                    {
                                                            feeList.map((options) => (
                                                                <option key={options.id} value={options.id} >
                                                                    {options.feeTypeName}
                                                                </option>
                                                            ))
                                                        
                                                    }
                                                </select>
                                                   ):(
                                                    <input
                                                            type="text"
                                                            value={props?.updatevalues?.feeType}
                                                            disabled="disabled"
                                                            className="form-controls"
                                                        />
                                                   ) 
                                                }
                                                
                                            </div>
                                        </div>
                                    </div>
                                
                                

                                <div className="col-md-6" id="fee_amount">
                                    <div className="form-group">
                                        <label>Fixed Amount*</label>
                                        <div className="form-controls">
                                            <Input
                                                type="number"
                                                maxLength="9"
                                                onInput={maxLengthCheck}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                className="form-control no-icon"
                                                name="prefundedAmount"
                                                value={FeeCost}
                                                onChange={onChangeFeeCost}
                                                validations={[CheckNegativeFeeAmount,FeeAmountRequired]}
                                                disabled={props?.calledfrom==="delete"}
                                            />
                                            <span className=""></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6" id="fee_percentage">
                                    <div className="form-group">
                                        <label>Fee Percentage*</label>
                                        <div className="form-controls">
                                            <Input
                                                type="number"
                                                maxLength="9"
                                                onInput={maxLengthCheck}
                                                onWheel={(e) => e.target.blur()}
                                                onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                className="form-control no-icon"
                                                name="prefundedAmount"
                                                value={FeePercentage}
                                                onChange={onChangeFeePercentage}
                                                validations={props?.calledfrom!=="delete" ?([CheckNegativeFeePercentage,FePercentageRequired]):("")}
                                                disabled={props?.calledfrom==="delete"}
                                            />
                                            <span className=""></span>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <div className="form-group submit-field d-flex justify-content-center mt-40 mb-0">
                                        <a
                                            className="btn btn-outline mr-19"
                                            data-bs-dismiss="modal"
                                            onClick={props.onHide}
                                        >
                                            Cancel
                                        </a>
                                        {
                                            props?.calledfrom === "add" && (
                                                <a className="btn btn-large" onClick={addFees}>
                                                    Add Custom Fees
                                                </a>
                                            )
                                        }
                                        {
                                            props?.calledfrom === "update" && (
                                                <a className="btn btn-large" onClick={updateFees}>
                                                    Update Fees
                                                </a>
                                            )
                                        }
                                        {
                                            props?.calledfrom==="delete" &&(
                                                <a className="btn btn-large" onClick={deleteFees}>
                                                Delete Fees
                                            </a>
                                            )
                                        }

                                    </div>
                                </div>
                            </div>
                            <CheckButton style={{ display: "none" }} ref={checkBtn} />
                        </Form>
                    </div>
                </div>
            </div>
            <DialoguePopup
                show={modalShow}
                onHide={() => setModalShow(false)}
                modelheading={modelhdng}
                modelbody={modelbdy}
                modelicon={MdlIcon}
            />

        </div>
    );
};

export default AddClientFee;
