import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";

const API_URL = Env.SettingsApiUrl;
const changePassword = (userId, currentPassword, newPassword) => {
  return axios
    .post(
      API_URL + "changepassword",
      { userId, currentPassword, newPassword },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const checkCurrentPassword = (userId, currentPassword, newPassword) => {
  return axios
    .post(
      API_URL + "checkcurrentpassword",
      { userId, currentPassword, newPassword },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const settingsObj = {
  changePassword,
  checkCurrentPassword,
};
export default settingsObj;
