import { useState, useEffect, Fragment } from "react";
// import cardArrow from "../../assets/images/icons/bo-arrow.svg";
// import addclienticon from "../../assets/images/icons/add-client.svg";
import DonutChart from "../../helpers/DonutChart";
import AddClient from "./AddClient";
import SearchGridClient from "./SearchGridClients";
import { Env } from "../../enviroment/environment";
import clientmanagmentObj from "../../services/client-managment.service";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import { Link } from "react-router-dom";
import { history } from "../../helpers/history";
import ModelPopup from "../../helpers/ModelPopup";
import TransferClient from "./TransferClient";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import AddSuperClient from "../superclient/AddSuperClient";
const API_URL = Env.ClientAdmin;

const Clients = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [Content, setContent] = useState();
  const [DataSuccessful, setDataSuccessful] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [donutChart, setDonutChart] = useState();
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [modalShowTransferClient, setModalShowTransferClient] = useState(false);
  const [modelconfirmsuperclient, setModelconfirmsuperclient] = useState(false);
  if (currentUser) {
    haspermissions.CreateClient =
      currentUser?.user?.Permissions?.includes("Create Client");
    haspermissions.LedgerTransfer =
      currentUser?.user?.Permissions?.includes("Ledger Transfer");
  }

  useEffect(() => {
    getCountDashboard();
  }, []);

  const getCountDashboard = () => {
    setBoLoader(true);
    setDataSuccessful(false);
    clientmanagmentObj.getclientcount().then(
      (response) => {
        setContent(response.data.payload.result);
        const lst_donutchart = {
          Active: response.data.payload.result.activeClient,
          Suspended: response.data.payload.result.inActiveClient,
        };
        setDonutChart(lst_donutchart);
        setDataSuccessful(true);
        setBoLoader(false);
      },
      (error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      }
    );
  };
  const goToClients = () => {
    getCountDashboard();
  };
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      <BoLoader loading={boloader} />
      {/* <h3>Statistics</h3> */}
      <div className="row mt-20">
        {/* {DataSuccessful && (
          <div className="col-sm-8 col-md-6 col-xl-6">
            <div className="col-stats col-stats-chart mb-40">
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <div className="chart-wrap">
                    <DonutChart
                      chartdata={{ ...donutChart }}
                      colorscheme={["#89455e", "#E0DCDC"]}
                    />
                    <div className="donut-center-text">
                      <h5>{Content.totalClient}</h5>
                      <p>Total Clients</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-4 col-12">
                  <div className="chart-legends">
                    <div className="row">
                      <div className="col-6 col-md-12">
                        <div className="mb-20">
                          <p>Active Clients</p>
                          <h5>
                            <span></span>
                            {Content.activeClient}
                          </h5>
                        </div>
                      </div>
                      <div className="col-8 col-md-12">
                        <div>
                          <p>Suspended Clients</p>
                          <h5>
                            <span></span>
                            {Content.inActiveClient}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {haspermissions.CreateClient && (
          <Fragment>
          <div
            className="col-sm-6 col-md-3 col-xl-2"
            onClick={() => setCnfrmModalShow(true)}
          >
            <div className="col-stats col-stats-action mb-40 col-stats-mbl">
              <div data-bs-target="\clients" data-bs-toggle="modal">
                <span className="icon-add-client">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                </span>
                {/* <img src={addclienticon} alt="addclient" /> */}
                <br />
                <Link to="#" className="pt-3">
                  Create Client
                  <span className="icon-bo-arrow"></span>
                  {/* <img src={cardArrow} alt="arrow" /> */}
                </Link>
              </div>
            </div>
          </div>
          {/* <div
            className="col-sm-6 col-md-3 col-xl-2"
            onClick={() => setModelconfirmsuperclient(true)}
          >
            <div className="col-stats col-stats-action mb-40 col-stats-mbl">
              <div data-bs-target="\clients" data-bs-toggle="modal">
                <span className="icon-add-client">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                </span>
                
                <br />
                <Link to="#" className="pt-3">
                  Create Super Client
                  <span className="icon-bo-arrow"></span>
                  
                </Link>
              </div>
            </div>
          </div> */}
          </Fragment>
          
        )}
        {haspermissions.LedgerTransfer && (
          <div
            className="col-md-3 col-sm-6 col-xl-2"
            onClick={() => setModalShowTransferClient(true)}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-load-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                  <span className="path12"></span>
                  <span className="path13"></span>
                  <span className="path14"></span>
                </span>
                <br />
                <Link to="#" className="mt-3">
                  Ledger Transfer
                  <span className="icon-bo-arrow"></span>
                </Link>
              </div>
            </div>
          </div>
        )}
      </div>
      {DataSuccessful && (
        <Fragment>
          <SearchGridClient
            methodname="getclients"
            placeholdervalue="Search Client"
            headersdata={[
              "LOGO",
              "CLIENT NAME",
              //"ADDRESS",
              "REG. DATE",
              //"CONTACT PERSON",
              "STATUS",
              "TOTAL BALANCE",
              // "CURRENCY",
              // "PROVIDER",
              // "VIEW",
            ]}
            apiurl={API_URL}
            screenname="clients"
            createclientpermission={haspermissions.CreateClient}
          />
        </Fragment>
      )}

    
      {haspermissions.CreateClient && (
        <AddSuperClient
          show={modelconfirmsuperclient}
          onHide={() => setModelconfirmsuperclient(false)}
          onAddClient={() => {
            setModelconfirmsuperclient(false);
            setModalsuccessfullShow(true);
          }}
        />
      )}
        
        {haspermissions.CreateClient && (
        <AddClient
          show={confirmmodalShow}
          onHide={() => setCnfrmModalShow(false)}
          onAddClient={() => {
            setCnfrmModalShow(false);
            setModalsuccessfullShow(true);
          }}
        />
      )}


      <TransferClient
        show={modalShowTransferClient}
        onHide={() => setModalShowTransferClient(false)}
      />
      <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Client Added Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                goToClients();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default Clients;
