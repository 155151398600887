import React from "react";

const SuperAdminHelp = () => {
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      This is help page for super admin
    </div>
  );
};

export default SuperAdminHelp;
