import { useState, Fragment, useEffect } from "react";
import DonutChart from "../../helpers/DonutChart";
import chartsImg from "../../assets/images/chart.svg";
// import viewAllImg from "../../assets/images/icons/arrow-right.svg";
// import rcvngImg from "../../assets/images/icons/receiving.svg";
// import wdrwImg from "../../assets/images/icons/withdraw.svg";
// import trnsfrImg from "../../assets/images/icons/transfer.svg";
// import stackedCrdsImg from "../../assets/images/stacked-cards.png";
import BoLoader from "../../helpers/BoLoader";
import cardHolderService from "../../services/cardholder.service";
import SearchGrid from "../../helpers/SearchGrid";
import { Env } from "../../enviroment/environment";
import CardDetails from "./CardDetails";
import useWindowDimensions from "../../helpers/WindowWidth";
const API_URL = Env.CardsApiUrl;

const CardHolderTransactions = () => {
  const [transContent, settransContent] = useState();
  const { height, width } = useWindowDimensions();
  const [boloader, setBoLoader] = useState(false);
  const [transDataSuccessful, settransDataSuccessful] = useState(false);
  useEffect(() => {
    setBoLoader(true);
    settransDataSuccessful(true);
    cardHolderService.gettransactiondata().then(
      (response) => {
        setBoLoader(false);
        settransContent(response.data.payload);
        settransDataSuccessful(true);
      },
      (error) => {
        setBoLoader(false);
      }
    );
  }, []);
  return (
    <Fragment>
      <div className="col-xl-6 col-lg-8 col-md-12 bo-main-content">
        <BoLoader loading={boloader} />
        <section>
          <div>
            <form onSubmit={(e) => e.preventDefault}>
              <div className="row align-items-center row-head-clients">
                <div className="col-xl-9 col-sm-9 col-12">
                  <h3>Overall Transactions</h3>
                </div>
                <div className="col-xl-3 col-sm-3 col-12 input-hide">
                  <form>
                    <div className="form-group mb-0">
                      <div className="form-controls">
                        <input
                          className="form-control right-icon"
                          placeholder="Last Week"
                        />
                        <span className="icon-calender _ico icon-right"></span>
                      </div>
                      <span className="help-block d-none"></span>
                    </div>
                  </form>
                </div>
                <div className="col-12">
                  <div className="col-stats col-stats-chart mb-40 mt-20">
                    {transDataSuccessful && (
                      <div className="row align-items-center">
                        <div className="col-md-4 col-12">
                          <div className="chart-wrap">
                            {transContent ? (
                              <DonutChart
                                chartdata={{ ...transContent }}
                                colorscheme={["#89455E", "#E0DCDC"]}
                                className="donut-chart"
                              />
                            ) : (
                              <img src={chartsImg} alt="chartsImg"></img>
                            )}
                          </div>
                        </div>
                        <div className="col-md-8 col-12">
                          <div className="chart-legends chart-legends-transaction">
                            <div className="row">
                              <div className="col-md-4 col-6 mb-20">
                                <p>Grocery</p>
                                <h5>
                                  <span className="bg-russian"></span>$75,000
                                </h5>
                              </div>
                              <div className="col-md-4 col-6 mb-20">
                                <p>ATM Withdrawal</p>
                                <h5>
                                  <span className="bg-pink"></span>$30,000
                                </h5>
                              </div>
                              <div className="col-md-4 col-6">
                                <p>Bank Transfer</p>
                                <h5>
                                  <span className="bg-dustpink"></span>$30,000
                                </h5>
                              </div>
                              <div className="col-md-4 col-6 mb-20">
                                <p>Travel</p>
                                <h5>
                                  <span className="bg-prim"></span>$25,000
                                </h5>
                              </div>
                              <div className="col-md-4 col-6 mb-20">
                                <p>Bills and Utilities</p>
                                <h5>
                                  <span className="bg-amour"></span>$22,000
                                </h5>
                              </div>
                              <div className="col-md-4 col-6">
                                <p>Others</p>
                                <h5>
                                  <span className="bg-submarine"></span>$60,000
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </section>

        <div className="row">
          {width <= 991 && <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar" >
            <div className="bo-right-sidebar-wrap">
              <div className="mb-20">
                <h3>
                  Your Cards<span className="bo-badge bo-badge-large">3</span>
                </h3>
              </div>
              <CardDetails cht="Y" />
            </div>
          </div>}
        </div>
        <SearchGrid
          methodname="getCardHolderTransaction"
          placeholdervalue="Search Transaction"
          headersdata={[
            "TRANSACTION ID",
            "DATE",
            "TIME",
            "CATEGORY",
            "AMOUNT",
            "VIEW",
          ]}
          apiurl={API_URL}
          screenname="cardholdertransactions"
        />
      </div>
      {width > 991 && <div className="col-xl-3 col-lg-4 col-lg-0 bo-right-sidebar" >
        <div className="bo-right-sidebar-wrap">
          <div className="mb-20">
            <h3>
              Your Cards<span className="bo-badge bo-badge-large">3</span>
            </h3>
          </div>
          <CardDetails cht="Y" />
        </div>
      </div>}

    </Fragment>
  );
};

export default CardHolderTransactions;
