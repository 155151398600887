import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";
const API_URL = Env.AutoFeeApiUrl;


const getFeeTypes = () => {
    return axios
        .post(API_URL + "getfeetypes", {}, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

const addFeesAmount = (Id,ClientId,FeeTypeId,FeeCost,FeePercentage,IsDeleted) => {
    return axios
        .post(API_URL + "setfeerule", {Id,ClientId,FeeTypeId,FeeCost,FeePercentage,IsDeleted}, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

const updateFeesAmount = (Id,ClientId,FeeTypeId,FeeCost,FeePercentage,IsDeleted) => {
    return axios
        .post(API_URL + "setfeerule", {Id,ClientId,FeeTypeId,FeeCost,FeePercentage,IsDeleted}, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

const deleteFeesAmount = (Id,ClientId,FeeTypeId,FeeCost,FeePercentage,IsDeleted) => {
    return axios
        .post(API_URL + "setfeerule", {Id,ClientId,FeeTypeId,FeeCost,FeePercentage,IsDeleted}, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};
const getFeeRules = (FeeTypeId,ClientId,PageSize,StartIndex,PageNo,FetchAll) => {
    return axios
        .post(API_URL + "getfeerules", {FeeTypeId,ClientId,PageSize,StartIndex,PageNo,FetchAll}, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};
const getFailedFeeRules = (FeeTypeId,ClientId,PageSize,StartIndex,PageNo,FetchAll,TokenId) => {
    return axios
        .post(API_URL + "getfailedfee", {FeeTypeId,ClientId,PageSize,StartIndex,PageNo,FetchAll,TokenId}, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};

const clientFeeServiceObj = { getFeeTypes,addFeesAmount,updateFeesAmount,deleteFeesAmount,getFeeRules,getFailedFeeRules };
export default clientFeeServiceObj