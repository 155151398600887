
let LedgerTransfer=false;
let DepositTab=false;
let IssueCard=false;
let LoadCard=false;
let CreateClient=false;
let AdminRoleManagement=false;
let DebitCard=false;
let ActivatePhysicalCard=false;
let ViewLedgerHistory=false;
let ViewLedgerBalance=false;
let UpdateCardholderInfo=false;
let SuspendCard=false;
let BulkIssueCard = false;
let InternalAccounts=false;
let BulkLoadCard = false;
let ActivateBulkCard=false;
let FeeManagement=false;


export const haspermissions={
    LedgerTransfer,
    DepositTab,
    IssueCard,
    LoadCard,
    CreateClient,
    AdminRoleManagement,
    DebitCard,
    ActivatePhysicalCard,
    ViewLedgerHistory,
    ViewLedgerBalance,
    UpdateCardholderInfo,
    SuspendCard, 
    BulkIssueCard,
    InternalAccounts,
    BulkLoadCard,
    ActivateBulkCard,
    FeeManagement
}




