import stackedcards from "../../assets/images/stacked-cards.png";
import React, { Fragment } from "react";
const CardDetails = (props) => {
  return (
    <Fragment>
      {props.cht === "Y" && (
        <div className="text-center">
          <img className="img-fluid" src={stackedcards} alt="..." />
        </div>
      )}
      {props.cht === undefined && (
        <div className="col-xl-6 col-md-6 col-sm-6">
          <div
            id="myCards"
            className="carousel slide mb-32 text-center"
            data-bs-ride="carousel"
          >
            <img className="img-fluid" src={stackedcards} alt="..." />
          </div>
        </div>
      )}
    </Fragment>
  );
};
export default CardDetails;
