import React, { useState, useRef, Fragment, useEffect } from "react";
import { history } from "../../helpers/history";
import validator from "validator";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import emptyclient from "../../assets/images/icons/clients-empty.svg";
import transactionService from "../../services/transaction.service";
import { useSelector } from "react-redux";
import moment from "moment";
import ModelPopup from "../../helpers/ModelPopup";
import CurrencyFormat from 'react-currency-format';

const SearchGridInputData = [
  {
    Name: "",
    UserId: "",
    StartDate: "",
    EndDate: "",
    PageNumber: 0,
    // Size:10,
    scrolled: false,
    isFirstTime: true,
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
var centreAlign = {
  textAlign: "center"
}

const SearchGridCardTransactions = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [StartDate, setDateFrom] = useState("");
  const [EndDate, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);
  const [UserId, setUserId] = useState(props.UserId);
  const [totalCount, setTotalCount] = useState(0);
  //Transaction Models Showing Values
  const [TransactionsType, setTransactionsType] = useState("");
  const [Status, setStatus] = useState("");
  const [CurrencyCode, setCurrencyCode] = useState("");
  const [Amount, setAmount] = useState("");
  const [date, setDate] = useState("");
  const [ReferenceNumber, setReferenceNumber] = useState("");
  const [MerchantName, setMerchantName] = useState("");
  const [DescriptionGlobal, setDescriptionGlobal] = useState("");
  const [DescriptionGlobalHeaders, setDescriptionGlobalHeaders] = useState("");
  //Issuer B4B Feeses
  const [FxFee, setFxFee] = useState("");
  const [FxPadding, setFxPadding] = useState("");
  const [AuthAmount, setAuthAmount] = useState("");
  const [BoFixedFee, setBoFixedFee] = useState("");
  const [BoFxFee, setBoFxFee] = useState("");
  //Model popup Transactions
  const [modalShowTransactions, setModalShowTransactions] = useState(false);
  const [Month, setMonth] = useState(0);
  const [Year, setYear] = useState(0);

  const styleObj = {
    fontSize: 10,
  }
  useEffect(() => {
    SearchClickHandler(null, true);
  }, []);
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].StartDate = e;
    if (e) {
      let date = SearchGridInputData[0].StartDate;
      if (SearchGridInputData[0].StartDate !== "") {
        SearchGridInputData[0].StartDate = moment(SearchGridInputData[0].StartDate).format("YYYY-MM-DD");
        setDateFrom(date);
      } else {
        setDateFrom(date);
      }
    }
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].EndDate = e;
    if (e) {
      let date = SearchGridInputData[0].EndDate;
      if (SearchGridInputData[0].EndDate !== "") {
        SearchGridInputData[0].EndDate = moment(SearchGridInputData[0].EndDate).format("YYYY-MM-DD");
        setDateTo(date);
      } else {
        setDateTo(date);
      }
    }
  };
  const onChangeMonth = (e) => {
    const name = e.target.selectedOptions[0].value;
    setMonth(parseInt(name));
  }

  const onChangeYear = (e) => {
    const name = e.target.selectedOptions[0].value;
    setYear(parseInt(name));
  }

  const filterRecordsGlobal = (obj) => {
    var array = obj;
    let reArr = [];
    let amountPos = 0;
    let amountFx = 0;
    let amountAtm = 0;
    let descriptionPos = "";
    let descriptionFx = "";
    let descriptionAtm = "";
    let index = array.findIndex(x => (x.merchantName === "POS Fee" || x.merchantName === "LOCAL TAX"));
    let index1 = array.findIndex(x => x.merchantName === "FX Fee");
    let index2 = array.findIndex(x => x.merchantName === "ATM Fee");
    let previousdatePos = moment(array[index]?.dateOfTransaction).format("MMMM D, YYYY");
    let previousdateFx = moment(array[index1]?.dateOfTransaction).format("MMMM D, YYYY");
    let previousdateAtm = moment(array[index2]?.dateOfTransaction).format("MMMM D, YYYY");
    array.forEach((data, index) => {

      if (data?.merchantName == "POS Fee" || data?.merchantName == "LOCAL TAX") {
        let date = moment(data?.dateOfTransaction).format("MMMM D, YYYY")
        if (date == previousdatePos) {
          amountPos = amountPos + data?.authAmount;
          let obj = " " + descriptionPos + data?.merchantName + " " + " : " + data?.authAmount;
          descriptionPos = obj;

        }
        else {
          let obj = {
            transactionType: null,
            status: data?.status,
            authCurrencyCode: data?.authCurrencyCode,
            authAmount: amountPos?.toFixed(2),
            dateOfTransaction: previousdatePos,
            retrievalReferenceNumber: "NA",
            merchantName: "TXN FEE",
            mcc: "",
            transactionUuid: null,
            transactionCountry: data?.transactionCountry,
            transactionRespons: "",
            transactionMode: null,
            description: descriptionPos
          }
          amountPos = 0;
          previousdatePos = date;
          reArr.push(obj);
        }
      }
      else if (data?.merchantName == "FX Fee") {
        let date = moment(data?.dateOfTransaction).format("MMMM D, YYYY")
        if (date == previousdateFx) {
          amountFx = amountFx + data?.authAmount;
          let obj = " " + descriptionFx + data?.merchantName + " " + " : " + data?.authAmount;
          descriptionFx = obj;

        }
        else {
          let obj = {
            transactionType: null,
            status: data?.status,
            authCurrencyCode: data?.authCurrencyCode,
            authAmount: amountFx?.toFixed(2),
            dateOfTransaction: previousdateFx,
            retrievalReferenceNumber: "NA",
            merchantName: "FX FEE",
            mcc: "",
            transactionUuid: null,
            transactionCountry: data?.transactionCountry,
            transactionRespons: "",
            transactionMode: null,
            description: descriptionFx
          }
          previousdateFx = date;
          amountFx = 0;
          reArr.push(obj);
        }

      }
      else if (data?.merchantName == "ATM Fee") {
        let date = moment(data?.dateOfTransaction).format("MMMM D, YYYY")
        if (date == previousdateAtm) {
          amountAtm = amountAtm + data?.authAmount;
          let obj = " " + descriptionAtm + data?.merchantName + " " + " : " + data?.authAmount;
          descriptionAtm = obj;

        }
        else {
          let obj = {
            transactionType: null,
            status: data?.status,
            authCurrencyCode: data?.authCurrencyCode,
            authAmount: amountAtm?.toFixed(2),
            dateOfTransaction: previousdateAtm,
            retrievalReferenceNumber: "NA",
            merchantName: "ATM FEE",
            mcc: "",
            transactionUuid: null,
            transactionCountry: data?.transactionCountry,
            transactionRespons: "",
            transactionMode: null,
            description: descriptionAtm
          }
          amountAtm = 0;
          previousdateAtm = date;
          reArr.push(obj);
        }

      }
      else {
        reArr.push(data);
      }

    });
    reArr.sort((a, b) => {
      const dateA = new Date(a.dateOfTransaction);
      const dateB = new Date(b.dateOfTransaction);

      if (dateA < dateB) {
        return 1;
      } else if (dateA > dateB) {
        return -1;
      } else {
        return 0;
      }
    })
    setSearchData(reArr);
  }
  const SearchClickHandler = (e, isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (isFromUseEffect) {
      SearchGridInputData[0].PageNumber = 0;
      setSearchData("");
    }
    setModalShow(false);
    setSearchDisabled(false);
    transactionService
      .searhGridTransactionsCards(
        UserId,
        SearchGridInputData[0].StartDate,
        SearchGridInputData[0].EndDate,
        SearchGridInputData[0].PageNumber
        //SearchGridInputData[0].Size,
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
            setTotalCount(resp.data.payload.result.item1.totalElements);
          }
          if (
            resp.data.payload.result.item1.content.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.item1.totalElements);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.item1.content
              );
              setSearchData(appendedData);
            } else {

              let reversearray = resp.data.payload.result.item1.content;
              let filtereddata = reversearray.reverse()
              if (props?.issuerCategory === "IssuerG")
                sortDateTransaction(filtereddata);
              else
                setSearchData(filtereddata);
              SearchGridInputData[0].PageNumber = 0;
              props?.setDataset(reversearray);
            }
            setSearchGridSuccess(true);
            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };

  const GetCardTransactionsspecificmonth = (e, isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (isFromUseEffect) {
      SearchGridInputData[0].PageNumber = 0;
      setSearchData("");
    }
    setModalShow(false);
    setSearchDisabled(false);
    transactionService
      .GetCardTransactionsspecificmonth(
        UserId,
        Month,
        Year
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
            setTotalCount(resp.data.payload.result.item1.totalElements);
          }
          if (
            resp.data.payload.result.item1.content.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.result.item1.totalElements);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.item1.content
              );
              setSearchData(appendedData);
            } else {
              let reversearray = resp.data.payload.result.item1.content;
              let filtereddata = reversearray.reverse()
              if (props?.issuerCategory === "IssuerG")
                sortDateTransaction(filtereddata);
              else
                setSearchData(filtereddata);
              SearchGridInputData[0].PageNumber = 0;
              props?.setDataset(reversearray);
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            // if (SearchGridInputData[0].isFirstTime) {
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
            // }
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        setTotalCount(0);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            GetCardTransactionsspecificmonth();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].PageNumber + 1 <= totalCount - 1
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].PageNumber =
        SearchGridInputData[0].PageNumber + 1;
      SearchClickHandler();
    }
  };
  
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      if (props?.issuerCategory === "IssuerG"&& key=="STATUS")
            return <th key={index}>{"DESCRIPTION"}</th>;
        // if(key==="DATE")
        // return <th style={centreAlign} key={index} >{ key}</th>;
        // else
        else
        return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const showTransactionsPopup = (
    transactionType,
    merchantName,
    status,
    cardTransactionAmount,
    transactionCurrencyCode,
    dateOfTransaction,
    retrievalReferenceNumber,
    transactionResponse,
    description
  ) => {
    setModalShowTransactions(true);
    setTransactionsType(transactionType);
    setStatus(status);
    setCurrencyCode(transactionCurrencyCode);
    setAmount(cardTransactionAmount);
    setDate(dateOfTransaction);
    setReferenceNumber(retrievalReferenceNumber);
    setMerchantName(merchantName);
    let fee = transactionResponse?.split(",")
    if (props?.issuerCategory === "IssuerB4B") {
      if (status === "Pending") {
        setAuthAmount(fee[0]);
        setFxPadding(fee[1]);
      }
      else {
        setAuthAmount(fee[0]);
        setFxPadding(fee[1]);
        setFxFee(fee[2]);
        if(fee?.length>3)setBoFixedFee(fee[3]);
        if(fee?.length>4) setBoFxFee(fee[4]);
      }
    }
    if (props?.issuerCategory === "IssuerG")
      setGlobalTranFees(description, merchantName);
    else
      setDescriptionGlobal(description);

  };
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        transactionType,
        merchantName,
        status,
        authCurrencyCode,
        authAmount,
        dateOfTransaction,
        retrievalReferenceNumber,
        transactionCountry,
        transactionResponse, description, localDescription
      } = data; //destructuring
      return (
        <tr key={retrievalReferenceNumber}>
          <td style={fontStyle} title={transactionType} >{transactionType?.substr(0, 20)}{transactionType?.length > 20 && ("...")}</td>
          <td style={fontStyle} title={merchantName} >{merchantName?.substr(0, 10) ?? '-'}{merchantName?.length > 10 && ("...")}</td>
         
          {props?.issuerCategory === "IssuerG" ? (<td style={fontStyle}>{localDescription}</td>) : (<td style={fontStyle}>{status}</td>)}
          {props?.issuerCategory === "IssuerB4B" ? (<>
            {
            authAmount < 0 ? (<td><CurrencyFormat value={authAmount} displayType={'text'} thousandSeparator={true} allowNegative={false} prefix="(" suffix=")" /></td>) : (
              <td style={fontStyle}><CurrencyFormat value={calculateB4BFee(transactionResponse,authAmount)} displayType={'text'} thousandSeparator={true} /> {authCurrencyCode}</td>
            )
          }
          </>):(<>
          
            {
            authAmount < 0 ? (<td><CurrencyFormat value={authAmount} displayType={'text'} thousandSeparator={true} allowNegative={false} prefix="(" suffix=")" /></td>) : (
              <td style={fontStyle}><CurrencyFormat value={authAmount} displayType={'text'} thousandSeparator={true} /> {authCurrencyCode}</td>
            )
          }
          </>)}
         
         


          <td style={fontStyle} >
            <DateFormat date={dateOfTransaction} />
          </td>
         
          <td style={fontStyle} title={transactionCountry}>{transactionCountry?.substr(0, 10)}...</td>

          <td>
            <span
              className="icon-show"
              onClick={() =>
                showTransactionsPopup(
                  transactionType,
                  merchantName,
                  status,
                  authAmount,
                  authCurrencyCode,
                  dateOfTransaction,
                  retrievalReferenceNumber,
                  transactionResponse,
                  description
                )
              }
            ></span>
          </td>
        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setCompanyName("");
  };
  const ClearSearchData = () => {
    if (props?.issuerCategory === "IssuerG") {
      setCompanyName("");
      dateToHandler("");
      dateFromHandler("");
      setDateFrom("");
      setDateTo("");
      if (document.getElementById("MonthIssuerG") !== null)
        document.getElementById("MonthIssuerG").value = 0;
      if (document.getElementById("YearIssuerG") !== null)
        document.getElementById("YearIssuerG").value = 0;
      setMonth(0);
      setYear(0);
      SearchClickHandler();
    }
    else {
      setCompanyName("");
      dateToHandler("");
      dateFromHandler("");
      setDateFrom("");
      setDateTo("");
      setMonth(0);
      setYear(0);
      ClearInputData();
    }



    SearchGridInputData[0].EndDate = "";
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
 

  const renderTransactionDetails = () => {
    return (

      <div class="row">
        <div class="col-md-12">
          <h3>Transaction Details</h3>
          <div class="mt-16">
            <form onSubmit={(e) => e.preventDefault()}>
              <ul class="ul-card-details-popup">
                <li class="d-flex justify-content-between mb-3">
                  <label>Transaction Type</label>
                  <span>{TransactionsType}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Status</label>
                  <span>{Status}</span>
                </li>
                <li class="d-flex justify-content-between mb-3">
                  <label>Merchant Name</label>
                  <span>{MerchantName}</span>
                </li>
                {
                  props?.issuerCategory === "IssuerG" ?

                    (<Fragment>
                      {
                        DescriptionGlobalHeaders?.length > 4 ? (<Fragment>
                          <li class="d-flex justify-content-between mb-3">
                            <label>{DescriptionGlobalHeaders?.actual}</label>
                            <span>{DescriptionGlobal?.actual}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>{DescriptionGlobalHeaders?.atmfee}</label>
                            <span>{DescriptionGlobal?.atmfee}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>{DescriptionGlobalHeaders?.atmfixedfee}</label>
                            <span>{DescriptionGlobal?.atmfixedfee}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>{DescriptionGlobalHeaders?.atmfixedfeelocaltax}</label>
                            <span>{DescriptionGlobal?.atmfixedfeelocaltax}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>{DescriptionGlobalHeaders?.atmfeelocaltax}</label>
                            <span>{DescriptionGlobal?.atmfeelocaltax}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>{DescriptionGlobalHeaders?.internationalatmfee}</label>
                            <span>{DescriptionGlobal?.internationalatmfee}</span>
                          </li>
                        </Fragment>) : (<Fragment>
                          <li class="d-flex justify-content-between mb-3">
                            <label>Actual Transaction</label>
                            <span>{DescriptionGlobal?.actual}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>POS Fee</label>
                            <span>{DescriptionGlobal?.pos}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>FX Fee</label>
                            <span>{DescriptionGlobal?.fx}</span>
                          </li>
                          <li class="d-flex justify-content-between mb-3">
                            <label>Local Tax</label>
                            <span>{DescriptionGlobal?.localtax}</span>
                          </li>
                        </Fragment>)
                      }

                      <li class="d-flex justify-content-between mb-3">

                        {
                          Status === "COMPLETION" ? (<label> Settlement Amount</label>) : (<label>Total Amount</label>)
                        }
                        <span><CurrencyFormat value={Amount} displayType={'text'} thousandSeparator={true} /> &nbsp; {CurrencyCode} </span>
                      </li>
                    </Fragment>) :


                    (
                      <Fragment>
                        <li class="d-flex justify-content-between mb-3">
                          <label>Description</label>
                          <span>{DescriptionGlobal}</span>
                        </li>
                        <li class="d-flex justify-content-between mb-3">

                          {
                            Status === "COMPLETION" ? (<label> Settlement Amount</label>) : (<label>Amount</label>)
                          }
                          <span><CurrencyFormat value={Amount} displayType={'text'} thousandSeparator={true} /> &nbsp; {CurrencyCode} </span>
                        </li>
                      </Fragment>
                    )
                }


                {
                  props?.issuerCategory === "IssuerB4B" && (
                    <Fragment>
                      <li class="d-flex justify-content-between mb-3">
                        <label>Auth Amount</label>
                        <span><CurrencyFormat value={AuthAmount} displayType={'text'} thousandSeparator={true} />  </span>
                      </li>
                      {
                        Status === "PENDING" ? (
                          <li class="d-flex justify-content-between mb-3">
                            <label>FX Padding</label>
                            <span><CurrencyFormat value={FxPadding} displayType={'text'} thousandSeparator={true} /></span>
                          </li>
                        ) : (
                          <>
                            <li class="d-flex justify-content-between mb-3">
                              <label>Fixed Fee</label>
                              <span><CurrencyFormat value={FxPadding} displayType={'text'} thousandSeparator={true} /></span>
                            </li>
                            <li class="d-flex justify-content-between mb-3">
                              <label>FX Fee</label>
                              <span><CurrencyFormat value={FxFee} displayType={'text'} thousandSeparator={true} /></span>
                            </li>
                            <li class="d-flex justify-content-between mb-3">
                              <label>Bo Fixed Fee</label>
                              <span><CurrencyFormat value={BoFixedFee} displayType={'text'} thousandSeparator={true} /></span>
                            </li>
                            <li class="d-flex justify-content-between mb-3">
                              <label>Bo FX Fee</label>
                              <span><CurrencyFormat value={BoFxFee} displayType={'text'} thousandSeparator={true} /></span>
                            </li>
                          </>
                        )
                      }
                    </Fragment>
                  )
                }
                <li class="d-flex justify-content-between mb-3">
                  <label>Date</label>
                  <span><DateFormat date={date} /></span>
                </li>
                <li class="d-flex justify-content-between">
                  <label>Ref #</label>
                  <span>{ReferenceNumber}</span>
                </li>

              </ul>

            </form>
          </div>
        </div>
      </div>

    );
  };

  const sortDateTransaction = (obj) => {
    let reArr = obj;
    reArr.sort((a, b) => {
      const dateA = new Date(a.dateOfTransaction);
      const dateB = new Date(b.dateOfTransaction);

      if (dateA < dateB) {
        return 1;
      } else if (dateA > dateB) {
        return -1;
      } else {
        return 0;
      }
    })
    setSearchData(reArr);
  }
  const setGlobalTranFees = (values, type) => {
    let finalVal = values?.split(",")
    let val = finalVal?.length;
    let posexsist = finalVal?.includes("-0.2");
    let posval = "";
    if (type !== "ATM") {
      setDescriptionGlobalHeaders
        ({
          actual: "Actual", atmfee: "ATM FEE",
          atmfixedfee: "ATM Fixed FEE",
          atmfixedfeelocaltax: "ATM FIXED FEE LOCAL TAX",
          atmfeelocaltax: "ATM FEE LOCAL TAX", internationalatmfee: "FX Fee", length: 4
        })
      if (val == 1) {
        let shownvalue = { actual: finalVal[0], pos: 0, fx: 0, localtax: 0 }
        setDescriptionGlobal(shownvalue);
      }
      else if (val == 2) {
        if (posexsist)
          posval = "-0.2";
        else
          posval = finalVal[1]
        let shownvalue = { actual: finalVal[0], pos: posval, fx: 0, localtax: 0 }
        setDescriptionGlobal(shownvalue);

      }
      else if (val == 3) {
        if (posexsist)
          posval = "-0.2";
        else
          posval = 0
        let shownvalue = { actual: finalVal[0], pos: posval, fx: 0, localtax: finalVal[2] }
        setDescriptionGlobal(shownvalue);
      }
      else if (val == 4) {
        // if (posexsist)
        //   posval = "-0.2";
        // else
          posval = finalVal[2];
        let shownvalue = { actual: finalVal[0], pos: posval, fx: finalVal[1], localtax: finalVal[3] }
        setDescriptionGlobal(shownvalue);
      }
    }
    else if (type == "ATM") {
      setDescriptionGlobalHeaders({
        actual: "Actual", atmfee: "ATM FEE", atmfixedfee: "ATM Fixed FEE", atmfixedfeelocaltax: "ATM FIXED FEE LOCAL TAX",
        atmfeelocaltax: "ATM FEE LOCAL TAX", internationalatmfee: "FX Fee", length: 6
      })
      if (val == 1) {
        let shownvalue = { actual: finalVal[0], atmfee: 0, atmfixedfee: 0, atmfixedfeelocaltax: 0, atmfeelocaltax: 0 }
        setDescriptionGlobal(shownvalue);
      }
      else if (val == 2) {
        let shownvalue = { actual: finalVal[0], atmfee: finalVal[1], atmfixedfee: 0, atmfixedfeelocaltax: 0, atmfeelocaltax: 0 }
        setDescriptionGlobal(shownvalue);
      }
      else if (val == 3) {
        let shownvalue = { actual: finalVal[0], atmfee: finalVal[1], atmfixedfee: finalVal[2], atmfixedfeelocaltax: 0, atmfeelocaltax: 0 }
        setDescriptionGlobal(shownvalue);
      }
      else if (val == 4) {
        let shownvalue = { actual: finalVal[0], atmfee: finalVal[1], atmfixedfee: finalVal[2], atmfixedfeelocaltax: finalVal[3], atmfeelocaltax: 0 }
        setDescriptionGlobal(shownvalue);
      }
      else if (val == 5) {
        let shownvalue = { actual: finalVal[0], atmfee: finalVal[1], atmfixedfee: finalVal[2], atmfixedfeelocaltax: finalVal[3], atmfeelocaltax: finalVal[4] }
        setDescriptionGlobal(shownvalue);
      }
      else if (val == 6) {
        let shownvalue = { actual: finalVal[0], atmfee: finalVal[2], atmfixedfee: finalVal[3], internationalatmfee: finalVal[1], atmfixedfeelocaltax: finalVal[4], atmfeelocaltax: finalVal[5] }
        setDescriptionGlobal(shownvalue);
      }
    }

  }
  const calculateB4BFee=(transactionResponse,authAmount)=>{
    let fee = transactionResponse?.split(",")
    if (props?.issuerCategory === "IssuerB4B") {
      if(fee?.length>3){
        let len=fee?.length;
        let val;
        if(len ===3)
         val=(Number(fee[0])+Number(fee[1])+Number(fee[2]));
         if(len ===4)
         val=(Number(fee[0])+Number(fee[1])+Number(fee[2])+Number(fee[3]));
         if(len ===5)
         val=(Number(fee[0])+Number(fee[1])+Number(fee[2])+Number(fee[3])+Number(fee[4]));
        return val.toFixed(2);
      }
      else return authAmount 
      }
  }
  const calculateB4BFeeNegative=(transactionResponse,authAmount)=>{
    let fee = transactionResponse?.split(",")
    if (props?.issuerCategory === "IssuerB4B") {
      if(fee?.length>1){
        let val=(Number(Math.abs(fee[0]))+Number(Math.abs(fee[1]))+Number(Math.abs(fee[2])))
        
        return val.toFixed(2);
      }
      else return authAmount 
      }
  }
  const refereshTransac=()=>{

    setBoFixedFee("");
    setBoFxFee("");
    setFxFee("");
  }
  return (
    <Fragment>
      <div className="row align-items-center mt-14">
        {/* <div className="col-6">
          <h3> Overall Transactions</h3>
        </div>
        */}



      </div>
      <div className="mt-0 mt-sm-4">
        <div className="tab-content" id="myTabContent2">
          <div
            className="tab-pane fade show active"
            id="allClients"
            role="tabpanel"
            aria-labelledby="allClients-tab"
          >
            {/* no client found  */}
            <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
              <div className="text-center">
                <img src="../../assets/images/icons/clients-empty.svg" />
                <h3>Empty Clients Listing</h3>
                <h6>There is no Client(s) available for now.</h6>
              </div>
            </div>
            <Fragment>
              <form onSubmit={(e) => e.preventDefault()}>
                <div className="row row-search">
                  <div className="col-xl-12 col-12">
                    <div className="form-group">
                      <div className="form-controls">
                        <input
                          type="text"
                          className="form-control"
                          placeholder={props.placeholdervalue}
                          onClick={handleInputSearchClick}
                          disabled={SearchInputDisabled}
                        />
                        <span className="icon-search _ico"></span>
                        {/* search box  */}
                        <div
                          className="bo-cardsearch"
                          style={{ display: modalShow ? "" : "none" }}
                        >
                          <div className="row">
                            <div className="col-md-12">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                            </div>
                            {props?.issuerCategory === "IssuerG" ? (<div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Month</label>
                                <div className="form-controls">
                                  <select
                                    onChange={onChangeMonth}
                                    className="form-control no-icon"
                                    id="MonthIssuerG"
                                  >
                                    <option select="selected" value={Month}>
                                      Select
                                    </option>
                                    <option value={1}>
                                      January
                                    </option>
                                    <option value={2}>
                                      February
                                    </option>
                                    <option value={3}>
                                      March
                                    </option>
                                    <option value={4}>
                                      April
                                    </option>
                                    <option value={5}>
                                      May
                                    </option>
                                    <option value={6}>
                                      June
                                    </option>
                                    <option value={7}>
                                      July
                                    </option>

                                    <option value={8}>
                                      August
                                    </option>
                                    <option value={9}>
                                      September
                                    </option>
                                    <option value={10}>
                                      October
                                    </option>
                                    <option value={11}>
                                      November
                                    </option>
                                    <option value={12}>
                                      December
                                    </option>

                                  </select>
                                </div>
                              </div>
                            </div>) : (<div className="col-md-6">
                              <div className="form-group form-search mr-20">
                                <label>Date From</label>
                                <div className="form-controls">
                                  <ReactDatePickerHelper
                                    datevalue={StartDate}
                                    onchangedate={dateFromHandler}
                                  />
                                </div>
                              </div>
                            </div>)}
                            {
                              props?.issuerCategory === "IssuerG" ? (<div className="col-md-6">
                                <div className="form-group form-search mr-20">
                                  <label>Year</label>
                                  <div className="form-controls">
                                    <select
                                      onChange={onChangeYear}
                                      className="form-control no-icon"
                                      id="YearIssuerG"
                                    >
                                      <option select="selected" value={0}>
                                        Select
                                      </option>
                                      <option value={2022}>
                                        2022
                                      </option>
                                      <option select="selected" value={2023}>
                                        2023
                                      </option>
                                      <option select="selected" value={2024}>
                                        2024
                                      </option>
                                    </select>
                                  </div>
                                </div>
                              </div>) : (<div className="col-md-6">
                                <div className="form-group form-search mr-20">
                                  <label>Date To</label>
                                  <div className="form-controls">
                                    <ReactDatePickerHelper
                                      datevalue={EndDate}
                                      onchangedate={dateToHandler}
                                    />
                                  </div>
                                </div>
                              </div>)
                            }


                            <div className="col-md-12">
                              <div className="form-group submit-field form-search">
                                {
                                  props?.issuerCategory === "IssuerG" ? (
                                    <button
                                      id="searchBtnClients"
                                      className="btn btn-large mr-19"
                                      onClick={GetCardTransactionsspecificmonth}
                                    >
                                      Search
                                    </button>

                                  ) : (
                                    <button
                                      id="searchBtnClients"
                                      className="btn btn-large mr-19"
                                      onClick={SearchClickHandler}
                                    >
                                      Search
                                    </button>
                                  )
                                }

                                <button
                                  className="btn btn-outline"
                                  onClick={ClearSearchData}
                                >
                                  Clear Search
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <span className="help-block d-none"></span>
                    </div>
                  </div>
                </div>
              </form>
            </Fragment>
          </div>

          {searchGridSuccess && (
            <div className="table-wrap" ref={searchTbl}>
              <table
                className="table table-borderless bo-table table-cards"
              //onScroll={tblScrollHandler}
              //onWheel={tblScrollHandler}
              >
                <thead>
                  <tr>{renderTableHeader()}</tr>
                </thead>
                <tbody>{renderTableData()}</tbody>
              </table>
            </div>
          )}
          {loadingData ? (
            <div className="loading-more">
              <div className="loader">
                <div></div>
              </div>
              <p>loading data</p>
            </div>
          ) : (totalCount === 0) ? (
            <div className="d-flex align-content-center justify-content-center  ">
              <div className="text-center">
                <img src={emptyclient} />
                <h3>Empty Transactions Listing</h3>
                <h6>There are no Transactions available for now.</h6>
              </div>
            </div>
          ) : (
            ""
          )}
        </div>
      </div>
      <div>
        <ModelPopup
          key={1}
          show={modalShowTransactions}
          onHide={() => {setModalShowTransactions(false); refereshTransac();}}
          modelheading={""}
          modelbody={renderTransactionDetails()}
          showheader={"false"}
          dialogClassName="modal-dialog modal-dialog-centered mwidth-52"
          className="smallPopup issueCardSmall"
          backdrop="static"
          keyboard={false}
        />
      </div>
    </Fragment>
  );

};

export default SearchGridCardTransactions;
