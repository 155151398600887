import { useState, useEffect } from "react";
import SearchGrid from "../../helpers/SearchGrid";
import { Env } from "../../enviroment/environment";
import { useDispatch, useSelector } from "react-redux";

import ClientProfile from "./ClientProfile";
import RoleManagment from "../rolemanagment/RoleManagment"
import SearchGridDebitTransaction from "./SearchGridDebitTransaction";
import clientmanagmentObj from "../../services/client-managment.service";
import BoLoader from "../../helpers/BoLoader";
import { useLocation } from 'react-router-dom';
import { history } from "../../helpers/history";
import ExportToExcel from "./ExportToExcel";
import { haspermissions } from "../permissions/PermissionTypes";
import DonutChart from "../../helpers/DonutChart";
import SearchGridPendingCards from "../cardholders/SearchGridPendingCards";
import SearchGridSuspendedCard from "../cardholders/SearchGridSuspendedCard";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import commonService from "../../services/common.service";
import { Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
const API_URL = Env.CardsApiUrl;
const prop = history;
const SearchGridInputData = [
  {
    Name: "",
    TokenId: "",
    IssueDate: "",
    ExpiryDate: "",
    DateFrom: "",
    DateTo: "",
    CardNumber:"",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    SearchClearClicked: false,
    ClientId: "",
    IssuerType: "",
  },
];
const ClientDetails = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [content, setContent] = useState(<p>No Record Found</p>);
  const [AllClientClassName, setAllClientClassName] = useState(
    "nav-link active d-flex align-items-center"
  );
  const [NewClientClassName, setNewClientClassName] = useState("nav-link d-flex align-items-center");
  const [AllClientAreaSelected, setAllClientAreaSelected] = useState("false");
  const [NewClientsAreaSelected, setNewClientsAreaSelected] = useState("false");
  //Role Managment
  const [RoleClassName, setRoleClassName] = useState("nav-link d-flex align-items-center");
  const [RoleAreaSelected, setRoleAreaSelected] = useState("false");
  //Debit Card Grid Transaction
  const [DebitClassName, setDebitClassName] = useState("nav-link d-flex align-items-center");
  const [DebitAreaSelected, setDebitAreaSelected] = useState("false");
  const [balance, setBalance] = useState();
  const [cardlimts, setCardlimts] = useState();

  //Loader
  const [boloader, setBoLoader] = useState(false);
  //ExportToExcel
  const [dataset, setDataset] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [isExcelDownload, setiisExcelDownload] = useState(false);
  const [showExcelDownloadModal, setshowExcelDownloadModal] = useState(false);
  //Cards Section
  const [AllCardsTab, setAllCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [AllCardsTabAreaSelected, setAllCardsTabAreaSelected] =
    useState("false");
  const [NewCardsTab, setNewCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [NewCardsTabAreaSelected, setNewCardsTabAreaSelected] =
    useState("false");
    const [SuspendedCardTab, setSuspendedCardTab] = useState(
      "nav-link d-flex align-items-center"
    );
    const [SuspendedCardTabAreaSelected, setSuspendedCardTabAreaSelected] =
      useState("false");
      const [ClosedCardTab, setClosedCardTab] = useState(
        "nav-link d-flex align-items-center"
      );
      const [ClosedCardTabAreaSelected, setClosedCardTabAreaSelected] =
        useState("false");
        const [SuspendCardContent, setSuspendCardContent] = useState();
      //add client id as a backup change the whole configuration in the future.
      const [clientId, setClientId] = useState(prop?.location?.state?.id);
  if (currentUser) {
    haspermissions.LedgerTransfer = currentUser?.user?.Permissions?.includes("Ledger Transfer");
    haspermissions.DepositTab = currentUser?.user?.Permissions?.includes("Deposit Tab");
    haspermissions.IssueCard = currentUser?.user?.Permissions?.includes("Issue Card");
    haspermissions.LoadCard = currentUser?.user?.Permissions?.includes("Load Card");
    haspermissions.CreateClient = currentUser?.user?.Permissions?.includes("Create Client");
    haspermissions.AdminRoleManagement = currentUser?.user?.Permissions?.includes("Admin Role Management");
    haspermissions.ViewLedgerHistory = currentUser?.user?.Permissions?.includes("View ledger history");
    haspermissions.ViewLedgerBalance = currentUser?.user?.Permissions?.includes("View ledger balance");


  }

  const allClients = () => {
    setAllClientClassName("nav-link active d-flex align-items-center");
    setAllClientAreaSelected("true");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setRoleClassName("nav-link  d-flex align-items-center");
    setRoleAreaSelected("false");
    setDebitClassName("nav-link  d-flex align-items-center");
    setDebitAreaSelected("false");
    btnProfile();
  };
  const newClients = () => {
    setNewClientClassName("nav-link active d-flex align-items-center");
    setNewClientsAreaSelected("true");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setRoleClassName("nav-link  d-flex align-items-center");
    setRoleAreaSelected("false");
    setDebitClassName("nav-link  d-flex align-items-center");
    setDebitAreaSelected("false");
    btnCards();
  };
  const roleManagment = () => {
    setRoleClassName("nav-link active d-flex align-items-center");
    setRoleAreaSelected("true");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setDebitClassName("nav-link  d-flex align-items-center");
    setDebitAreaSelected("false");
    btnRoleManagment();
  };
  const btnCards = () => {
    setAllCardsTab("nav-link active d-flex align-items-center");
    setAllCardsTabAreaSelected("true");
    setNewCardsTab("nav-link d-flex align-items-center");
    setNewCardsTabAreaSelected("false");
    setSuspendedCardTab("nav-link d-flex align-items-center");
    setSuspendedCardTabAreaSelected("false");
    setClosedCardTab("nav-link  d-flex align-items-center");
    setClosedCardTabAreaSelected("false");
    setiisExcelDownload(false);
    setContent(
      <div>
        <SearchGrid
          methodname="getcards"
          placeholdervalue="Search Card"
          headersdata={[
            "FIRST NAME",
            "LAST NAME",
            "Token ID",
            "CARD NO",
            "CLIENT NAME",
            "EXPIRY DATE",
            "ISSUE DATE",
            "EMAIL",
            "VIEW",
          ]}
          apiurl={API_URL}
          screenname="cardholder"
          clientId={prop?.location.state?.id}
          setDataset={setDataset}
        />
      </div>
    );
  };
  useEffect(() => {
    //btnProfile();
    setBoLoader(true)
    clientmanagmentObj
      .getprofiledetails(prop?.location?.state?.id).then((response) => {
        let res = response.data.payload.result;
        setBalance(res?.balance);
        btnDebitCardGrid(res?.balance);
        setCardlimts(res);
        setBoLoader(false)
      }).catch(() => setBoLoader(false));

  }, []);


  const btnProfile = () => {
    setContent(<ClientProfile clientId={prop?.location?.state?.id ?? clientId} userId={prop?.location?.state?.userId}
      clientStatus={prop?.location?.state?.clientStatus} balance={setBalance}
    />);
  };
  const btnRoleManagment = () => {
    setContent(<RoleManagment calledfrom="client" clientId={prop?.location.state?.id ?? clientId} userId={prop?.location?.state?.userId}
      clientStatus={prop?.location?.state?.clientStatus}

    />);
  };

  const btnGridTransactions = () => {
    setRoleClassName("nav-link  d-flex align-items-center");
    setRoleAreaSelected("false");
    setAllClientClassName("nav-link d-flex align-items-center");
    setAllClientAreaSelected("false");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");
    setDebitClassName("nav-link active d-flex align-items-center");
    setDebitAreaSelected("true");

  }
  const btnDebitCardGrid = (res) => {
    if (haspermissions?.ViewLedgerHistory || haspermissions?.ViewLedgerBalance) {
      btnGridTransactions();
      setContent(
        <div>
          <SearchGridDebitTransaction
            placeholdervalue="Search"
            headersdata={[
              "DATE",
              "TRANSACTION",
              "TRANSACTION TYPE",
              "TOKEN ID",
              "AMOUNT",
              "FROM CLIENT",
              "TO CLIENT",
              "DESCRIPTION",


            ]}
            calledfrom="client" clientId={prop?.location?.state?.id ?? clientId} userId={prop?.location.state?.userId
            } balance={balance ? balance : res}
          /></div>);
    }
    else {
      newClients();
    }

  };
  //Cards in Client Section
  const btnAllCards = () => {
    setContent(
      <div>
      <h3></h3>
      <SearchGrid
        methodname="getcards"
        placeholdervalue="Search Card"
        headersdata={[
          "FIRST NAME",
          "LAST NAME",
          "Token ID",
          // "CARD NO",
          "CLIENT NAME",
          "EXPIRY DATE",
          "ISSUE DATE",
          "STATUS",
          "VIEW",
        ]}
        apiurl={API_URL}
        screenname="cardholder"
        clientId={prop?.location.state?.id}
        setDataset={setDataset}
      />
      </div>
    );
  };

  const btnPendingCards=()=>{
    setAllCardsTab("nav-link  d-flex align-items-center");
    setAllCardsTabAreaSelected("false");
    setNewCardsTab("nav-link active d-flex align-items-center");
    setNewCardsTabAreaSelected("true");
    setSuspendedCardTab("nav-link d-flex align-items-center");
    setSuspendedCardTabAreaSelected("false");
    setClosedCardTab("nav-link  d-flex align-items-center");
    setClosedCardTabAreaSelected("false");
    setiisExcelDownload(false);
    setContent(<section className="">
    <SearchGridPendingCards
     methodname="getcards"
      placeholdervalue="Search Pending Cards"
      headersdata={[
        "FIRST NAME",
        "LAST NAME",
        "Token ID",
        "CARD NO",
      "CLIENT NAME",
      "EXPIRY DATE",
      "ISSUE DATE",
      "EMAIL",
      "VIEW",
      ]}
      apiurl={API_URL}
      screenname="cardholder"
      clientId={String(prop?.location.state?.id)}
      setDataset={setDataset}
      activeCardCallback={()=>{btnCards();}}

    />
  </section>);

  }
  const btnSuspendedCards=(cardstatus)=>{
    if (cardstatus === "Closed") {
      setSuspendedCardTab("nav-link d-flex align-items-center");
      setSuspendedCardTabAreaSelected("false");
      setClosedCardTab("nav-link active d-flex align-items-center");
      setClosedCardTabAreaSelected("true");
      setAllCardsTab("nav-link  d-flex align-items-center");
      setAllCardsTabAreaSelected("false");
      setNewCardsTab("nav-link  d-flex align-items-center");
      setNewCardsTabAreaSelected("false");
      setiisExcelDownload(false);
      setSuspendCardContent(<section className="">
      <SearchGridSuspendedCard
       methodname="getsuspendcard"
        placeholdervalue="Search Closed Cards"
        headersdata={[
        "FIRST NAME",
        "LAST NAME",
        "Token ID",
        "CARD NO.",
        "CLIENT NAME",
        "SUSPENDED DATE",
        "DESCRIPTION",
        "ISSUE DATE",
        "VIEW",
        ]}
        apiurl={API_URL}
        screenname="cardholder"
        clientId={prop?.location.state?.id}
        setDataset={setDataset}
        cardstatuses={cardstatus}
  
      />
    </section>);
    }
    else {
      setSuspendedCardTab("nav-link active d-flex align-items-center");
      setSuspendedCardTabAreaSelected("true");
      setClosedCardTab("nav-link  d-flex align-items-center");
      setClosedCardTabAreaSelected("false");
      setAllCardsTab("nav-link  d-flex align-items-center");
      setAllCardsTabAreaSelected("false");
      setNewCardsTab("nav-link  d-flex align-items-center");
      setNewCardsTabAreaSelected("false");
      setiisExcelDownload(false);
      setContent(<section className="">
      <SearchGridSuspendedCard
       methodname="getsuspendcard"
        placeholdervalue="Search Suspended Cards"
        headersdata={[
        "FIRST NAME",
        "LAST NAME",
        "Token ID",
        "CARD NO.",
        "CLIENT NAME",
        "SUSPENDED DATE",
        "DESCRIPTION",
        "ISSUE DATE",
        "VIEW",
        ]}
        apiurl={API_URL}
        screenname="cardholder"
        clientId={prop?.location.state?.id}
        setDataset={setDataset}
        cardstatuses={cardstatus}
  
      />
    </section>);
    }
   

  }
  const showExcelModel = (e) => {
    setshowExcelDownloadModal(true);
  }
  const downloadExportToExcel = () => {
    if(AllCardsTabAreaSelected==="true"){
      setBoLoader(true);
      commonService.excelDownloadActiveAndPending(
        "",   //name
        "",   //tokenid
        "",  //RequestID or CardNumber
        "",  //IssuerType or IssuerCategory
        "0001-01-01",
        "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        prop?.location?.state?.id?.toString(),//clientid
        "true",//Status
        true //FetchAll
      ).then((resp)=>{
        setBoLoader(false);
        setDataset(resp?.data?.payload?.result?.pageModel)
        setshowExcelDownloadModal(false);
        setiisExcelDownload(true);
        setDateFrom("");
        setDateTo("");
      }).catch((err)=>{
        setBoLoader(false);
      });
    }
    else if(NewCardsTabAreaSelected==="true"){
      setBoLoader(true);
      commonService.excelDownloadActiveAndPending(
        "",   //name
        "",   //tokenid
        "",  //RequestID or CardNumber
        "",  //IssuerType or IssuerCategory
        "0001-01-01",
        "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        prop?.location?.state?.id?.toString(),//clientid
        "false", //Status
        true //FetchAll
      ).then((resp)=>{
        setBoLoader(false);
        setDataset(resp?.data?.payload?.result?.pageModel)
        setshowExcelDownloadModal(false);
        setiisExcelDownload(true);
        setDateFrom("");
        setDateTo("");
      }).catch((err)=>{
        setBoLoader(false);
      });
    }
    else if (SuspendedCardTabAreaSelected==="true"){
      setBoLoader(true);
      commonService.searhGridSuspendedCard(
        "", //name
        "", //tokenid
         "0001-01-01",
        "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        "getsuspendcard", //methodname
        API_URL,
        prop?.location?.state?.id?.toString(),
        null, //status
        "", //tokenid
        "", //cardnumber
        "", //IssuerType
        true
      ).then((resp)=>{
        setBoLoader(false);
        setDataset(resp?.data?.payload?.result?.pageModel)
        setshowExcelDownloadModal(false);
        setiisExcelDownload(true);
        setDateFrom("");
        setDateTo("");
      }).catch((err)=>{
        setBoLoader(false);
      });
    }
  }
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  return (
     <Fragment>
    <div className="col-lg-9 col-md-12 bo-main-content ">
      <div className="bo-tabs-mbl">
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
          <BoLoader loading={boloader} />
          {
            haspermissions.ViewLedgerHistory || haspermissions.ViewLedgerBalance ? (
              <li className="nav-item" role="presentation">
                <button
                  className={DebitClassName}
                  id="transactions-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#transactions"
                  onClick={btnDebitCardGrid}
                  type="button"
                  role="tab"
                  aria-controls="transactions"
                  aria-selected={DebitAreaSelected}
                >
                  <span className="icon-transaction"></span>Ledger History
                </button>
              </li>
            ) : ("")
          }
          <li className="nav-item" role="presentation">
            <button
              // className="nav-link d-flex align-items-center"
              className={NewClientClassName}
              id="cards-tab"
              data-bs-toggle="tab"
              onClick={newClients}
              data-bs-target="#cards"
              type="button"
              role="tab"
              aria-controls="cards"
              // aria-selected="true"
              aria-selected={NewClientsAreaSelected}
            >
              <span className="icon-card"></span>Cards
            </button>
          </li>
          {
            haspermissions.AdminRoleManagement && (
              <li className="nav-item" role="presentation">
                <button
                  className={RoleClassName}
                  id="transactions-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#transactions"
                  onClick={roleManagment}
                  type="button"
                  role="tab"
                  aria-controls="transactions"
                  aria-selected={RoleAreaSelected}
                >
                  <span className="icon-transaction"></span> Client User Management
                </button>
              </li>
            )
          }

          {haspermissions.CreateClient && (
            <li className="nav-item" role="presentation">
              <button
                className={AllClientClassName}
                // className="nav-link active d-flex align-items-center"
                id="profile-tab"
                data-bs-toggle="tab"
                onClick={allClients}
                data-bs-target="#profile"
                type="button"
                role="tab"
                aria-controls="profile"
                // aria-selected="true"
                aria-selected={AllClientAreaSelected}
              >
                <span className="icon-client"></span>
                Profile info
              </button>
            </li>
          )}

          {/* <li className="nav-item" role="presentation">
            <button
              className="nav-link d-flex align-items-center disabled"
              id="roles-tab"
              data-bs-toggle="tab"
              data-bs-target="#roles"
              type="button"
              role="tab"
              aria-controls="roles"
              aria-selected="false"
            >
              <span className="icon-variant"></span>Roles
            </button>
          </li> */}
        </ul>
      </div>
      <div className="bo-main-content-sub">

        <div className="tab-content" id="myTabContent2">
          {
            NewClientsAreaSelected === "true" && (
              <div>
                <div className="col-md-12 col-xl-12 mb-20">
                  <div className="col-stats col-stats-chart">
                    <div className="row align-items-center">
                      <div className="col-md-12">
                        <div className="chart-legends">
                          <div className="row">

                            <div className="col-md-2 ">
                              <div className="mb-20">
                                <p>Total Cards</p>
                                <h5>
                                  <span></span>
                                  {cardlimts?.cardLimitTotal}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="mb-20">
                                <p>Issued Cards</p>
                                <h5>
                                  <span></span>
                                  {cardlimts?.cardLimitTotal - cardlimts?.cardLimitRemaining}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div>
                                <p>Cards In Inventory</p>
                                <h5>
                                  <span></span>
                                  {cardlimts?.cardLimitRemaining}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div>
                                <p>Active Cards</p>
                                <h5>
                                  <span></span>
                                  {cardlimts?.totalActiveCard}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div>
                                <p>Pending Cards</p>
                                <h5>
                                  <span></span>
                                  {cardlimts?.totalPendingCard}
                                </h5>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div>
                                <p>Suspended Cards</p>
                                <h5>
                                  <span></span>
                                  {cardlimts?.suspendedCard}
                                </h5>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                    <div className="bo-tabs-mbl">
                      <ul
                        className="nav nav-tabs nav-tabs-profile"
                        id="myTab2"
                        role="tablist"
                      >
                        <li className="nav-item" role="presentation">
                          <button
                            className={AllCardsTab}
                            id="allcards-tab"
                            data-bs-toggle="tab"
                            onClick={btnCards}
                            data-bs-target="#allCards"
                            type="button"
                            role="tab"
                            aria-controls="allcards"
                            aria-selected={AllCardsTabAreaSelected}
                          >
                            <span className="icon-card"></span>
                            Active Cards
                          </button>

                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={NewCardsTab}
                            id="newcards-tab"
                            data-bs-toggle="tab"
                            onClick={btnPendingCards}
                            data-bs-target="#newcards"
                            type="button"
                            role="tab"
                            aria-controls="newcards"
                            aria-selected={NewCardsTabAreaSelected}
                          >
                            <span className="icon-card-check"></span>Pending Cards
                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={SuspendedCardTab}
                            id="newcards-tab"
                            data-bs-toggle="tab"
                            onClick={() => btnSuspendedCards("")}
                            data-bs-target="#newcards"
                            type="button"
                            role="tab"
                            aria-controls="newcards"
                            aria-selected={SuspendedCardTabAreaSelected}
                          >
                            <span className="icon-card-check"></span>Suspended Cards

                          </button>
                        </li>
                        <li className="nav-item" role="presentation">
                          <button
                            className={ClosedCardTab}
                            id="newcards-tab"
                            data-bs-toggle="tab"
                            onClick={() => btnSuspendedCards("Closed")}
                            data-bs-target="#newcards"
                            type="button"
                            role="tab"
                            aria-controls="newcards"
                            aria-selected={ClosedCardTabAreaSelected}
                          >
                            <span className="icon-card-check"></span>Closed Cards

                          </button>
                        </li>
                        <li className="nav-item ms-auto" role="presentation">
                          
                        <Fragment>
              {
                isExcelDownload ? (<Fragment>
                  {AllCardsTabAreaSelected === "true" && (
                    <ExportToExcel
                      Dataset={dataset}
                      Name="AllCards"
                      calledfrom="cards"
                    />
                  )}
                  {NewCardsTabAreaSelected === "true" && (
                    <ExportToExcel
                      Dataset={dataset}
                      Name="PendingCards"
                      calledfrom="cards"
                    />
                  )}
                  {SuspendedCardTabAreaSelected === "true" && (
                    <ExportToExcel
                      Dataset={dataset}
                      Name="SuspendedCards"
                      calledfrom="suspendedcards"
                    />
                  )}
                </Fragment>
                ) : (
                  <button id="searchBtnExportToExcel" className="btn btn-large mr-19" onClick={showExcelModel}> Export</button>)
              }
            </Fragment>
                        </li>
                      </ul>
                    </div>

              </div>
            )
          }
          {ClosedCardTabAreaSelected === "true" ? SuspendCardContent: content}
         
        </div>
      </div>
    </div>
    <ModelPopup
    show={showExcelDownloadModal}
    onHide={() => setshowExcelDownloadModal(false)}
    modelheading={""}
    showheader={"false"}
    className="smallPopup issueCardSmall"
    dialogClassName="modal-dialog modal-dialog-centered mwidth-52"
    backdrop="static"
    keyboard={false}
    modelbody={[
      <Fragment>
        <div className="row">
          <div className="col-md-12">
            <h2>Export To Excel</h2>
            <div className="mt-16">
              <div>
                <form onSubmit={(e) => e.preventDefault()}>
                  <div className="row">

                    <div className="col-md-6">
                      <div className="form-group form-search mr-20">
                        <label>Date From</label>
                        <div className="form-controls">
                          <ReactDatePickerHelper
                            datevalue={dateFrom}
                            onchangedate={dateFromHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group form-search mr-20">
                        <label>Date To</label>
                        <div className="form-controls">
                          <ReactDatePickerHelper
                            datevalue={dateTo}
                            onchangedate={dateToHandler}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-12">
                      <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">

                        <button
                          className="btn btn-large"
                          onClick={downloadExportToExcel}
                        >
                          Done
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Fragment>,
    ]}
  />
  </Fragment>
  );
};

export default ClientDetails;
