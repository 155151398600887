import React, { Fragment, useRef, useState } from "react";
import BoLoader from "../../helpers/BoLoader";
import ModelPopup from "../../helpers/ModelPopup";
import Form from "react-validation/build/form";
import GetAllClients from "../../helpers/GetAllClients";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import DialoguePopup from "../../helpers/DialoguePopup";
import { Link } from "react-router-dom";
import superClientServiceObj from "../../services/super-client.service";
import GetAllSuperClients from "../../helpers/GetAllSuperClients";

const AssociateSubClients = props => {
 
  //SuccessFull Modal
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);

  //Loader
  const [boloader, setBoLoader] = useState(false);
  //Parameters
  const [cliendId, setClientId] = useState("");
  const [superClientId, setSuperClientId] = useState("");
  //Dialogue Popup
  const [dialougeModalShow, setDialougeModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const form = useRef();
  const checkBtn = useRef();
  //Validation Checks
  const [showSuperClientErr, setShowSuperClientErr] = useState("d-none");
  const [showClientErr, setShowClientErr] = useState("d-none");


  const clientRequired = value => {
    
    if (!value) {
      document.getElementById("id_client").classList.add("has_error");
      setShowClientErr("");
      return false;
    } else {
      document.getElementById("id_client").classList.remove("has_error");
      setShowClientErr("d-none");
      return true;
    }
  };
  const superClientRequired = value => {
    
    if (!value) {
      document.getElementById("id_superclient").classList.add("has_error");
      setShowSuperClientErr("");
      return false;
    } else {
      document.getElementById("id_superclient").classList.remove("has_error");
      setShowSuperClientErr("d-none");
      return true;
    }
  };

 
  const associateClientToSuperclient = e => {
    
    if (e) e.preventDefault();
    let superclient;
    let client;
    if (cliendId) client = parseInt(cliendId);
    if (superClientId) superclient = parseInt(superClientId);
    let client_chk = clientRequired(client);
    let superclient_chck = superClientRequired(superclient);
    if (
      client_chk &&
      superclient_chck
    ) {
      setBoLoader(true);
      superClientServiceObj
        .associateClientToSuperclient(client,superclient,false )
        .then(
          response => {
            setBoLoader(false);
            if (response && response.data && response.data.payload) {
                setModalsuccessfullShow(true);
                props.onHide();
                setShowClientErr("d-none");
                setShowSuperClientErr("d-none");
            }
          },
          error => {
            setBoLoader(false);
            setModalsuccessfullShow(false);
            let msgBody = error?.response?.data?.message
              ? error?.response?.data?.message
              : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
            (async () => {
              let resp = await RefreshToken(
                error && error.response && error.response.status
              );
              if (resp) {
                window.location.reload();
                setBoLoader(false);
              } else {
                setBoLoader(false);
              }
            })();
          }
        );
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setDialougeModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onShowModelClearFields = () => {
    setClientId("");
    setSuperClientId("");
    setShowClientErr("d-none");
    setShowSuperClientErr("d-none");
    props.onHide();
  };
  const ClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].value;
    setClientId(clientName);
  };
  const SuperClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].id;
    setSuperClientId(clientName);
  };
  const renderTransferClient = () => {
    return (
      <Fragment>
        <div className="row">
          <BoLoader loading={boloader} />
          <div className="col-md-12">
            <h2> Associate Clients</h2>
            <div className="mt-16">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content" id="myTabContent">
                    <div>
                      <div>
                        <Form onSubmit={(e) => e.preventDefault()} ref={form}>
                          <div className="row mt-16">
                            <div className="col-md-6" id="id_superclient">
                              <div className="form-controls ">
                                <label className="mb-24">Super Clients*</label>
                                <div className="form-controls">
                                  <GetAllSuperClients
                                    superclientnamechangehandler={SuperClientNameChangeHandler}
                                    searchgriddata={"Y"}
                                  />
                                  <span className={"help-block " + showSuperClientErr}>
                                    Super Client Is Required!
                                  </span>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6" id="id_client">
                            <div className="form-controls">
                            <label className="mb-24"> Clients*</label>
                                  <div className="form-controls">
                                    <GetAllClients
                                      clientnamechangehandler={
                                        ClientNameChangeHandler
                                      }
                                      searchgriddata={"Y"}
                                    />
                                  </div>
                                  <span className={"help-block " + showClientErr}>
                                    Client is required!
                                  </span>
                                </div>
                            </div>
                            
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                              <a
                                className="btn btn-outline mr-19"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  onShowModelClearFields();
                                }}
                              >
                                Cancel
                              </a>
                              <a
                                className="btn btn-large"
                                onClick={associateClientToSuperclient}
                              >
                                Done
                              </a>
                            </div>
                          </div>
                          <CheckButton
                            style={{ display: "none" }}
                            ref={checkBtn}
                          />
                        </Form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </Fragment>
    );
  };
  return (
    <Fragment>
      <ModelPopup
        key={1}
        show={props.show}
        onHide={() => onShowModelClearFields()}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        backdrop="static"
        keyboard={false}
        modelbody={renderTransferClient()}
      />
      <DialoguePopup
        show={dialougeModalShow}
        onHide={() => {
          setDialougeModalShow(false);
        }}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        backdrop="static"
        keyboard={false}
      />
      <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Asscoiated Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </Fragment>
  );
};
export default AssociateSubClients;
