import React, { useState, useRef, Fragment, useEffect } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import RefreshToken from "../../helpers/RefreshToken";
import DialoguePopup from "../../helpers/DialoguePopup";
import BoLoader from "../../helpers/BoLoader";
import cardsService from "../../services/cards.services";
import DateFormat from "../../helpers/DateFormat";
import ModelPopup from "../../helpers/ModelPopup";
import transactionObj from "../../services/transaction.service";

const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status:"",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
const SeacrhGridFeeBreakDown = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [clientId, setclientId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  //Dialouge Popup Error Messages
  const [modalShowDialouge, setModalShowDialouge] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  //Success Message
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [userId, setUserId] = useState();
  //Desciption Modal
  const [modalShowNotesDesc, setmodalShowNotesDesc] = useState(false);
  const [modalShowNotesAccept, setmodalShowNotesAccept] = useState(false);
  const [descriptionnotes, setDescriptionnotes] = useState(false);
  const [IdNotes, setIdNotes] = useState(false);

  useEffect(() => {
    SearchClickHandler()
   
  }, []);
 
 
 
  const SearchClickHandler = (e,isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    setModalShow(false);
    setModalShow(false);
    setSearchDisabled(false);
    transactionObj.getFailedFeeSingleUser
      (
        props?.tokenid

      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp?.data?.payload?.length);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload
              );
              setSearchData(appendedData);
            } else {
              setSearchData(resp.data.payload);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
          }
        }
        
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}  style={{textAlign: "center"}}>{key.toUpperCase()}</th>;
    });
  };
 
  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        createdDate,
        tokenID,
        amount,
        errorMessage,
        feeType
        
      } = data; //destructuring
      return (
        <tr key={index}>
             <td>
            <DateFormat date={createdDate} />
          </td>
             <td style={fontStyle}>{errorMessage}</td>
             <td style={fontStyle}>{amount}</td>
           <td style={fontStyle}>{feeType}</td>
           <td style={fontStyle}>{tokenID}</td>
         
           
        </tr>
      );
    });
  };
 
  const showNotesDesc=(description)=>{
    setmodalShowNotesDesc(true);
    setDescriptionnotes(description)
  }
 
  const showAcceptRejectModel=(id)=>{
    setIdNotes(id)
    setmodalShowNotesAccept(true);
  }

  return (
    <div className="mt-0 mt-sm-4">
      <div className={classContent} id="myTabContent2">
      <BoLoader loading={boloader} />
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          {/* no client found  */}
          <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src="../../assets/images/icons/clients-empty.svg" /> */}
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
        </div>

        {searchGridSuccess && (
          <div className="table-wrap" ref={searchTbl}>
            <table
              className="table table-borderless bo-table table-cards"
              onScroll={tblScrollHandler}
              onWheel={tblScrollHandler}
            >
              <thead>
                <tr>{renderTableHeader()}</tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
        )}
        {loadingData ? (
          <div className="loading-more">
            <div className="loader">
              <div></div>
            </div>
            <p>loading data</p>
          </div>
        ) : totalCount === 0 ? (
          <div className="d-flex align-content-center justify-content-center  no-clients-msg">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src={emptyclient} /> */}
              <h3>Empty Details Listing</h3>
              <h6>There is no Detail(s) available for now.</h6>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
         
      <DialoguePopup
        show={modalShowDialouge}
        onHide={() => setModalShowDialouge(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      
      />
    </div>
  );
};

export default SeacrhGridFeeBreakDown;
