import React, { useState, useEffect } from "react";
import DonutChart from "../../helpers/DonutChart";
// import cardArrow from "../../assets/images/icons/bo-arrow.svg";
// import addclienticon from "../../assets/images/icons/add-client.svg";
import depositService from "../../services/deposit.service";
import SearchGrid from "../../helpers/SearchGrid";
import { Env } from "../../enviroment/environment";
import { Fragment } from "react";
import DepositClientGrid from "./DepositClientGrid";
import DepositClientSubmit from "./DepositClientSubmit";
import { useSelector } from "react-redux";
import RefreshToken from "../../helpers/RefreshToken";
import { Link } from "react-router-dom";
import ExportToExcel from "../superadmin/ExportToExcel";

const API_URL = Env.DepositApiUrl;

const DepositClients = () => {
    // const [modalShow, setModalShow] = useState(false);
    const { user: currentUser } = useSelector((state) => state.auth);
    const [confirmmodalShow, setCnfrmModalShow] = useState(false);
    const [depositContent, setDepositContent] = useState();
    const [clientAdminDepositDataSuccessful, setclientAdminDepositDataSuccessful] = useState(false);
    const [ClientUuid, setClientUuid] = useState(String(currentUser.user.ClientId));

    useEffect(() => {
        setclientAdminDepositDataSuccessful(false);
        depositService.getDepositStatusData(ClientUuid).then(
            (response) => {
                setDepositContent(response.data.payload);
                setclientAdminDepositDataSuccessful(true);
            },
            (error) => {
                (async () => {
                    let resp = await RefreshToken(error && error.response && error.response.status);
                    if (resp) {
                        window.location.reload();

                    }
                })();
            }
        );
    }, []);
    return (
        <div className="col-xl-9 col-lg-12 bo-main-content">
            <h3>Stats</h3>
            <div className="row mt-20">
                {clientAdminDepositDataSuccessful && (
                    <div className="col-md-6 col-xl-4">
                        <div className="col-stats col-stats-chart mb-40">
                            <div className="row align-items-center">
                                <div className="col-md-6 col-12">
                                    <div className="chart-wrap">
                                        <DonutChart
                                            chartdata={{ ...depositContent }}
                                            colorscheme={["#89455E", "#C1A6B0", "#E0DCDC"]}
                                        />
                                        <div className="donut-center-text">
                                            <h5>{depositContent.totalDeposit}</h5>
                                            <p>Total Deposits</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-12">
                                    <div className="chart-legends ">
                                        <div className="row">
                                            <div className="col-6 col-md-12">
                                                <div className="mb-20">
                                                    <p>Total Deposits</p>
                                                    <h5>
                                                        <span></span>${depositContent.totalDeposit}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-12">
                                                <div className="mb-20">
                                                    <p>Last Deposit</p>
                                                    <h5>
                                                        <span></span>${depositContent.lastDeposit}
                                                    </h5>
                                                </div>
                                            </div>
                                            <div className="col-6 col-md-12">
                                                <div>
                                                    <p>Avg. Deposit</p>
                                                    <h5>
                                                        <span></span>${depositContent.avgDeposit}
                                                    </h5>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )}
                <div className="col-md-3 col-xl-2" onClick={() => setCnfrmModalShow(true)}>
                    <div className="col-stats col-stats-action mb-40">
                        <div data-bs-target="\clients" data-bs-toggle="modal">
                            <span className="icon-add-client">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                            </span>
                            {/* <img src={addclienticon} /> */}
                            <br />
                            <Link to="#" className="pt-3">
                                Deposit
                                <span className="icon-bo-arrow"></span>
                                {/* <img src={cardArrow} /> */}
                            </Link>
                        </div>
                    </div>
                </div>
            </div>

            <div>
                <h3>Deposit</h3>
                <DepositClientGrid
                    methodname="getdeposits"
                    placeholdervalue="Search Deposit"
                    headersdata={[
                        "REFERENCE NO.",
                        "DATE",
                        "AMOUNT",
                        "SENDING BANK",
                        "ACCOUNT NO",
                        "STATUS",
                        "VIEW",
                    ]}
                    apiurl={API_URL}
                    screenname="deposit"
                />
                <DepositClientSubmit show={confirmmodalShow} onHide={() => setCnfrmModalShow(false)} />

            </div>
        </div>
    );
};

export default DepositClients;
