import Modal from "react-bootstrap/Modal";
import crossImg from "../assets/images/icons/cross.svg";
const ModelPopup = (props) => {
  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header
        style={{ display: props.showheader === "true" ? "" : "none" }}
      >
        <Modal.Title id="contained-modal-title-vcenter">
          {props.modelheading}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="mt-16">
        <img
          className="cross-icon cross-modal"
          src={crossImg}
          aria-label="Close"
          onClick={props.onHide}
        />
        {props.modelbody}
      </Modal.Body>
    </Modal>
  );
};
export default ModelPopup;
