import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";

const API_URL = Env.HelpApiUrl;
const submitHelpQuery = (userId, title, description) => {
  return axios
    .post(
      API_URL + "help",
      { userId, title, description },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const HelpObj = {
  submitHelpQuery,
};
export default HelpObj;
