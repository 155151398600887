import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";

const API_URL = Env.TransactionApiUrl;
const API_CARD_HOLDER=Env.CardHolderApiUrl;
const API_AUTOFEE=Env.AutoFeeApiUrl;
const API_IBAN=Env.IBANApiUrl;
const getTransactionStatsData = (clientId) => {
  return axios
    .get(API_URL + "getTransactionstats?clientId=" + clientId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const searhGridTransactionsSuperAdmin = (
  filterDto
) => {
  return axios
    .post(
      API_URL + "getprefundtransaction?userId=" + filterDto.userId ,
      {
        DateFrom:filterDto.DateFrom,
        DateTo :filterDto.DateTo,
        StartIndex :filterDto.StartIndex,

      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getVerificationPayee = (
  TokenId,
  Amount,
  SourceCurrencyCode,
  DestinationCurrencyCode,
  ClientId,
  UserId
) => {
  return axios
    .post(
      API_URL + "GetFundWalletVerification",
      {
        TokenId,
        Amount,
        SourceCurrencyCode,
        DestinationCurrencyCode,
        ClientId,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const saveFundWalletSuperAdmin = (
          Amount,
          SourceCurrencyCode,
          DestinationCurrencyCode,
          ClientId,
          UserId,
          Email,
          CustomerHashId,
          WalletHashId,
          TokenId,
          ReceiverName,
          CardHashId
) => {
  return axios
    .post(
      API_URL + "FundWalletClient",
      {
        Amount,
          SourceCurrencyCode,
          DestinationCurrencyCode,
          ClientId,
          UserId,
          Email,
          CustomerHashId,
          WalletHashId,
          TokenId,
          ReceiverName,
          CardHashId
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};

const searhGridTransactionsCards = (
  UserId,
  StartDate,
  EndDate,
  // Status,
  PageNumber,
  // Size,
  // Order
) => {
  return axios
    .post(
      API_URL + "GetCardTransactions ",
      {
        UserId,
        StartDate,
        EndDate,
        // Status,
        PageNumber,
        // Size,
        // Order
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const debitCard = (
  
  AmountDebit,
  TokenId,
  CardFeeCurrencyCode,
  ClientId,Description,UserId
  
) => {
return axios
.post(
API_URL + "debitamountfromcardholder",
{
  TokenId,
  AmountDebit,
  CardFeeCurrencyCode,
  ClientId,Description,
  UserId
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const searhGridSendMoney = (name, UserId, dateFrom, dateTo, startIndex) => {
  return axios
    .post(
      API_URL + "getp2ptransactions?userId=" + UserId,
      {
        name,
        UserId,
        dateFrom,
        dateTo,
        startIndex,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const GetCardTransactionsspecificmonth = (
  UserId,
  Month,
  Year
) => {
  return axios
    .post(
      API_URL + "GetCardTransactionsspecificmonth ",
      {
      UserId,
       Month,
       Year
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const tryAgainFailedFee = (tokenId) => {
  return axios
    .post(API_URL + "tryagainfailedfee?tokenId=" + tokenId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const getCardHolderUuid = (tokenid) => {
  debugger
  return axios
    .get(
      API_CARD_HOLDER + "getcardholderuuid?tokenId=" + tokenid,
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getFailedFeeSingleUser = (tokenid) => {
  return axios
    .post(API_AUTOFEE + "getFailedFeeSingleUser?tokenid=" + tokenid, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const deletefailedfee = (tokenid) => {
  return axios
    .post(API_AUTOFEE + "deletefailedfee?tokenid=" + tokenid, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const searhGridIBANTransactions = (
  AccountId,
  DateFrom,
  DateTo,
  PageSize,
) => {
  return axios
    .post(
      API_IBAN + "getaccounttransactions ",
      {
        AccountId,
        DateFrom,
        DateTo,
        PageSize,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const getListOfBeneficiaries = (
  accountId,
  
) => {
  return axios
    .post(
      API_IBAN +"getListOfBeneficiaries?accountId=" + accountId,
      {
       
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const addbeneficiary = (
   AccountId 
  ,Currency 
  ,FirstName 
  ,MiddleName 
  ,LastName 
  ,AddressLine1 
  ,AddressLine2 
  ,City 
  ,ZipCode 
  ,Type 
  ,AccountNumber 
  ,AccountCode 
) => {
return axios
.post(
API_IBAN + "addbeneficiary",
{
   AccountId 
  ,Currency 
  ,FirstName 
  ,MiddleName 
  ,LastName 
  ,AddressLine1 
  ,AddressLine2 
  ,City 
  ,ZipCode 
  ,Type 
  ,AccountNumber 
  ,AccountCode 
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const accountTransfer = (
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  Reference ,
  ToAccountId ,
) => {
return axios
.post(
  API_IBAN + "acounttransfer",
{
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  Reference ,
  ToAccountId ,
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const benificaryTransfer = (
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  BeneficaryId,
) => {
return axios
.post(
  API_IBAN + "accounttobeneficary",
{
  FromAccountId ,
  Currency ,
  Amount ,
  Reason ,
  BeneficaryId,
},
{ headers: authHeader() }
)
.then((response) => {
return response;
});
};
const transactionObj = {
  getTransactionStatsData,
  searhGridTransactionsSuperAdmin,
  getVerificationPayee,
  saveFundWalletSuperAdmin,
  searhGridTransactionsCards,
  debitCard,
  searhGridSendMoney,
  GetCardTransactionsspecificmonth,
  tryAgainFailedFee,
  getCardHolderUuid,
  getFailedFeeSingleUser,
  deletefailedfee,
  searhGridIBANTransactions,
  addbeneficiary,
  getListOfBeneficiaries,
  accountTransfer,
  benificaryTransfer
};
export default transactionObj;
