import { useState, useRef, useEffect, Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import Input from "react-validation/build/input";
import BoLoader from "../../helpers/BoLoader";
import Form from "react-validation/build/form";
import DialoguePopup from "../../helpers/DialoguePopup";
import CheckButton from "react-validation/build/button";
import RefreshToken from "../../helpers/RefreshToken";
import transactionService from "../../services/transaction.service";

const AddBenificiary = props => {
    const CheckNegativeAmount = value => {
        let obj = parseFloat(value);
        if (obj < 0) {
            document.getElementById("id_Amount").classList.add("has_error");
            return <span className="help-block">Amount cannot be negative!</span>;
        } else document.getElementById("id_Amount").classList.remove("has_error");
    };
    const CheckZeroAmount = value => {
        let obj = parseFloat(value);
        if (obj === 0) {
            document.getElementById("id_Amount").classList.add("has_error");
            return (
                <span className="help-block">Please enter amount greater than 0!</span>
            );
        } else document.getElementById("id_Amount").classList.remove("has_error");
    };
    const accountNumberRequired = value => {
        if (!value) {
            document.getElementById("id_AccountNumber").classList.add("has_error");
            return <span className="help-block">Token ID is required!</span>;
        } else
            document.getElementById("id_AccountNumber").classList.remove("has_error");
    };
    const AmountRequired = value => {
        if (!value) {
            document.getElementById("id_Amount").classList.add("has_error");
            return <span className="help-block">Amount is required!</span>;
        } else document.getElementById("id_Amount").classList.remove("has_error");
    };
    const { user: currentUser } = useSelector(state => state.auth);
    const [boloader, setBoLoader] = useState(false);
    const [ReceiverCardNumber, setReceiverCardNumber] = useState("");
    const [Amount, setAmount] = useState("");
    const [SourceCurrencyCode, setSourceCurrencyCode] = useState("EUR");
    const [DestinationCurrencyCode, setDestinationCurrencyCode] = useState("EUR");
    const [ClientId, setClientId] = useState();

    //mSwipe Payment Processor
    const [CardHashId, setCardHashId] = useState();

    const [FirstName, setFirstName] = useState();
    const [MiddleName, setMiddleName] = useState();
    const [LastName, setLastName] = useState();
    const [AddressLine1, setAddressLine1] = useState();
    const [AddressLine2, setAddressLine2] = useState();
    const [City, setCity] = useState();
    const [ZipCode, setZipCode] = useState();
    const [Type, setType] = useState();
    const [AccountNumber, setAccountNumber] = useState();
    const [AccountCode, setAccountCode] = useState();
    const [Currency, setCurrency] = useState();
    const [SortCode, setSortCode] = useState();
    const [IBAN, setIBAN] = useState();
    //Dialogue Popup
    const [dialougeModalShow, setDialougeModalShow] = useState(false);
    const [modelhdng, setMdlHdng] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");
    //Modal Popups
    const [confirmModalShow, setconfirmModalShow] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [showClientErr, setShowClientErr] = useState("d-none");

    const form = useRef();
    const checkBtn = useRef();

    const maxLengthCheck = object => {
        if (object.target.value.length > object.target.maxLength) {
            object.target.value = object.target.value.slice(
                0,
                object.target.maxLength
            );
        }
        let t = object.target.value;
        if (t.includes("."))
            object.target.value = (t.substr(0, t.indexOf(".")) + t.substr(t.indexOf("."), 3));
    };
   
   
    const onChangeType = (e) => {
        const name = e?.target?.selectedOptions[0]?.value;
        setType(name);
    }
    const onChangeIban = e => {
        const name = e.target.value;
        setAccountNumber(name);
      };
      const onChangeAccountNumber = e => {
        const name = e.target.value;
        setAccountNumber(name);
      };
      const onChangeSortCode = e => {
        const name = e.target.value;
        setAccountCode(name);
      };
    
      const onChangeFirstName = e => {
        let value = e.target.value;
        value = value.replace(/[^a-z0-9 ]/gi, '')
        setFirstName(value);
      };
      const onChangeLastName = e => {
        let value = e.target.value;
        value = value.replace(/[^a-z0-9 ]/gi, '')
        setLastName(value);
      };
      const onChangeMiddleName = e => {
        let value = e.target.value;
        value = value.replace(/[^a-z0-9 ]/gi, '')
        setMiddleName(value);
      };
      const onChangeAddressLine1 = e => {
        const name = e.target.value;
        setAddressLine1(name);
        
      };
      const onChangeAddressLine2 = e => {
        const name = e.target.value;
        setAddressLine2(name);
       
      };
      const onChangeCity = e => {
        let value = e.target.value;
        value = value.replace(/[^A-Za-z ]/ig, '')
        setCity(value);
      };
      const onChangeZipCode = e => {
        let value = e.target.value;
        value = value.replace(/[^a-z0-9 ]/gi, '')
        setZipCode(value);
        
      };
    const renderLoadCard = () => {
        return (
            <Fragment>
                <div className="row">
                    <BoLoader loading={boloader} />
                    <div className="col-md-12">
                        <h2>Add Benificiary</h2>
                        <div className="mt-16">
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="tab-content" id="myTabContent">
                                        <div>
                                            <div>
                                                <Form onSubmit={loadCardSave} ref={form}>
                                                    <div className="row mt-16">

                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_firstName">
                                                                <label>First Name</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="cardholderName"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        value={FirstName}
                                                                        onChange={onChangeFirstName}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_MiddleName">
                                                                <label>Middle Name</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="cardholderName"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        maxLength="20"
                                                                        value={MiddleName}
                                                                        onChange={onChangeMiddleName}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_LastName">
                                                                <label>Last Name</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="cardholderName"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        maxLength="20"
                                                                        value={LastName}
                                                                        onChange={onChangeLastName}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_AddressLine1">
                                                                <label>Address Line 1</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="cardholderName"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        maxLength="50"
                                                                        value={AddressLine1}
                                                                        onChange={onChangeAddressLine1}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_AddressLine2">
                                                                <label>Address Line 2</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="cardholderName"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        maxLength="50"
                                                                        value={AddressLine2}
                                                                        onChange={onChangeAddressLine2}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_City">
                                                                <label>city</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="cardholderName"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        maxLength="50"
                                                                        value={City}
                                                                        onChange={onChangeCity}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6">
                                                            <div className="form-group" id="id_ZipCode">
                                                                <label>Zip Code</label>
                                                                <div className="form-controls">
                                                                    <Input
                                                                        type="text"
                                                                        className="form-control no-icon"
                                                                        name="cardholderName"
                                                                        onWheel={(e) => e.target.blur()}
                                                                        maxLength="50"
                                                                        value={ZipCode}
                                                                        onChange={onChangeZipCode}
                                                                    />
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-md-6" id="id_Type">
                                                            <div className="form-group">
                                                                <label>Type*</label>
                                                                <div className="form-controls">
                                                                    <select
                                                                        onChange={onChangeType}
                                                                        className="form-control no-icon"
                                                                    >
                                                                        <option select="selected" value={"Select"}>
                                                                            Select
                                                                        </option>
                                                                        <option select="selected" value={"IBAN"}>
                                                                            IBAN
                                                                        </option>
                                                                        <option select="selected" value={"ACCOUNT_NUMBER"}>
                                                                            Account Number
                                                                        </option>
                                                                    </select>
                                                                   
                                                                </div>

                                                            </div>
                                                        </div>
                                                        {Type == "IBAN" && (
                                                            <div className="col-md-6">
                                                                <div className="form-group" id="id_Iban">
                                                                    <label>IBAN*</label>
                                                                    <div className="form-controls">
                                                                        <Input
                                                                            type="text"
                                                                            className="form-control no-icon"
                                                                            name="LastName"
                                                                            value={IBAN}
                                                                            onChange={onChangeIban}
                                                                            // validations={[
                                                                            //     AmountRequired,
                                                                            //     CheckNegativeAmount,
                                                                            //     CheckZeroAmount,
                                                                            // ]}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        )}
                                                        {
                                                            Type == "ACCOUNT_NUMBER" && (
                                                                <Fragment>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" id="id_AccountNumber">
                                                                            <label>Account Number*</label>
                                                                            <div className="form-controls">
                                                                                <Input
                                                                                    type="text"
                                                                                    className="form-control no-icon"
                                                                                    name="LastName"
                                                                                    value={AccountNumber}
                                                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                                    maxLength="9"
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onInput={maxLengthCheck}
                                                                                    onChange={onChangeAccountNumber}
                                                                                    // validations={[
                                                                                    //     AmountRequired,
                                                                                    //     CheckNegativeAmount,
                                                                                    //     CheckZeroAmount,
                                                                                    // ]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-md-6">
                                                                        <div className="form-group" id="id_SortCode">
                                                                            <label>Sort Code*</label>
                                                                            <div className="form-controls">
                                                                                <Input
                                                                                    type="number"
                                                                                    className="form-control no-icon"
                                                                                    name="LastName"
                                                                                    value={SortCode}
                                                                                    onKeyDown={(evt) => ["e", "E", "+", "-"].includes(evt.key) && evt.preventDefault()}
                                                                                    maxLength="9"
                                                                                    onWheel={(e) => e.target.blur()}
                                                                                    onInput={maxLengthCheck}
                                                                                    onChange={onChangeSortCode}
                                                                                    // validations={[
                                                                                    //     AmountRequired,
                                                                                    //     CheckNegativeAmount,
                                                                                    //     CheckZeroAmount,
                                                                                    // ]}
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </Fragment>
                                                            )
                                                        }


                                                    </div>
                                                    <div className="col-md-12">
                                                        <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                                                            <a
                                                                className="btn btn-outline mr-19"
                                                                data-bs-dismiss="modal"
                                                                onClick={() => {
                                                                    onShowModelClearFields();
                                                                }}
                                                            >
                                                                Cancel
                                                            </a>
                                                            <a
                                                                className="btn btn-large"
                                                                onClick={loadCardSave}
                                                            >
                                                                Done
                                                            </a>
                                                        </div>
                                                    </div>
                                                    <CheckButton
                                                        style={{ display: "none" }}
                                                        ref={checkBtn}
                                                    />
                                                </Form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        );
    };
    
    const loadCardSave = e => {
        if (e) e.preventDefault();
        setBoLoader(true);
        transactionService
            .addbeneficiary(
                 props?.AccountId 
                ,Currency 
                ,FirstName 
                ,MiddleName 
                ,LastName 
                ,AddressLine1 
                ,AddressLine2 
                ,City 
                ,ZipCode 
                ,Type 
                ,AccountNumber 
                ,AccountCode 
            )
            .then(
                response => {
                    setBoLoader(false);
                    if (response && response.data && response.data.payload) {
                        if (response.data.payload) {
                            setconfirmModalShow(false);
                            setModalShow(true);
                        }
                    }
                },
                error => {
                    setBoLoader(false);
                    setconfirmModalShow(false);
                    setModalShow(false);
                    let msgBody = error?.response?.data?.message
                        ? error?.response?.data?.message
                        : "Error Occured";
                    let errIcon = "icon-error";
                    errorAndSuccessModl("Error", msgBody, errIcon);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            window.location.reload();
                            setBoLoader(false);
                        } else {
                            setBoLoader(false);
                        }
                    })();
                }
            );
    };

    const closeConfrmModal = () => {
        setconfirmModalShow(false);
    };

    const renderConfirmDetails = () => {
        return (
            <div className="row">
                <BoLoader loading={boloader} />
                <div className="col-md-12">
                    <h2>Confirmation</h2>
                    <div className="mt-16">
                        <div>
                            <div className="row">
                                <div className="col-md-12">
                                    <p>Please Confirm Details of Transactions.</p>
                                </div>
                                <div className="col-md-12">
                                    <ul className="ul-card-details-popup mt-20">
                                        <li className="d-flex justify-content-between mb-16">
                                            <label>Name</label>
                                            <span>{FirstName}</span>
                                        </li>
                                       
                                        <li className="d-flex justify-content-between mb-16">
                                            <label>Amount</label>
                                            <span>{Amount}</span>
                                        </li>

                                    </ul>
                                    <div class="form-group submit-field d-flex justify-content-between mt-40">
                                        <button
                                            class="btn btn-outline mr-19"
                                            onClick={closeConfrmModal}
                                            data-bs-dismiss="modal"
                                        >
                                            Cancel
                                        </button>
                                        <a class="btn btn-large" onClick={loadCardSave}>
                                            Confirm
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const closeP2pModal = e => {
        e.preventDefault();
        setModalShow(false);
        setconfirmModalShow(false);
        setAmount("");
        if (props?.calledfrom === "cardholderdetails") window.location.reload();
    };
    const renderDetails = () => {
        return (
            <div className="row">
                <BoLoader loading={boloader} />
                <div className="col-md-12">
                    <div class="icon-alert icon-alert-popup">
                        <span class="icon-successful">
                            <span className="path1"></span>
                            <span className="path2"></span>
                        </span>
                    </div>
                    <h2>Created</h2>
                    <div className="mt-16">
                        <div>
                            <Form onSubmit={closeP2pModal}>
                                <div className="row">
                                    <div className="col-md-12">
                                        <p>Benificiary Added Successfully.</p>
                                    </div>
                                    <div className="col-md-12">
                                        
                                        <div class="form-group submit-field text-center mt-40">
                                            <button class="btn btn-large" onclick={closeP2pModal}>
                                                Done
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    };
    const errorAndSuccessModl = (heading, body, errIcon) => {
        setDialougeModalShow(true);
        setMdlHdng(heading);
        setMdlBdy(body);
        setMdlIcon(errIcon);
    };
    const onShowModelClearFields = () => {
        if (props.calledfrom !== "cardholderdetails") {

            setAmount("");
        }
        else {
            setAmount("");
        }
        props.onHide();
    };
    return (
        <Fragment>
            <ModelPopup
                key={21}
                show={props.show}
                onHide={() => onShowModelClearFields()}
                modelheading={""}
                showheader={"false"}
                className="smallPopup issueCardSmall"
                dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
                backdrop="static"
                keyboard={false}
                modelbody={renderLoadCard()}
            />
            <ModelPopup
                key={22}
                show={confirmModalShow}
                onHide={() => setconfirmModalShow(false)}
                modelheading={""}
                showheader={"false"}
                dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                className="smallPopup issueCardSmall"
                backdrop="static"
                keyboard={false}
                modelbody={renderConfirmDetails()}
            />
            <ModelPopup
                key={23}
                show={modalShow}
                onHide={() => setModalShow(false)}
                modelheading={""}
                modelbody={renderDetails()}
                showheader={"false"}
                dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                className="smallPopup issueCardSmall"
                backdrop="static"
                keyboard={false}
            />
            <DialoguePopup
                show={dialougeModalShow}
                onHide={() => {
                    setDialougeModalShow(false);

                }}
                modelheading={modelhdng}
                modelbody={modelbdy}
                modelicon={MdlIcon}
                backdrop="static"
                keyboard={false}
            />
        </Fragment>
    );
};

export default AddBenificiary;
