
const checkSpecialCharacters=(value)=>{
   let specialCharacters =/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    return specialCharacters.test(value);
}
const checkAlpha=(value)=>{
    let specialCharacters =/[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~0-9]/;
     return specialCharacters.test(value);
 }
export const TrimCharacters={
    checkSpecialCharacters,
    checkAlpha
}