import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";
const API_URL = Env.SuperClientApiUrl;
const API_URL_CLIENT = Env.ClientAdmin;


const getSuperClientList = () => {
    return axios
        .post(API_URL + "getsuperclientlist", {}, { headers: authHeader() })
        .then((response) => {
            return response;
        });
};
const addSuperClient = (
    Name,
    Email,
    Password,
    PhoneNumber,
  ) => {
    return axios
      .post(
        API_URL + "createsuperclient",
        {
            Name,
            Email,
            Password,
            PhoneNumber,
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  };
  const editSuperClient = (
    Id,
    Name,
    Email,
    phoneNumber,
    IsDeleted
  ) => {
    return axios
      .post(
        API_URL + "editsuperclient",
        {
            Id,
            Name,
            Email,
            phoneNumber,
            IsDeleted
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  };
  const associateClientToSuperclient = (
    clientId,
    superClientId,
    IsDeleted
  ) => {
    return axios
      .post(
        API_URL + "associateclienttosuperclient",
        {
            clientId,
            superClientId,
            IsDeleted
        },
        { headers: authHeader() }
      )
      .then((response) => {
        return response;
      });
  };
  const getclients = (name, ClientId, dateFrom, dateTo, startIndex, Status,TokenId,IssuerType,SuperClientId) => {
    return axios
      .post(
        API_URL_CLIENT + "getclients",
        {
          name,
          ClientId,
          dateFrom,
          dateTo,
          startIndex,
          Status,
          TokenId,
          IssuerType,
          SuperClientId
        },
        { headers: authHeader() }
      )
      .then((resp) => {
        return resp;
      });
  };


const superClientServiceObj = { getSuperClientList,addSuperClient,editSuperClient,associateClientToSuperclient,getclients };
export default superClientServiceObj