import ModelPopup from "../../helpers/ModelPopup";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";
import AddIbanAccountsBody from "./AddIbanAccountsBody";

const AddIbanAccount = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);

  if (!currentUser) {
    return <Redirect to="/login" />;
  }
  return (
    <ModelPopup
      show={props.show}
      onHide={props.onHide}
      modelheading={""}
      showheader={"false"}
      className="smallPopup issueCardSmall"
      dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
      backdrop="static"
      keyboard={false}
      modelbody={<AddIbanAccountsBody show={props.show} onHide={props.onHide} />}
    />
  );
};

export default AddIbanAccount;
