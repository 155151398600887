import axios from "axios";
import authHeader from "./auth-header";
import { Env } from "../enviroment/environment";
const API_URL = Env.CardsApiUrl;
const searhGrid = (
  name,
  tokenId,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  // cardNumber,
  startIndex,
  methodName,
  url,
  ClientId,
  Status,
  IssuerType

) => {
  return axios
    .post(
      url + methodName,
      {
        name,
        tokenId,
        issueDate,
        expiryDate,
        dateFrom,
        dateTo,
        // cardNumber,
        startIndex,
        ClientId,
        Status,
        IssuerType
      },
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};


const searhGridCardNumber = (
  name,
  tokenId,
  RequestId,
  IssuerType,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  startIndex,
  methodName,
  url,
  ClientId,
  Status,
  Email,
) => {
  return axios
    .post(
      url + methodName,
      {
        name,
        tokenId,
        RequestId,
        IssuerType,
        issueDate,
        expiryDate,
        dateFrom,
        dateTo,
        startIndex,
        ClientId,
        Status,
        Email,
      },
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const searhGridSuspendedCard = (
  CardHolderName,
  tokenId,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  startIndex,
  methodName,
  url,
  ClientId,
  Status,
  TokenId,
  CardNumber,
  IssuerType,
  FetchAll
) => {
  return axios
    .post(
      url + methodName,
      {
        CardHolderName,
        tokenId,
        issueDate,
        expiryDate,
        dateFrom,
        dateTo,
        startIndex,
        ClientId,
        Status,
        TokenId,
        CardNumber,
        IssuerType,
        FetchAll
      },
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const excelDownloadActiveAndPending = (
  name,
  tokenId,
  RequestId,
  IssuerType,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  startIndex,
  ClientId,
  Status,
  FetchAll
) => {
  return axios
    .post(
      API_URL + "getcards",
      {
        name,
        tokenId,
        RequestId,
        IssuerType,
        issueDate,
        expiryDate,
        dateFrom,
        dateTo,
        startIndex,
        ClientId,
        Status,
        FetchAll
      },
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const searhGridAccountIbans = (
  AccountTitle,
  AccountNumber,
  name,
  tokenId,
  RequestId,
  IssuerType,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  startIndex,
  methodName,
  url,
  ClientId,
  Status
) => {
  return axios
    .post(
      url + methodName,
      {
        AccountTitle,
        AccountNumber,
        name,
        tokenId,
        RequestId,
        IssuerType,
        issueDate,
        expiryDate,
        dateFrom,
        dateTo,
        startIndex,
        ClientId,
        Status,
      },
      {
        headers: authHeader(),
      }
    )
    .then((resp) => {
      return resp;
    });
};
const commonService = {
  searhGrid,
  searhGridCardNumber,
  searhGridSuspendedCard,
  excelDownloadActiveAndPending,
  searhGridAccountIbans
};
export default commonService;
