import React, { useState, useRef } from "react";
import Form from "react-validation/build/form";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import { isEmail } from "validator";
import { forgotPassword } from "../../actions/account";
import { useDispatch, useSelector } from "react-redux";
import BoPaymentWrapper from "../BoPaymentLeftWrapper";
import BoLoader from "../../helpers/BoLoader";
import useWindowDimensions from "../../helpers/WindowWidth";

const required = (value) => {
  if (!value) {
    document.getElementById("frgt_Pas_Email_FrmGrp").classList.add("has_error");
    return (
      <span className="help-block">Please enterd a valid email address</span>
    );
  } else
    document
      .getElementById("frgt_Pas_Email_FrmGrp")
      .classList.remove("has_error");
};
const validEmail = (value) => {
  if (!isEmail(value)) {
    document.getElementById("frgt_Pas_Email_FrmGrp").classList.add("has_error");
    return (
      <span className="help-block">Please enterd a valid email address</span>
    );
  } else
    document
      .getElementById("frgt_Pas_Email_FrmGrp")
      .classList.remove("has_error");
};
const ForgotPassword = () => {
  const form = useRef();
  const { width } = useWindowDimensions();
  const checkBtn = useRef();
  const [email, setEmail] = useState("");
  const [successful, setSuccessful] = useState(false);
  const [boloader, setBoLoader] = useState(false);

  const { message } = useSelector((state) => state.message);
  const dispatch = useDispatch();
  const onChangeEmail = (e) => {
    const email = e.target.value;
    setEmail(email);
  };
  const handleForgotPassword = (e) => {
    e.preventDefault();
    setSuccessful(false);
    form.current.validateAll();

    let WebHostUrl = window.location.origin + "/";
    if (checkBtn.current.context._errors.length === 0) {
      setBoLoader(true);
      dispatch(forgotPassword(email, WebHostUrl))
        .then(() => {
          setSuccessful(true);
          setBoLoader(false);
        })
        .catch(() => {
          setSuccessful(false);
          setBoLoader(false);
        });
    }
  };
  return (
    <div className="row min-vh-100">
      <BoLoader loading={boloader} />
      { width > 767 && <BoPaymentWrapper pagename={["Forgot", <br />, "Password"]} />}
      
      <div className="col-md-7 col-lg-8 onbo-content onbo-content-mbl">
        <div className="row">
          <div className="bo-on-screens position-relative">
            <div className="col-md-8 mb-5">
              <h3 className="mb-4">Forgot Password</h3>
              <p>
                Enter your email to help us find you. We’ll send you a link to
                recover your account.
              </p>
            </div>
            <div className="col-md-8 col-lg-6 col-xl-5">
              <Form onSubmit={handleForgotPassword} ref={form}>
                <div className="form-group" id="frgt_Pas_Email_FrmGrp">
                  <label htmlFor="email">Email</label>
                  <div className="form-controls">
                    <Input
                      type="email"
                      className="form-control"
                      name="email"
                      value={email}
                      onChange={onChangeEmail}
                      validations={[required, validEmail]}
                      placeholder="Enter Email"
                    />
                    <span className="icon-email _ico"></span>
                    {message && <span className="help-block has_error"> {message}</span>}
                  </div>
                 
                </div>
                <div className="form-group submit-field">
                  {!successful && (
                    <button className="btn btn-large w-100">
                      verify email{" "}
                    </button>
                  )}
                  {successful && (
                    <button className="btn btn-large w-100">
                      Resend Email
                    </button>
                  )}
                </div>

                <CheckButton style={{ display: "none" }} ref={checkBtn} />
              </Form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ForgotPassword;
