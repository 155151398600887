import SettingsPageData from "../SettingsPageData";

const Settings = () => {
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      <SettingsPageData />
    </div>
  );
};

export default Settings;
