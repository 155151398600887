import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

import BoLoader from "../../helpers/BoLoader";
import ChangePinGrid from "./ChangePinGrid";
const ChangePin = () => {
  // const [modalShow, setModalShow] = useState(false);
  const { user: currentUser } = useSelector((state) => state.auth);

  const [refereshdata, setRefereshdata] = useState(false);
  //Loader
  const [boloader, setBoLoader] = useState(false);
  //ExportToExcel
  const [dataset, setDataset] = useState();
  useEffect(() => {
    setRefereshdata(true);
  }, []);


  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      {/* <h3>Stats</h3> */}
      <div className="row ">
        <BoLoader loading={boloader} />
        <div className="col-sm-6">
          <h3>Change Pin Request(s)</h3>
        </div>
      </div>

      <div>
        {refereshdata && (
          <div>
            <ChangePinGrid
              methodname="changepin"
              placeholdervalue="Search"
              headersdata={[
                "NAME",
                "TOKEN ID",
                "CARD NO.",
                "CLIENT NAME",
                "PIN",
                "DATE",
                "STATUS",
                "VIEW",
              ]}
              screenname="changepin"
              refereshpage={setRefereshdata}
            />
          </div>
        )}
      </div>

    </div>
  );
};

export default ChangePin;
