import SearchGridSendMoney from "../cardholders/SearchGridSendMoney";

const CHDSendMoney = () => {
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
      <SearchGridSendMoney
        placeholdervalue="Search Send Money"
        headersdata={["SENDER","RECIEVER", "SENDER Token Id","RECIEVER TOKEN ID","CLIENT NAME", "Date", "Amount"]}
        screenname="clients"
        UserId={""}
      />
    </div>
  );
};

export default CHDSendMoney;
