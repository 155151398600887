import { useState, useEffect, Fragment } from "react";
import { Env } from "../../enviroment/environment";
import BoLoader from "../../helpers/BoLoader";
import { Link } from "react-router-dom";
import { history } from "../../helpers/history";
import ModelPopup from "../../helpers/ModelPopup";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import SearchGridClient from "../superadmin/SearchGridClients";
import AddSuperClientUser from "../rolemanagment/AddSuperClientUser";
const API_URL = Env.ClientAdmin;
const prop = history;
const SuperClientDetails = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [DataSuccessful, setDataSuccessful] = useState(true);
  const [boloader, setBoLoader] = useState(false);
  const [ledgerBalances, setLedgerBalance] = useState();
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  
  if (currentUser) {
    haspermissions.CreateClient =
      currentUser?.user?.Permissions?.includes("Create Client");
    haspermissions.LedgerTransfer =
      currentUser?.user?.Permissions?.includes("Ledger Transfer");
  }

  
  return (
    <div className="col-xl-9 col-lg-12 bo-main-content">
        
        <div className="row mt-20">
        
         
           
           
              {/* {ledgerBalances?.split('\n').slice(0, -1).map((line, index) => (
                <> 
                <div className="col-md-3 col-xl-3">
                 <div
                 className="col-stats col-stats-card mb-40"
                 style={{ backgroundImage: `url(${""})`, height:`12rem` }}
               >
                <h3>Ledger Balance: </h3> &nbsp; &nbsp;
                <h3 key={index}>{line || '\u00A0'}</h3>
                <span className="icon-Wallets"></span>
                </div>
                </div>
                </>
  
))}  */}
          
           
          
         
       
       
            <div
            className="col-sm-4 col-md-3 col-xl-2"
            onClick={() => setCnfrmModalShow(true)}
          >
            <div className="col-stats col-stats-action mb-40 col-stats-mbl">
              <div data-bs-target="\clients" data-bs-toggle="modal">
                <span className="icon-add-client">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                </span>
               
                <br />
                <a  className="pt-3">
                  Add New Super ClientUser
                  <span className="icon-bo-arrow"></span>
                 
                </a>
              </div>
            </div>
          </div>

      </div>
      <BoLoader loading={boloader} />
      {DataSuccessful && (
        <Fragment>
          <SearchGridClient
            methodname="getclients"
            placeholdervalue="Search Client"
            headersdata={[
              "LOGO",
              "CLIENT NAME",
              "REG. DATE",
              "STATUS",
              "LEDGER BALANCE",
              // "VIEW",
            ]}
            apiurl={API_URL}
            screenname="clients"
            createclientpermission={haspermissions.CreateClient}
            calledfrom={"superclient"}
            superclientid={prop?.location?.state?.id}
            ledgerBalances={setLedgerBalance}
          />
        </Fragment>
      )}
       <AddSuperClientUser
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        onAddClient={() => {
          setCnfrmModalShow(false);
          setModalsuccessfullShow(true);
        }}
        userId={"userId"}
        superclientid={prop?.location?.state?.id}
        calledfrom={props?.calledfrom}
      />
     <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>User Added Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <a
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                               
                              }}
                            >
                              Done
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default SuperClientDetails;
