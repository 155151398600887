import React, { useState, useRef, Fragment, useEffect } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import RefreshToken from "../../helpers/RefreshToken";
import DialoguePopup from "../../helpers/DialoguePopup";
import BoLoader from "../../helpers/BoLoader";
import cardsService from "../../services/cards.services";
import DateFormat from "../../helpers/DateFormat";
import ModelPopup from "../../helpers/ModelPopup";

const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status:"",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
const SearchGridRejectedData = (props) => {

  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(true);
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  //Dialouge Popup Error Messages
  const [modalShowDialouge, setModalShowDialouge] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  //Success Message
  const [boloader, setBoLoader] = useState(false);
  useEffect(() => {
    SearchClickHandler()
   
  }, []);

  const SearchClickHandler = () => {
    setSearchData(props?.data);
  };
 
 
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
 
  const renderTableData = () => {
    if(props?.calledfrom==="bulkload"){
        return props?.data?.map((data, index) => {
            const {
              amount,
              currency,
              errorMessage,
              tokenId
            } = data; //destructuring
            return (
              <tr key={index}>
                 <td style={fontStyle}>{amount}</td>
                 <td style={fontStyle}>{currency}</td>
                 <td style={fontStyle}>{tokenId}</td>
                 <td style={fontStyle} title={errorMessage}>{errorMessage?.substr(0, 40)}{errorMessage?.length > 40 && ("...")}</td>
              </tr>
            );
          }); 
    }
    else{
        return props?.data?.map((data, index) => {
            const {
              firstName,
              lastName,
              email,
              errorMessage
            } = data; //destructuring
            return (
              <tr key={index}>
                 <td style={fontStyle}>{firstName+" "+ lastName}</td>
                 <td style={fontStyle} title={email}>{email?.substr(0, 15)} {errorMessage?.length > 15 && ("...")}</td>
                 <td style={fontStyle}title={errorMessage}>{errorMessage?.substr(0, 40)}{errorMessage?.length > 40 && ("...")}</td>
              </tr>
            );
          });
    }
  
  };
 
  return (
    <div className="mt-0 mt-sm-4">
      <div className={classContent} id="myTabContent2">
      <BoLoader loading={boloader} />
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          {/* no client found  */}
          <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src="../../assets/images/icons/clients-empty.svg" /> */}
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
        </div>

        {searchGridSuccess && (
          <div className="table-wrap" ref={searchTbl}>
            <table
              className="table table-borderless bo-table table-cards"
            >
              <thead>
                <tr>{renderTableHeader()}</tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
        )}
        {loadingData ? (
          <div className="loading-more">
            <div className="loader">
              <div></div>
            </div>
            <p>loading data</p>
          </div>
        ) : props?.data === 0 ? (
          <div className="d-flex align-content-center justify-content-center  no-clients-msg">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src={emptyclient} /> */}
              <h3>Empty Notes Listing</h3>
              <h6>There is no Note(s) available for now.</h6>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <DialoguePopup
        show={modalShowDialouge}
        onHide={() => setModalShowDialouge(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      
      />
    </div>
  );
};

export default SearchGridRejectedData;
