import React, { useState, useRef, Fragment, useEffect, useCallback } from "react";
import { useDispatch, useSelector } from "react-redux";
import validator from "validator";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import GetAllClients from "../../helpers/GetAllClients";
import { haspermissions } from "../permissions/PermissionTypes";
import ExportToExcel from "../superadmin/ExportToExcel";
import clientFeeServiceObj from "../../services/clienfeeservice";
import DialoguePopup from "../../helpers/DialoguePopup";
import ModelPopup from "../../helpers/ModelPopup";
import transactionObj from "../../services/transaction.service";
import BoLoader from "../../helpers/BoLoader";
import SeacrhGridFeeBreakDown from "./SearchGridFeeBreakDown";
const headersdatafailedfee = [
    "CLIENT NAME",
    "CURRENCY",
    "TOKEN ID",
    "AMOUNT",
    "RETRY",
    "VIEW",
    "DELETE"
]
const SearchGridInputData = [
    {
        FeeTypeId: "",
        ClientId: "",
        TokenId: "",
        ScreenName: "",
        PageSize: 10,
        StartIndex: 1,
        PageNo: 1,
        FetchAll: false,
        scrolled: false,
        isFirstTime: true,
    },
];
var fontStyle = {
    textTransform: "uppercase",
};
const SearchGridClientFees = (props) => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [boloader, setBoLoader] = useState(false);
    const [modalShow, setModalShow] = useState(false);
    const [clientId, setclientId] = useState("");
    const searchTbl = useRef();
    const [loadingData, setLoadingData] = useState(false);
    const [searchData, setSearchData] = useState();
    const [searchGridSuccess, setSearchGridSuccess] = useState(false);
    const [FeeListClassName, setFeeListClassName] = useState("nav-link");
    const [FailedFeeListtClassName, setFailedFeeListClassName] = useState("nav-link");
    const [FeeListAreaSelected, setFeeListAreaSelected] = useState("false");
    const [FailedFeeListAreaSelected, setFailedFeeListAreaSelected] = useState("false");
    const [SearchInputDisabled, setSearchDisabled] = useState(false);
    const [clientIdVal, setClientIdVal] = useState("");
    const [totalCount, setTotalCount] = useState(0);
    const [classContent, setClassContent] = useState("tab-content");
    const [dataset, setDataset] = useState();
    const [FeeTypeId, setFeeType] = useState();
    const [feeList, setFeeList] = useState([]);
    const [retryconfirmationshow, setRetryConfirmationShow] = useState(false);
    const [deleteconfirmationshow, setDeleteConfirmationShow] = useState(false);
    const [failedFeeId, setFailedFeeId] = useState();
    const [singleFeeData, setSingleFeeData] = useState();
    //Dialogue Popup
    const [modelhdng, setMdlHdng] = useState("");
    const [modelbdy, setMdlBdy] = useState("");
    const [MdlIcon, setMdlIcon] = useState("");
    const [dialougemodalShow, setDialougemodalShow] = useState(false);
    const [modalShowDetailsFee, setModalShowDetailsFee] = useState(false);
    const [tokenid, setTokenId] = useState("");
    const observer = useRef();
    const searchTblRef = useRef();
    if (currentUser) {
        haspermissions.InternalAccounts = currentUser?.user?.Permissions?.includes("Internal Accounts");
    }
    const lastElementRef = useCallback(node => {
        if (loadingData) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting) {
                tblScrollHandler();

            }
        })
        if (node) observer.current.observe(node)
    }, [loadingData, searchData])
    useEffect(() => {
        SearchGridInputData[0].ScreenName = "approved";
        setclientId("");
        setClientIdVal("");
        feeListScreen();
        if (props.calledfrom === "dashboard")
            setClassContent("");
    }, []);
    useEffect(() => {
        clientFeeServiceObj.getFeeTypes().then((resp) => {
            if (resp) {
                setFeeList(resp?.data?.payload)
            }
        }).catch()
    }, [])
    const handleInputSearchClick = (e) => {
        e.preventDefault();
        setModalShow(true);
        setSearchDisabled(true);
        setclientId("");
    };
    const SearchClickHandler = (e, calledfrom) => {
        setLoadingData(true);
        if (!SearchGridInputData[0].scrolled) {
            setSearchGridSuccess(false);
        }
        if (calledfrom == "search")
            SearchGridInputData[0].StartIndex = 1;
        setModalShow(false);
        setModalShow(false);
        setSearchDisabled(false);
        let startindex =SearchGridInputData[0].StartIndex;
        if (SearchGridInputData[0].ScreenName === "approved") {
            clientFeeServiceObj.getFeeRules
                (
                    SearchGridInputData[0].FeeTypeId,
                    clientIdVal,
                    SearchGridInputData[0].PageSize,
                    startindex,
                    SearchGridInputData[0].PageNo,
                    SearchGridInputData[0].FetchAll,
                    tokenid,

                )
                .then((resp) => {
                    setLoadingData(false);
                    if (resp && resp.data && resp.data.payload) {
                        if (resp.data.success) {
                            setModalShow(false);
                            setSearchDisabled(false);
                        }
                        if (
                            resp.data.payload.pageModel.length > 0 ||
                            searchData.length > 0
                        ) {
                            setTotalCount(resp.data.payload.totalCount);
                            if (SearchGridInputData[0].scrolled) {
                                let appendedData = searchData.concat(
                                    resp.data.payload.pageModel
                                );
                                setSearchData(appendedData);
                                setDataset(appendedData);
                            } else {
                                setSearchData(resp.data.payload.pageModel);
                                setDataset(resp.data.payload.pageModel);
                                SearchGridInputData[0].StartIndex = 1;
                            }
                            setSearchGridSuccess(true);
                            SearchGridInputData[0].scrolled = false;

                            searchTbl.current.onscroll = tblScrollHandler;
                            SearchGridInputData[0].isFirstTime = false;

                        }
                    }
                })
                .catch((error) => {
                    setTotalCount(0);
                    setSearchGridSuccess(false);
                    setLoadingData(false);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            SearchClickHandler();
                        }
                    })();
                });
        }
        else if (SearchGridInputData[0].ScreenName === "failed") {
            clientFeeServiceObj.getFailedFeeRules
                (
                    SearchGridInputData[0].FeeTypeId,
                    clientIdVal,
                    SearchGridInputData[0].PageSize,
                    startindex,
                    SearchGridInputData[0].PageNo,
                    SearchGridInputData[0].FetchAll,
                    tokenid,

                )
                .then((resp) => {
                    setLoadingData(false);
                    if (resp && resp.data && resp.data.payload) {
                        if (resp.data.success) {
                            setModalShow(false);
                            setSearchDisabled(false);
                        }
                        if (
                            resp.data.payload.pageModel.length > 0 ||
                            searchData.length > 0
                        ) {
                            setTotalCount(resp.data.payload.totalCount);
                            if (SearchGridInputData[0].scrolled) {
                                let appendedData = searchData.concat(
                                    resp.data.payload.pageModel
                                );
                                setSearchData(appendedData);
                                setDataset(appendedData);
                            } else {
                                setSearchData(resp.data.payload.pageModel);
                                setDataset(resp.data.payload.pageModel);
                                SearchGridInputData[0].StartIndex = 1;
                            }
                            setSearchGridSuccess(true);
                            SearchGridInputData[0].scrolled = false;

                            searchTbl.current.onscroll = tblScrollHandler;
                            SearchGridInputData[0].isFirstTime = false;

                        }
                    }
                })
                .catch((error) => {
                    setTotalCount(0);
                    setSearchGridSuccess(false);
                    setLoadingData(false);
                    (async () => {
                        let resp = await RefreshToken(
                            error && error.response && error.response.status
                        );
                        if (resp) {
                            SearchClickHandler();
                        }
                    })();
                });
        }

    };

    const tblScrollHandler = (e) => {
        if (
            !SearchGridInputData[0].scrolled &&
            SearchGridInputData[0].StartIndex + 10 <= totalCount
        ) {
            SearchGridInputData[0].scrolled = true;
            SearchGridInputData[0].StartIndex =
                SearchGridInputData[0].StartIndex + 10;
            SearchClickHandler();
        }
    };

    const showClientDetails = (id,
        clientName,
        clientId,
        feeType,
        feeCost,
        feePercentage,
        action) => {
        let obj = {
            id, clientName, clientId,
            feeType,
            feeCost,
            feePercentage
        }
        props?.updateFee(action);
        props?.updatevalues(obj);
    };

    const renderTableHeader = () => {
        if (SearchGridInputData[0].ScreenName === "approved") {
            let header = Object.values(props?.headersdata);
            return header.map((key, index) => {
                return <th key={index}>{key.toUpperCase()}</th>;
            });
        }
        else if (SearchGridInputData[0].ScreenName === "failed") {
            let header = Object.values(headersdatafailedfee);
            return header.map((key, index) => {
                return <th key={index}>{key.toUpperCase()}</th>;
            });
        }

    };

    const feeListScreen = () => {
        SearchGridInputData[0].ScreenName = "approved";
        SearchGridInputData[0].FeeTypeId = "";
        SearchGridInputData[0].ClientId = "";
        SearchGridInputData[0].StartIndex = 1;
        SearchGridInputData[0].PageNo = 1;
        SearchGridInputData[0].PageSize = 10;
        SearchGridInputData[0].TokenId = "";
        setclientId("");
        setClientIdVal("");
        setFeeType("");
        setTokenId("");
        setFeeListClassName("nav-link active d-flex align-items-center");
        setFeeListAreaSelected("true");
        setFailedFeeListClassName("nav-link d-flex align-items-center");
        setFailedFeeListAreaSelected("false");
        setSearchDisabled(false);
        SearchClickHandler(null, "approved");
    };
    const failedFeeListScreen = () => {
        SearchGridInputData[0].ScreenName = "failed";
        SearchGridInputData[0].FeeTypeId = "";
        SearchGridInputData[0].ClientId = "";
        SearchGridInputData[0].StartIndex = 1;
        SearchGridInputData[0].PageNo = 1;
        SearchGridInputData[0].PageSize = 10;
        SearchGridInputData[0].TokenId = "";
        setFeeType("");
        setclientId("");
        setClientIdVal("");
        setTokenId("");
        setFailedFeeListClassName("nav-link d-flex align-items-center active");
        setFailedFeeListAreaSelected("true");
        setFeeListClassName("nav-link d-flex align-items-center");
        setFeeListAreaSelected("false");
        setSearchDisabled(false);
        SearchClickHandler(null, "failed");
    };

    const renderTableData = () => {
        if (SearchGridInputData[0].ScreenName === "approved") {
            return searchData.map((data, index) => {
                const {
                    id,
                    clientName,
                    clientId,
                    feeType,
                    feeCost,
                    feePercentage
                } = data; //destructuring

                return (
                    <tr key={id} ref={
                        searchData?.length === index + 1 ? lastElementRef : searchTblRef
                    }>
                        <td style={fontStyle} title={clientName}>{clientName?.substr(0, 200) ?? "-"}{clientName?.length > 200 && ("...")}</td>
                        <td>{feeType}</td>
                        <td>{feeCost}</td>
                        <td>{feePercentage}</td>
                        <td>
                            <span
                                className="icon-show"
                                onClick={() => showClientDetails(
                                    id,
                                    clientName,
                                    clientId,
                                    feeType,
                                    feeCost,
                                    feePercentage,
                                    "update"
                                )}
                            ></span>
                        </td>
                        <td>
                            <span style={{ background: 'transparent' }}
                                onClick={() => showClientDetails(
                                    id,
                                    clientName,
                                    clientId,
                                    feeType,
                                    feeCost,
                                    feePercentage,
                                    "delete"
                                )}
                            >
                                <svg xmlns="http://www.w3.org/2000/svg" height="0.9em" viewBox="0 0 448 512"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg>
                            </span>
                        </td>
                    </tr>
                );
            });
        }
        else if (SearchGridInputData[0].ScreenName === "failed") {
            return searchData.map((data, index) => {
                const {
                    userId,
                    clientName,
                    currency,
                    tokenId,
                    totalAmount,
                } = data; //destructuring

                return (
                    <tr key={userId} ref={
                        searchData?.length === index + 1 ? lastElementRef : searchTblRef
                    }>
                        <td style={fontStyle} title={clientName}>{clientName?.substr(0, 200) ?? "-"}{clientName?.length > 200 && ("...")}</td>
                        <td>{currency}</td>
                        <td>{tokenId}</td>
                        <td>{totalAmount}</td>
                        <td>
                            <span

                                onClick={() => { getFailedFeeSingleUser(tokenId); }}
                            ><svg xmlns="http://www.w3.org/2000/svg" height="0.9em" viewBox="0 0 512 512"><path d="M105.1 202.6c7.7-21.8 20.2-42.3 37.8-59.8c62.5-62.5 163.8-62.5 226.3 0L386.3 160H336c-17.7 0-32 14.3-32 32s14.3 32 32 32H463.5c0 0 0 0 0 0h.4c17.7 0 32-14.3 32-32V64c0-17.7-14.3-32-32-32s-32 14.3-32 32v51.2L414.4 97.6c-87.5-87.5-229.3-87.5-316.8 0C73.2 122 55.6 150.7 44.8 181.4c-5.9 16.7 2.9 34.9 19.5 40.8s34.9-2.9 40.8-19.5zM39 289.3c-5 1.5-9.8 4.2-13.7 8.2c-4 4-6.7 8.8-8.1 14c-.3 1.2-.6 2.5-.8 3.8c-.3 1.7-.4 3.4-.4 5.1V448c0 17.7 14.3 32 32 32s32-14.3 32-32V396.9l17.6 17.5 0 0c87.5 87.4 229.3 87.4 316.7 0c24.4-24.4 42.1-53.1 52.9-83.7c5.9-16.7-2.9-34.9-19.5-40.8s-34.9 2.9-40.8 19.5c-7.7 21.8-20.2 42.3-37.8 59.8c-62.5 62.5-163.8 62.5-226.3 0l-.1-.1L125.6 352H176c17.7 0 32-14.3 32-32s-14.3-32-32-32H48.4c-1.6 0-3.2 .1-4.8 .3s-3.1 .5-4.6 1z" /></svg></span>
                        </td>
                        <td>
                            <span
                                className="icon-show"
                                onClick={() => { viewFailedFeeDetails(tokenId); }}
                            ></span>
                        </td>

                        <td>
                            <span
                                style={{ background: 'transparent' }}
                                onClick={() => { deleteFailedFeeSingleUser(tokenId); }}
                            ><svg xmlns="http://www.w3.org/2000/svg" height="0.9em;" viewBox="0 0 548 813"><path d="M135.2 17.7L128 32H32C14.3 32 0 46.3 0 64S14.3 96 32 96H416c17.7 0 32-14.3 32-32s-14.3-32-32-32H320l-7.2-14.3C307.4 6.8 296.3 0 284.2 0H163.8c-12.1 0-23.2 6.8-28.6 17.7zM416 128H32L53.2 467c1.6 25.3 22.6 45 47.9 45H346.9c25.3 0 46.3-19.7 47.9-45L416 128z" /></svg></span>
                        </td>
                    </tr>
                );
            });
        }
    };
    const HideSearchPopupDiv = () => {
        setModalShow(false);
        setSearchDisabled(false);
        setclientId("");
    };
    const ClearSearchData = () => {
        setclientId("");
        SearchGridInputData[0].Status = ""
        SearchGridInputData[0].TokenId = ""
        SearchGridInputData[0].IssuerType = ""
        SearchGridInputData[0].FeeTypeId = ""
        SearchGridInputData[0].ClientId = ""
        SearchGridInputData[0].StartIndex = 0;
        SearchGridInputData[0].PageNo = 1;
        SearchGridInputData[0].PageSize = 10;
        ClearInputData();
    };
    const ClearInputData = () => {
        if (document.getElementById("clientNameDD") !== null)
            document.getElementById("clientNameDD").value = "";
        setClientIdVal("");
        setTimeout(() => {
            [...document.getElementsByClassName("r-input-group")].forEach(
                (element) => {
                    element.children[0].value = "";
                }
            );
            document.getElementById("searchBtnClients").click();
        }, 500);
    };
    const ClientNameChangeHandler = (e) => {
        const clientName = e.target.selectedOptions[0].value;
        setClientIdVal(clientName);
    };
    const onChangeFeeType = e => {
        let obj = e.target.selectedOptions[0].value;
        if (obj != "") {
            let value = obj;
            setFeeType(value);
            SearchGridInputData[0].FeeTypeId=value;
        }
    }
    const retryFailedFee = () => {
        setBoLoader(true);
        transactionObj.tryAgainFailedFee(failedFeeId).then((resp) => {
            if (resp) {
                setBoLoader(false);
                let msgBody = resp?.response?.data?.message
                    ? resp?.response?.data?.message
                    : "Run SuccessFully";
                let errIcon = "icon-success";
                errorAndSuccessModl("Success", msgBody, errIcon);
            }


        }).catch((resp) => {
            setBoLoader(false);
            let msgBody = resp?.response?.data?.message
                ? resp?.response?.data?.message
                : "Run SuccessFully";
            let errIcon = "icon-success";
            errorAndSuccessModl("Success", msgBody, errIcon);
        });
    }
    const deleteFailedFee = () => {
        setBoLoader(true);
        transactionObj.deletefailedfee(failedFeeId).then((resp) => {
            if (resp) {
                setBoLoader(false);
                let msgBody = resp?.response?.data?.message
                    ? resp?.response?.data?.message
                    : "Run SuccessFully";
                let errIcon = "icon-success";
                errorAndSuccessModl("Success", msgBody, errIcon);
            }


        }).catch((error) => {
            setBoLoader(false);
            let msgBody = error?.response?.data?.message
                ? error?.response?.data?.message
                : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
    const onHideRetryPopupHandler = () => {
        setRetryConfirmationShow(false)
    }
    const onHideDeletePopupHandler = () => {
        setDeleteConfirmationShow(false)
    }
    const errorAndSuccessModl = (heading, body, errIcon) => {
        setDialougemodalShow(true);
        setMdlHdng(heading);
        setMdlBdy(body);
        setMdlIcon(errIcon);
    };
    const refereshPage = () => {
        failedFeeListScreen();
    }
    const retryAll = () => {
        setFailedFeeId(0);
        setRetryConfirmationShow(true);
    }

    const getFailedFeeSingleUser = (tokenid) => {
        setBoLoader(true);
        transactionObj.getFailedFeeSingleUser(tokenid).then((resp) => {
            if (resp) {
                setBoLoader(false);
                setSingleFeeData(resp?.data?.payload);
                setRetryConfirmationShow(true);
                setFailedFeeId(tokenid);
            }
        }).catch((error) => {
            setBoLoader(false);
            let msgBody = error?.response?.data?.message
                ? error?.response?.data?.message
                : "Error Occured";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
        });

    }
    const deleteFailedFeeSingleUser = (tokenid) => {
        setFailedFeeId(tokenid);
        setDeleteConfirmationShow(true);

    }

    const viewFailedFeeDetails = (tokenId) => {
        setFailedFeeId(tokenId);
        setModalShowDetailsFee(true);

    }
    const tokenChangeHandler = (e) => {
        SearchGridInputData[0].TokenId = e.target.value;
        if (validator.isNumeric(SearchGridInputData[0].TokenId))
            setTokenId(SearchGridInputData[0].TokenId);
        else setTokenId("");
    };
    return (
        <div className="mt-md-0 mt-4">
            {props.calledfrom !== "dashboard" &&
                <div className="bo-tabs-mbl">
                    <ul className="nav nav-tabs" id="myTab2" role="tablist">
                        <li className="nav-item" role="presentation">
                            <button
                                className={FeeListClassName}
                                id="allClients-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#allClients"
                                type="button"
                                role="tab"
                                onClick={feeListScreen}
                                aria-controls="allClients"
                                aria-selected={FeeListAreaSelected}
                            >
                                <span className="icon-load-card"></span>Custom Fee
                            </button>

                        </li>
                        <li className="nav-item" role="presentation">
                            <button
                                className={FailedFeeListtClassName}
                                id="newClients-tab"
                                data-bs-toggle="tab"
                                data-bs-target="#newClients"
                                type="button"
                                role="tab"
                                aria-controls="newClients"
                                onClick={failedFeeListScreen}
                                aria-selected={FailedFeeListAreaSelected}
                            >
                                <span className="icon-load-card"></span>Past Due Fee
                            </button>
                        </li>

                        <li className="nav-item ms-auto">
                            <div className="mt-16 mt-sm-0 mb-16">
                                <ExportToExcel
                                    Dataset={dataset}
                                    calledfrom="client"
                                    Name={"Client"}
                                />
                            </div>
                        </li>
                        {FailedFeeListAreaSelected === "true" && (
                            <a className="btn btn-large mt-16 mt-sm-0 mb-16" onClick={retryAll}>
                                Retry All
                            </a>

                        )}


                    </ul>
                </div>
            }
            <BoLoader loading={boloader} />
            <div className={classContent} id="myTabContent2">
                <div
                    className="tab-pane fade show active"
                    id="allClients"
                    role="tabpanel"
                    aria-labelledby="allClients-tab"
                >
                    <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
                        <div className="text-center">
                            <span className="icon-clients-empty">
                                <span className="path1"></span><span className="path2"></span>
                                <span className="path3"></span><span className="path4"></span>
                                <span className="path5"></span><span className="path6"></span>
                            </span>
                            <h3>Empty Client Fees Listing</h3>
                            <h6>There is no Data available for now.</h6>
                        </div>
                    </div>
                    <Fragment>
                        <form onSubmit={(e) => e.preventDefault()}>
                            <div className="row row-search">
                                <div className="col-xl-12 col-12">
                                    <div className="form-group">
                                        <div className="form-controls">
                                            <input
                                                type="text"
                                                className="form-control"
                                                placeholder={props.placeholdervalue}
                                                onClick={handleInputSearchClick}
                                                disabled={SearchInputDisabled}
                                            />
                                            <span className="icon-search _ico"></span>
                                            <div
                                                className="bo-cardsearch"
                                                style={{ display: modalShow ? "" : "none" }}
                                            >
                                                <div className="row">
                                                    <div className="col-sm-6">
                                                        <div className="col-md-4">
                                                            <img
                                                                className="cross-icon cross-modal"
                                                                src={crossImgs}
                                                                aria-label="Close"
                                                                onClick={HideSearchPopupDiv}
                                                                alt=""
                                                            />
                                                        </div>
                                                        <div className=" col-md-12 form-group form-search mr-20">
                                                            <label>Fee Type</label>
                                                            <div className="form-controls">
                                                                <select
                                                                    className="form-control no-icon"
                                                                    onChange={onChangeFeeType}
                                                                >
                                                                    {feeList.map((options) => (
                                                                        <option key={options.id} value={options.id} >
                                                                            {options.feeTypeName}
                                                                        </option>
                                                                    ))}

                                                                </select>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <div className="form-group form-search mr-20">
                                                            <label className="mb-24">Client Name</label>
                                                            <div className="form-controls">
                                                                <GetAllClients
                                                                    clientnamechangehandler={
                                                                        ClientNameChangeHandler
                                                                    }
                                                                    searchgriddata={"Y"}
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {
                                                        FailedFeeListAreaSelected === "true" ? (
                                                            <div className="col-md-6">
                                                                <div className="form-group form-search">
                                                                    <label>Token ID</label>
                                                                    <div className="form-controls">
                                                                        <input
                                                                            className="form-control"
                                                                            value={tokenid}
                                                                            onChange={tokenChangeHandler}
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        ):(<></>)
                                                    }



                                                    <div className="col-md-12">
                                                        <div className="form-group submit-field form-search">
                                                            <button
                                                                id="searchBtnClients"
                                                                className="btn btn-large mr-19"
                                                                onClick={() => (SearchClickHandler("", "search"))}
                                                            >
                                                                Search
                                                            </button>
                                                            <button
                                                                className="btn btn-outline"
                                                                onClick={ClearSearchData}
                                                            >
                                                                Clear Search
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="help-block d-none"></span>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </Fragment>
                </div>

                {searchGridSuccess && (
                    <div className="table-wrap" ref={searchTbl}>
                        <table
                            className="table table-borderless bo-table table-cards table-clients"
                        // onScroll={tblScrollHandler}
                        // onWheel={tblScrollHandler}
                        >
                            <thead>

                                <tr>{renderTableHeader()}</tr>
                            </thead>
                            <tbody>{renderTableData()}</tbody>
                        </table>
                    </div>
                )}
                {loadingData ? (
                    <div className="loading-more">
                        <div className="loader">
                            <div></div>
                        </div>
                        <p>loading data</p>
                    </div>
                ) : totalCount === 0 ? (
                    <div className="d-flex align-content-center justify-content-center  no-clients-msg">
                        <div className="text-center">
                            <span className="icon-clients-empty">
                                <span className="path1"></span><span className="path2"></span>
                                <span className="path3"></span><span className="path4"></span>
                                <span className="path5"></span><span className="path6"></span>
                            </span>
                            {/* <img src={emptyclient} /> */}
                            <h3>Empty Fees Listing</h3>
                            <h6>There is no Data available for now.</h6>
                        </div>
                    </div>
                ) : (
                    ""
                )}
            </div>
            <ModelPopup
                key={4}
                show={retryconfirmationshow}
                onHide={() => setRetryConfirmationShow(false)}
                modelheading={""}
                showheader={"false"}
                className="smallPopup issueCardSmall"
                dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                backdrop="static"
                keyboard={false}
                modelbody={[
                    <Fragment>
                        <div class="row">
                            <div class="col-md-12">
                                <h2>Confirmation</h2>
                                <div class="mt-16">
                                    <div>
                                        <form>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>Are you sure you want to Retry?</p>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                                                        <a
                                                            class="btn btn-outline"
                                                            onClick={onHideRetryPopupHandler}
                                                        >
                                                            No
                                                        </a>
                                                        <a
                                                            class="btn btn-large"
                                                            onClick={() => {
                                                                retryFailedFee();
                                                                setRetryConfirmationShow(false);
                                                            }}
                                                        >
                                                            Yes
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>,
                ]}
            />
            <ModelPopup
                show={modalShowDetailsFee}
                key={22}
                onHide={() => { setModalShowDetailsFee(false) }}
                modelheading={""}
                showheader={"false"}
                className="smallPopup issueCardSmall"
                dialogClassName="modal-dialog modal-dialog-centered mwidth-86"
                modelbody={[
                    <Fragment>
                        <form onSubmit={e => e.preventDefault()}>
                            <div className="row mt-16">
                                <div className="col-md-12">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group" id="notes_description">
                                                <h2 style={{ textAlign: "center" }}>Breakdown of Fees</h2>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                        <SeacrhGridFeeBreakDown
                            methodname="feebreakdown"
                            placeholdervalue="Search"
                            headersdata={[
                                "DATE",
                                "ERROR MESSSAGE",
                                "AMOUNT",
                                "FEE TYPE",
                                "TOKEN ID",
                            ]}
                            screenname="clientfee"
                            tokenid={failedFeeId}
                        />
                    </Fragment>,
                ]}
            />
            <DialoguePopup
                show={dialougemodalShow}
                onHide={() => setDialougemodalShow(false)}
                hideallpopups={() => { setDialougemodalShow(false); refereshPage(); }}
                modelheading={modelhdng}
                modelbody={modelbdy}
                modelicon={MdlIcon}
            />
            <ModelPopup
                key={4}
                show={deleteconfirmationshow}
                onHide={() => setDeleteConfirmationShow(false)}
                modelheading={""}
                showheader={"false"}
                className="smallPopup issueCardSmall"
                dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                backdrop="static"
                keyboard={false}
                modelbody={[
                    <Fragment>
                        <div class="row">
                            <div class="col-md-12">
                                <h2>Confirmation</h2>
                                <div class="mt-16">
                                    <div>
                                        <form>
                                            <div class="row">
                                                <div class="col-md-12">
                                                    <p>Are you sure you want to Delete?</p>
                                                </div>
                                                <div class="col-md-12">
                                                    <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                                                        <a
                                                            class="btn btn-outline"
                                                            onClick={onHideDeletePopupHandler}
                                                        >
                                                            No
                                                        </a>
                                                        <a
                                                            class="btn btn-large"
                                                            onClick={() => {
                                                                deleteFailedFee();
                                                                setDeleteConfirmationShow(false);
                                                            }}
                                                        >
                                                            Yes
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>,
                ]}
            />
        </div>
    );
};

export default SearchGridClientFees;
