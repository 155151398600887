import { Fragment, useState, useEffect } from "react";
import DonutChart from "../../helpers/DonutChart";
import cardsService from "../../services/cards.services";
import { useSelector } from "react-redux";
import RefreshToken from "../../helpers/RefreshToken";
import { Env } from "../../enviroment/environment";
import { Link } from "react-router-dom";
import SearchGridClient from "./SearchGridClients";
import SearchGrid from "../../helpers/SearchGrid";
import IssueCard from "../cardholders/IssueCard";
import LoadCard from "../cardholders/LoadCard";
import ModelPopup from "../../helpers/ModelPopup";
import BoLoader from "../../helpers/BoLoader";
import clientmanagmentObj from "../../services/client-managment.service";
import { haspermissions } from "../permissions/PermissionTypes";

const API_URL_CLIENT = Env.ClientAdmin;
const API_URL = Env.CardsApiUrl;

const SuperAdminDashboard = () => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [Content, setContentClient] = useState();

  const [cardContent, setCardContent] = useState();

  const [superAdminCardsDataSuccessful, setSuperAdminCardsDataSuccessful] =
    useState(false);
  const clientId = currentUser.role.includes("Client admin")
    ? currentUser.user.ClientId
    : "";
  const [AllCardsTab, setAllCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [AllCardsTabAreaSelected, setAllCardsTabAreaSelected] =
    useState("false");
  const [NewCardsTab, setNewCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [NewCardsTabAreaSelected, setNewCardsTabAreaSelected] =
    useState("false");
  const [content, setContent] = useState(<p>No Record Found</p>);
  const [NewCardContent, setNewCardContent] = useState();
  const [modalShowLoadCard, setmodalShowLoadCard] = useState(false);
  const [modalShow, setModalShow] = useState(false);
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [DataSuccessful, setDataSuccessful] = useState(false);
  const [donutChart, setDonutChart] = useState();
  if(currentUser){
    haspermissions.CreateClient= currentUser?.user?.Permissions?.includes("Create Client");
    haspermissions.LedgerTransfer= currentUser?.user?.Permissions?.includes("Create Client");
  
  }
  useEffect(() => {
    setBoLoader(true);
    setDataSuccessful(false);
    clientmanagmentObj.getclientcount().then(
      (response) => {
        setContentClient(response.data.payload.result);
        const lst_donutchart = {
         
          Active: response.data.payload.result.activeClient,
          Suspended: response.data.payload.result.inActiveClient,
        };
        setDonutChart(lst_donutchart);
        setDataSuccessful(true);
        setBoLoader(false);
      },
      (error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      }
    );
  }, []);
  useEffect(() => {
    btnAllClients();
    setBoLoader(true);
    setSuperAdminCardsDataSuccessful(false);
    cardsService.getCardStatusData(clientId).then(
      (response) => {
        setBoLoader(false);
        setCardContent(response.data.payload.result);
        setSuperAdminCardsDataSuccessful(true);
      },
      (error) => {
        (async () => {
          setBoLoader(false);
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
          } else {
          }
        })();
      }
    );
  }, []);
  const btnAllCards = () => {
    setAllCardsTab("nav-link active d-flex align-items-center");
    setAllCardsTabAreaSelected("true");
    setNewCardsTab("nav-link d-flex align-items-center");
    setNewCardsTabAreaSelected("false");
    setContent(
      <div>
        <SearchGrid
          methodname="getcards"
          placeholdervalue="Search Card"
          headersdata={[
            "FIRST NAME",
            "LAST NAME",
            "TOKEN ID",
            "CARD NO",
            "COMPANY NAME",
            "EXPIRY DATE",
            "ISSUE DATE",
            "STATUS",
            "VIEW",
          ]}
          apiurl={API_URL}
          screenname="cardholder"
          clientId={clientId}
        />
      </div>
    );
  };
  const btnAllClients = () => {
    setNewCardsTab("nav-link active d-flex align-items-center");
    setNewCardsTabAreaSelected("true");
    setAllCardsTab("nav-link d-flex align-items-center");
    setAllCardsTabAreaSelected("false");
    setNewCardContent(
      <SearchGridClient
        methodname="getclients"
        placeholdervalue="Search Client"
        headersdata={[
          "LOGO",
          "COMPANY NAME",
          //"ADDRESS",
          "REG. DATE",
          //"CONTACT PERSON",
          "STATUS",
          "TOTAL BALANCE",
          // "CURRENCY",
          // "PROVIDER",
          // "VIEW",
        ]}
        apiurl={API_URL_CLIENT}
        screenname="clients"
        calledfrom="dashboard"
        createclientpermission={ haspermissions.CreateClient}
      />
    );
  };
  const IssueCardPopupHandler = () => {
    setModalShow(true);
    setCnfrmModalShow(false);
  };
  const LoadCardPopupHandler = () => {
    setmodalShowLoadCard(true);
  };
  return (
    <Fragment>
      <section>
        <div className="row align-items-center row-head-clients">
          <BoLoader loading={boloader} />
          <div className="col-xl-10 col-sm-9 col-6">
            {/* <h3>Statistics</h3> */}
          </div>
          {/* <div className="col-xl-2 col-sm-3 col-6">
            <form>
              <div className="form-group mb-0">
                <div className="form-controls">
                  <input
                    className="form-control right-icon"
                    placeholder="Date From"
                  />
                  <span className="icon-calender _ico icon-right"></span>
                </div>
                <span className="help-block d-none"></span>
              </div>
            </form>
          </div> */}
        </div>
        <div className="row mt-20">
         {/* { superAdminCardsDataSuccessful && (<Fragment>
          {AllCardsTabAreaSelected === "true" && (
            <div className="col-md-6 col-xl-4">
              <div className="col-stats col-stats-chart mb-40">
                <div className="row align-items-center">
                  <div className="col-md-6 col-12">
                    <div className="chart-wrap">
                      <DonutChart
                        chartdata={{ ...cardContent }}
                        colorscheme={["#89455E", "#E0DCDC"]}
                        className="donut-chart"
                      />
                      <div className="donut-center-text">
                        <h5>{cardContent.Active + cardContent.InActive}</h5>
                        <p>Total Cards</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="chart-legends">
                      <div className="row">
                        <div className="col-6 col-md-12">
                          <div className="mb-20">
                            <p>Cards Active</p>
                            <h5>
                              <span></span>
                              {cardContent.Active}
                            </h5>
                          </div>
                        </div>
                        <div className="col-6 col-md-12">
                          <div>
                            <p>Cards Inactive</p>
                            <h5>
                              <span></span>
                              {cardContent.InActive}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          </Fragment>)} */}
          {/* { DataSuccessful &&(<Fragment>
          { NewCardsTabAreaSelected=== "true" && (
            <div className="col-sm-7 col-md-6 col-xl-5">
              <div className="col-stats col-stats-chart mb-40">
                <div className="row align-items-center">
                  <div className="col-md-6 col-12">
                    <div className="chart-wrap">
                      <DonutChart
                        chartdata={{ ...donutChart }}
                        colorscheme={["#89455e", "#E0DCDC"]}
                      />
                      <div className="donut-center-text">
                        <h5>{Content.totalClient}</h5>
                        <p>Total Clients</p>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6 col-12">
                    <div className="chart-legends">
                      <div className="row">
                        <div className="col-6 col-md-12">
                          <div className="mb-20">
                            <p>Active Clients</p>
                            <h5>
                              <span></span>
                              {Content.activeClient}
                            </h5>
                          </div>
                        </div>
                        <div className="col-6 col-md-12">
                          <div>
                            <p>Suspended Clients</p>
                            <h5>
                              <span></span>
                              {Content.inActiveClient}
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          </Fragment>)} */}
          {/* <div
            className="col-md-3 col-lg-3 col-6 col-xl-2"
            onClick={LoadCardPopupHandler}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-load-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                  <span className="path12"></span>
                  <span className="path13"></span>
                  <span className="path14"></span>
                </span>
               
                <br />
                <Link to="#" className="mt-3">
                  Load Card
                  <span className="icon-bo-arrow"></span>
                  
                </Link>
              </div>
            </div>
          </div> */}
          {/* <div
            className="col-md-3 col-lg-3 col-6 col-xl-2"
            onClick={() => setCnfrmModalShow(true)}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-issued-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                </span>
              
                <br />
                <Link to="#" className="mt-3">
                  Issue Card
                  <span className="icon-bo-arrow"></span>
                 
                </Link>
              </div>
            </div>
          </div> */}
        </div>
      </section>
      <ModelPopup
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <h2>Issue Card</h2>
                <div className="mt-16">
                  <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Do you want to Issue Card?</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                            {/* <button
                              className="btn btn-outline"
                              onClick="openModalBulkIssue()"
                            >
                              Bulk Issue
                            </button> */}
                            <button
                              className="btn btn-large"
                              onClick={IssueCardPopupHandler}
                            >
                              Issue Card
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <IssueCard
        show={modalShow}
        onHide={() => setModalShow(false)}
        //allclients={allClients}
      />
      {/* )} */}

      <LoadCard
        show={modalShowLoadCard}
        onHide={() => setmodalShowLoadCard(false)}
      />
      <section>
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
           <li className="nav-item" role="presentation">
            <button
              className={NewCardsTab}
              id="newClients-tab"
              data-bs-toggle="tab"
              onClick={btnAllClients}
              data-bs-target="#newClients"
              type="button"
              role="tab"
              aria-controls="newClients"
              aria-selected={NewCardsTabAreaSelected}
            >
              <span className="icon-client"></span>Clients
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={AllCardsTab}
              id="cards-tab"
              data-bs-toggle="tab"
              onClick={btnAllCards}
              data-bs-target="#cards"
              type="button"
              role="tab"
              aria-controls="cards"
              aria-selected={AllCardsTabAreaSelected}
            >
              <span className="icon-card"></span>Cards
            </button>
          </li>
         
        </ul>
        <div className="">
          <div className="tab-content" id="myTabContent2">
            {AllCardsTabAreaSelected === "true" && content}
            {NewCardsTabAreaSelected === "true" && NewCardContent}
          </div>
        </div>
      </section>
    </Fragment>
  );
};

export default SuperAdminDashboard;
