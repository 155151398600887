import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";
const API_URL = Env.CardsApiUrl;
const API_URL_Transaction = Env.TransactionApiUrl;
const API_URL_IBAN = Env.IBANApiUrl;

const IssueCardToCardHolder = (
  FirstName, LastName, PreferredName, Gender,
  DateOfBirth, Email, Mobile, Nationality, DeliveryAddress1, DeliveryCity,
  DeliveryZipCode, BillingAddress1, BillingCity, BillingZipCode, cardFeeCurrencyCode,
  cardType, CountryCode, cardIssuanceAction, BillingCountry,
  DeliveryCountry,DeliveryMode,
  ClientId ,SpendingLimits,BillingState,DeliveryState,CardColor,CardVisaMaster
) => {
  return axios
    .post(
      API_URL + "issuecard",
      {
        FirstName, LastName, PreferredName, Gender,
        DateOfBirth, Email, Mobile, Nationality, DeliveryAddress1, DeliveryCity,
        DeliveryZipCode, BillingAddress1, BillingCity, BillingZipCode, cardFeeCurrencyCode,
        cardType, CountryCode, cardIssuanceAction, BillingCountry,
        DeliveryCountry,DeliveryMode, ClientId,SpendingLimits ,BillingState,DeliveryState,CardColor,CardVisaMaster
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};

const changeCardHolderPin = (Pin,
  TokenId,
  UserId,) => {
  return axios
    .post(
      API_URL + "setupdatepin",
      {
        Pin,
        TokenId,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const IssueBulkCardHolder = (formData,options,clientid) => {
  return axios
    .post(API_URL + "issuebulkcard?clientid=" + clientid,
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const BulkLoadCardHolder = (formData,options) => {
  return axios
    .post(API_URL_Transaction + "bulkload",
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};


const IssuePhysicalCardToCardHolder = (
  FirstName,
  LastName,
  PreferredName,
  Nationality,
  CountryCode,
  Gender,
  Mobile,
  DateOfBirth,
  DeliveryAddress1,
  addressLine2,
  DeliveryCity,
  DeliveryZipCode,
  DeliveryCountry,
  Email,
  cardType,
  cardFeeCurrencyCode,
  ClientId,
  CardProxyNumber,
  CardNumber,
  DeliveryState,CardColor
) => {
  return axios
    .post(
      API_URL + "activatephysicalcard",
      {
        FirstName,
        LastName,
        PreferredName,
        Nationality,
        CountryCode,
        Gender,
        Mobile,
        DateOfBirth,
        DeliveryAddress1,
        addressLine2,
        DeliveryCity,
        DeliveryZipCode,
        DeliveryCountry,
        Email,
        cardType,
        cardFeeCurrencyCode,
        ClientId,
        CardProxyNumber,
        CardNumber,
        DeliveryState,CardColor
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const approveRejectCardHolder = (status, cardUuid) => {
  return axios
    .post(
      API_URL + "approverejectcard?status=" + status + "&cardUuid=" + cardUuid,
      {},
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const RegisterCardHolder = (
  Name,
  email,
  phoneNumber,
  DateofBirth,
  Address,
  cardExpiry,
  availableBalance,
  Rolename,
  username,
  cardId,
  ClientId
) => {
  return axios
    .post(
      API_URL + "registercardholder",
      {
        Name,
        email,
        phoneNumber,
        DateofBirth,
        Address,
        cardExpiry,
        availableBalance,
        Rolename,
        username,
        cardId,
        ClientId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const getCardStatusData = (clientId) => {
  return axios
    .post(
      API_URL + "getcarddashboardstats?clientId=" + clientId,
      {},
      {
        headers: authHeader(),
      }
    )
    .then((res) => {
      return res;
    });
};
const searhGrid = (
  name,
  tokenId,
  issueDate,
  expiryDate,
  dateFrom,
  dateTo,
  startIndex,
  methodName
) => {
  return axios
    .post(API_URL + methodName, {
      name,
      tokenId,
      issueDate,
      expiryDate,
      dateFrom,
      dateTo,
      startIndex,
    },  {
      headers: authHeader(),
    })
    .then((resp) => {
      return resp;
    });
};
const approverejectkyc = (userId, status) => {
  return axios
    .post(
      API_URL + "approverejectkyc",
      { userId, status },

      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const searhGridNotes = (name, ClientId, dateFrom, dateTo, startIndex, Status, userId) => {
  return axios
    .post(
      API_URL + "getallnotes?userId=" + userId,
      {
        name,
        ClientId,
        dateFrom,
        dateTo,
        startIndex,
        Status,
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const onSaveNotes = (
  UserId,
  OperatorUserId,
  Description,
) => {
  return axios
    .post(
      API_URL + "savenotes",
      {
        UserId,
        OperatorUserId,
        Description,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
}
const saveResponseNote = (
  id
) => {
  return axios
    .post(
      API_URL +`markednoteread?Id=${id}`,
      {},
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
}
const IssueBulkActivateCard = (formData,options,clientid) => {
  return axios
    .post(API_URL + "activatebulkcard?clientid=" + clientid,
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const refillbalance = (userId) => {
  return axios
    .post(API_URL_Transaction + "refillbalance?userId=" + userId,{}, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const createiban = (
  FirstName 
 ,LastName 
 ,PreferredName 
 ,Nationality 
 ,CountryCode 
 ,Gender 
 ,Mobile 
 ,DateOfBirth 
 ,DeliveryAddress1 
 ,DeliveryCity 
 ,DeliveryZipCode 
 ,DeliveryCountry 
 ,Email 
 ,AccountTitle 
 ,Currency 
 ,ClientId
) => {
  return axios
    .post(
      API_URL_IBAN + "createiban",
      {
  FirstName 
 ,LastName 
 ,PreferredName 
 ,Nationality 
 ,CountryCode 
 ,Gender 
 ,Mobile 
 ,DateOfBirth 
 ,DeliveryAddress1 
 ,DeliveryCity 
 ,DeliveryZipCode 
 ,DeliveryCountry 
 ,Email 
 ,AccountTitle 
 ,Currency 
 ,ClientId
      },
      { headers: authHeader() }
    )
    .then((response) => {
      if (response.data.success) {
      }

      return response;
    });
};
const batchPayments = (formData,options,accountid) => {
  return axios
    .post(API_URL + "batchPayments?AccountId=" + accountid,
      formData,options, { headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const ActivatePhysicalCard = (
  CardNumber,
  KitNumber,
  UserId,
) => {
  return axios
    .post(
      API_URL + "activatecardbycardholder",
      {
        CardNumber,
        KitNumber,
        UserId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const replaceCard = (
  CardNumber,
  KitNumber,
  userId,
) => {
  return axios
    .post(
      API_URL + "assignnewcard?KitNumber=" + KitNumber + "&userId=" + userId,
      {
        KitNumber,
        userId,
      },
      { headers: authHeader() }
    )
    .then((response) => {
      return response;
    });
};
const creditLimitCalculation = (userId) => {
  return axios
    .post(API_URL_Transaction + "creditlimitcalculation?userId=" + userId, {
      headers: authHeader(),
    })
    .then((res) => {
      return res;
    });
};
const getCardLimits = (UserId) => {
  return axios
    .post(API_URL_Transaction + "getcardtransactionlimit", {UserId},{ headers: authHeader() })
    .then((response) => {
      return response;
    });
};
const cardsService = {
  IssueCardToCardHolder,
  IssueBulkCardHolder,
  RegisterCardHolder,
  getCardStatusData,
  searhGrid,
  approveRejectCardHolder,
  approverejectkyc,
  IssuePhysicalCardToCardHolder,
  searhGridNotes,
  onSaveNotes,
  changeCardHolderPin,
  saveResponseNote,
  BulkLoadCardHolder,
  IssueBulkActivateCard,
  refillbalance,
  createiban,
  batchPayments,
  ActivatePhysicalCard,
  replaceCard,
  creditLimitCalculation,
  getCardLimits
};
export default cardsService;
