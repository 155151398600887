import React from "react";
import creditlimit from "../../assets/images/icons/credit-limit.svg";
import onlinelimit from "../../assets/images/icons/online-limit.svg";
import transferlimit from "../../assets/images/icons/transfer-limit.svg";
import atmlimit from "../../assets/images/icons/atm-limit.svg";

const CardLimit = () => {
  return (
    <div className="col-xl-6 col-md-6 col-sm-6">
      <div className="row">
        <div className="col-6">
          <div className="bo-card-box bg-blush">
            <div className="mb-3">
              <img src={creditlimit} alt="crdtlmt" />
            </div>
            <h4>$4000</h4>
            <div className="progress w-100 mt-2 mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="32"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="d-flex align-items-center bo-card-stats mb-1">
              <span>Credit Limit</span>
              <span>25%</span>
            </div>
            <p>$20000</p>
          </div>
        </div>
        <div className="col-6">
          <div className="bo-card-box bg-blush">
            <div className="mb-3">
              <img src={onlinelimit} alt="onlinelmt" />
            </div>
            <h4>$7500</h4>
            <div className="progress w-100 mt-2 mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="32"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="d-flex align-items-center bo-card-stats">
              <span>Online Limit</span>
              <span>50%</span>
            </div>
            <p>$15000</p>
          </div>
        </div>
        <div className="col-6">
          <div className="bo-card-box bg-blush">
            <div className="mb-3">
              <img src={transferlimit} alt="trnsfrlmt" />
            </div>
            <h4>$3000</h4>
            <div className="progress w-100 mt-2 mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="32"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="d-flex align-items-center bo-card-stats">
              <span>Transfer Limit</span>
              <span>40%</span>
            </div>
            <p>$10000</p>
          </div>
        </div>
        <div className="col-6">
          <div className="bo-card-box bg-blush">
            <div className="mb-3">
              <img src={atmlimit} alt="atmlmt" />
            </div>
            <h4>$3750</h4>
            <div className="progress w-100 mt-2 mb-2">
              <div
                className="progress-bar"
                role="progressbar"
                aria-valuenow="32"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
            <div className="d-flex align-items-center bo-card-stats">
              <span>ATM Limit</span>
              <span>75%</span>
            </div>
            <p>$20000</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardLimit;
