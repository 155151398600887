import React, { useEffect, useState, Fragment } from "react";
import IssueCard from "./IssueCard";
import { useSelector } from "react-redux";
import DonutChart from "../../helpers/DonutChart";
import cardsService from "../../services/cards.services";
import SearchGrid from "../../helpers/SearchGrid";
import ModelPopup from "../../helpers/ModelPopup";
import { Env } from "../../enviroment/environment";
import { Link } from "react-router-dom";
import BoLoader from "../../helpers/BoLoader";
import GetAllClients from "../../helpers/GetAllClients";
import RefreshToken from "../../helpers/RefreshToken";
import LoadCard from "./LoadCard";
import BulkIssueCard from './BulkIssueCard'
import SearchGridPendingCards from "./SearchGridPendingCards";
import ExportToExcel from "../superadmin/ExportToExcel";
import { haspermissions } from "../permissions/PermissionTypes";
import SearchGridSuspendedCard from "./SearchGridSuspendedCard";
import IssuePhysicalCard from "./IssuePhysicalCard";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import commonService from "../../services/common.service";
import BulkLoadCard from "./BulkLoadCard";
import BulkActivateCard from "./BulkActivateCard";
const API_URL = Env.CardsApiUrl;
const SearchGridInputData = [
  {
    Name: "",
    TokenId: "",
    IssueDate: "",
    ExpiryDate: "",
    DateFrom: "",
    DateTo: "",
    CardNumber:"",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    SearchClearClicked: false,
    ClientId: "",
    IssuerType: "",
  },
];
const CardHolders = () => {
  const [modalShow, setModalShow] = useState(false);
  const [modalShowPhysical, setModalShowPhysical] = useState(false);
  const [modalShowLoadCard, setmodalShowLoadCard] = useState(false);
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [confirmmodalShowPhysical, setCnfrmModalPhysical] = useState(false);
  const [bulkIssueCard, setBulkIssueCard] = useState(false);
  const [isExcelDownload, setiisExcelDownload] = useState(false);
  const [showExcelDownloadModal, setshowExcelDownloadModal] = useState(false);
  const [bulkIssueCardActivate, setBulkIssueCardActivate] = useState(false);
  const [bulkLoadCard, setBulkLoadCard] = useState(false);

  const { user: currentUser } = useSelector((state) => state.auth);
  const [cardContent, setCardContent] = useState();
  const [superAdminCardsDataSuccessful, setSuperAdminCardsDataSuccessful] =
    useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [allClients, setAllClients] = useState();
  const clientId = currentUser.role.includes("Client admin")
    ? currentUser.user.ClientId
    : "";
  const [AllCardsTab, setAllCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [AllCardsTabAreaSelected, setAllCardsTabAreaSelected] =
    useState("false");
  const [NewCardsTab, setNewCardsTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [NewCardsTabAreaSelected, setNewCardsTabAreaSelected] =
    useState("false");
  const [SuspendedCardTab, setSuspendedCardTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [SuspendedCardTabAreaSelected, setSuspendedCardTabAreaSelected] =
    useState("false");
  const [ClosedCardTab, setClosedCardTab] = useState(
    "nav-link d-flex align-items-center"
  );
  const [ClosedCardTabAreaSelected, setClosedCardTabAreaSelected] =
    useState("false");
  const [content, setContent] = useState(<p>No Record Found</p>);
  const [NewCardContent, setNewCardContent] = useState();
  const [SuspendCardContent, setSuspendCardContent] = useState();
  //Export To Excel
  const [dataset, setDataset] = useState();
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const [IssuerCategory, setIssuerCategory] = useState("");
  if (currentUser) {
    haspermissions.IssueCard = currentUser?.user?.Permissions?.includes("Issue Card");
    haspermissions.LoadCard = currentUser?.user?.Permissions?.includes("Load Card");
    haspermissions.ActivatePhysicalCard = currentUser?.user?.Permissions?.includes("Activate Physical Card");
    haspermissions.BulkIssueCard = currentUser?.user?.Permissions?.includes("Issue Bulk Card");
    haspermissions.ActivateBulkCard = currentUser?.user?.Permissions?.includes("Activate Bulk Card");
    haspermissions.BulkLoadCard = currentUser?.user?.Permissions?.includes("Issue Bulk Load");
  }
  useEffect(() => {
    setBoLoader(true);
    btnAllCards();

    setSuperAdminCardsDataSuccessful(false);
    cardsService.getCardStatusData(clientId).then(
      (response) => {
        setBoLoader(false);
        setCardContent(response.data.payload.result);
        setSuperAdminCardsDataSuccessful(true);
        setAllClients(<GetAllClients />);
      },
      (error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      }
    );
  }, []);

  const btnAllCards = () => {
    setAllCardsTab("nav-link active d-flex align-items-center");
    setAllCardsTabAreaSelected("true");
    setNewCardsTab("nav-link d-flex align-items-center");
    setNewCardsTabAreaSelected("false");
    setSuspendedCardTab("nav-link d-flex align-items-center");
    setSuspendedCardTabAreaSelected("false");
    setClosedCardTab("nav-link d-flex align-items-center");
    setClosedCardTabAreaSelected("false");
    setiisExcelDownload(false);
    setContent(
      <div>
        <h3></h3>
        <SearchGrid
          methodname="getcards"
          placeholdervalue="Search Card"
          headersdata={[
            "FIRST NAME",
            "LAST NAME",
            "Token ID",
            "CARD NO",
            "CLIENT NAME",
            "EXPIRY DATE",
            "ISSUE DATE",
            "EMAIL",
            "VIEW",
          ]}
          apiurl={API_URL}
          screenname="cardholder"
          clientId={clientId}
          setDataset={setDataset}
          IssuerCategory={setIssuerCategory}
        />
      </div>
    );
  };
  // const btnNewCards = () => {
  //   setNewCardsTab("nav-link active d-flex align-items-center");
  //   setNewCardsTabAreaSelected("true");
  //   setAllCardsTab("nav-link d-flex align-items-center");
  //   setAllCardsTabAreaSelected("false");
  //   setNewCardContent(
  //     <SearchGrid
  //       methodname="getcards"
  //       placeholdervalue="Search Card"
  //       headersdata={[
  //         "CARD HOLDER",
  //         "CARD NUMBER",
  //         "COMPANY NAME",
  //         "EXPIRY DATE",
  //         "ISSUE DATE",
  //         "STATUS",
  //         "VIEW",
  //       ]}
  //       apiurl={API_URL}
  //       screenname="newcards"
  //       clientId={clientId}


  //     />
  //   );
  // };
  const btnPendingCards = () => {
    setAllCardsTab("nav-link  d-flex align-items-center");
    setAllCardsTabAreaSelected("false");
    setNewCardsTab("nav-link active d-flex align-items-center");
    setNewCardsTabAreaSelected("true");
    setSuspendedCardTab("nav-link d-flex align-items-center");
    setSuspendedCardTabAreaSelected("false");
    setClosedCardTab("nav-link d-flex align-items-center");
    setClosedCardTabAreaSelected("false");
    setiisExcelDownload(false);
    setNewCardContent(<section className="">
      <SearchGridPendingCards
        methodname="getcards"
        placeholdervalue="Search Pending Cards"
        headersdata={[
          "FIRST NAME",
          "LAST NAME",
          "Token ID",
          "CARD NO",
          "CLIENT NAME",
          "EXPIRY DATE",
          "ISSUE DATE",
          "EMAIL",
          "VIEW",
        ]}
        apiurl={API_URL}
        screenname="cardholder"
        clientId={clientId}
        setDataset={setDataset}

      />
    </section>);

  }
  const btnSuspendedCards = (cardstatus) => {
    if (cardstatus === "Closed") {
      setSuspendedCardTab("nav-link d-flex align-items-center");
      setSuspendedCardTabAreaSelected("false");
      setClosedCardTab("nav-link active d-flex align-items-center");
      setClosedCardTabAreaSelected("true");
    }
    else {
      setSuspendedCardTab("nav-link active d-flex align-items-center");
      setSuspendedCardTabAreaSelected("true");
      setClosedCardTab("nav-link  d-flex align-items-center");
      setClosedCardTabAreaSelected("false");
    }
    setAllCardsTab("nav-link  d-flex align-items-center");
    setAllCardsTabAreaSelected("false");
    setNewCardsTab("nav-link  d-flex align-items-center");
    setNewCardsTabAreaSelected("false");
    setiisExcelDownload(false);
    setSuspendCardContent(<section className="">
      <SearchGridSuspendedCard
        methodname="getsuspendcard"
        placeholdervalue="Search Suspended Cards"
        headersdata={[
          "FIRST NAME",
          "LAST NAME",
          "Token ID",
          "CARD NO.",
          "CLIENT NAME",
          "SUSPENDED DATE",
          "DESCRIPTION",
          "ISSUE DATE",
          "VIEW",
        ]}
        apiurl={API_URL}
        screenname="cardholder"
        clientId={clientId}
        setDataset={setDataset}
        cardstatuses={cardstatus}

      />
    </section>);

  }
  const IssueCardPopupHandler = () => {
    setModalShow(true);
    setCnfrmModalShow(false);
  };
  const IssueCardPhysicalPopupHandler = () => {
    setModalShowPhysical(true);
    setCnfrmModalPhysical(false);
  };
  const LoadCardPopupHandler = () => {
    setmodalShowLoadCard(true);
  };

  const BulkIssueCardPopHandler = () => {
    setBulkIssueCard(true);
  };
  const BulkIssueActivateCardPopHandler = () => {
    setBulkIssueCardActivate(true);
  }
  const showExcelModel = (e) => {
    setshowExcelDownloadModal(true);
  }
  const downloadExportToExcel = () => {
    if(AllCardsTabAreaSelected==="true"){
      setBoLoader(true);
      commonService.excelDownloadActiveAndPending(
        "",   //name
        "",   //tokenid
        "",  //RequestID or CardNumber
        IssuerCategory,  //IssuerType or IssuerCategory
        "0001-01-01",
        "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        "",//clientid
        "true",//Status
        true //FetchAll
      ).then((resp)=>{
        setBoLoader(false);
        setDataset(resp?.data?.payload?.result?.pageModel)
        setshowExcelDownloadModal(false);
        setiisExcelDownload(true);
        setDateFrom("");
        setDateTo("");
      }).catch((err)=>{
        setBoLoader(false);
      });
    }
    else if(NewCardsTabAreaSelected==="true"){
      setBoLoader(true);
      commonService.excelDownloadActiveAndPending(
        "",   //name
        "",   //tokenid
        "",  //RequestID or CardNumber
        IssuerCategory,  //IssuerType or IssuerCategory
        "0001-01-01",
        "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        "",//clientid
        "false", //Status
        true //FetchAll
      ).then((resp)=>{
        setBoLoader(false);
        setDataset(resp?.data?.payload?.result?.pageModel)
        setshowExcelDownloadModal(false);
        setiisExcelDownload(true);
        setDateFrom("");
        setDateTo("");
      }).catch((err)=>{
        setBoLoader(false);
      });
    }
    else if (SuspendedCardTabAreaSelected==="true"){
      setBoLoader(true);
      commonService.searhGridSuspendedCard(
        "", //name
        "", //tokenid
         "0001-01-01",
        "0001-01-01",
        dateFrom || "0001-01-01",
        dateTo || "0001-01-01",
        SearchGridInputData[0].startIndex,
        "getsuspendcard", //methodname
        API_URL,
        "",
        null, //status
        "", //tokenid
        "", //cardnumber
        IssuerCategory, //IssuerType
        true
      ).then((resp)=>{
        setBoLoader(false);
        setDataset(resp?.data?.payload?.result?.pageModel)
        setshowExcelDownloadModal(false);
        setiisExcelDownload(true);
        setDateFrom("");
        setDateTo("");
      }).catch((err)=>{
        setBoLoader(false);
      });
    }
  }
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date.getTime());
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const BulkLoadCardPopHandler = () => {
    setBulkLoadCard(true);
  };
  
  return (
    <div className="col-xl-9 col-md-12 bo-main-content">
      <BoLoader loading={boloader} />
      {/* <h3>Stats</h3> */}
      <div className="row mt-20">
        {/* {superAdminCardsDataSuccessful && (
          <div className="col-md-6 col-xl-4">
            <div className="col-stats col-stats-chart mb-40">
              <div className="row align-items-center">
                <div className="col-md-6 col-12">
                  <div className="chart-wrap">
                    <DonutChart
                      chartdata={{ ...cardContent }}
                      colorscheme={["#89455E", "#E0DCDC"]}
                      className="donut-chart"
                    />
                    <div className="donut-center-text">
                      <h5>{cardContent.Active + cardContent.InActive}</h5>
                      <p>Total Cards</p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-12">
                  <div className="chart-legends">
                    <div className="row">
                      <div className="col-6 col-md-12">
                        <div className="mb-20">
                          <p>Cards Active</p>
                          <h5>
                            <span></span>
                            {cardContent.Active}
                          </h5>
                        </div>
                      </div>
                      <div className="col-6 col-md-12">
                        <div>
                          <p>Cards Inactive</p>
                          <h5>
                            <span></span>
                            {cardContent.InActive}
                          </h5>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )} */}
        {
          haspermissions.LoadCard && (
            <Fragment>
            <div className="col-md-3 col-6 col-xl-2" onClick={LoadCardPopupHandler}>
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-load-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                    <span className="path12"></span>
                    <span className="path13"></span>
                    <span className="path14"></span>
                  </span>
                  {/* <img src={loadCardImg} alt="loadcard" /> */}
                  <br />
                  <Link to="#" className="mt-3">
                    Load Card
                    <span className="icon-bo-arrow"></span>
                    {/* <img src={cardArrow} alt="loadcardarrow" /> */}
                  </Link>
                </div>
              </div>
            </div>
            {
              haspermissions.BulkLoadCard &&(
                <Fragment>
            <div className="col-md-3 col-6 col-xl-2" onClick={BulkLoadCardPopHandler}>
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-load-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                    <span className="path12"></span>
                    <span className="path13"></span>
                    <span className="path14"></span>
                  </span>
                  {/* <img src={loadCardImg} alt="loadcard" /> */}
                  <br />
                  <Link to="#" className="mt-3">
                      Bulk Load Card
                    <span className="icon-bo-arrow"></span>
                    {/* <img src={cardArrow} alt="loadcardarrow" /> */}
                  </Link>
                </div>
              </div>
            </div>
                </Fragment>
              )
            }
          
            </Fragment>
          )
        }
        {
          haspermissions.IssueCard && (
            <div
              className="col-md-3 col-6 col-xl-2"
              onClick={() => setCnfrmModalShow(true)}
            >
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-issued-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                  </span>
                  {/* <img src={issueCardImg} alt="issuecard" /> */}
                  <br />
                  <Link to="#" className="mt-3">
                    Issue Card
                    <span className="icon-bo-arrow"></span>
                    {/* <img src={cardArrow} alt="issuecardarrow" /> */}
                  </Link>
                </div>
              </div>
            </div>
          )
        }
        {
          haspermissions.ActivatePhysicalCard && (
            <div
              className="col-md-3 col-8 col-sm-6 col-xl-2"
              onClick={() => setCnfrmModalPhysical(true)}
            >
              <div className="col-stats col-stats-action mb-40">
                <div>
                  <span className="icon-issued-card">
                    <span className="path1"></span>
                    <span className="path2"></span>
                    <span className="path3"></span>
                    <span className="path4"></span>
                    <span className="path5"></span>
                    <span className="path6"></span>
                    <span className="path7"></span>
                    <span className="path8"></span>
                    <span className="path9"></span>
                    <span className="path10"></span>
                    <span className="path11"></span>
                  </span>
                  {/* <img src={issueCardImg} alt="issuecard" /> */}
                  <br />
                  <Link to="#" className="mt-3">
                    Activate Physical Card
                    <span className="icon-bo-arrow"></span>
                    {/* <img src={cardArrow} alt="issuecardarrow" /> */}
                  </Link>
                </div>
              </div>
            </div>
          )
        }

        
       {
          haspermissions.BulkIssueCard &&(
            <div
            className="col-md-3 col-8 col-sm-6 col-xl-2"
            onClick={BulkIssueCardPopHandler}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-issued-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                </span>
                {/* <img src={issueCardImg} alt="issuecard" />  */}
                <br />
                <Link to="#" className="mt-3">
                  Bulk Issue Card
                  <span className="icon-bo-arrow"></span>
                  
                </Link>
              </div>
            </div>
          </div>
          )
        }
         {
          haspermissions.ActivateBulkCard &&(
            <div
            className="col-md-3 col-8 col-sm-6 col-xl-2"
            onClick={BulkIssueActivateCardPopHandler}
          >
            <div className="col-stats col-stats-action mb-40">
              <div>
                <span className="icon-issued-card">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                  <span className="path7"></span>
                  <span className="path8"></span>
                  <span className="path9"></span>
                  <span className="path10"></span>
                  <span className="path11"></span>
                </span>
                {/* <img src={issueCardImg} alt="issuecard" />  */}
                <br />
                <Link to="#" className="mt-3">
                  Bulk Activate Cards
                  <span className="icon-bo-arrow"></span>
                  
                </Link>
              </div>
            </div>
          </div>
          )
        }
      



        <ModelPopup
          show={confirmmodalShow}
          onHide={() => setCnfrmModalShow(false)}
          modelheading={""}
          showheader={"false"}
          className="smallPopup issueCardSmall"
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          backdrop="static"
          keyboard={false}
          modelbody={[
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <h2>Issue Card</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Do you want to Issue Card?</p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              {/* <button
                                className="btn btn-outline"
                                onClick="openModalBulkIssue()"
                              >
                                Bulk Issue
                              </button> */}
                              <button
                                className="btn btn-large"
                                onClick={IssueCardPopupHandler}
                              >
                                Issue Card
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>,
          ]}
        />
        <ModelPopup
          show={confirmmodalShowPhysical}
          onHide={() => setCnfrmModalPhysical(false)}
          modelheading={""}
          showheader={"false"}
          className="smallPopup issueCardSmall"
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          backdrop="static"
          keyboard={false}
          modelbody={[
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <h2>Activate Physical Card</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Do you want to Activate Physical Card?</p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              {/* <button
                                className="btn btn-outline"
                                onClick="openModalBulkIssue()"
                              >
                                Bulk Issue
                              </button> */}
                              <button
                                className="btn btn-large"
                                onClick={IssueCardPhysicalPopupHandler}
                              >
                                Activate Physical Card
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>,
          ]}
        />

        {/* {allClients && ( */}
        <IssueCard
          show={modalShow}
          onHide={() => setModalShow(false)}
        //allclients={allClients}
        />
        <IssuePhysicalCard
          show={modalShowPhysical}
          onHide={() => setModalShowPhysical(false)}
        //allclients={allClients}
        />
        {/* )} */}

        <LoadCard show={modalShowLoadCard} onHide={() => setmodalShowLoadCard(false)} />


        <BulkIssueCard show={bulkIssueCard} onHide={() => setBulkIssueCard(false)} calledfrom={"bulkissue"}/>
        <BulkActivateCard show={bulkIssueCardActivate} onHide={() => setBulkIssueCardActivate(false)} calledfrom={"bulkissueactivate"} />
        <BulkLoadCard show={bulkLoadCard} onHide={() => setBulkLoadCard(false)} />

      </div>

      <div className="bo-tabs-mbl">
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
          <li className="nav-item" role="presentation">
            <button
              className={AllCardsTab}
              id="allcards-tab"
              data-bs-toggle="tab"
              onClick={btnAllCards}
              data-bs-target="#allCards"
              type="button"
              role="tab"
              aria-controls="allcards"
              aria-selected={AllCardsTabAreaSelected}
            >
              <span className="icon-card"></span>
              Active Cards
            </button>

          </li>
          <li className="nav-item" role="presentation">
            <button
              className={NewCardsTab}
              id="newcards-tab"
              data-bs-toggle="tab"
              onClick={btnPendingCards}
              data-bs-target="#newcards"
              type="button"
              role="tab"
              aria-controls="newcards"
              aria-selected={NewCardsTabAreaSelected}
            >
              <span className="icon-card-check"></span>Pending Cards
              <span className="bo-badge bo-badge-large">

                {cardContent?.PendingCards}</span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={SuspendedCardTab}
              id="newcards-tab"
              data-bs-toggle="tab"
              onClick={() => btnSuspendedCards("")}
              data-bs-target="#newcards"
              type="button"
              role="tab"
              aria-controls="newcards"
              aria-selected={SuspendedCardTabAreaSelected}
            >
              <span className="icon-card-check"></span>Suspended Cards
              <span className="bo-badge bo-badge-large">

                {cardContent?.InActive}</span>
            </button>
          </li>
          <li className="nav-item" role="presentation">
            <button
              className={ClosedCardTab}
              id="newcards-tab"
              data-bs-toggle="tab"
              onClick={() => btnSuspendedCards("Closed")}
              data-bs-target="#newcards"
              type="button"
              role="tab"
              aria-controls="newcards"
              aria-selected={ClosedCardTabAreaSelected}
            >
              <span className="icon-card-check"></span>Closed Cards
            </button>
          </li>

          <li className="nav-item ms-auto mb-20" role="presentation">
            <Fragment>
              {
                isExcelDownload ? (<Fragment>
                  {AllCardsTabAreaSelected === "true" && (
                    <ExportToExcel
                      Dataset={dataset}
                      Name="AllCards"
                      calledfrom="cards"
                    />
                  )}
                  {NewCardsTabAreaSelected === "true" && (
                    <ExportToExcel
                      Dataset={dataset}
                      Name="PendingCards"
                      calledfrom="cards"
                    />
                  )}
                  {SuspendedCardTabAreaSelected === "true" && (
                    <ExportToExcel
                      Dataset={dataset}
                      Name="SuspendedCards"
                      calledfrom="suspendedcards"
                    />
                  )}
                </Fragment>
                ) : (
                  <button id="searchBtnExportToExcel" className="btn btn-large mr-19" onClick={showExcelModel}> Export</button>)
              }
            </Fragment>


          </li>
        </ul>
      </div>

      <div className="">
        <div className="tab-content" id="myTabContent2">
          {AllCardsTabAreaSelected === "true" && content}
          {NewCardsTabAreaSelected === "true" && NewCardContent}
          {SuspendedCardTabAreaSelected === "true" && SuspendCardContent}
          {ClosedCardTabAreaSelected === "true" && SuspendCardContent}
        </div>
      </div>

      {/* </div> */}
      <ModelPopup
        show={showExcelDownloadModal}
        onHide={() => setshowExcelDownloadModal(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-52"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <h2>Export To Excel</h2>
                <div className="mt-16">
                  <div>
                    <form onSubmit={(e) => e.preventDefault()}>
                      <div className="row">

                        <div className="col-md-6">
                          <div className="form-group form-search mr-20">
                            <label>Date From</label>
                            <div className="form-controls">
                              <ReactDatePickerHelper
                                datevalue={dateFrom}
                                onchangedate={dateFromHandler}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="form-group form-search mr-20">
                            <label>Date To</label>
                            <div className="form-controls">
                              <ReactDatePickerHelper
                                datevalue={dateTo}
                                onchangedate={dateToHandler}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">

                            <button
                              className="btn btn-large"
                              onClick={downloadExportToExcel}
                            >
                              Done
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default CardHolders;
