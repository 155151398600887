import { Fragment } from "react";
import GetAllClients from "./GetAllClients";
import ReactDatePickerHelper from "./ReactDatePickerHelper";
const SearchGridInputHelper = (props) => {
  return (
    <form onSubmit={(e) => e.preventDefault()}>
      
      <div className="row mt-20 row-search">
        <div className="col-xl-12 col-12">
       
          <div className="form-group">
            <div className="form-controls">
              <input
                type="text"
                placeholder={props.placeholdervalue}
                onClick={props.handleInputSearchClick}
                className="form-control"
               // disabled={props.searchinputdisabled}
              />
              <span className="icon-search _ico"></span>
              <div
                className="bo-cardsearch"
                style={{ display: props.modalshow ? "" : "none" }}
              >
                <div className="row">
                  <div className="col-md-6">
                    <img
                      className="cross-icon cross-modal"
                      src={props.crossimg}
                      aria-label="Close"
                      onClick={props.hidesearchpopupdiv}
                    />
                     {props.screenname !== "cardholdertransactions" && (
                    <div className=" form-search mr-20">
                      <div className="form-group form-search">
                        <label className=" mb-24">Client Name</label>
                        <div className="form-controls">
                          <GetAllClients
                            clientnamechangehandler={
                              props.clientnamechangehandler
                            }
                            searchgriddata={"Y"}
                          />
                        </div>
                      </div>
                    </div>
                  )}
                  
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-search  mr-20">
                      <label>
                        {props.screenname === "deposit"
                          ? "Reference No."
                          : "Token ID"}
                      </label>
                      <div className="form-controls">
                        <input
                          className="form-control"
                          value={props.tokenid}
                          onChange={props.tokenchangehandler}
                          maxLength={10}
                        />
                      </div>
                    </div>
                  </div>

                  {props.screenname!=="deposit" && (
                    <Fragment>
                    <div className="col-md-6">
                  <div className="form-group form-search ">
                      <label>Name</label>
                      <div className="form-controls">
                        <input
                          type="text"
                          value={props.name}
                          onChange={props.namechangehandler}
                          className="form-control"
                          maxLength={80}
                        />
                      </div>
                    </div>
                    </div>
                    <div className="col-md-6">
                  <div className="form-group form-search">
                    <label>Last 4 Card No.</label>
                    <div className="form-controls">
                    <input type="text" pattern="\d*" maxlength="4"
                    value={props.cardNumber}
                    onChange={props.cardchangehandler}/>
                  </div>
                  </div>
                </div>
                    </Fragment>)}
                  {(props.screenname === "cardholder" ||
                    props.screenname === "transactions" ||
                    props.screenname === "cardholdertransactions") && (
                    <Fragment>
                      <div className="col-md-6">
                        <div className="form-group form-search mr-20">
                          <label>Date Issue</label>
                          <div className="form-controls">
                            <ReactDatePickerHelper
                              datevalue={props.dateissued}
                              onchangedate={props.dateissuehandler}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group form-search">
                          <label>Date Expiry</label>
                          <div className="form-controls">
                            <ReactDatePickerHelper
                              datevalue={props.dateexpiry}
                              onchangedate={props.dateexpiryhandler}
                            />
                          </div>
                        </div>
                      </div>
                    </Fragment>
                  )}

                  <div className="col-md-6">
                    <div className="form-group form-search mr-20">
                      <label>Date From</label>
                      <div className="form-controls">
                        <ReactDatePickerHelper
                          datevalue={props.datefrom}
                          onchangedate={props.datefromhandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group form-search">
                      <label>Date To</label>
                      <div className="form-controls">
                        <ReactDatePickerHelper
                          datevalue={props.dateto}
                          onchangedate={props.datetohandler}
                        />
                      </div>
                    </div>
                  </div>

                  
                {props.screenname!=="deposit" &&(
                  <Fragment>
                 
                <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Issuer</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={props.onChangeMswipeIssuer}
                                  value={props.IssuerCategory}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value={"IssuerB"}>
                                    MSW-B
                                  </option>
                                  {/* <option value={"IssuerS"}>
                                    MSW-S
                                  </option>
                                  <option value={"IssuerSP"}>
                                    MSW-SP
                                  </option> */}
                                  <option value={"IssuerG"}>
                                    MSW-G
                                  </option>
                                  <option value={"IssuerB4B"}>
                                    MSW-B4B
                                  </option>
                                  <option value={"IssuerB4Virtual"}>
                                   MSW-B4B-V
                                  </option>
                                  {/* <option value={"IssuerW"}>
                                    WAL
                                  </option> */}
                                   <option value={"IssuerI"}>
                                    MSW-I
                                  </option>
                                  <option value={"IssuerT"}>
                                    MSW-T
                                  </option>
                                  <option value={"IssuerM"}>
                                    MSW-M
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                  </Fragment>
                )}
                 <div className="col-md-6">
                  <div className="form-group form-search">
                    <label>Email</label>
                    <div className="form-controls">
                    <input type="text"  maxlength="100"
                    value={props.email}
                    onChange={props.emailChangehandler}/>
                  </div>
                  </div>
                </div>
                          
                 
                  <div className="col-md-12">
                    <div className="form-group submit-field form-search">
                     
                      <button
                        id="searchBtn"
                        className="btn btn-large mr-19 "
                        onClick={props.searchclickhandler}
                      >
                        Search
                      </button>

                      <button
                        className="btn btn-outline "
                        onClick={props.clearsearchdata}
                      > 
                        Clear Search
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SearchGridInputHelper;
