import { useState, useEffect, Fragment } from "react";
import { Env } from "../../enviroment/environment";
import clientmanagmentObj from "../../services/client-managment.service";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import { Link } from "react-router-dom";
import ModelPopup from "../../helpers/ModelPopup";
import { useDispatch, useSelector } from "react-redux";
import { haspermissions } from "../permissions/PermissionTypes";
import SearchGridClientFees from "./SearchGridClientFees";
import AddClientFee from "./AddClientFee";
const API_URL = Env.ClientAdmin;

const ClientFees = () => {
    const { user: currentUser } = useSelector((state) => state.auth);
    const [confirmmodalShow, setCnfrmModalShow] = useState(false);
    const [calledfrom, setCalledfrom] = useState(false);
    const [updatefeeval, setupdatefeeval] = useState({});
    const [Content, setContent] = useState();
    const [DataSuccessful, setDataSuccessful] = useState(true);
    const [boloader, setBoLoader] = useState(false);
    const [donutChart, setDonutChart] = useState();
    const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
    if (currentUser) {
        haspermissions.CreateClient =
            currentUser?.user?.Permissions?.includes("Create Client");
        haspermissions.LedgerTransfer =
            currentUser?.user?.Permissions?.includes("Ledger Transfer");
    }

    useEffect(() => {
        setDataSuccessful(true);
    }, []);

    const goToClients = () => {
        setDataSuccessful(true);
    };
    const addClientFeeHandler = (callfrom) => {
        setCalledfrom(callfrom)
        setCnfrmModalShow(true);
    }
    return (
        <div className="col-xl-9 col-lg-12 bo-main-content">
            <BoLoader loading={boloader} />
            <h3>Fee Management</h3>
            <div className="row mt-20">
                <div
                    className="col-sm-6 col-md-3 col-xl-2"
                    onClick={() => addClientFeeHandler("add")}
                >
                    <div className="col-stats col-stats-action mb-40 col-stats-mbl">
                        <div data-bs-target="\feemanagment" data-bs-toggle="modal">
                            <span className="icon-load-card">
                                <span className="path1"></span>
                                <span className="path2"></span>
                                <span className="path3"></span>
                                <span className="path4"></span>
                                <span className="path5"></span>
                                <span className="path6"></span>
                            </span>

                            <br />
                            <Link to="#" className="pt-3">
                                Add Custom Fees
                                <span className="icon-bo-arrow"></span>

                            </Link>
                        </div>
                    </div>
                </div>

            </div>

            <Fragment>
                {
                    DataSuccessful && (
                        <SearchGridClientFees
                            methodname="getclients"
                            placeholdervalue="Search Client Fees"
                            headersdata={[
                                "CLIENT NAME",
                                "FEE TYPE",
                                "FEE COST",
                                "FEE PERCENTAGE",
                                "EDIT",
                                "DELETE",
                            ]}
                            apiurl={API_URL}
                            screenname="clients"
                            createclientpermission={haspermissions.CreateClient}
                            updateFee={(val) => addClientFeeHandler(val)}
                            updatevalues={(val) => setupdatefeeval(val)}
                            DataSuccessful={DataSuccessful}

                        />
                    )
                }
            </Fragment>

            <AddClientFee
                show={confirmmodalShow}
                onHide={() => setCnfrmModalShow(false)}
                onAddClient={() => {
                    setCnfrmModalShow(false);
                    setDataSuccessful(false);
                    setModalsuccessfullShow(true);
                }}
                calledfrom={calledfrom}
                updatevalues={updatefeeval}
            />

            <ModelPopup
                key={5}
                show={modalsuccessfullShow}
                onHide={() => setModalsuccessfullShow(false)}
                modelheading={""}
                showheader={"false"}
                className="smallPopup issueCardSmall"
                dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
                backdrop="static"
                keyboard={false}
                modelbody={[
                    <Fragment>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="icon-alert icon-alert-popup">
                                    <span className="icon-successful">
                                        <span className="path1"></span>
                                        <span className="path2"></span>
                                    </span>
                                </div>
                                <h2>Successful</h2>
                                <div className="mt-16">
                                    <div>
                                        <form>
                                            <div className="row">
                                                <div className="col-md-12">
                                                    {
                                                        calledfrom === "add" && (
                                                            <p>Fees Added Successfully</p>
                                                        )
                                                    }
                                                    {
                                                        calledfrom === "update" && (
                                                            <p>Fees Updated Successfully</p>
                                                        )
                                                    }
                                                    {
                                                        calledfrom === "delete" && (
                                                            <p>Fees Deleted Successfully</p>
                                                        )
                                                    }

                                                </div>
                                                <div className="col-md-12">
                                                    <div className="form-group submit-field mt-40 mb-0">
                                                        <Link
                                                            to="#"
                                                            className="btn btn-large w-100"
                                                            onClick={() => {
                                                                setModalsuccessfullShow(false);
                                                                goToClients();
                                                            }}
                                                        >
                                                            Done
                                                        </Link>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Fragment>,
                ]}
            />
        </div>
    );
};

export default ClientFees;
