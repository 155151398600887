import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const ReactDatePickerHelper = (props) => {
  return (
    <DatePicker
      selected={props.datevalue}
      onChange={props.onchangedate}
      // isClearable
      showMonthDropdown
      showYearDropdown
      dateFormat="MMMM dd,YYY"
      className="form-control"
      maxDate={props.maximumdate}
    />
  );
};

export default ReactDatePickerHelper;
