import React, { useState, useRef, Fragment, useEffect } from "react";
import { history } from "../../helpers/history";
import clientmanagmentObj from "../../services/client-managment.service";
import validator from "validator";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import ExportToExcel from "../superadmin/ExportToExcel";
import DialoguePopup from "../../helpers/DialoguePopup";
import ModelPopup from "../../helpers/ModelPopup";
import { Link } from "react-router-dom";
import BoLoader from "../../helpers/BoLoader";

const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status:"",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
const ChangePinGrid = (props) => {
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [clientId, setclientId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  const [Status, setStatus] = useState();
  const [dataset, setDataset] = useState();
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  //Dialouge Popup Error Messages
  const [modalShowDialouge, setModalShowDialouge] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  //Success Message
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [boloader, setBoLoader] = useState(false);
  const [userId, setUserId] = useState();

  const errorAndSuccessModl = (heading, body, Icon) => {
    setModalShowDialouge(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(Icon);
  };
  useEffect(() => {
    SearchClickHandler()
   
  }, []);
 
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date.getTime());
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const SearchClickHandler = (e,isFromUseEffect) => {
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
    if (clientId || clientIdVal !== "")
      SearchGridInputData[0].ClientId = !clientId ? clientIdVal : clientId;
    else SearchGridInputData[0].ClientId = "";
    setModalShow(false);
    setModalShow(false);
    setSearchDisabled(false);
    clientmanagmentObj
      .searhGridChangePin(
        name,
        SearchGridInputData[0].ClientId.toString(),
        dateFrom || new Date("0001-01-01"),
        dateTo || new Date("0001-01-01"),
        SearchGridInputData[0].startIndex,
        SearchGridInputData[0].Status,
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp?.data && resp?.data?.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.result?.pageModel?.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload?.result?.totalCount);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload.result.pageModel
              );
              setSearchData(appendedData);
             setDataset(appendedData);
            } else {
              setSearchData(resp.data.payload?.result?.pageModel);
             setDataset(resp.data.payload?.result?.pageModel);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);

            SearchGridInputData[0].scrolled = false;
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  const showClientDetails = (id) => {
  setCnfrmModalShow(true);
  setUserId(id);
  };
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
 
 
  const emptyClientId = (status) => {
    setclientId("");
    setClientIdVal("");
    setStatus(status);
    setStatus(status);
  };

  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        cardHolderName,
        tokenId,
        cardNumber,
        clientName,
        pin,
        date,
        status,
        cardUserId,
      } = data; //destructuring

      let cardNumberResult = cardNumber?.replace(cardNumber?.substring(4,12), "-xxxx-xxxx-")
      return (
        <tr key={index}>
          
          <td style={fontStyle}>{cardHolderName}</td>
          <td style={fontStyle}>{tokenId}</td>
          <td style={fontStyle}>{cardNumberResult}</td>
          {/* <td style={fontStyle}>{cardNumber}</td> */}
          <td style={fontStyle}>{clientName}</td>
          <td style={fontStyle}>{pin}</td>
         <td><DateFormat date={date} /></td> 
          <td style={fontStyle}>{status}</td>
              <td>
              <span
                className="icon-show"
                onClick={() => showClientDetails(cardUserId)}
              ></span>
            </td>
           
         
        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setclientId("");
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    setStatus("");
    SearchGridInputData[0].Status=""
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setClientIdVal("");
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
const acceptPin=(isNotify)=>{
    setBoLoader(true);
    setCnfrmModalShow(false);
    clientmanagmentObj
    .pinChangeNotify(userId,isNotify).then((response)=>{
        setBoLoader(false);
if(response)
setModalsuccessfullShow(true);
    }).catch((error)=>{
        setBoLoader(false);
        let msgBody = error?.response?.data?.message
          ? error?.response?.data?.message
          : "Error Occured while Registering client";
        let errIcon = "icon-error";
        errorAndSuccessModl("Error", msgBody, errIcon);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            if (resp) {
                acceptPin();
              setBoLoader(false);
            } else {
              setBoLoader(false);
            }
          }
        })();
    });
}
  return (
    <div className="mt-0 mt-sm-4">
      <div className={classContent} id="myTabContent2">
      <BoLoader loading={boloader} />
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          {/* no client found  */}
          <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src="../../assets/images/icons/clients-empty.svg" /> */}
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
          <Fragment>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row row-search">
                <div className="col-xl-12 col-12">
                  <div className="form-group">
                    <div className="form-controls">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.placeholdervalue}
                        onClick={handleInputSearchClick}
                        disabled={SearchInputDisabled}
                      />
                      <span className="icon-search _ico"></span>
                      {/* search box  */}
                      <div
                        className="bo-cardsearch"
                        style={{ display: modalShow ? "" : "none" }}
                      >
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="col-md-4">
                            <img
                              className="cross-icon cross-modal"
                              src={crossImgs}
                              aria-label="Close"
                              onClick={HideSearchPopupDiv}
                              alt=""
                            />
                            </div>
                            <div className=" col-md-12 form-group form-search mr-20">
                              <label>Name</label>
                              <div className="form-controls mr-20">
                                <input
                                  type="text"
                                  value={name}
                                  onChange={nameChangeHandler}
                                  className="form-control"
                                  maxLength={20}
                                />
                              </div>
                            </div>
                          </div>
                          
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date From</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateFrom}
                                  onchangedate={dateFromHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date To</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateTo}
                                  onchangedate={dateToHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                  
                  <div className="form-controls"></div>
                </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field form-search">
                              <button
                                id="searchBtnClients"
                                className="btn btn-large mr-19"
                                onClick={SearchClickHandler}
                              >
                                Search
                              </button>
                              <button
                                className="btn btn-outline"
                                onClick={ClearSearchData}
                              >
                                Clear Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="help-block d-none"></span>
                  </div>
                </div>
              </div>
            </form>
          </Fragment>
        </div>

        {searchGridSuccess && (
          <div className="table-wrap" ref={searchTbl}>
            <table
              className="table table-borderless bo-table table-cards"
              onScroll={tblScrollHandler}
              onWheel={tblScrollHandler}
            >
              <thead>
                <tr>{renderTableHeader()}</tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
        )}
        {loadingData ? (
          <div className="loading-more">
            <div className="loader">
              <div></div>
            </div>
            <p>loading data</p>
          </div>
        ) : totalCount === 0 ? (
          <div className="d-flex align-content-center justify-content-center  no-clients-msg">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
              {/* <img src={emptyclient} /> */}
              <h3>Empty Change Pin Listing</h3>
              <h6>There is no Pin(s) available for now.</h6>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <DialoguePopup
        show={modalShowDialouge}
        onHide={() => setModalShowDialouge(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      
      />
      <ModelPopup
        key={1}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Pin Changed Submitted Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                props?.refereshpage(true);
                                window.location.reload();
                                //SearchClickHandler();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={1}
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Notify Card Holder</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Do you want to notify Cardholder?</p>
                        </div>
                        <div className="col-md-12">
                         <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
                            <button
                            type="button"
                            className="btn btn-outline mr-19"
                             
                              onClick={() => {
                                setCnfrmModalShow(false);
                                acceptPin(true);
                              }}
                           >
                              Yes
                           </button>
                            <button
                             type="button"
                             className="btn btn-large"
                              onClick={() => {
                                setCnfrmModalShow(false);
                                acceptPin(false);
                              }}
                            >
                              NO
                              </button>
                              </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default ChangePinGrid;
