
import React, { useState,useEffect} from "react";
import { useDispatch} from "react-redux";
import CountDownTimer from "./countDownTimer";
import { history } from "../../helpers/history";
import BoLoader from "../../helpers/BoLoader";
import DialoguePopup from "../../helpers/DialoguePopup";
import VerificationInput from "react-verification-input";
import { login } from "../../actions/auth";

let interval = "";

const TwoFaLogin = props => {
    const dispatch = useDispatch();
  const [modalShow, setModalShow] = useState(false);
  const [disable2faButton, setDisable2faButton] = useState("disabled");
  const [boloader, setBoLoader] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [showtimer, settimerflag] = useState(true);
useEffect(() => {
    document.getElementById("timerbtn").click();
    interval = setInterval(() => {
        let mint = document.getElementById("mints");
        let secs = document.getElementById("secs");
        if (
          mint &&
          mint.textContent === "00" &&
          secs &&
          secs.textContent === "00"
        )
          setDisable2faButton("");
      }, 10000);
}, [])

  const getOTP = e => {
    if (e.length === 6) {
        setBoLoader(true);
        let emailcode=parseInt(e);
        let smscode=parseInt(e);
      dispatch(login(props.email,props.password,props.rememberMe,emailcode,smscode))
      .then((res) => {
        setBoLoader(false);
       history.push("/dashboard");
      })
      .catch((error) => {
        setBoLoader(false);
        let msgBody = error?.response?.data?.message
        ? error?.response?.data?.message
        : "You have entered an invalid code.";
      let errIcon = "icon-error";
      errorAndSuccessModl("Error", msgBody, errIcon);
      });
    }
  };
  const Resend2FA = () => {
    setDisable2faButton("disabled");
document.getElementById("timerbtn").click();
 props.resendcode();
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  return (
    <div className="row">
      <BoLoader loading={boloader} />
      <div className="col-md-12 mb-40">
        <h3>    Verify Your Email</h3>
      </div>
      <div className="col-sm-12 col-md-10">
        <div className="col-md-12">
          <h6 className="ms-lg4">   Enter 6 digits code sent to your email </h6>
          <div className="form-group authentications">
            <label htmlFor="email"></label>
            <div className="form-controls d-flex">
              <VerificationInput
                // removeDefaultStyles
                placeholder=""
                autoFocus={true}
                onChange={e => getOTP(e)}
                classNames={{
                  container: "container",
                  character: "character",
                  characterInactive: "character--inactive",
                  characterSelected: "character--selected",
                }}
              />
            </div>
            {showtimer && (
                  <div className="col-md-12 d-flex align-items-center mt-16 m-4">
                    {" "}
                    <CountDownTimer />
                    {/* </b>
                    </p> */}
                  </div>
                )}

                {showtimer && (
                  <div className="col-md-6 mt-40">
                    <div className="form-group submit-field">
                      <button
                        id="Resend2faBtn"
                        type="button"
                        className="btn btn-large"
                        onClick={Resend2FA}
                        disabled={disable2faButton}
                      >
                        Resend Code
                      </button>
                    </div>
                  </div>
                )}
          </div>
         
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
        hideallpopups={() => setModalShow(false)}
      />
    </div>
  );
};

export default TwoFaLogin;
