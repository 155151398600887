import { useState, useEffect, useRef, Fragment } from "react";
import clientmanagmentObj from "../../services/client-managment.service";
import { history } from "../../helpers/history";
import BoLoader from "../../helpers/BoLoader";
import DialoguePopup from "../../helpers/DialoguePopup";
import Input from "react-validation/build/input";
import CheckButton from "react-validation/build/button";
import Form from "react-validation/build/form";
import PhoneInput from "react-phone-input-2";
import RefreshToken from "../../helpers/RefreshToken";
import ModelPopup from "../../helpers/ModelPopup";
import {Link} from "react-router-dom";
import roleManagmentObj from "../../services/role-managment.service";
import * as EmailValidator from "email-validator";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import { subYears } from "date-fns";
let obj = [];

var fontStyle = {
  textTransform: "uppercase",
};


const ProfileSuperAdminUser = (props) => {

  //Password states
// const [Password, setPassword] = useState("");
// const [confirmpassword, setConfirmPassword] = useState("");
// const [passwordShown, setPasswordShown] = useState(false);
// const [cPasswordShown, setCPasswordShown] = useState(false);
// const [showHidePassClass, setshowHidePassClass] = useState("icon-hidden");
// const [showHideCPassClass, setshowHideCPassClass] = useState("icon-hidden");
  const [FirstName, setFirstName] = useState("");
  const [LastName, setLastName] = useState("");
  const [DateofBirth, setDateofBirth] = useState();

  const [PhoneNumber, setphoneNumber] = useState("");
  const [Email, setemail] = useState("");
  const [PermissionsList, setPermissionsList] = useState([]);
  const [AssignedPermissions, setAssignedPermissions] = useState([]);

  const [Role, SetRole] = useState("Super admin");
  const [Address, setAddress] = useState("");
  const [isValidPhoneNumber, setisValidPhoneNumber] = useState(true);
 
  const [Permissions, setPermissions] = useState([]);
 
//  const [clientId, setClientId] = useState(props.id);
  const [userId, setuserId] = useState(props.userId);
  const [Status, setStatus] = useState("Active");

  //Loader
  const [boloader, setBoLoader] = useState(false);
  //Error/Successfull popup showings
  const [modalShow, setModalShow] = useState(false);
  const [modelhdng, setMdlHdng] = useState("");
  const [modelbdy, setMdlBdy] = useState("");
  const [MdlIcon, setMdlIcon] = useState("");
  //UseRef
  const form = useRef();
  const checkBtn = useRef();
  const [showPhoneErr, setshowPhoneErr] = useState("d-none");
  //Modals
  const [modaldeactivateshow, setModaldeactivateshow] = useState(false);
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [modalsuccesssave, setModalsuccesssave] = useState(false);
  const [showPhoneMinimumErr, setshowPhoneMinimumErr] = useState("d-none");
  const [showDOBErr, setShowDOBErr] = useState("d-none");
  const [modalsuccessfulltheme, setModalsuccesstheme] = useState(false);
    //Suspend Unsuspend User States:
    const [IsActive, setIsActive] = useState(true);
    const [IsEmailConfirmed, setIsEmailConfirmed] = useState(true);
    const [ClientId, setClientId] = useState(1);
    const [Id, setId] = useState();



    // useEffect(() => {
    //   let params="";
    // if(props?.calledfrom==="client")
    //  params="client admin";
    // else params="super admin"
    //     clientmanagmentObj
    //       .getPermissions(params)
    //       .then(response => {
    //         if (response && response.data && response.data.payload) {
    //           const lst_permissions = response.data.payload.result.map(data => {
    //             return {
    //               UserId: data.id,
    //               ClaimType:data.claimType,
    //               claimValue: data.claimValue,
    //               checked: data.isAssigned,
    //             };
    //           });
    //           setPermissionsList(lst_permissions);
    //         }
    //       })
    //       .catch(error => {
    //         (async () => {
    //           let resp = await RefreshToken(
    //             error && error.response && error.response.status
    //           );
    //           if (resp) {
    //             window.location.reload();
    //           }
    //         })();
    //       });
    //   }, []);
    // const required = (value) => {
    //     if (!value) {
    //       document.getElementById("Password").classList.add("has_error");
    //       return <span className="help-block">Password is required</span>;
    //     } else document.getElementById("Password").classList.remove("has_error");
    //   };
      // const prequired = (value) => {
      //   if (!value) {
      //     document.getElementById("Password").classList.add("has_error");
      //     return <span className="help-block">Password is required</span>;
      //   } else document.getElementById("Password").classList.remove("has_error");
      // };
      // const crequired = (value) => {
      //   if (!value) {
      //     document.getElementById("cPassword").classList.add("has_error");
      //     return <span className="help-block">Password is required</span>;
      //   } else document.getElementById("cPassword").classList.remove("has_error");
      // };
      // let passwordValue = "";
      // const vpassword = (value) => {
      //   passwordValue = value;
      //   if (value.length < 6 || value.length > 40) {
      //     document.getElementById("Password").classList.add("has_error");
      //     return (
      //       <div className="help-block" role="alert">
      //         The password must be between 6 and 40 characters.
      //       </div>
      //     );
      //   }
      // };
      // const vcpassword = (value) => {
      //   if (value.length < 6 || value.length > 40) {
      //     document.getElementById("cPassword").classList.add("has_error");
      //     return (
      //       <div className="help-block" role="alert">
      //         The password must be between 6 and 40 characters.
      //       </div>
      //     );
      //   } else if (value !== passwordValue) {
      //     document.getElementById("cPassword").classList.add("has_error");
      //     return (
      //       <span className="help-block" role="alert">
      //         The password and confirm password must match
      //       </span>
      //     );
      //   }
      // };
  const firstNameRequired = value => {
    if (!value) {
      document.getElementById("firstname").classList.add("has_error");
      return <span className="help-block">First Name is required!</span>;
    } else document.getElementById("firstname").classList.remove("has_error");
  };
  const lastNameRequired = value => {
    if (!value) {
      document.getElementById("lastname").classList.add("has_error");
      return <span className="help-block">Last Name is required!</span>;
    } else document.getElementById("lastname").classList.remove("has_error");
  };
  const emailRequired = value => {
    if (!value) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Email is required!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const checkValidEail = emailAdress => {
    let value = EmailValidator.validate(emailAdress);
    return value;
  };
  const emailFormatRequired = value => {
    let verifyemailcheck = checkValidEail(value);
    if (!verifyemailcheck) {
      document.getElementById("email").classList.add("has_error");
      return <span className="help-block">Please enter valid email!</span>;
    } else document.getElementById("email").classList.remove("has_error");
  };
  const addressRequired = value => {
    if (!value) {
      document.getElementById("addressroles").classList.add("has_error");
      return <span className="help-block">Address is required!</span>;
    } else
      document.getElementById("addressroles").classList.remove("has_error");
  };
  const requiredDob = (value) => {
    if (!value) {
      document.getElementById("dobsuperadmin").classList.add("has_error");
      setShowDOBErr("");
      return false;
    } else {
      document.getElementById("dobsuperadmin").classList.remove("has_error");
      setShowDOBErr("d-none");
      return true;
    }
  };
  // const showHideCPassHandler = () => {
  //   setCPasswordShown(cPasswordShown ? false : true);
  //   setshowHideCPassClass(cPasswordShown ? "icon-hidden" : "icon-show");
  // };
  // const onChangeConfirmPassword = (e) => {
  //   const confirmPassword = e.target.value;
  //   setConfirmPassword(confirmPassword);
  // };
  // const onChangePassword = (e) => {
  //   const password = e.target.value;
  //   setPassword(password);
  // };
  // const showHidePassHandler = () => {
  //   setPasswordShown(passwordShown ? false : true);
  //   setshowHidePassClass(passwordShown ? "icon-hidden" : "icon-show");
  // };
  useEffect(() => {
    setTimeout(() => {
      setuserId(props?.userId);
    }, 2000);
    userDetails();
  }, []);
  useEffect(() => {
    if (PhoneNumber?.length === 0) {
      setisValidPhoneNumber(true);
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else if (PhoneNumber?.length < 3) {
      setisValidPhoneNumber(false);
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    } else {
      setisValidPhoneNumber(true);
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneMinimumErr("");
    }
  }, [PhoneNumber]);
  const userDetails = () => {
    setBoLoader(true);
    roleManagmentObj
      .getadmindetail(userId)
      .then((response) => {
        if (response && response.data && response.data.message === "Success") {
          setBoLoader(false);
          let res = response.data.payload.result;
          if (res) {
            setFirstName(res.firstName);
            setLastName(res.lastName);
            setemail(res.email);
            setAddress(res.address);
            setId(res.id)
            
            setphoneNumber(res.phoneNumber);
           
            setStatus(res.isActive ?"Active":"Suspended")
            const lst_permissions = res.permissions.map(data => {
                return {
                  UserId: String(data.id),
                  ClaimType: data.claimType,
                  claimValue: data.claimValue,
                  checked: data.isAssigned,
                };
              });
              setAssignedPermissions(lst_permissions);
              const assign_perm= lst_permissions.filter(
                i => i.checked === true
              );
              setPermissions(assign_perm)

              if (res.dateofBirth) {
                let DOB = new Date(res.dateofBirth);
                setDateofBirth(DOB);
                }
          }
        }
      })
      .catch((error) => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            userDetails();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      });
  };

  const onChangeFirstName = e => {
    const firstname = e.target.value;
    setFirstName(firstname)
  };
  const onChangeLastName = e => {
    const lastname = e.target.value;
    setLastName(lastname);
    
  };
  const requiredPhoneNo = value => {
    if (PhoneNumber.length < 3) setisValidPhoneNumber(false);
    else setisValidPhoneNumber(true);
    if (!value) {
      document.getElementById("phonenumber").classList.add("has_error");
      setshowPhoneErr("");
      return false;
    } else {
      document.getElementById("phonenumber").classList.remove("has_error");
      setshowPhoneErr("d-none");
      return true;
    }
  };
 
  const onChangeEmail = e => {
    const Email = e.target.value;
    setemail(Email);
  };
  
  const onChangeaddress = e => {
    const Address = e.target.value;
    setAddress(Address);
  };
  const onChangeDOB = (value) => {
    
    let DOB = value;
    if (DOB) DOB = new Date(DOB);
    setDateofBirth(DOB);
    requiredDob(value);
  };
  //UpdateUser
  const saveUser = (e) => {
    
   // e.preventDefault();
    form.current.validateAll();
    let mobile = requiredPhoneNo(PhoneNumber);
    // let  IsActive=true;
    // let IsEmailConfirmed=true;
    // let ClientId=1
    if (checkBtn.current.context._errors.length === 0 && mobile) {
      setBoLoader(true);
      roleManagmentObj
        .addUpdateUserRoles(
          props?.clientId? props?.clientId:1,
          !props?.clientId===1 ?Role :"Client admin",
            FirstName,
            LastName,
            Email,
            Address,
            DateofBirth,
            PhoneNumber,
            "",
            IsActive,
            IsEmailConfirmed,
            Permissions,
            Id
        )
        .then((response) => {
          setBoLoader(false);
          if (response && response.data && !response.data.isError) {
        //     setid("");
        //     setName("");
        //     setEmail("");
        //     setAddress("");
        //     setPointOfContract("");
        //     setPhoneNumber("");
        //     setPrefundedAmount();
        //     setclientStatusId();
        //    // history.goBack();
           setModalsuccesssave(true);
           //history.push("clients");
          }
        })
        .catch((err) => {
          setBoLoader(false);
          let msgBody = err.response.data.title
            ? err.response.data.title
            : "Error Occured while Registering user";
          let errIcon = "icon-error";
          errorAndSuccessModl("Error", msgBody, errIcon);
        });
    }
  };
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const onCancel = () => {
    history.goBack();
    //history.push("clients");
  };
  
  const maxLengthCheck = (object) => {
    if (object.target.value.length > object.target.maxLength) {
      object.target.value = object.target.value.slice(
        0,
        object.target.maxLength
      );
    }
  };
  const onSuspendClient = () => {
    setIsEmailConfirmed(false);
    setIsActive(false);
    setBoLoader(true);
    roleManagmentObj
    .suspendUser(
      Id,true
    )
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && !res.data.isError) {
          setModaldeactivateshow(false);
          setModalsuccessfullShow(true);
        }
      })
      .catch((err) => {
        setBoLoader(false);
        setModaldeactivateshow(false);
      });
  };
  const onUnsuspendClient = () => {
    setIsEmailConfirmed(true);
    setIsActive(true);
    setBoLoader(true);
    roleManagmentObj
    .unSuspendUser(
      Id,false
    )
      .then((res) => {
        setBoLoader(false);
        if (res && res.data && !res.data.isError) {
          setModaldeactivateshow(false);
          setModalsuccessfullShow(true);
        }
      })
      .catch((err) => {
        setBoLoader(false);
        setModaldeactivateshow(false);
      });
  };
  const openModal = () => {
    setModaldeactivateshow(true);
  };
  const goToClients = () => {
    history.goBack();
  };
  const onChangePhoneNumber = e => {
    requiredPhoneNo(e);
    setphoneNumber(e);
  };
  const handleSingleCheck = e => {
    obj=Permissions
    if (e.target.checked === true) {
      obj = obj.concat({
        UserId: e.target.id,
        ClaimType: e.target.attributes.ClaimType.value,
        ClaimValue: e.target.attributes.claimvalue.value,
        checked:e.target.checked
      });
      setPermissions(obj);
      const index = AssignedPermissions.findIndex((ch) => String(ch.UserId) === e.target.id);
      AssignedPermissions[index].checked=e.target.checked
    }
    
    if (e.target.checked === false) {
      obj = Permissions.filter(
        i => i.ClaimType !== e.target.attributes.ClaimType.value
      );
      setPermissions(obj);
      const index = AssignedPermissions.findIndex((ch) => String(ch.UserId) === e.target.id);
      AssignedPermissions[index].checked=e.target.checked
    }
  };
  return (
    <div
      className="tab-pane fade show active"
      id="profile"
      role="tabpanel"
      aria-labelledby="profile-tab"
    >
      <div className="row">
        <BoLoader loading={boloader} />
        {/* <div className="col-6">
          <div className="logo-large">
            {logoUrl !== "" ? (
              <img src={logoUrl} alt="clientProfile" />
            ) : (
              <span className="td-logo">
                <h1 style={fontStyle}>{clientName.charAt(0)}</h1>
              </span>
            )}
          </div>
        </div> */}
        {Status === "Active" && (
          <div className="col-12" onClick={openModal}>
            <div className="form-group submit-field d-flex justify-content-end mb-0">
              <a className="btn btn-outline btn-outline-large">
                Suspend User
              </a>
            </div>
          </div>
        )}
        {Status === "Suspended" && (
          <div className="col-12" onClick={openModal}>
            <form>
              <div className="form-group submit-field d-flex justify-content-end mb-0">
                <a className="btn btn-outline btn-outline-large">
                  Unsuspend User
                </a>
              </div>
            </form>
          </div>
        )}

        <div className="col-md-8">
          <div>
            <Form onSubmit={saveUser} ref={form}>
              <div className="row mt-40">
              <div className="col-md-6" id="firstname">
                  <div className="form-group">
                    <label>First Name*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="Name"
                        onChange={onChangeFirstName}
                        validations={[firstNameRequired]}
                        value={FirstName}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="lastname">
                  <div className="form-group">
                    <label>Last Name*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="Name"
                        onChange={onChangeLastName}
                        validations={[lastNameRequired]}
                        value={LastName}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="email">
                  <div className="form-group">
                    <label>Email*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control no-icon"
                        name="email"
                        maxLength="50"
                        onChange={onChangeEmail}
                        validations={[
                          emailRequired,
                         
                          emailFormatRequired,
                        ]}
                        value={Email}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="addressroles">
                  <div className="form-group">
                    <label>Address*</label>
                    <div className="form-controls">
                      <Input
                        type="text"
                        className="form-control left-icon"
                        onChange={onChangeaddress}
                        value={Address}
                        validations={[addressRequired]}
                        maxLength="40"
                      />
                      <span className="icon-home _ico"></span>
                    </div>
                  </div>
                </div>
                <div className="col-md-6" id="phonenumber">
                  <div className="form-group">
                    <label>Phone Number*</label>
                    <div className="form-group">
                      <PhoneInput
                        className=""
                        country={"gb"}
                        onChange={onChangePhoneNumber}
                        value={PhoneNumber}
                      />
                      <span className={"help-block " + showPhoneErr}>
                        Phone No is required!
                      </span>
                      {!isValidPhoneNumber && (
                        <div id="id_minimumphonelength">
                          <span className={"help-block " + showPhoneMinimumErr}>
                            Minimum three number are required!
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                <div className="col-md-6">
                            <div className="form-group mb-3" id="dobsuperadmin">
                              <label>Date Of Birth* </label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={DateofBirth}
                                  onchangedate={onChangeDOB}
                                  maximumdate={subYears(new Date(), 18)}
                                />
                                <span className="icon-calender _ico"></span>
                                <span className="icon-round icon-calender-mini"></span>
                                <span className="fs-12 clr-submarine mt-2 d-block">
                                  Age should be greater than 18 years
                                </span>
                                {/* <span className="icon-round icon-calender-mini"></span> */}
                                <span className={"help-block " + showDOBErr}>
                                  DOB is required!
                                </span>
                              </div>
                            </div>
                          </div>
                          {/* <div class="col-md-6">
                    <div className="form-group" id="Password">
                      <label htmlFor="password">Password*</label>
                      <div class="form-controls">
                        <Input
                          type={passwordShown ? "text" : "password"}
                          className="form-control"
                          name="password"
                          value={Password}
                          onChange={onChangePassword}
                          validations={[prequired, vpassword]}
                        />
                        <span class="icon-lock _ico"></span>
                        <span
                          className={"show-password " + showHidePassClass}
                          onClick={showHidePassHandler}
                        ></span>
                      </div>
                    </div>
                  </div> */}
                  {/* <div class="col-md-6" id="cPassword">
                    <div className="form-group">
                      <label htmlFor="confirmPassword">Confirm Password*</label>
                      <div class="form-controls">
                        <Input
                          type={cPasswordShown ? "text" : "password"}
                          className="form-control"
                          name="confirmPassword"
                          value={confirmpassword}
                          onChange={onChangeConfirmPassword}
                          validations={[crequired, vcpassword]}
                        />
                        <span class="icon-lock _ico"></span>
                        <span
                          className={"show-password " + showHideCPassClass}
                          onClick={showHideCPassHandler}
                        ></span>
                      </div>
                    </div>
                  </div>    */}
                <div className="col-md-6" id="permissions">
                <label>Permissions</label>
                <br/>
                <br/>
                <br/>

                  <ul className="checkbox-block-ul mt--24">
                    {AssignedPermissions.map(PermissionsList => {
                      return (
                        <li>
                          <div className="bo-checkbox bo-checkbox-round">
                            <input
                              key={PermissionsList.UserId}
                              onChange={handleSingleCheck}
                              type="checkbox"
                              id={PermissionsList.UserId}
                              ClaimType={PermissionsList.ClaimType}
                              ClaimValue={PermissionsList.ClaimType}
                              checked={PermissionsList.checked}
                            />
                            <label htmlFor={PermissionsList.UserId}>
                              {PermissionsList.ClaimType}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                  <span className={"help-block " + showDOBErr}>
                    Permission is required!
                  </span>
                </div>
                {/* <div className="col-md-6" id="permissions">
                <label>Assigned Permissions*</label>
                <br/>
                <br/>
                <br/>
                  <ul className="checkbox-block-ul mt--24">
                    {AssignedPermissions.map(PermissionsList => {
                      return (
                        <li>
                          <div className="bo-checkbox bo-checkbox-round">
                            <input
                              key={PermissionsList.UserId}
                              onChange={handleSingleCheck}
                              type="checkbox"
                              id={PermissionsList.UserId}
                              ClaimType={PermissionsList.ClaimType}
                              ClaimValue={PermissionsList.claimValue}
                              
                              checked={PermissionsList.checked}
                            />
                            <label htmlFor={PermissionsList.UserId}>
                              {PermissionsList.ClaimType}
                            </label>
                          </div>
                        </li>
                      );
                    })}
                  </ul>
                 
                </div> */}
                <div className="col-md-12">
                  <div className="form-group submit-field d-flex mt-16 mb-0">
                    <a className="btn btn-large mr-19" onClick={saveUser}>
                      Save
                    </a>
                    <a className="btn btn-outline" onClick={onCancel}>
                      Cancel
                    </a>
                  </div>
                </div>
              </div>
              <CheckButton style={{ display: "none" }} ref={checkBtn} />
            </Form>
          </div>
        </div>
      </div>
      <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
      <ModelPopup
        key={3}
        show={modaldeactivateshow}
        onHide={() => setModaldeactivateshow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        modelbody={[
          <Fragment>
            <div class="row">
              <div class="col-md-12">
                <h2>Confirmation</h2>
                <div class="mt-16">
                  <div>
                    {Status === "Suspended" ? (
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Are you sure you want to unsuspend user?</p>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              <Link
                                to="#"
                                className="btn btn-large"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  setModaldeactivateshow(false);
                                  // localStorage.setItem("activeInactiveSendmoney", "true");
                                  //  setpermission = localStorage.getItem("activeInactiveSendmoney");
                                }}
                              >
                                No
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-outline"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  onUnsuspendClient();
                                  // localStorage.setItem("activeInactiveSendmoney", "false");
                                  // setpermission = localStorage.getItem("activeInactiveSendmoney");
                                  //  document.getElementById("dashboardNavLink").click();
                                }}
                              >
                                Yes
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    ) : (
                      <form>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Are you sure you want to suspend user?</p>
                          </div>
                          <div class="col-md-12">
                            <div class="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              <Link
                                to="#"
                                className="btn btn-large"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  setModaldeactivateshow(false);
                                  //localStorage.setItem("activeInactiveSendmoney", "false");
                                  //setpermission = localStorage.getItem("activeInactiveSendmoney");
                                }}
                              >
                                No
                              </Link>
                              <Link
                                to="#"
                                className="btn btn-outline"
                                data-bs-dismiss="modal"
                                onClick={() => {
                                  onSuspendClient(false);
                                  // setModaldeactivateshow(false);
                                  // localStorage.setItem("activeInactiveSendmoney", "true");
                                  // setpermission = localStorage.getItem("activeInactiveSendmoney");
                                  // document.getElementById("dashboardNavLink").click();
                                }}
                              >
                                Yes
                              </Link>
                            </div>
                          </div>
                        </div>
                      </form>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      <ModelPopup
        key={1}
        show={modalsuccessfullShow}
        onHide={() => {setModalsuccessfullShow(false); document.getElementById("clientNavLink").click();}}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          {Status === "Active" ? (
                            <p>User Suspended Successfully</p>
                          ) : (
                            <p>User Unsuspended Successfully</p>
                          )}
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                userDetails();
                                // document.getElementById("rolesNavLink").click();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
      
      
       <ModelPopup
        key={5}
        show={modalsuccesssave}
        onHide={() => setModalsuccesssave(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>User Updated Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccesssave(false);
                                goToClients();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default ProfileSuperAdminUser;
