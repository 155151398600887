import { useState, useEffect } from "react";
import SearchGrid from "../../helpers/SearchGrid";
import { Env } from "../../enviroment/environment";

import ClientProfile from "../superadmin/ClientProfile";
import ProfileSuperAdminUser from "./ProfileSuperAdminUser";
const API_URL = Env.CardsApiUrl;

const UpdateSuperAdminUser = (props) => {
  const [content, setContent] = useState(<p>No Record Found</p>);
  const [AllClientClassName, setAllClientClassName] = useState(
    "nav-link active d-flex align-items-center"
  );
  const [NewClientClassName, setNewClientClassName] = useState("nav-link d-flex align-items-center");
  const [AllClientAreaSelected, setAllClientAreaSelected] = useState("false");
  const [NewClientsAreaSelected, setNewClientsAreaSelected] = useState("false");
  const allClients = () => {
    setAllClientClassName("nav-link active d-flex align-items-center");
    setAllClientAreaSelected("true");
    setNewClientClassName("nav-link d-flex align-items-center");
    setNewClientsAreaSelected("false");

    btnProfile();
  };

  useEffect(() => {
    btnProfile();
  }, []);

  const btnProfile = () => {
    setContent(<ProfileSuperAdminUser clientId={props.history.location.state.clientId} userId={props.history.location.state.id} 
      clientStatus={props.history.location.state.clientStatus} calledfrom={props.history.location.state.calledfrom}
    />);
  };
  return (
    <div className="col-lg-9 col-md-12 bo-main-content ">
      <div className="bo-tabs-mbl">
        <ul
          className="nav nav-tabs nav-tabs-profile"
          id="myTab2"
          role="tablist"
        >
          <li className="nav-item d-flex align-items-center" role="presentation">
            <button
              className={AllClientClassName}
              // className="nav-link active d-flex align-items-center"
              id="profile-tab"
              data-bs-toggle="tab"
              onClick={allClients}
              data-bs-target="#profile"
              type="button"
              role="tab"
              aria-controls="profile"
              // aria-selected="true"
              aria-selected={AllClientAreaSelected}
            >
              <span className="icon-client"></span>
              Profile info
            </button>
          </li>
          
        </ul>
      </div>
      <div className="bo-main-content-sub">
        <div className="tab-content" id="myTabContent2">
          {content}
        </div>
      </div>
    </div>
  );
};

export default UpdateSuperAdminUser;
