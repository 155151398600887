import React, { useState, useRef, Fragment, useEffect, useCallback } from "react";
import {useSelector } from "react-redux";
import { history } from "../../helpers/history";
import DateFormat from "../../helpers/DateFormat";
import crossImgs from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import ReactDatePickerHelper from "../../helpers/ReactDatePickerHelper";
import GetAllClients from "../../helpers/GetAllClients";
import { haspermissions } from "../permissions/PermissionTypes";
import superClientServiceObj from "../../services/super-client.service";
import ExportToExcel from "../superadmin/ExportToExcel";
import EditSuperClient from "./EditSuperClient";
import ModelPopup from "../../helpers/ModelPopup";
import { Link } from "react-router-dom";
const SearchGridInputData = [
  {
    Name: "",
    ClientId: "",
    DateFrom: "",
    DateTo: "",
    startIndex: 1,
    scrolled: false,
    isFirstTime: true,
    Status: "",
    TokenId: "",
    IssuerType: "",
  },
];
var fontStyle = {
  textTransform: "uppercase",
};
const SearchGridSuperClient = (props) => {
  const { user: currentUser } = useSelector((state) => state.auth);
  const [modalShow, setModalShow] = useState(false);
  const [name, setCompanyName] = useState("");
  const [clientId, setclientId] = useState("");
  const [dateFrom, setDateFrom] = useState("");
  const [dateTo, setDateTo] = useState("");
  const searchTbl = useRef();
  const [loadingData, setLoadingData] = useState(false);
  const [searchData, setSearchData] = useState();
  const [searchGridSuccess, setSearchGridSuccess] = useState(false);
  const [SuperClientClassName, setSuperClientClassName] = useState("nav-link");
  const [NewClientClassName, setNewClientClassName] = useState("nav-link");
  const [SuperClientAreaSelected, setSuperClientAreaSelected] = useState("false");
  const [SearchInputDisabled, setSearchDisabled] = useState(false);
  const [clientIdVal, setClientIdVal] = useState("");
  const [totalCount, setTotalCount] = useState(0);
  const [classContent, setClassContent] = useState("tab-content");
  const [Status, setStatus] = useState();
  const [Currency, setCurrency] = useState();
  const [dataset, setDataset] = useState();
  const [IssuerCategory, setIssuerCategory] = useState("");
  const [isShown, setIsShown] = useState(0);
  const [editclient,setEditclient]=useState({
      id:0,name:"",email:"",phoneNumber:"",userId:""
    }
  );
  const [modalShowEditSuperClient, setModalShowEditSuperClient] = useState(false);
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const observer = useRef();
  const searchTblRef = useRef();
  if (currentUser) {
    haspermissions.InternalAccounts = currentUser?.user?.Permissions?.includes("Internal Accounts");
  }
  const lastElementRef = useCallback(node => {
    if (loadingData) return
    if (observer.current) observer.current.disconnect()
    observer.current = new IntersectionObserver(entries => {
      if (entries[0].isIntersecting) {
        tblScrollHandler()

      }
    })
    if (node) observer.current.observe(node)
  }, [loadingData, searchData])
  useEffect(() => {
    setclientId("");
    setClientIdVal("");
    superClients();
    if (props.calledfrom === "dashboard")
      setClassContent("");
  }, []);
 
  const handleInputSearchClick = (e) => {
    e.preventDefault();
    setModalShow(true);
    setSearchDisabled(true);
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
  };
  const nameChangeHandler = (e) => {
    SearchGridInputData[0].Name = e.target.value;
    setCompanyName(SearchGridInputData[0].Name);
  };
  const dateFromHandler = (e) => {
    SearchGridInputData[0].DateFrom = e;
    if (e)
      SearchGridInputData[0].DateFrom = dateFilter(
        SearchGridInputData[0].DateFrom
      );
    setDateFrom(SearchGridInputData[0].DateFrom);
  };
  const dateFilter = (date) => {
    return new Date(date);
  };
  const dateToHandler = (e) => {
    SearchGridInputData[0].DateTo = e;
    if (e)
      SearchGridInputData[0].DateTo = dateFilter(SearchGridInputData[0].DateTo);
    setDateTo(SearchGridInputData[0].DateTo);
  };
  const SearchClickHandler = (e, calledfrom) => {
    
    setLoadingData(true);
    if (!SearchGridInputData[0].scrolled) {
      setSearchGridSuccess(false);
    }
   
    if(calledfrom=="search")
    SearchGridInputData[0].startIndex=1;
    setModalShow(false);
    setModalShow(false);
    setSearchDisabled(false);
    superClientServiceObj
      .getSuperClientList(
      )
      .then((resp) => {
        setLoadingData(false);
        if (resp && resp.data && resp.data.payload) {
          if (resp.data.success) {
            setModalShow(false);
            setSearchDisabled(false);
          }
          if (
            resp.data.payload.length > 0 ||
            searchData.length > 0
          ) {
            setTotalCount(resp.data.payload.length);
            if (SearchGridInputData[0].scrolled) {
              let appendedData = searchData.concat(
                resp.data.payload
              );
              setSearchData(appendedData);
              setDataset(appendedData);
            } else {
              setSearchData(resp.data.payload);
              setDataset(resp.data.payload);
              SearchGridInputData[0].startIndex = 1;
            }
            setSearchGridSuccess(true);
            SearchGridInputData[0].scrolled = false;
            searchTbl.current.onscroll = tblScrollHandler;
            SearchGridInputData[0].isFirstTime = false;
          }
        }
      })
      .catch((error) => {
        setSearchGridSuccess(false);
        setLoadingData(false);
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            SearchClickHandler();
          }
        })();
      });
  };
  const tblScrollHandler = (e) => {
    if (
      !SearchGridInputData[0].scrolled &&
      SearchGridInputData[0].startIndex + 10 <= totalCount
    ) {
      SearchGridInputData[0].scrolled = true;
      SearchGridInputData[0].startIndex =
        SearchGridInputData[0].startIndex + 10;
      SearchClickHandler();
    }
  };
  
  const renderTableHeader = () => {
    let header = Object.values(props.headersdata);
    return header.map((key, index) => {
      return <th key={index}>{key.toUpperCase()}</th>;
    });
  };
  const superClients = () => {

    setSuperClientClassName("nav-link active d-flex align-items-center");
    setSuperClientAreaSelected("true");
    emptyClientId("Active");
    setSearchDisabled(false);
    SearchGridInputData[0].startIndex = 1;
    SearchGridInputData[0].ClientId = "";
    SearchGridInputData[0].Status = "Active";
    SearchGridInputData[0].TokenId = ""
    SearchGridInputData[0].IssuerType = ""
    setIssuerCategory("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    SearchClickHandler();
  };



  const emptyClientId = (status) => {
    setclientId("");
    setClientIdVal("");
    setStatus(status);
    setStatus(status);
  };

  const renderTableData = () => {
    return searchData.map((data, index) => {
      const {
        id,
        name,
        email,
        phoneNumber,
        userId,
      } = data; //destructuring

      return (
        <tr key={id} ref={
          searchData?.length === index + 1 ? lastElementRef : searchTblRef
        }>
            <td style={fontStyle}>
              <span className="td-logo" onClick={() => showSubClients(id,name,email,phoneNumber,userId)}>{name.charAt(0)}</span>
            </td>
          {/* <td style={fontStyle} title={name}>{name?.substr(0, 200) ?? "-"}{name?.length > 200 && ("...")}</td> */}
          <td><span style={{ cursor:'grab',color:'#0d6efd' }}  title={name} onClick={() => showSubClients(id,name,email,phoneNumber,userId)}>
            {name?.substr(0, 200) ?? "-"}{name?.length > 200 && ("...")}</span></td>
          {/* <td style={fontStyle} title={name}>{name?.substr(0, 200) ?? "-"}{name?.length > 200 && ("...")}</td> */}
          {/* <td>
            <DateFormat date={registrationDate} />
          </td> */}
          <td>{email}</td>
          {/* <td>{phoneNumber}</td> */}
          <td>
                <span
                  className="icon-show"
                  onClick={() => editSuperClients(id,name,email,phoneNumber,userId)}
                ></span>
              </td>
              {/* <td>
                <span
                  className="icon-show"
                  onClick={() => showSubClients(id,name,email,phoneNumber,userId)}
                ></span>
              </td> */}
        </tr>
      );
    });
  };
  const HideSearchPopupDiv = () => {
    setModalShow(false);
    setSearchDisabled(false);
    setclientId("");
    setCompanyName("");
  };
  const ClearSearchData = () => {
    setclientId("");
    setCompanyName("");
    dateToHandler("");
    dateFromHandler("");
    setStatus("");
    setCurrency("");
    setIssuerCategory("");
    SearchGridInputData[0].Status = ""
    SearchGridInputData[0].TokenId = ""
    SearchGridInputData[0].IssuerType = ""
    ClearInputData();
  };
  const ClearInputData = () => {
    if (document.getElementById("clientNameDD") !== null)
      document.getElementById("clientNameDD").value = "";
    setClientIdVal("");
    setTimeout(() => {
      [...document.getElementsByClassName("r-input-group")].forEach(
        (element) => {
          element.children[0].value = "";
        }
      );
      document.getElementById("searchBtnClients").click();
    }, 500);
  };
  const ClientNameChangeHandler = (e) => {
    const clientName = e.target.selectedOptions[0].value;
    setClientIdVal(clientName);
  };
  const onChangestatus = (e) => {
    const status = e.target.selectedOptions[0].value;
    if (status != "") {
      setStatus(status);
      SearchGridInputData[0].Status = status;
    }
  };
  const onChangeCurrency = (e) => {
    const currency = e.target.selectedOptions[0].value;
    if (currency != "") {
      setStatus(currency);
      SearchGridInputData[0].TokenId = currency;
    }
  };
  const onChangeMswipeIssuer = e => {
    const name = e.target.selectedOptions[0].value;
    SearchGridInputData[0].IssuerType = name;
    setIssuerCategory(name);
  };
  const showSubClients = (id, userId, clientStatus) => {
    history.push("/superclientdetails", { id, userId, clientStatus });
  };
  const editSuperClients = (id,name,email,phoneNumber,userId) => {
    setEditclient({id:id,name:name,email:email,phoneNumber:phoneNumber,userId:userId})
    setModalShowEditSuperClient(true);
  };

 const refereshSuperClient=()=>{
  superClients();
 }
  return (
    <div className="mt-md-0 mt-4">
      {props.calledfrom !== "dashboard" &&
        <div className="bo-tabs-mbl">
          <ul className="nav nav-tabs" id="myTab2" role="tablist">
            <li className="nav-item" role="presentation">
              <button
                className={SuperClientClassName}
                id="allClients-tab"
                data-bs-toggle="tab"
                data-bs-target="#allClients"
                type="button"
                role="tab"
                onClick={superClients}
                aria-controls="allClients"
                aria-selected={SuperClientAreaSelected}
              >
                <span className="icon-client"></span>Super Clients
              </button>

            </li>
          
            <li className="nav-item ms-auto">
              <div className="mt-16 mt-sm-0 mb-16">
                <ExportToExcel
                  Dataset={dataset}
                  calledfrom="client"
                  Name={"Client"}
                />
              </div>
            </li>


          </ul>
        </div>
      }
      <div className={classContent} id="myTabContent2">
        <div
          className="tab-pane fade show active"
          id="allClients"
          role="tabpanel"
          aria-labelledby="allClients-tab"
        >
          <div className="d-flex align-content-center justify-content-center no-clients-msg d-none">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
             
              <h3>Empty Clients Listing</h3>
              <h6>There is no Client(s) available for now.</h6>
            </div>
          </div>
          <Fragment>
            <form onSubmit={(e) => e.preventDefault()}>
              <div className="row row-search">
                <div className="col-xl-12 col-12">
                  <div className="form-group">
                    <div className="form-controls">
                      <input
                        type="text"
                        className="form-control"
                        placeholder={props.placeholdervalue}
                        onClick={handleInputSearchClick}
                        disabled={SearchInputDisabled}
                      />
                      <span className="icon-search _ico"></span>
                      {/* search box  */}
                      <div
                        className="bo-cardsearch"
                        style={{ display: modalShow ? "" : "none" }}
                      >
                        <div className="row">
                          <div className="col-sm-6">
                            <div className="col-md-4">
                              <img
                                className="cross-icon cross-modal"
                                src={crossImgs}
                                aria-label="Close"
                                onClick={HideSearchPopupDiv}
                                alt=""
                              />
                            </div>
                            <div className=" col-md-12 form-group form-search mr-20">
                              <label>Name</label>
                              <div className="form-controls mr-20">
                                <input
                                  type="text"
                                  value={name}
                                  onChange={nameChangeHandler}
                                  className="form-control"
                                  maxLength={80}
                                />
                              </div>
                            </div>
                          </div>
                         
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date From</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateFrom}
                                  onchangedate={dateFromHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label>Date To</label>
                              <div className="form-controls">
                                <ReactDatePickerHelper
                                  datevalue={dateTo}
                                  onchangedate={dateToHandler}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search mr-20">
                              <label className="mb-24">Client Name</label>
                              <div className="form-controls">
                                <GetAllClients
                                  clientnamechangehandler={
                                    ClientNameChangeHandler
                                  }
                                  searchgriddata={"Y"}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Status</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangestatus}
                                  value={Status}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value="Active">
                                    Active
                                  </option>
                                  <option value="Suspended">
                                    Suspended
                                  </option>
                                  {haspermissions.InternalAccounts && (
                                    <option value="InternalAccounts">
                                      Internal Accounts
                                    </option>
                                  )}

                                  <option value="ExternalAccounts">
                                    External Accounts
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Currency</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangeCurrency}
                                  value={Currency}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value="USD">
                                    USD
                                  </option>
                                  <option value="GBP">
                                    GBP
                                  </option>
                                  <option value="EUR">
                                    EUR
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                          <div className="col-md-6">
                            <div className="form-group form-search">
                              <label>Issuer</label>
                              <div className="form-controls">
                                <select
                                  className="form-control no-icon"
                                  onChange={onChangeMswipeIssuer}
                                  value={IssuerCategory}
                                >
                                  <option value="">
                                    Select
                                  </option>
                                  <option value={"IssuerB"}>
                                    MSW-B
                                  </option>
                                  {/* <option value={"IssuerS"}>
                                    MSW-S
                                  </option>
                                  <option value={"IssuerSP"}>
                                    MSW-SP
                                  </option> */}
                                  <option value={"IssuerG"}>
                                    MSW-G
                                  </option>
                                  <option value={"IssuerB4B"}>
                                    MSW-B4B
                                  </option>
                                  <option value={"IssuerB4Virtual"}>
                                    MSW-B4B-V
                                  </option>
                                  {/* <option value={"IssuerW"}>
                                    WAL
                                  </option> */}
                                  <option value={"IssuerI"}>
                                    MSW-I
                                  </option>
                                  <option value={"IssuerT"}>
                                    MSW-T
                                  </option>
                                  <option value={"IssuerM"}>
                                    MSW-M
                                  </option>
                                </select>
                              </div>
                            </div>
                            <div className="form-controls"></div>
                          </div>
                          
                          <div className="col-md-12">
                            <div className="form-group submit-field form-search">
                              <button
                                id="searchBtnClients"
                                className="btn btn-large mr-19"
                                onClick={()=>(SearchClickHandler("","search"))}
                              >
                                Search
                              </button>
                              <button
                                className="btn btn-outline"
                                onClick={ClearSearchData}
                              >
                                Clear Search
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <span className="help-block d-none"></span>
                  </div>
                </div>
              </div>
            </form>
          </Fragment>
        </div>

        {searchGridSuccess && (
          <div className="table-wrap" ref={searchTbl}>
            <table
              className="table table-borderless bo-table table-cards table-clients"
            >
              <thead>
                <tr>{renderTableHeader()}</tr>
              </thead>
              <tbody>{renderTableData()}</tbody>
            </table>
          </div>
        )}
        {loadingData ? (
          <div className="loading-more">
            <div className="loader">
              <div></div>
            </div>
            <p>loading data</p>
          </div>
        ) : totalCount === 0 ? (
          <div className="d-flex align-content-center justify-content-center  no-clients-msg">
            <div className="text-center">
              <span className="icon-clients-empty">
                <span className="path1"></span><span className="path2"></span>
                <span className="path3"></span><span className="path4"></span>
                <span className="path5"></span><span className="path6"></span>
              </span>
             
              <h3>Empty Clients Listing</h3>
              <h6>There is no Super Client(s) available for now.</h6>
            </div>
          </div>
        ) : (
          ""
        )}
      </div>
      <EditSuperClient
      show={modalShowEditSuperClient}
      onHide={() => setModalShowEditSuperClient(false)}
      editsuperclient={editclient}
      onAddClient={() => {
        setModalShowEditSuperClient(false);
        setModalsuccessfullShow(true);
      }}
      />
       <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>Edit Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <Link
                              to="#"
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                refereshSuperClient();
                              }}
                            >
                              Done
                            </Link>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );

        };
export default SearchGridSuperClient;
