import axios from "axios";
import { Env } from "../enviroment/environment";
import authHeader from "./auth-header";

const API_URL = Env.DepositApiUrl;
const getDepositStatusData = (ClientUuid) => {
  return axios
    .post(
      API_URL + "getdepositstats?ClientUuid=" + ClientUuid,
      {},
      { headers: authHeader() }
    )
    .then((res) => {
      return res;
    });
};
const approverejectdeposit = (Status, DepositUuid, AcceptedAmount,DepositCurrency) => {
  return axios
    .post(
      API_URL +
      "approverejectdeposit",
      {
        Status, DepositUuid, AcceptedAmount,DepositCurrency
      },
      { headers: authHeader() }
    )
    .then((res) => {
      return res;
    });
};
const getDepositCounts = (
  ClientId,
  Status,
  dateFrom,
  dateTo,
  expiryDate,
  issueDate,
  name,
  startIndex,
  tokenId
) => {
  return axios
    .post(
      API_URL + "getdeposits",
      {
        ClientId,
        Status,
        dateFrom,
        dateTo,
        expiryDate,
        issueDate,
        name,
        startIndex,
        tokenId
      },
      { headers: authHeader() }
    )
    .then((resp) => {
      return resp;
    });
};
const depositService = {
  getDepositStatusData,
  approverejectdeposit,
  getDepositCounts
};
export default depositService;
