import React,{ useState, useRef, useEffect, Fragment } from "react";
import ModelPopup from "../../helpers/ModelPopup";
import { useSelector } from "react-redux";
import Input from "react-validation/build/input";
import Form from "react-validation/build/form";
import crossimg from "../../assets/images/icons/cross.svg";
import RefreshToken from "../../helpers/RefreshToken";
import IssueBulkCardService from "../../services/cards.services";
import BoLoader from "../../helpers/BoLoader";
import download from 'downloadjs';
import Dropzone from 'react-dropzone';
import result from "../../middlewareTemp/csd.csv"
import file from '../../middlewareTemp/SampleLoad.csv'
import SearchGridRejectedData from "./SearchGridRejectedData";
import DialoguePopup from "../../helpers/DialoguePopup";
import ExportToExcel from "../superadmin/ExportToExcel";

const BulkLoadCard = props => {
  const [ShowClietErr, setShowClientErr] = useState("d-none");
  const [ExcelErrorModel, setExcelErrorModalShow] = useState(false);
  const [RejectedData, setRejectedData] = useState(); 
  const [modalShowRejectedData, setModalShowRejectedData] = useState();
    //loader
    const [boloader, setBoLoader] = useState(false); 
     //Dialogue Popup
     const [modalShow, setModalShow] = useState(false);
     const [modelhdng, setMdlHdng] = useState("");
     const [modelbdy, setMdlBdy] = useState("");
     const [MdlIcon, setMdlIcon] = useState("");
  const errorAndSuccessModl = (heading, body, errIcon) => {
    setModalShow(true);
    setMdlHdng(heading);
    setMdlBdy(body);
    setMdlIcon(errIcon);
  };
  const renderLoadCard = () => {
//for uploading file
    const UploadFile = (props) => {
      const [file, setFile] = useState(null); // state for storing actual image
      const [previewSrc, setPreviewSrc] = useState(""); // state for storing previewImage
      const [errorMsg, setErrorMsg] = useState(true);
      const [isPreviewAvailable, setIsPreviewAvailable] = useState(false); // state to show preview only for images
      const dropRef = useRef(); // React ref for managing the hover state of droppable area
      const { user: currentUser } = useSelector(state => state.auth);
      const issueBulkCardHandler = (e) => {
        e.preventDefault();
        let clientid=currentUser.user.ClientId;
        if(clientid)
         clientid = parseInt(clientid);
         else
         setShowClientErr("")
        IssueBulkCardService
          .IssueBulkCardHolder(
            clientid,
            file
          )
          .then(
            response => {
              if (response && response.data && response.data.payload) {
               alert("insertion successfully")
              }
            }).catch(err=>{
              console.log(err)
            })
      };

      const handleOnSubmit = async (event) => {
        setExcelErrorModalShow(true);
        event.preventDefault();
      };

      const handleCancelButton = async (event) => {
        onShowModelClearFields()
      };
      
      const onDrop = (files) => {
        const file_data = files[0];
        console.log("🚀 ~ file: BulkIssueCard.js ~ line 74 ~ onDrop ~ file_data", file_data)
        const fileReader = new FileReader();
        let reg = /(.*?)\.(csv|xml)$/;
        if (!file_data.name.match(reg)) {
          setErrorMsg(false)
          return alert('file is not supported')
        }
        const [uploadedFile] = file_data;
        setFile(uploadedFile);
        fileReader.onload = () => {
          setPreviewSrc(fileReader.result);
        };
        setIsPreviewAvailable(uploadedFile.name.match(/\.(csv|xml)$/));  
      };
      return (
        <React.Fragment>
          <Form className="search-form" onSubmit={handleOnSubmit}>
            {errorMsg && <p className="errorMsg">{errorMsg}</p>}
          
            <div className="upload-section">
              <Dropzone onDrop={onDrop}>
                {({ getRootProps, getInputProps }) => (
                  <div {...getRootProps({ className: "drop-zone" })} ref={dropRef}>
                    <input {...getInputProps()} />
                    <p>Drag and drop a file OR click here to select a file</p>
                    {file && (
                      <div>
                        <strong>Selected file:</strong> {file.name}
                      </div>
                    )}
                  </div>
                )}
              </Dropzone>
              { 
                isPreviewAvailable ? (
                  <div className="image-preview">
                    <img className="preview-image" src={previewSrc} alt="Preview" />
                  </div>
                ) :errorMsg===false ?( 
                  <div className="preview-message">
                    <h2>No preview available for this file</h2>
                  </div>
                ):(
                  <div className="preview-message">
                  <h2>please upload file</h2>
                </div>
                )
             }
            </div>
          <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
          <a
            className="btn btn-outline mr-19"
            data-bs-dismiss="modal"
            onClick={handleCancelButton}
          >
            Cancel
          </a>
          <a
            className="btn btn-large"
            onClick={issueBulkCardHandler}
          >
            Submit
          </a>
        </div>
          </Form>
        </React.Fragment>
      );
    };


    const UploadCsvFile=()=>{
      const [fileSize, setFileSize] = useState();
      const [isValidFile, setisValidFile] = useState(true);
      const [fileName, setFileName] = useState("");
      const [fileUrl, setFileUrl] = useState("");
      const [statusState, setStatusState] = useState("");

      const formatBytes = (bytes, decimals = 2) => {
        if (bytes === 0) return "0 Bytes";
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + " " + sizes[i];
      };
  
      const handleCancelButton = async (event) => {
        onShowModelClearFields()
      };
      const { user: currentUser } = useSelector(state => state.auth);

      const onSubmitBulkIssueCard = e => {
        setBoLoader(true);
        const file_data = e.target.files[0];
        const formdata = new FormData();
        const fileReader = new FileReader();
        let reg = /(.*?)\.(csv|xml)$/;
        if (!file_data.name.match(reg)) {
          setisValidFile(false);
          setBoLoader(false);
          return false;
        }
        setFileName(file_data.name);
        var size = formatBytes(file_data.size);
        setFileSize(size);
        formdata.append("File", file_data);
        let name = file_data.name
        setFileUrl({file_data})
        IssueBulkCardService
          .BulkLoadCardHolder(formdata)
          .then(response => {
            setBoLoader(false);
            if(response?.data?.result?.item2.length>0){
              setRejectedData(response?.data?.result?.item2);
              setModalShowRejectedData(true);
            }
            else if(response?.data?.result?.item2.length===0)
            {
              let msgBody = "Data Saved Successfully";
            let errIcon = "icon-success";
            errorAndSuccessModl("Success", msgBody, errIcon);
            }
            let name = response?.data?.result;
            setFileUrl(name);
          })
          .catch(error => {
            setBoLoader(false);
            let msgBody = error?.response?.data?.message
            ? error?.response?.data?.message
            : "Something Went Wrong";
            let errIcon = "icon-error";
            errorAndSuccessModl("Error", msgBody, errIcon);
          });
      };

      const [file, setFile] = useState();

      const fileReader = new FileReader();
  
      const handleOnChange = (e) => {
          setFile(e.target.files[0]);
      };
  
      const handleOnSubmit1 = (e) => {
          e.preventDefault();
          let clientid=statusState;
          if(clientid)
           clientid = parseInt(clientid);
          if (file) {
              fileReader.onload = function (event) {
                  const csvOutput = event.target.result;
                  let clientid=statusState;
                  if(clientid)
                  clientid = parseInt(clientid);
                  IssueBulkCardService
                  .IssueBulkCardHolder(
                    clientid,
                    csvOutput
                  )
                  .then(
                    response => {
                      if (response && response.data && response.data.payload) {
                       alert("insertion successfully")
                      }
                    }).catch(err=>{
                      console.log(err)
                    })
                        }
              fileReader.readAsText(file);
          }
          else(
            console.log('no file here')
          )
      };

      const uploadFileAddClient = e => {
        document.getElementById("AddClientFileUpload").click();
      };

      const handleOnSubmit = async (event) => {
        event.preventDefault();
      };
      
      const issueBulkCardHandler = (e) => {
        e.preventDefault();
        console.log('jjjjjjjj', fileUrl)
        let clientid=statusState;
       
        IssueBulkCardService
          .IssueBulkCardHolder(
            fileUrl,
            clientid
          )
          .then(
            response => {
              if (response && response.data && response.data.payload) {
               alert("insertion successfully")
              }
            }).catch(err=>{
              console.log(err)
            })
      };

      const onCrossImg = () => {
        setFileName("");
        setisValidFile(true);
      };
      const ClientNameChangeHandler = (e) => {
         setStatusState(e.target.value)
      };
    
      const requiredClient = value => {
        if (!value) {
          document.getElementById("id_client").classList.add("has_error");
          setShowClientErr("");
          return false;
        } else {
          document.getElementById("id_client").classList.remove("has_error");
          setShowClientErr("d-none");
          return true;
        }
      };

      return(<>
        <Form className="search-form" onSubmit={handleOnSubmit}>
        <DownloadFile/>
        {fileName === "" ? (
          <div className="col-md-6 ">
            <div className="form-group">
              <label>Attach   CSV File</label>
              <div className="form-controls">
                <div>
                  <Input
                    type="file"
                    hidden
                    id="AddClientFileUpload"
                    className="form-control"
                    accept=".csv,.xml,.json"
                    name="logo"
                    onChange={onSubmitBulkIssueCard}
                  />
                  <h5
                    className="form-control file-control"
                    onClick={uploadFileAddClient}
                  >
                    <label>Drag files here to upload or </label> Browse
                    file
                  </h5>
                  {!isValidFile && (
                    <span className="help-block has_error">
                      Invalid file format
                    </span>
                  )}
                </div>
                <span className="icon-upload-file _ico">
                  <span className="path1"></span>
                  <span className="path2"></span>
                </span>
              </div>
              {fileName !== "" && (
                <label className="mt-3 mb-0">{fileName}</label>
              )}
              <span className="fs-12 clr-submarine mt-2 d-block">
                Supported file types XML/CSV file.
              </span>
            </div>
          </div>
        ) : (
          <div className="col-md-6">
                    <div className="form-group">
                      <div className="bo-file d-flex">
                        <div className="file-name">
                          <span className="icon-upload-file _ico">
                            <span className="path1"></span>
                            <span className="path2"></span>
                          </span>
                          {/* <img src={fileuploadimg} alt="" /> */}
                          <h5>
                            <span>{fileName}</span>
                          </h5>
                        </div>
                        <div className="ml-auto file-size" onClick={onCrossImg}>
                          <span>{fileSize}</span>
                          <img
                            className="cross-icon"
                            src={crossimg}
                            onClick={onCrossImg}
                            alt=""
                          />
                        </div>
                      </div>
                    </div>
                  </div>
        )}
        </Form>
        </>)
    };

    //for downloading file 
    const DownloadFile = (props) => {
    // const [filesList, setFilesList] = useState([]);
    const [errorMsg, setErrorMsg] = useState('');
    const filesList = [{
    id:1, title:"imhhjjk", description:"kkkkkkkkkkk", file_path:result, file_mimetype:'pdf'
    }]
    const downloadFile = async (file) => {
        try {
        setErrorMsg('');
        return download(file);
        } catch (error) {
        if (error.response && error.response.status === 400) {
            setErrorMsg('Error while downloading file. Try again later');
        }
        }
    };
    return (
        <div className="files-container">
        {errorMsg && <p className="errorMsg">{errorMsg}</p>}
                    <tr >
                    <td>
                        <a
                        onClick={() =>
                            downloadFile(file)
                        }
                        style={{ textAlign: "center" }}
                        >
                        Click To Download Sample CSV File
                        </a>
                    </td>
                    </tr>
        </div>
    );
    };
    function App() {
      const [file, setFile] = useState();
      const [array, setArray] = useState([]);
      const fileReader = new FileReader();
      const { user: currentUser } = useSelector(state => state.auth);
      const handleOnChange = (e) => {
        const form_data = e.target.files[0]
        const fromData = new FormData
        fromData.append('file', form_data)
        let reg = /(.*?)\.(csv|xml)$/;
        if (e.target.files[0].name.match(reg)) {
          setFile(form_data);
          return true
        }
        else{
          alert('file not supported')
          return false
        }

      };

      const handleCancelButton = async (event) => {
        onShowModelClearFields()
      };

      const issueBulkCardHandler = (e) => {
        debugger
        e.preventDefault();
        console.log('jjjjjjjj', file)
        let clientid = currentUser?.user?.ClientId
        console.log("🚀 ~ file: BulkIssueCard.js ~ line 522 ~ issueBulkCardHandler ~ clientid", clientid)
        if(clientid)
         clientid = parseInt(clientid);
        IssueBulkCardService
          .IssueBulkCardHolder(
            file,
            clientid,
          )
          .then(
            response => {
              if (response && response.data && response.data.payload) {
               alert("insertion successfully")
              }
            }).catch(err=>{
              console.log(err)
            })
      };
    
      const csvFileToArray = string => {
        const csvHeader = string.slice(0, string.indexOf("\n")).split(",");
        const csvRows = string.slice(string.indexOf("\n") + 1).split("\n");
        const array = csvRows.map(i => {
          const values = i.split(",");
          const obj = csvHeader.reduce((object, header, index) => {
            object[header] = values[index];
            return object;
          }, {});
          return obj;
        });
        setArray(array);
      };
      const handleOnSubmit = (e) => {
        e.preventDefault();
    
        if (file) {
          fileReader.onload = function (event) {
            const text = event.target.result;
            csvFileToArray(text);
          };
    
          fileReader.readAsText(file);
        }
      };
    
      const headerKeys = Object.keys(Object.assign({}, ...array));
    
      return (
        <div style={{ textAlign: "left" }}>
          <h1>Issue Bulk Card </h1>
          <DownloadFile/>
          <form>
            <input
              type={"file"}
              id={"csvFileInput"}
              accept={".csv"}
              onChange={handleOnChange}
            />
    
            <button
              onClick={(e) => {
                handleOnSubmit(e);
              }}
            >
              View File
            </button>
            <div className="form-group submit-field d-flex justify-content-end mt-20 mb-0">
          <a
            className="btn btn-outline mr-19"
            data-bs-dismiss="modal"
            onClick={handleCancelButton}
          >
            Cancel
          </a>
          <a
            className="btn btn-large"
            onClick={issueBulkCardHandler}
          >
            Submit
          </a>
        </div>
          </form>
    
          <br />
    
          <table>
            <thead>
              <tr key={"header"}>
                {headerKeys.map((key) => (
                  <th>{key}</th>
                ))}
              </tr>
            </thead>
    
            <tbody>
              {array.map((item) => (
                <tr key={item.id}>
                  {Object.values(item).map((val) => (
                    <td>{val}</td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      );
    }
    return (
        <>
      <UploadCsvFile/>
      </>
    );
  };

  const onShowModelClearFields = () => {
    if (props.calledfrom !== "cardholderdetails") {
   
    props.onHide();
  }}
  return (
    <Fragment>
       <BoLoader loading={boloader} />
       <DialoguePopup
        show={modalShow}
        onHide={() => setModalShow(false)}
        modelheading={modelhdng}
        modelbody={modelbdy}
        modelicon={MdlIcon}
      />
      <ModelPopup
        key={21}
        show={props.show}
        onHide={() => onShowModelClearFields()}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
        backdrop="static"
        keyboard={false}
        modelbody={renderLoadCard()}
      />
       <ModelPopup
          show={ExcelErrorModel}
          onHide={() => setExcelErrorModalShow(false)}
          modelheading={""}
          showheader={"false"}
          className="smallPopup issueCardSmall"
          dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
          backdrop="static"
          keyboard={false}
          modelbody={[
            <Fragment>
              <div className="row">
                <div className="col-md-12">
                  <h2>There are some errors in the Document</h2>
                  <div className="mt-16">
                    <div>
                      <form onSubmit={(e) => e.preventDefault()}>
                        <div className="row">
                          <div className="col-md-12">
                            <p>Download The Excel </p>
                          </div>
                          <div className="col-md-12">
                            <div className="form-group submit-field d-flex justify-content-between mt-20 mb-0">
                              {/* <button
                                className="btn btn-outline"
                                onClick="openModalBulkIssue()"
                              >
                                Bulk Issue
                              </button> */}
                              <button
                                className="btn btn-large"
                                // onClick={IssueCardPopupHandler}
                              >
                                Export To Excel
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Fragment>,
          ]}
        />
         <ModelPopup
            show={modalShowRejectedData}
            key={22}
            onHide={() => {setModalShowRejectedData(false)}}
            modelheading={""}
            showheader={"false"}
            className="smallPopup issueCardSmall"
            dialogClassName="modal-dialog modal-dialog-centered mwidth-76"
            modelbody={[
              <Fragment>
                <div className="nav-item ms-auto" role="presentation">
                            <ExportToExcel
                              Dataset={RejectedData}
                              Name="BulkLoads"
                              calledfrom="bulkload"
                            />
                        </div>
                <SearchGridRejectedData
                  methodname="notes"
                  placeholdervalue="Search"
                  headersdata={[
                    "AMOUNT",
                    "CURRENCY",
                    "TOKENID",
                    "ERROR MESSAGE",
                  ]}
                  screenname="changepin"
                  calledfrom="bulkload"
                  data={RejectedData}
                />
              </Fragment>,
            ]}
          />
    </Fragment>
  );
};

export default BulkLoadCard;
