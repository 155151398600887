import { useState, useEffect, Fragment } from "react";
// import cardArrow from "../../assets/images/icons/bo-arrow.svg";
// import addclienticon from "../../assets/images/icons/add-client.svg";
import DonutChart from "../../helpers/DonutChart";
import AddSuperAdminUser from "./AddSuperAdminUser";
import { Env } from "../../enviroment/environment";
import clientmanagmentObj from "../../services/client-managment.service";
import BoLoader from "../../helpers/BoLoader";
import RefreshToken from "../../helpers/RefreshToken";
import { Link } from "react-router-dom";
import { history } from "../../helpers/history";
import ModelPopup from "../../helpers/ModelPopup";
import SearchGridSuperAdminRoles from "./SearchGridSuperAdminRoles";
import roleManagmentObj from "../../services/role-managment.service";
//import TransferClient from "./TransferClient";

const API_URL = Env.ClientAdmin;

const RoleManagment = (props) => {
  const [confirmmodalShow, setCnfrmModalShow] = useState(false);
  const [Content, setContent] = useState();
  const [DataSuccessful, setDataSuccessful] = useState(true);
  const [boloader, setBoLoader] = useState(false);
  const [donutChart, setDonutChart] = useState();
  const [modalsuccessfullShow, setModalsuccessfullShow] = useState(false);
  const [dynamicClass, setDynamicClass] = useState("col-xl-9 col-lg-12 bo-main-content");
  const [clientId, setClientId] = useState(1);
  const [userId, setuserId] = useState();

  useEffect(() => {
    if(props?.calledfrom==="client"){
      setDynamicClass("")
      setTimeout(() => {
        setClientId(props.clientId);
        setuserId(props.userId);
      }, 2000);
    }
  }, []);
  useEffect(() => {
    getCountDashboard();
  }, []);

  const getCountDashboard = () => {
    setBoLoader(true);
    setDataSuccessful(false);
    roleManagmentObj.getsubadminstats(props?.clientId?props?.clientId:1).then(
      response => {
        setContent(response.data.payload.result);
        const lst_donutchart = {
          Active: response.data.payload.result.activeUsers,
          Suspended: response.data.payload.result.inActiveUsers,
        };
        setDonutChart(lst_donutchart);
        setDataSuccessful(true);
        setBoLoader(false);
      },
      error => {
        (async () => {
          let resp = await RefreshToken(
            error && error.response && error.response.status
          );
          if (resp) {
            window.location.reload();
            setBoLoader(false);
          } else {
            setBoLoader(false);
          }
        })();
      }
    );
  };
  const goBack = () => {
    getCountDashboard();
  };
  return (
    <div className={dynamicClass}>
      <BoLoader loading={boloader} />
      {props?.clientStatus==="Suspended" ?(<></>):(
<>
{
        props.calledfrom ==="client" ?(
          <h3>Client User Management</h3>
        ):(
          <h3>Role Management</h3>
        )
      }
</>
      )}
     
     
      <div className="row mt-20">
        {
          props?.clientStatus==="Suspended" ?(<></>):(
            <div
            className="col-sm-4 col-md-3 col-xl-2"
            onClick={() => setCnfrmModalShow(true)}
          >
            <div className="col-stats col-stats-action mb-40 col-stats-mbl">
              <div data-bs-target="\clients" data-bs-toggle="modal">
                <span className="icon-add-client">
                  <span className="path1"></span>
                  <span className="path2"></span>
                  <span className="path3"></span>
                  <span className="path4"></span>
                  <span className="path5"></span>
                  <span className="path6"></span>
                </span>
               
                <br />
                <a  className="pt-3">
                  Add New User
                  <span className="icon-bo-arrow"></span>
                 
                </a>
              </div>
            </div>
          </div>
          )
        }
      </div>
      {DataSuccessful && (
        <Fragment>
          <SearchGridSuperAdminRoles
            methodname="getclients"
            placeholdervalue="Search Users"
            headersdata={[
              "First Name",
              "Last Name",
              "Email",
              "STATUS",
              "Phone Number",
              "Date of Birth",
              "VIEW",
            ]}
            apiurl={API_URL}
            screenname="clients"
            clientId={props.clientId}
            calledfrom={props?.calledfrom}
            clientStatus={props?.clientStatus}
          />
        </Fragment>
      )}
      {
        props?.calledfrom==="client" ?( <AddSuperAdminUser
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        onAddClient={() => {
          setCnfrmModalShow(false);
          setModalsuccessfullShow(true);
        }}
        userId={props?.userId}
        clientId={props?.clientId}
        calledfrom={props?.calledfrom}
      />):( <AddSuperAdminUser
        show={confirmmodalShow}
        onHide={() => setCnfrmModalShow(false)}
        onAddClient={() => {
          setCnfrmModalShow(false);
          setModalsuccessfullShow(true);
        }}
        userId={userId}
        clientId={clientId}
        calledfrom={props?.calledfrom}
      />)
      }
     
      
      {/* <TransferClient
        show={modalShowTransferClient}
        onHide={() => setModalShowTransferClient(false)}
      /> */}
      <ModelPopup
        key={5}
        show={modalsuccessfullShow}
        onHide={() => setModalsuccessfullShow(false)}
        modelheading={""}
        showheader={"false"}
        className="smallPopup issueCardSmall"
        dialogClassName="modal-dialog modal-dialog-centered mwidth-42"
        backdrop="static"
        keyboard={false}
        modelbody={[
          <Fragment>
            <div className="row">
              <div className="col-md-12">
                <div className="icon-alert icon-alert-popup">
                  <span className="icon-successful">
                    <span className="path1"></span>
                    <span className="path2"></span>
                  </span>
                </div>
                <h2>Successful</h2>
                <div className="mt-16">
                  <div>
                    <form>
                      <div className="row">
                        <div className="col-md-12">
                          <p>User Added Successfully</p>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group submit-field mt-40 mb-0">
                            <a
                              className="btn btn-large w-100"
                              onClick={() => {
                                setModalsuccessfullShow(false);
                                goBack();
                              }}
                            >
                              Done
                            </a>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Fragment>,
        ]}
      />
    </div>
  );
};

export default RoleManagment;
